import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import { IoLogoOctocat, IoMdPlayCircle } from 'react-icons/io';
import { Helmet } from 'react-helmet-async';
import {
	fontSize,
	fontWeight,
	palette,
	defaultFlexCenter,
	defaultInput,
	signinUpBtn,
} from '../../../modules/defines/style';
import DemoCategory from '../DemoCategory';
import DemoPopup from '../DemoPopup';
import ConferenceDemo from './ConferenceDemo';
import Popup from '../../ui/Popup';
import { EMAIL_REGEX } from '../../utill/regex';
import DemoLoading from '../DemoLoading';
import myAxios from '../../../modules/api/myAxios';
import CallPopup from '../../main/CallPopup';

export default function Video({ type }) {
	const { t } = useTranslation();
	const location = useLocation();
	const [videoCallOpen, setVideoCallOpen] = useState(false);
	const [videoCallReactOpen, setVideoCallReactOpen] = useState(false);
	const [videoConferenceOpen, setVideoConferenceOpen] = useState(false);
	const [videoConferenceReactOpen, setVideoConferenceReactOpen] =
		useState(false);
	const [testKeyOpen, setTestKeyOpen] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [isComplete, setIsComplete] = useState(false);
	const [formData, setFormData] = useState({ email: '' });
	const [errorMsg, setErrorMsg] = useState('');
	const onOffVideoCall = () => {
		setVideoCallOpen(!videoCallOpen);
	};
	const onOffVideoConference = () => {
		setVideoConferenceOpen(!videoConferenceOpen);
	};
	const onOffVideoCallReact = () => {
		setVideoCallReactOpen(!videoCallReactOpen);
	};
	const onOffVideoConferenceReact = () => {
		setVideoConferenceReactOpen(!videoConferenceReactOpen);
	};
	const handleChange = e => {
		setFormData(prev => ({ ...prev, [e.target.name]: e.target.value }));
	};
	const onOffTestKey = () => {
		setTestKeyOpen(!testKeyOpen);
	};
	const getTestKey = async () => {
		setErrorMsg('');
		const result = EMAIL_REGEX.test(formData.email);
		if (result === false) return setErrorMsg('* 유효하지 않은 이메일입니다.');
		try {
			setIsLoading(true);
			const response = await myAxios.post('/users/temp-service', formData);
			if (response.data.result === 'success') {
				setIsLoading(false);
				setIsComplete(true);
			}
		} catch (error) {
			setIsLoading(false);
			if (error.response.data.status === 4037)
				return setErrorMsg('* 이미 발급받은 테스트키가 존재합니다.');
			if (error.response.data.statusCode === 400)
				return setErrorMsg('* 유효하지 않은 이메일입니다.');
		}
	};
	return (
		<>
			<StyledTitle>
				{type === 'old' ? (
					<Helmet>
						<meta name="robots" content="noindex,nofollow" />
					</Helmet>
				) : (
					<Helmet>
						<title>{t('helmet.demo.video.title')}</title>
						<meta
							name="keywords"
							content="데모,SDK,OmnitalkSDK,API,javascript,typescript,unity,unreal,ios,android,js,ts,asset,flutter,jsx,react,api-reference,WebRTC,WebRTCdemo,audio,video,message,telephone,chat"
						/>
						<meta name="description" content={t('helmet.demo.video.desc')} />
						<meta property="og:title" content={t('helmet.demo.video.title')} />
						<meta
							property="og:description"
							content={t('helmet.demo.video.desc')}
						/>
						<meta property="og:url" content="https://omnitalk.io/demo/video" />
						<link rel="canonical" href="https://omnitalk.io/demo/video/" />
						<meta
							property="twitter:title"
							content={t('helmet.demo.video.title')}
						/>
						<meta
							property="twitter:description"
							content={t('helmet.demo.video.desc')}
						/>
						<meta
							property="twitter:card"
							content={t('helmet.demo.video.card')}
						/>
						<meta name="robots" content="index" />
						<meta name="title" content={t('helmet.demo.video.title')}></meta>
						<meta name="author" content="omnitalk"></meta>
						<meta property="og:type" content="website" />
					</Helmet>
				)}

				<section>
					<h1>{type === 'old' && <>Legacy </>}Video Demo </h1>

					<span>
						{t('demo.video.testDescOne')}
						<br />
						{t('demo.video.testDescTwo')}
						<br />
						{t('demo.video.testDescThree')}
					</span>
					<div className="btn-wrap">
						<button
							className="button"
							type="button"
							onClick={() => onOffTestKey()}
						>
							{t('demo.button.key')}
						</button>
					</div>
				</section>
			</StyledTitle>

			<DemoCategory />
			{location.pathname === '/demo/video' ||
			location.pathname === '/demo/legacy/video' ? (
				<>
					{/* -------------------1. video Call */}
					<StyledAudioWrap>
						<StyledAudioImg>
							<div className="demo-btn">
								<button type="button" onClick={onOffVideoCall}>
									{t('demo.button.test')}
								</button>
							</div>
							<h3>Video Call</h3>
							<div className="video-img-wrap call">
								<img
									alt="video-call"
									src="https://user-images.githubusercontent.com/125844802/228786289-9db30592-f80d-4f79-9026-04877670ca11.png"
								/>
							</div>
						</StyledAudioImg>
						<StyledAudioDesc>
							<h4>Video Call</h4>
							<div className="description-wrap">
								<span>{t('demo.video.jsCallOne')}</span>
								<span>{t('demo.video.jsCallTwo')}</span>
								<span>{t('demo.video.jsCallThree')}</span>
								{type !== 'old' && (
									<span>
										<span>{t('demo.docslinkIntro')}</span>
										<a
											className="text-main-50 hover:text-main-100 text-base font-medium inline-block"
											href="https://docs.omnitalk.io/typescript/developers-guide/video-call"
											target="_blank"
											rel="noreferrer"
										>
											{t('demo.docslink')}
										</a>
										<span>{t('demo.docslinkEnd')}</span>
									</span>
								)}
							</div>
							<div className="feature-wrap">
								<ul>
									<li>Add-On: basic, mic&cam select</li>
									<li>Feature: html5/css/javascript, bootstrap, jquery</li>
									<li>Version: 1.0</li>
									<li>Creator: jason@omnistory.net</li>
								</ul>
							</div>
							<div className="btn-wrap">
								<div className="btn-git">
									<button type="button" onClick={() => onOffVideoCall()}>
										<IoMdPlayCircle size="18" className="btn-git__icon" />
										{t('demo.button.test')}
									</button>
								</div>
								<div className="btn-git">
									<button
										type="button"
										style={{ marginLeft: '14px' }}
										onClick={() =>
											type === 'old'
												? window.open(
														'https://github.com/omnistory-labs/demo/tree/main/video/call/1',
												  )
												: window.open(
														'https://github.com/omnistory-labs/omnitalk.sdk/tree/demo-js',
												  )
										}
									>
										<IoLogoOctocat size="18" className="btn-git__icon" />
										{t('demo.button.git')}
									</button>
								</div>
							</div>
						</StyledAudioDesc>
					</StyledAudioWrap>
					{/* -------------------2. video Conference */}
					<StyledAudioWrap>
						<StyledAudioImg>
							<div className="demo-btn">
								<button type="button" onClick={() => onOffVideoConference()}>
									{t('demo.button.test')}
								</button>
							</div>
							<h3>Video Conference</h3>

							<div className="video-img-wrap conf">
								<img
									className="demo-image__telephone"
									alt="video-conference"
									src="https://user-images.githubusercontent.com/125844802/228786298-c135d475-28df-48e1-9f82-21b64d48da45.png"
								/>
							</div>
						</StyledAudioImg>
						<StyledAudioDesc>
							<h4>Video Conference</h4>

							<div className="description-wrap">
								<span>{t('demo.video.jsConferenceOne')}</span>
								<span>{t('demo.video.jsConferenceTwo')}</span>
								<span>{t('demo.video.jsConferenceThree')}</span>
								<span>{t('demo.video.jsConferenceFour')}</span>
								{type !== 'old' && (
									<span>
										<span>{t('demo.docslinkIntro')}</span>
										<a
											className="text-main-50 hover:text-main-100 text-base font-medium inline-block"
											href="https://docs.omnitalk.io/typescript/developers-guide/video-room"
											target="_blank"
											rel="noreferrer"
										>
											{t('demo.docslink')}
										</a>
										<span>{t('demo.docslinkEnd')}</span>
									</span>
								)}
							</div>
							<div className="feature-wrap">
								<ul>
									<li>Add-On: basic, mic&cam select</li>
									<li>Feature: html5/css/javascript, bootstrap, jquery</li>
									<li>Version: 1.0</li>
									<li>Creator: jason@omnistory.net</li>
								</ul>
							</div>
							<div className="btn-wrap">
								<div className="btn-git">
									<button type="button" onClick={() => onOffVideoConference()}>
										<IoMdPlayCircle size="18" className="btn-git__icon" />
										{t('demo.button.test')}
									</button>
								</div>
								<div className="btn-git">
									<button
										type="button"
										style={{ marginLeft: '14px' }}
										onClick={() =>
											type === 'old'
												? window.open(
														'https://github.com/omnistory-labs/demo/tree/main/video/conf/1',
												  )
												: window.open(
														'https://github.com/omnistory-labs/omnitalk.sdk/tree/demo-js',
												  )
										}
									>
										<IoLogoOctocat size="18" className="btn-git__icon" />
										{t('demo.button.git')}
									</button>
								</div>
							</div>
						</StyledAudioDesc>
					</StyledAudioWrap>
					{/* -------------------3. video Call  reacts----------------------------------- */}
					<StyledAudioWrap>
						<StyledAudioImg>
							<div className="demo-btn">
								<button type="button" onClick={onOffVideoCallReact}>
									{t('demo.button.test')}
								</button>
							</div>
							<h3>Video Call For React</h3>
							<div className="video-img-wrap react">
								<img
									alt="video-call"
									src="https://user-images.githubusercontent.com/125844802/230569493-dd2312ea-63fe-46d2-8469-597ee5466588.png"
								/>
							</div>
						</StyledAudioImg>
						<StyledAudioDesc>
							<h4>Video Call For React</h4>
							<div className="description-wrap">
								<span>{t('demo.video.reactCallOne')}</span>
								<span>{t('demo.video.reactCallTwo')}</span>
								<span>{t('demo.video.reactCallThree')}</span>
								{type !== 'old' && (
									<span>
										<span>{t('demo.docslinkIntro')}</span>
										<a
											className="text-main-50 hover:text-main-100 text-base font-medium inline-block"
											href="https://docs.omnitalk.io/typescript/developers-guide/video-call"
											target="_blank"
											rel="noreferrer"
										>
											{t('demo.docslink')}
										</a>
										<span>{t('demo.docslinkEnd')}</span>
									</span>
								)}
							</div>
							<div className="feature-wrap">
								<ul>
									<li>Add-On: basic, mic&cam select,mute</li>
									<li>Feature: React</li>
									<li>Version: 1.0</li>
									<li>Creator: luna@omnistory.net</li>
								</ul>
							</div>
							<div className="btn-wrap">
								<div className="btn-git">
									<button type="button" onClick={() => onOffVideoCallReact()}>
										<IoMdPlayCircle size="18" className="btn-git__icon" />
										{t('demo.button.test')}
									</button>
								</div>
								<div className="btn-git">
									<button
										type="button"
										style={{ marginLeft: '14px' }}
										onClick={() =>
											type === 'old'
												? window.open(
														'https://github.com/omnistory-labs/demo/tree/main/video/call/2',
												  )
												: window.open(
														'https://github.com/omnistory-labs/omnitalk.sdk/tree/demo-react',
												  )
										}
									>
										<IoLogoOctocat size="18" className="btn-git__icon" />
										{t('demo.button.git')}
									</button>
								</div>
							</div>
						</StyledAudioDesc>
					</StyledAudioWrap>
					{/* -------------------4. video Conference react -----------------*/}
					<StyledAudioWrap>
						<StyledAudioImg>
							<div className="demo-btn">
								<button
									type="button"
									onClick={() => onOffVideoConferenceReact()}
								>
									{t('demo.button.test')}
								</button>
							</div>
							<h3>Video Conference For React</h3>
							<div className="video-img-wrap react">
								<img
									className="demo-image__telephone"
									alt="video-conference"
									src="https://user-images.githubusercontent.com/125844802/230567748-a3c1dcab-ff66-44d7-89d9-f2a0623a25f0.png"
								/>
							</div>
						</StyledAudioImg>
						<StyledAudioDesc>
							<h4>Video Conference For React</h4>
							<div className="description-wrap">
								<span>{t('demo.video.reactConferenceOne')}</span>
								<span>{t('demo.video.reactConferenceTwo')}</span>
								<span>{t('demo.video.reactConferenceThree')}</span>
								<span>{t('demo.video.reactConferenceFour')}</span>
								{type !== 'old' && (
									<span>
										<span>{t('demo.docslinkIntro')}</span>
										<a
											className="text-main-50 hover:text-main-100 text-base font-medium inline-block"
											href="https://docs.omnitalk.io/typescript/developers-guide/video-room"
											target="_blank"
											rel="noreferrer"
										>
											{t('demo.docslink')}
										</a>
										<span>{t('demo.docslinkEnd')}</span>
									</span>
								)}
							</div>
							<div className="feature-wrap">
								<ul>
									<li>Add-On: basic, cam select</li>
									<li>Feature: React</li>
									<li>Version: 1.0</li>
									<li>Creator: luna@omnistory.net</li>
								</ul>
							</div>
							<div className="btn-wrap">
								<div className="btn-git">
									<button
										type="button"
										onClick={() => onOffVideoConferenceReact()}
									>
										<IoMdPlayCircle size="18" className="btn-git__icon" />
										{t('demo.button.test')}
									</button>
								</div>
								<div className="btn-git">
									<button
										type="button"
										style={{ marginLeft: '14px' }}
										onClick={() =>
											type === 'old'
												? window.open(
														'https://github.com/omnistory-labs/demo/tree/main/video/conf/2',
												  )
												: window.open(
														'https://github.com/omnistory-labs/omnitalk.sdk/tree/demo-react',
												  )
										}
									>
										<IoLogoOctocat size="18" className="btn-git__icon" />
										{t('demo.button.git')}
									</button>
								</div>
							</div>
						</StyledAudioDesc>
					</StyledAudioWrap>
					{testKeyOpen && (
						<>
							<Popup onClose={onOffTestKey}>
								{isComplete ? (
									<>
										<StyledCompleted>
											<h2>Completed!</h2>
											<div className="img-wrap">
												<img
													src="https://user-images.githubusercontent.com/125844802/228473825-93294135-6080-425c-b926-ef909805ced5.png"
													alt="completed"
												/>
											</div>
											<div className="txt-wrap">
												<span>{t('demo.complete.textOne')}</span>
												<span>{t('demo.complete.textTwo')}</span>
											</div>
										</StyledCompleted>
									</>
								) : (
									<StyledInCompleted>
										<div className="container">
											<h2>{t('demo.button.key')}</h2>
											<div className="input-wrap">
												<input
													placeholder="email"
													onChange={handleChange}
													name="email"
												/>
												<button type="button" onClick={() => getTestKey()}>
													{t('demo.incomplete.button')}
												</button>
											</div>
											<div className="info-wrap">
												<span className="error">
													{errorMsg}
													<br />
												</span>
												<span className="notice">
													{t('demo.incomplete.textOne')}
													<br /> {t('demo.incomplete.textTwo')}
												</span>
											</div>
										</div>
									</StyledInCompleted>
								)}
								{isLoading && (
									<>
										<DemoLoading />
									</>
								)}
							</Popup>
						</>
					)}
					{videoCallOpen && (
						<DemoPopup onClose={onOffVideoCall} size="md">
							<iframe
								style={{
									border: 'none',
									width: '100%',
									height: '500px',
									overflowY: 'auto',
								}}
								id="videoCall"
								scrolling="no"
								allow="camera *;microphone * display-capture *;"
								title="video-call"
								src={`${
									type === 'old'
										? 'https://omnitalk.io/testbed/old/video/call/1'
										: 'https://omnitalk.io/testbed/video/call/1'
								}`}
							/>
						</DemoPopup>
					)}
					{videoConferenceOpen && (
						<ConferenceDemo onClose={onOffVideoConference} size="lg">
							<iframe
								style={{
									border: 'none',
									width: '100%',
									height: '400px',
									overflowY: 'auto',
								}}
								id="videoConference"
								// scrolling="yes"
								allow="camera *;microphone * display-capture *;"
								title="video-conference"
								src={`${
									type === 'old'
										? 'https://omnitalk.io/testbed/old/video/conf/1'
										: 'https://omnitalk.io/testbed/video/conf/1'
								}`}
							/>
						</ConferenceDemo>
					)}
					{videoCallReactOpen && (
						<DemoPopup onClose={onOffVideoCallReact} size="lg">
							<iframe
								style={{
									border: 'none',
									width: '100%',
									height: '880px',
									overflowY: 'auto',
								}}
								id="videoCall"
								scrolling="no"
								allow="camera *;microphone * display-capture *;"
								title="video-call"
								src={`${
									type === 'old'
										? 'https://omnitalk.io/testbed/old/video/call/2'
										: 'https://omnitalk.io/testbed/video/call/2'
								}`}
							/>
						</DemoPopup>
					)}
					{videoConferenceReactOpen && (
						<ConferenceDemo onClose={onOffVideoConferenceReact} size="lg">
							<iframe
								style={{
									border: 'none',
									width: '100%',
									height: '880px',
									overflowY: 'auto',
								}}
								id="videoConference"
								// scrolling="yes"
								allow="camera *;microphone * display-capture *;"
								title="video-conference"
								src={`${
									type === 'old'
										? 'https://omnitalk.io/testbed/old/video/conf/2'
										: 'https://omnitalk.io/testbed/video/conf/2'
								}`}
							/>
						</ConferenceDemo>
					)}
				</>
			) : null}
			<CallPopup></CallPopup>
		</>
	);
}
const StyledTitle = styled.div`
padding-top: 120px;
margin: 0 auto;
text-align: center;
max-width: 1200px;
h1{
  font-weight: 600;
}
span{
    color: #545454;
    margin-bottom: 90px;
    line-height: 26px;
}
.btn-wrap{
.button {
  margin-top: 20px;
  border-radius: 5px;
display: inline-block;
width: 200px;
height: 54px;
text-align: center;
text-decoration: none;
line-height: 54px;
outline: none;
background-color: ${palette.main.default};
border: 2px solid ${palette.main.default};
color: #fff;
line-height: 50px;
}
.button::before,
.button::after {
position: absolute;
z-index: -1;
display: block;
content: '';
}
.button:hover {
background-color: #fff;
border-color: ${palette.main.default};
color: ${palette.main.default};
}
.button,
.button::before,
.button::after {
-webkit-box-sizing: border-box;
-moz-box-sizing: border-box;
box-sizing: border-box;
-webkit-transition: all .3s;
transition: all .3s;
}
}
@media screen and (max-width: 640px) and (min-width: 230px){
    h1{
      font-size: 24px;
    }
  }

`;
const StyledAudioWrap = styled.div`
display: flex;
margin: 0 auto;
max-width: 1000px;
justify-content: space-around;
margin-bottom: 105px;
@media screen and (max-width: 980px) and (min-width: 641px){
    padding: 0 3%;
}
@media screen and (max-width: 640px) and (min-width: 230px){
  padding: 0 10%;
}
@media screen and (max-width: 800px) and (min-width: 641px){
  justify-content: space-evenly;
}
@media screen and (max-width: 640px) and (min-width: 230px){
  flex-direction: column-reverse;
  align-items: center;
  margin-top: 20px;
}
`;
const StyledAudioImg = styled.div`
display: flex;
flex-direction: column;
  width: 340px;
  height: 340px;
  padding: 40px;
  border-radius: 15px;
  background-color: #fff;
  box-shadow: ${palette.shadow};
  position: relative;
  overflow: hidden;
    :hover{
        z-index: 99;
        background: rgba(232, 121, 58, 0.65);
        background:rgba(239, 189, 12, 0.30);
        background: rgba(245, 154, 17, 0.44);
        transition: all 1s;
        h3, .dot_wrap, p, .btn_wrap ,.video-img-wrap{
            display: none;
        }
        .demo-btn{
            display: block;
        }
        
    }

  h3{
    margin-top: 0px;
    text-align: center;
    margin-bottom: 13px;
    font-size: 1.2rem;
    font-weight: ${fontWeight.regular};
  }
 
  h4{
    display: inline-block;
    width: 100%;
    margin: 0;
    margin-bottom: 20px;
    text-align: start;
    font-weight: ${fontWeight.regular};
    font-size: ${fontSize.regular};
   
  }
  
  .video-img-wrap{
    position: absolute;
    top: 100px;
    left: 50%;
    transform: translateX(-50%);
    width: 270px;
    img{
        width: 270px;
    }
  
  }
  .video-img-wrap.react{
    position: absolute;
    top: 60px;
    left: 50%;
    transform: translateX(-50%);
    img{
        width: 270px;
    }
  }
  .demo-btn{
   position: absolute;
    display: none;
   top: 45%;
   left: 50%;
   transform: translateX(-50%);
   transition: all 2s;
   opacity:1;
   button{
    
    padding: 0 25px;
    height: 48px;
    background-color: #fff;
   color: #333;
   box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.16);
border-radius: 5px;
border: none;
font-weight: 600;

   }
  }
  p{
    /* padding-left: 14px; */
    position: relative;
    color: ${palette.text.default};
    text-align: start;
    span{
      position: absolute;
      top: 0;
      left: 0;
      font-size: ${fontSize.medium};
      color: ${palette.main.default};
    }
  }
  .btn_wrap{
    position: absolute;
    bottom: 40px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    ${defaultFlexCenter}
    justify-content: space-evenly;
    button{
    background-color: ${palette.opacity};
    border: 0;
    :hover{
      cursor: pointer;
    }
    img {
        width: 80%;
    }
  }

  }
  @media screen and (max-width: 980px) and (min-width: 801px){
    
    width: 300px;
    height: 310px;
    p.name{
      font-size: 19px;
    }
   
    .calling_spinner{
      
    position: absolute;
    top: 140px;
    left: 50%;
    transform: translateX(-50%);
    img{
        width: 56px;
        height: 56px;
        /* margin-bottom: 29px; */
    }
    .demo-image__telephone{
      width: 159px;
      margin-bottom: 0;
      padding-bottom: -20px;
    }
  }
  .video-img-wrap.react{
    position: absolute;
    top: 40px;
    left: 50%;
    transform: translateX(-50%);
    
    img{
        width: 270px;
    }
  }
  }
  @media screen and (max-width: 800px) and (min-width: 641px){
    width: 220px;
    height: 400px;
    p.name{
      font-size: 19px;
    }
    h3{
      font-size: 16px;
    }
    .calling_spinner{
    position: absolute;
    top: 160px;
    left: 50%;
    transform: translateX(-50%);
    img{
        width: 56px;
        height: 56px;
        /* margin-bottom: 29px; */
    }
    .demo-image__telephone{
      width: 159px;
      margin-bottom: 0;
      padding-bottom: -20px;
    }
  }
  .video-img-wrap{
    position: absolute;
    top: 150px;
    left: 50%;
    transform: translateX(-50%);
    width: 220px;
    img{
        padding: 0 5%;
    }
 
  }
  .video-img-wrap.react{
    position: absolute;
    top: 125px;
    left: 50%;
    transform: translateX(-50%);
    img{
        width: 220px;
        padding: 0 5%;
    }
  }
}
@media screen and (max-width: 640px) and (min-width: 230px){
    width: 220px;
    height: 240px;
    padding: 30px;
    h3{
      font-size: 16px;
    }
    p.name{
      font-size: 16px;
      margin-bottom: 0;
    }
    .calling_spinner{
    position: absolute;
    top: 140px;
    left: 50%;
    transform: translateX(-50%);
    img{
        width: 40px;
        height: 40px;
        display: none;
        /* margin-bottom: 29px; */
    }
    .demo-image__telephone{
      width: 159px;
      margin-bottom: 0;
      padding-bottom: -20px;
    }
  }
 
  .video-img-wrap{
    position: absolute;
    top: 80px;
    left: 50%;
    transform: translateX(-50%);
    width: 200px;
    img{
        width: 200px;
        padding: 0 5%;
    }
 
  }
  .video-img-wrap.react{
    position: absolute;
    top: 40px;
    left: 50%;
    transform: translateX(-50%);
    img{
        width: 200px;
        padding: 0 5%;
    }
 
  }
}

`;

const StyledAudioDesc = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
span{
    line-height: 26px;
    font-size: 1rem;
}
h4{
    margin-top: 0;
    font-size: 22px;
    margin-bottom: 22px;
    font-weight: 500;
}
button {
    background-color: #fff;
    border: none;
    padding: 0 23px;
    color: ${palette.main.bright};
    height: 42px;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.16);
    border-radius: 5px;
    font-weight: 700;
    font-size: 14px;
}
.description-wrap{
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
    width: 526px;
    color: ${palette.text.default};
}
.feature-wrap{
  color: ${palette.text.default};
  ul {
    padding-left: 12px;
    display: list-item;
    line-height: 22px;
    li{
      display: list-item;
       list-style-image: url("https://user-images.githubusercontent.com/125844802/228739533-2e2653bd-7418-4e10-9ec4-7a6c09cf7645.svg");
      }
}

}
.btn-wrap{
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
    .btn-git{
      display: flex;
      button{
        display: inline-flex;
        align-items: center;
      }
      .btn-git__icon{
      margin-right: 4px;
      }
    }
    button{
      transition: all .3s;
      :hover{
        color: #fff;
          cursor: pointer;
          background-color: ${palette.main.vivid};
        }
        span{
          font-size: ${fontSize.micro};
          font-weight: ${fontWeight.semiBold};
          color: ${palette.text.disabled};
          :hover{
            color: #fff;
          cursor: pointer;
          }
        }
    }
    img{
          display: flex;
          align-items: center;
          width: 22px;
          margin-right:6px;
        }
        
}
@media screen and (max-width: 980px) and (min-width: 801px){
    justify-content: flex-end;
  
    .description-wrap{
      width: 420px;
    }
}
@media screen and (max-width: 800px) and (min-width: 641px){
  .description-wrap{
      width: 342px;
    }
}
@media screen and (max-width: 640px) and (min-width: 580px){
  margin-bottom: 50px;
  h4{
    font-size: 18px;
    /* text-align: center; */
  }
  .btn-wrap{
    justify-content: center;
   } 
  .description-wrap{
      width: 410px; 
    }
    button {
    background-color: #fff;
    border: none;
    padding: 0 23px;
    color: ${palette.main.bright};
    height: 30px;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.16);
    border-radius: 5px;
    font-weight: 700;
font-size: 14px;
}

}
@media screen and (max-width: 579px) and (min-width: 230px){
  /* margin-top: 20px; */
  margin-bottom: 50px;
  .description-wrap{
      width: 100%;
    }
    button{
      height: 30px;
    }
    .btn-wrap{
    justify-content: center;
   } 
}      
`;
const StyledCompleted = styled.div`
h2{
  text-align: center;
  margin-bottom: 20px;
}
.img-wrap{
  display: flex;
}
 img {
  width: 120px;
  height: 120px;
  margin: 0 auto;
 }
 .txt-wrap{
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 14px;
 }
`;

const StyledInCompleted = styled.div`
.container{
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  h2{
    text-align: center;
    color: #333;
    font-size: 18px;
    margin-bottom: 30px;
  }
  .input-wrap{
    display: flex;
    flex-direction: column; 
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
    padding-left: 5px;
    padding-right: 5px;
  }
  input{
    ${defaultInput};
    border:none;
    border-bottom: 1px solid #d1d1d1;
    border-radius: 0;
  
    width: 100%;
    ::placeholder{
      padding-left: 5px;
    }
    margin-bottom: 10px; 
  }
  button{
    /* margin-left: 5px; */
    ${signinUpBtn}
    width: 30%;
    height: 30px;
    font-size: 14px;
    align-self: flex-end;
    margin-bottom: 10px; 

  }
  .info-wrap{
    
    font-size: 14px;
    text-align: center;
    .error{
      color: red;
      font-weight: 500;

    }
    .notice{
      vertical-align: middle;
      color: #545454;
    }
    .icon{
      vertical-align: text-bottom;
      margin-right: 3px;
      color: #146DCF;
      margin-bottom: 1px;
    }
  }
}
`;