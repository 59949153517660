import React, { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import sha256 from 'js-sha256';
import styled from 'styled-components';
import { AiFillHome } from 'react-icons/ai';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { useRecoilState } from 'recoil';
import { Helmet } from 'react-helmet-async';
import {
	defaultFlexCenter,
	defaultInput,
	disabledBtn,
	fontWeight,
	pagePaddingTop,
	palette,
	signinUpBtn,
} from '../modules/defines/style';
import { EMAIL_REGEX, PWD_REGEX } from '../components/utill/regex';
import googleLogo from '../assets/icon_google.svg';
import BASE_URL from '../config';
import { googleUserState, roleState } from '../recoil';
import pwdReset from '../assets/pwd_reset.png';
import PopupLoading from '../components/PopupLoading';
import myAxios from '../modules/api/myAxios';
import API from '../modules/api/api';

function Login() {
	const { t } = useTranslation();
	// eslint-disable-next-line no-unused-vars
	const [googleUser, setGoogleUser] = useRecoilState(googleUserState);
	// eslint-disable-next-line no-unused-vars
	const [role, setRole] = useRecoilState(roleState);
	const [step, setStep] = useState(0);
	const [email, setEmail] = useState('');
	const [emailMessage, setEmailMessage] = useState('');
	const [isEmail, setIsEmail] = useState(false);
	const [password, setPassword] = useState('');
	const [passwordMessage, setPasswordMessage] = useState('');
	const [isPassword, setIsPassword] = useState(false);
	const [signInError, setSignInError] = useState('');
	const [notValidStatus, setNotValidStatus] = useState(false);
	const [hidePassword, setHidePassword] = useState(true);
	const [isModalOpen, setModalOpen] = useState(false);
	const [pwdResetData, setPwdResetData] = useState({ name: '', email: '' });
	const [pwdError, setPwdError] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const [isComplete, setIsComplete] = useState(false);
	const eyeOpen = <FaEye />;
	const eyeSlashed = <FaEyeSlash />;
	const navigate = useNavigate();

	function googleLogin() {
		window.location.href = `${BASE_URL}/auth/google`;
		setGoogleUser('googleLogin');
	}

	const handleKeyDown = e => {
		if (e.keyCode === 13) {
			handleSubmit();
		}
		setSignInError('');
	};

	const handleSubmit = () => {
		const hashedPwd = sha256(password);
		const data = { email, password: hashedPwd };

		API.login(data).then(response => {
			switch (response.result) {
				case 'logined':
					setRole(response.result.role);
					navigate('/console/service/service-id');
					break;
				case 'wait':
					navigate('/wait');
					break;
				case 'inconsistency':
					return setSignInError(t('error.login.emailOrPw'));
				default:
					return response;
			}
		});
		setEmail('');
		setPassword('');
		setIsEmail(false);
		setIsPassword(false);
		setHidePassword(true);
	};

	const onChangeEmail = useCallback(e => {
		const emailCurrent = e.target.value;
		setEmail(emailCurrent);
		if (!EMAIL_REGEX.test(emailCurrent)) {
			setEmailMessage(t('error.login.email'));
			setIsEmail(false);
		} else {
			setEmailMessage('');
			setIsEmail(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onChangePassword = useCallback(e => {
		const passwordCurrent = e.target.value;
		setPassword(passwordCurrent);
		if (!PWD_REGEX.test(passwordCurrent)) {
			setPasswordMessage(t('error.login.password'));
			setIsPassword(false);
		} else {
			setPasswordMessage('');
			setIsPassword(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const passwordToogle = () => {
		setHidePassword(!hidePassword);
	};
	const handlePwdReset = e => {
		setPwdResetData({ ...pwdResetData, [e.target.name]: e.target.value });
	};

	const handlePwdResetSubmit = async e => {
		e.preventDefault();
		setIsLoading(true);
		setPwdError('');
		try {
			const response = await myAxios.post('/users/password', pwdResetData);
			if (response.data.result === 'success') {
				setIsLoading(false);
				setIsComplete(true);
			}
		} catch (error) {
			if (error.response.data.result === 'fail') {
				setIsLoading(false);
				setPwdError(t('error.login.check'));
			}
		}
	};

	useEffect(() => {
		setStep(0);
		setEmail('');
		setPassword('');
		setEmailMessage('');
		setPasswordMessage('');
		setSignInError('');
		setNotValidStatus(false);
	}, []);

	return notValidStatus ? (
		<StyledLoginWait>
			<Helmet>
				<title>{t('helmet.login.title')}</title>
				<meta charset="utf-8" />
				<meta name="title" content={t('helmet.login.title')}></meta>
				<meta name="author" content="omnitalk"></meta>
				<meta
					name="keywords"
					content="WebRTC, SIP, CPaaS, communication, cloud-based, integration, solution, provider,WebRTC CPAAS,SIP CPaaS,WebRTC SIP integration,Cloud-based WebRTC,Cloud-based SIP,WebRTC and SIP solution,WebRTC and SIP provider,옴니톡,옴니스토리,비대면통신,커뮤니케이션플랫폼,메타버스,통신,통신서비스"
				/>
				<meta name="description" content={t('helmet.login.desc')} />
				<meta property="og:title" content={t('helmet.login.ogTitle')} />
				<meta property="og:description" content={t('helmet.login.ogDesc')} />
				<meta property="og:url" content="https://omnitalk.io/login" />
				<link rel="canonical" href="https://omnitalk.io/login/" />
				<meta property="og:type" content="website" />
				<meta property="twitter:title" content={t('helmet.login.title')} />
				<meta property="twitter:card" content={t('helmet.login.ogCard')} />
				<meta property="twitter:description" content={t('helmet.login.desc')} />
				<meta name="robots" content="index" />
			</Helmet>
			<section>
				<h1>Welcome!</h1>
				<p>
					{t('wait.pOne')}
					<br />
					{t('wait.pTwo')}
				</p>
				<div className="home_btn">
					<Link to="/" style={{ color: '#FA5734' }}>
						{' '}
						<AiFillHome /> {t('wait.main')}{' '}
					</Link>
				</div>
			</section>
		</StyledLoginWait>
	) : step === 0 ? (
		<StyledLogin>
			<Helmet>
				<title>{t('helmet.login.title')}</title>
				<meta charset="utf-8" />
				<meta name="title" content={t('helmet.login.title')}></meta>
				<meta name="author" content="omnitalk"></meta>
				<meta
					name="keywords"
					content="WebRTC, SIP, CPaaS, communication, cloud-based, integration, solution, provider,WebRTC CPAAS,SIP CPaaS,WebRTC SIP integration,Cloud-based WebRTC,Cloud-based SIP,WebRTC and SIP solution,WebRTC and SIP provider,옴니톡,옴니스토리,비대면통신,커뮤니케이션플랫폼,메타버스,통신,통신서비스"
				/>
				<meta name="description" content={t('helmet.login.desc')} />
				<meta property="og:title" content={t('helmet.login.ogTitle')} />
				<meta property="og:description" content={t('helmet.login.ogDesc')} />
				<meta property="og:url" content="https://omnitalk.io/login" />
				<link rel="canonical" href="https://omnitalk.io/login/" />
				<meta property="og:type" content="website" />
				<meta property="twitter:title" content={t('helmet.login.title')} />
				<meta property="twitter:card" content={t('helmet.login.ogCard')} />
				<meta property="twitter:description" content={t('helmet.login.desc')} />
				<meta name="robots" content="index" />
			</Helmet>
			<section className="login_box">
				<form>
					<label htmlFor="email"> Email</label>
					<input
						value={email}
						className={`input ${isEmail ? 'success' : ''}`}
						id="email"
						name="email"
						type="email"
						placeholder="please enter your email address"
						maxLength="30"
						onChange={onChangeEmail}
						onKeyDown={handleKeyDown}
						required
					/>
					{email.length > 0 && (
						<span className={`message ${isEmail ? 'success' : 'error'}`}>
							{emailMessage}
						</span>
					)}
					<label htmlFor="password">Password</label>
					<div className="password-container">
						<input
							value={password}
							type={hidePassword ? 'password' : 'text'}
							className={`input ${isPassword ? 'success' : ''}`}
							id="password"
							name="password"
							placeholder="password"
							onChange={onChangePassword}
							onKeyDown={handleKeyDown}
						/>
						{hidePassword ? (
							<i
								tabIndex="0"
								role="button"
								onClick={passwordToogle}
								onKeyDown={event => {
									if (event.key === 'Enter' || event.key === ' ') {
										passwordToogle();
									}
								}}
							>
								{eyeSlashed}
							</i>
						) : (
							<i
								tabIndex="0"
								role="button"
								onClick={passwordToogle}
								onKeyDown={event => {
									if (event.key === 'Enter' || event.key === ' ') {
										passwordToogle();
									}
								}}
							>
								{eyeOpen}
							</i>
						)}
					</div>
					{password.length > 0 && (
						<span className={`message ${isPassword ? 'success' : 'error'}`}>
							{passwordMessage}
						</span>
					)}

					<>
						<span className="error">{signInError}</span>
						<button
							type="button"
							className={`${!isEmail || !isPassword ? 'disabled' : ''}`}
							disabled={!isEmail || !isPassword}
							onClick={handleSubmit}
						>
							{' '}
							Sign in
						</button>
					</>
					<div className="clause flex">
						<button
							className="mr-1"
							type="button"
							onClick={() => navigate('/signup')}
						>
							{t('login.signup')}
						</button>
						<button type="button" onClick={() => setModalOpen(true)}>
							{t('login.password')}
						</button>
					</div>

					<div className="signin_line">{t('login.login')}</div>
					<button
						type="button"
						className="social-login  flex items-center justify-center"
						onClick={googleLogin}
					>
						<img src={googleLogo} alt="google-logo" />
						Start with Google
					</button>

					{isModalOpen ? (
						isComplete ? (
							<StyledModal>
								<div className="modal-background">
									<div className="modal-wrapper">
										<div
											className="modal_content "
											style={{ height: '443px' }}
											id="modalPopup"
										>
											<button
												onClick={() => setModalOpen(false)}
												type="button"
												className="close"
											>
												✖
											</button>
											<img
												className="email"
												src="https://user-images.githubusercontent.com/125844802/228473825-93294135-6080-425c-b926-ef909805ced5.png"
												alt="pwd-reset-mail-img"
											/>
											<span>{t('login.textOne')}</span>
											<span>{t('login.textTwo')}</span>
										</div>
									</div>
								</div>
							</StyledModal>
						) : (
							<StyledModal>
								{isLoading && <PopupLoading />}
								<div className="modal-background">
									<div className="modal-wrapper">
										<div className="modal_content" id="modalPopup">
											<button
												onClick={() => setModalOpen(false)}
												type="button"
												className="close"
											>
												✖
											</button>
											<img src={pwdReset} alt="pwdRest-img" />
											<span>{t('login.modal.textOne')}</span>
											<span style={{ marginBottom: '16px' }}>
												{t('login.modal.textTwo')}
											</span>

											<div className="modal-label_box">
												<input
													onChange={handlePwdReset}
													name="email"
													type="email"
													placeholder={t('login.modal.placeholderEmail')}
													maxLength="30"
													required
												/>
											</div>
											<div className="modal-label_box">
												<input
													onChange={handlePwdReset}
													name="name"
													type="text"
													placeholder={t('login.modal.placeholderName')}
													maxLength="20"
													required
												/>
											</div>
											<button
												type="button"
												onClick={handlePwdResetSubmit}
												className={`${
													pwdResetData.email === '' || pwdResetData.name === ''
														? 'disabled'
														: 'accept'
												}`}
											>
												{t('login.modal.button')}
											</button>
											<span className="error">{pwdError}</span>
										</div>
									</div>
								</div>
							</StyledModal>
						)
					) : null}
				</form>
			</section>
		</StyledLogin>
	) : (
		<section>
			<div>
				<strong>{t('login.info.msgOne')}</strong> {t('login.info.msgTwo')}
				<br />
				{t('login.info.msgThree')}
				<br />
				{t('login.info.msgFour')}
				<br />
				<br />
				{t('login.info.msgFive')}
				<br />
			</div>
			<div>
				<button type="button" onClick={() => navigate('/signup')}>
					Continue
				</button>
			</div>
			<div>
				Already have an account?{' '}
				<button type="button" onClick={() => setStep(0)}>
					Login
				</button>
			</div>
		</section>
	);
}
export default Login;

const StyledLogin = styled.div`

width: 100vw;
height: 80vh;
${defaultFlexCenter}
.login_box{
  position: relative;
  form{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 420px;
    margin: 0 auto;
  ${defaultFlexCenter}
  flex-direction: column;
  label{
    width: 100%;
    padding-left: 10px;
    text-align: start;
    font-size: 15px;
    color: ${palette.text.default};
    line-height: 2.1rem;
  }
  input{
    margin-bottom: 12px;
    ${defaultInput}
  }
  input:focus{
    outline: none;
    border-color: red;
  }
 
  input#email.input.success,
  input#password.input.success {
  border-color: #7dc24b;
}

  .error {
    color: red;
    font-size: 14px;
    font-weight: 500;
  }
  button{
    ${signinUpBtn}
    margin-top: 16px;
    margin-bottom: 5px;
    
    :hover{
      cursor: pointer;
    }
  }
  .disabled{
    ${disabledBtn}
    margin-top: 20px;
  }
  p{
    margin: 0 auto;
    text-align: center;
    ${defaultFlexCenter}
    line-height: 30px;
    span{
      width: 100px;
    font-size: 14px;
    font-weight: ${fontWeight.regular};
    color: ${palette.text.light};
    }
 
    span:first-child{
      padding-left: 2px;
      margin-left: 20px;
    }
    
  }
  .message{
    font-size: 14px;
    font-weight: ${fontWeight.semiBold};
  }
  .social-login{
    background-color: #ffffff;
    color: #A4A3A3;
    font-size: 16px;
    border: none;
    margin-top: 13px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  }
  button > img {
  vertical-align: middle;
  padding-right: 8px;
  }
  .signin_line{
  display: flex;

  width: 100%;
  flex-basis:100%;
  align-items: center;
  color: #abb0b5;
  font-size: 14px;
  margin: 8px 0px;
  margin-top: 24px;
  
}
.signin_line::before{
  content: "";
  flex-grow: 1;
  margin: 0px 16px;
  background:  #ebebeb;
  height: 1px;
  font-size: 0px;
  line-height: 0px;
  margin: 0px 4px;
}
.signin_line::after{
  content: "";
  flex-grow: 1;
  margin: 0px 16px;
  background: #ebebeb;
  height: 1px;
  font-size: 0px;
  line-height: 0px;
  margin: 0px 4px;
}
.more-action-container{
  display: inline-flex;
  justify-content: center;
}
.more-action__divider{
  width: 1px;
  margin: 0 10px;
  height: 10px;
  pointer-events: none;
  border-left: ${palette.text.default};
}
.password-container{
  position: relative;
  display: flex;
  margin-bottom: 14px;
}
i {
  position: absolute;
  top: 24%;
  right: 6%;
}
i:hover {
  color: ${palette.main.default};
  cursor: pointer;
}

.clause{
      ${defaultFlexCenter}
      font-size: 14px;
      height: fit-content;
      color:#8d9297;
      button{
        margin-top: 5px;
        margin-bottom: 10px;
        font-size: 14px;
         text-decoration: underline;
        height: fit-content;
        width: fit-content;
        border: 0;
        /* border-right: 1px solid ${palette.gray.middle}; */
        background-color: ${palette.opacity};
        border-radius: 0px;
        color:#8d9297;
      }
      button:nth-child(2){
        border: 0;
      }
      button:nth-child(1){
        padding-left: 13px;
      }
    }
  }
}
@media screen and (max-width:579px) and (min-width:230px) {
  padding-top: 0px;
.login_box{
  form{
    width: 100%;
    padding: 0 5%;
    top:50%;

    .clause{
      ${defaultFlexCenter}
      font-size: 14px;
      height: fit-content;
      color:#8d9297;
      height: fit-content;
        width: fit-content;
      button{
        font-size: 14px;
        margin-top: 5px;
        margin-bottom: 10px;
         text-decoration: underline;
        height: fit-content;
        width: fit-content;
        border: 0;
        border-right: 1px solid ${palette.gray.middle};
        background-color: ${palette.opacity};
        border-radius: 0px;   
        color:#8d9297;
      }
      button:nth-child(2){
        border: 0;
      }
      button:nth-child(1){
        padding-left: 13px;
      }
    }
  label{
    width: 100%;
    text-align: start;
    font-size: 1rem;
    line-height: 40px;
    padding-left: 0;
  }
  label:nth-child(2){
    margin-bottom: 60px;
  }
  input{
    ${defaultInput}
    width: 100%;
    ::placeholder{
      font-size: 14px;
    }
  }
  .error{
    color: red;
  }
  button{
    ${signinUpBtn}
    width: 100%;
    margin-top: 40px;
    margin-bottom: 12px;
    :hover{
      cursor: pointer;
    }
  }
  .disabled{
    ${disabledBtn}
    width: 100%;
  }
  p{
    margin: 0 auto;
    text-align: center;
    ${defaultFlexCenter}
    line-height: 30px;
    span{
    width: 100px;
    font-size: 14px;
    font-weight: ${fontWeight.regular};
    }
  }
  .password-container{
    width: 100%;
  }

  i {
  position: absolute;
  top: 24%;
  right: 3%;
}
  }
}
}
`;

const StyledLoginWait = styled.div`
${pagePaddingTop}
section{
  h1{
    font-size: 2.125rem;
    margin-bottom: 70px;
    color: ${palette.main.default};
  }
  p{
    font-size: 1.75rem;
    margin-bottom: 30px;
  }
  .home_btn{
      margin-bottom: 80px;
    }
}
`;

const StyledModal = styled.div`
.modal-wrapper{
  position: fixed;
  z-index: 6;
  top: 30%;
  bottom:0%;
  transform: translate(-50%,-50%);
  body {
  font-family: "Pretendard", sans-serif;
  padding: 0;
  margin: 0;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
 
}

.modal_content {
  width: 420px;
  height: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  color: #000;
  text-align: center;
  border-radius: 20px;
  padding: 20px 20px 70px;
  padding-top: 10px;
  box-shadow: 0px 6px 18px -5px rgba(0, 0, 0, 0.2);

  .modal-label_box{
    display: inline-flex;
  }
  label{
    width: fit-content;
    line-height: 3;
    padding-right: 10px;

  }
  button.close {
    width: 30px;
    font-size: 20px;
    color: #c0c5cb;
    align-self: flex-end;
    background-color: transparent;
    border: none;
    margin-bottom: 10px;
  }
  img {
    width: 100px;
    margin-bottom: 15px;
  }

  input {
    width: 235px;
    margin-bottom: 6px;
  }
  .email{
    width: 150px;
  }
  input::placeholder{
    padding-left: 10px;
  }
  input:focus {
    outline: none;
    border-color: ${palette.main.default};
  }
  button.accept {
    background-color: ${palette.main.default};
    border: none;
    border-radius: 5px;
    width: 235px;
    padding: 14px;
    font-size: 16px;
    color: white;
  }
  button.disabled{
    width: 235px;
  }
}
}

@media screen and (max-width:579px) and (min-width:230px) {
  .modal-wrapper{
  .modal_content {
    width: 360px;
    height: 520px;
    span{
      font-size: 14px;
    }
    .accept{
      margin-top: 20px;
    }
  }
  .close{
    margin-top: 20px;
  }

}
}
`;