import styled from 'styled-components';

import { palette } from '../../modules/defines/style';

const colorSet = {
  red: {
    default: palette.red.default,
    dark: palette.red.dark,
  },
  blue: {
    default: palette.blue.default,
    dark: palette.blue.dark,
  },
  gray: {
    default: 'rgba(99,102,106,0.8)',
    dark: 'rgba(99,102,106,1)'
  }
};

const ButtonWrapper = styled.button`
cursor: pointer;
flex-shrink: 0;
  height: ${(props) => (props.size === 'large' ? '50px' : '40px')};
  line-height: ${(props) => (props.size === 'large' ? '48px' : '32px')};
  padding: 0 ${(props) => (props.size === 'large' ? '20px' : '15px')};
  border-radius: ${(props) => (props.shape === 'rounded' ? props.size === 'large' ? '25px' : '18px' : props.size === 'large' ? '10px' : '5px')};
  background-color: ${(props) => (props.theme === 'border' ? 'transparent' : colorSet[props.color || 'red'].default)};
  border: 1px solid ${(props) => colorSet[props.color || 'red'].default};
  color: ${(props) => (props.theme === 'border' ? colorSet[props.color || 'red'].default : palette.white)};
  font-size: ${(props) => (props.size === 'large' ? '16px' : '16px')};
  font-weight: 600;
  white-space: nowrap;
  width: ${(props) => props.width};
  /* width: ${(props) => (props.size === 'large' ? '400px' : '200px')}; */
  margin: 0 auto;
  /* margin: ${(props) => props.margin}; */

  :hover {
    background-color: ${(props) => (props.theme === 'border' ? 'transparent' : colorSet[props.color || 'red'].dark)};
    border-color: ${(props) => colorSet[props.color || 'red'].dark};
    color: ${(props) => (props.theme === 'border' ? colorSet[props.color || 'red'].dark : palette.white)};
  }
  :disabled {
    background-color: ${(props) => (props.theme === 'border' ? 'transparent' : palette.gray.middle)};
    border-color: ${palette.gray.middle};
    color: ${(props) => (props.theme === 'border' ? palette.gray.middle : palette.gray.light)};
  }
`;

const Button = (props) => (
  <ButtonWrapper type="button" {...props}>
    {props.children}
  </ButtonWrapper>
);

export default Button;