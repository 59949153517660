import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import Hero from '../components/main/Hero';
import DeveloperFirst from '../components/main/DeveloperFirst';
import DocsIntro from '../components/main/DocsIntro';
import MarqueeSlider from '../components/main/MarqueeSlider';
import Aside from '../components/Aside';
import { useEffect, useState } from 'react';
import ContactBox from '../components/main/ContactBox';
import ContactBoxButton from '../components/ui/ContactBoxButton';
import ContactPopup from '../components/main/ContactPopup';
import API from '../modules/api/api';
function Main() {
	const { t } = useTranslation();
	const [displayContact, setDisplayContact] = useState(false);
	const [callBackMessage, setCallBackMessage] = useState('');
	const [input, setInput] = useState('');
	const handleInputChange = e => {
		setInput(e.target.value);
	};
	useEffect(() => {
		setCallBackMessage('');
	}, []);
	const handleOpenContact = () => {
		setDisplayContact(true);
	};

	const handleSubmit = data => {
		API.addContactUs({
			title: data.title,
			name: data.name,
			email: data.email,
			phone: `${data.phone0}-${data.phone1}-${data.phone2}`,
			position: data.position,
			question: data.question,
			status: 0,
		}).then(
			response => {
				if (response.data.result === 'success') {
					setCallBackMessage(
						'문의 사항이 정상적으로 접수되었습니다.<br />빠른시간내에 안내드리겠습니다. 감사합니다.',
					);
				}
			},
			error => {
				console.log(error);
			},
		);
	};

	return (
		<>
			<Helmet>
				<title>{t('helmet.main.title')}</title>
				<meta charset="utf-8" />
				<meta name="title" content={t('helmet.main.title')}></meta>
				<meta name="author" content="omnitalk"></meta>
				<meta
					name="keywords"
					content="WebRTC, SIP, CPaaS, communication, cloud-based, integration, solution, provider,WebRTC CPAAS,SIP CPaaS,WebRTC SIP integration,Cloud-based WebRTC,Cloud-based SIP,WebRTC and SIP solution,WebRTC and SIP provider,옴니톡,옴니스토리,비대면통신,커뮤니케이션플랫폼,메타버스,통신,통신서비스"
				/>
				<meta name="description" content={t('helmet.main.desc')} />
				<meta property="og:title" content={t('helmet.main.ogTitle')} />
				<meta property="og:description" content={t('helmet.main.ogDesc')} />
				<meta property="og:url" content="https://omnitalk.io" />
				<link rel="canonical" href="https://omnitalk.io/" />

				<meta property="og:type" content="website" />
				<meta
					property="twitter:title"
					content={t('helmet.main.twitterTitle')}
				/>
				<meta property="twitter:card" content={t('helmet.main.twitterCard')} />
				<meta
					property="twitter:description"
					content={t('helmet.main.twitterDesc')}
				/>
				<meta name="robots" content="index" />
			</Helmet>
			<div className="dark:bg-black max-w-screen h-auto">
				<Hero handleOpenContact={handleOpenContact} />
				<DeveloperFirst />
				<DocsIntro />
				<MarqueeSlider />
				<Aside />
				<ContactBox>
					<input
						className="w-40 xs:text-[11px] sm:text-base"
						type="email"
						name="input"
						maxLength="40"
						value={input}
						onChange={handleInputChange}
						placeholder="이메일을 입력 하세요"
					/>
					<ContactBoxButton
						size="large"
						disabled={input.trim() === ''}
						onClick={handleOpenContact}
					>
						SEND
					</ContactBoxButton>
					{displayContact && (
						<ContactPopup
							email={input}
							onClose={() => setDisplayContact(false)}
							onSubmit={handleSubmit}
							callBackMessage={callBackMessage}
						/>
					)}
				</ContactBox>
			</div>
		</>
	);
}

export default Main;

