import styled from 'styled-components';
import { useState, useEffect } from 'react';
import moment from 'moment';
import { defaultFlexCenter, fontSize, fontWeight, palette } from '../../modules/defines/style';
import ServiceKey from './ServiceKey';
// import momentFormat from '../hook/momentFormat';

function ServiceView({ data, i18n }) {
  const [format, setFormat] = useState('');

  useEffect(() => {
    i18n.changeLanguage(navigator.language.slice(0, 2)).then(() => {
      moment.locale(i18n.language);
    });
  }, []);

  useEffect(() => {
    setTimeout(() => {
      switch (i18n.language) {
      case 'ko':
        setFormat(moment(data.reg_date * 1000).format('YYYY-MM-DD'));
        break;
      case 'en':
        setFormat(moment(data.reg_date * 1000).format('MM/DD/YYYY'));
        break;
      case 'ja':
        setFormat(moment(data.reg_date * 1000).format('YYYY年MM月DD日'));
        break;
      default:
        setFormat('');
      }
    }, 200);
  }, [i18n.language]);

  return (
    <>
      <StyledCard key={data}>
        <div className="top">
          <dl>
            <dt>Service ID</dt>
            <dd>{data.service_id}</dd>
          </dl>
          {data.service === 1 ? (
            <dl>
              <dt>Service Key</dt>
              <dd>{<ServiceKey keyValue={data.service_key} /> || '-'}</dd>
            </dl>
          ) : (
            <dl>
              <dt>N/A</dt>
              <dd>-</dd>
            </dl>
          )}
        </div>
        <div className="center">
          <dl>
            <dt>Domain</dt>
            <dd className="margin">{data.domain}</dd>
            <dt>Service</dt>
            <dd>
              {data.service === 1 ? 'APP' : 'WEB'}
            </dd>
          </dl>
          <dl>
            <dt>IP</dt>
            <dd className="margin">{data.ip_addr}</dd>
            <dt>Date</dt>
            {/* <dd>{ format }</dd> */}
            <dd>{ format }</dd>
          </dl>
        </div>
        <div className="bottom">
          <dl>
            <dt>Memo</dt>
            <dd>{data.memo || '-'}</dd>
          </dl>
        </div>
      </StyledCard>
    </>
  );
}

export default ServiceView;

const StyledCard = styled.div`
width: 460px;
height: 300px;
padding: 30px;
margin-right: 20px;
margin-bottom: 30px;
border-radius: 10px;
box-shadow: ${palette.shadow};
background-color: ${palette.gray.light};
div{
  width: 100%;
  ${defaultFlexCenter}
  justify-content: space-between;

  dl{
    width: 50%;
    dt{
      font-weight: ${fontWeight.medium};
    }
    dd{
      font-size:${fontSize.micro};
    }
  }
}
.top{
  dl{
    height: 70px;
  }
}
.center{
  margin-bottom: 10px;
  dl:nth-child(1){
    width: 100px;
    margin-right: 20px;
  }
  .margin{
    margin-bottom: 10px;
  }
}
.bottom{
  height: 80px;
  
}

@media screen and (max-width:979px) and (min-width:580px) {
margin-right: 0;
}

@media screen and (max-width:579px) and (min-width:230px) {
width: 100%;
height: 300px;
padding: 20px;
div{
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  dl{
    width: 100%;
    margin: 0;
    padding: 0;
    dt{
      font-weight: ${fontWeight.medium};
    }
    dd{
      font-size:${fontSize.micro};
      color: ${palette.text.light};
    }
  }
}
.top{
  dl{
    height: 50px;
  }
}
.center{
  width: 100%;
  padding-top: 10px;
  margin-bottom: 10px;
  ${defaultFlexCenter}
  flex-direction: row;
  dl:nth-child(1){
    width: 100px;
    margin-right: 40px;
    border-right: 0px;
  }
  .margin{
    margin-bottom: 10px;
  }
}
.bottom{
  height: 60px;
}

}
`;