import React from 'react';
import styled from 'styled-components';
import { FaPhoneSlash } from 'react-icons/fa';

const QuitAudioCallIconWapper = styled.div`
	position: absolute;
	bottom: 110px;
	left: calc(50% - 40px);
	width: 70px;
	height: 70px;
	border: 1px solid #ccc;
	border-radius: 50%;
	background: linear-gradient(
		234deg,
		rgba(128, 45, 45, 0.9) 0%,
		rgba(255, 140, 140, 1) 100%
	);
	background-color: #feae96;
	background-image: linear-gradient(315deg, #feae96 0%, #f53844 10%);
	background: linear-gradient(34deg, #ee5a24 0%, #ea2027 100%);

	svg {
		position: absolute;
		top: 25%;
		left: 25%;
		margin: 0;
		width: 50%;
		height: 50%;
		fill: #fff;
		/* transform: rotate( 135deg ); */
	}
	&:hover {
		/* animation: shake 1s 5 linear; */
		animation: vertical-shaking 1.3s infinite;
		@keyframes vertical-shaking {
			0% {
				transform: translateY(0);
			}
			25% {
				transform: translateY(10px);
			}
			50% {
				transform: translateY(-10px);
			}
			75% {
				transform: translateY(10px);
			}
			100% {
				transform: translateY(0);
			}
		}

		cursor: pointer;
	}

	@keyframes shake {
		0% {
			left: calc(50% - 25px);
		}
		50% {
			left: calc(50% - 30px);
		}
		70% {
			left: calc(50% - 20px);
		}
		100% {
			left: calc(50% - 25px);
		}
	}
`;

const QuitAudioCallIcon = ({ handleClick }) => {
  return (
    <QuitAudioCallIconWapper onClick={handleClick}><FaPhoneSlash /></QuitAudioCallIconWapper>

  );
};

export default React.memo(QuitAudioCallIcon);