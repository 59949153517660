import React from 'react';
import { useTranslation } from 'react-i18next';
import Slider from './Carousel';

export default function Hero({ handleOpenContact }) {
	const { t } = useTranslation();

	return (
		<>
			<article className="max-w-full">
				<div className="flex p-8 justify-center  items-center ">
					<div className=" lg:py-16 ">
						<div className="mr-auto place-self-center">
							<h1 className="max-w-2xl mb-4 text-4xl font-extrabold tracking-tight leading-none md:text-5xl xl:text-6xl dark:text-white">
								The{' '}
								<span className="block w-full py-2  leading-12  lg:inline bg-gradient-to-bl from-purple-500  to-main-50 text-transparent  bg-clip-text ">
									Simplest
								</span>{' '}
								Communication Platform
							</h1>

							<p className="xs:text-[0.938rem] max-w-xl mb-6 font-medium text-gray-800 lg:mb-8 md:text-lg lg:text-[1.375rem] lg:font-[400] lg:leading-[2.2rem] dark:text-white">
								{t('main.hero.desc')}
							</p>
							<a
								href="/login"
								className="group inline-flex items-center justify-center pr-5 py-3 mr-3 text-base font-medium text-center text-black rounded-lg bg-primary-700 hover:text-main-50 dark:hover:text-main-50 focus:outline-none dark:text-white"
							>
								{t('main.hero.start')}
								<svg
									className="w-5 h-5 ml-2 -mr-1 dark:text-white group-hover:text-main-50"
									fill="currentColor"
									viewBox="0 0 20 20"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										fillRule="evenodd"
										d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
										clipRule="evenodd"
									/>
								</svg>
							</a>

							<button
								onClick={handleOpenContact}
								className="inline-flex items-center  justify-center px-5 py-3 text-base font-medium text-center text-gray-900 border border-gray-300 rounded-lg hover:bg-gray-100 dark:hover:bg-main-50 dark:hover:border-none dark:text-white"
							>
								{t('main.hero.contact')}
							</button>
						</div>
					</div>
					<div className="hidden lg:mt-0 lg:col-span-5 lg:flex">
						<Slider />
					</div>
				</div>
			</article>
		</>
	);
}

