import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { defaultFlexCenter, fontSize, fontWeight, palette } from '../../modules/defines/style';

function ConsoleCategory({ setTab }) {
  const { t } = useTranslation();
  const [category, setCategory] = useState(0);
  return (
    <StyledTab>
      <ul className="web">
        <li className={`${category === 0 ? 'active_tab' : ''}`}>
          <button 
            className={`${category === 0 ? 'active' : ''}`}
            type="button"
            onClick={() => { setTab(0); setCategory(0); }}>
            {t('console.category.companyInfo')}
          </button>
        </li>

        <li className={`${category === 1 ? 'active_tab' : ''}`}>
          <button 
            className={`${category === 1 ? 'active' : ''}`}
            type="button"
            onClick={() => { setTab(1); setCategory(1); }}>
            {t('console.category.managerInfo')}
          </button>
        </li>
        <li className={`${category === 2 ? 'active_tab' : ''}`}>
          <button 
            className={`${category === 2 ? 'active' : ''}`}
            type="button"
            onClick={() => { setTab(2); setCategory(2); }}>
            {t('console.category.password')}
          </button>
        </li>
        
      </ul> 

    </StyledTab>
  );
}

export default ConsoleCategory;

const StyledTab = styled.div`
width: 100%;
max-width: 700px;
margin: 0 auto;
margin-bottom: 80px;
.web{
  position: relative;
  ${defaultFlexCenter}
  justify-content: space-between;
  margin-top: 34px;
  li{
    width: 100%;
    height: 42px;
    ${defaultFlexCenter}
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background-color: rgba( 244, 244, 244, 0.31 );
    border: 1px solid #eee;
    border-right: 0;
    border-bottom: 1px solid ${palette.gray.middle} ;
    button{
      width: 100%;
      color: ${palette.text.disabled};
      border: 0;
      background-color: ${palette.opacity};
      :hover{
        cursor: pointer;
      }
    }
  }
  li:last-child{
    border-right: 1px solid #eee;
  }
  .active_tab{
    border: 1px solid ${palette.gray.middle};
    border-bottom: 0;
    background-color: ${palette.white};
    button{
      width: 100%;
      font-size: ${fontSize.small};
      font-weight: ${fontWeight.semiBold};
      color: ${palette.main.default};
    }
  }
  .active_tab:last-child{
    border-right: 1px solid ${palette.gray.middle};
  }
}

@media screen and (max-width: 579px) and (min-width: 230px) {

  .menu_box{
    width: 100%;
    button{
      width: 100%;
      height: 42px;
      font-size: ${fontSize.small};
      color: ${palette.main.default};
      background-color: ${palette.white};
      border:1px solid ${palette.gray.middle};
    }
  }
  .web{
    .active_tab{
      button{
        font-size: 14px;
      }
    }
  }

}
`;