import { useEffect, useState, useRef } from 'react';
import parse from 'html-react-parser';
import styled from 'styled-components';
import { palette, zIndex, breakpoint } from '../../modules/defines/style';
import { ReactComponent as IconClose } from '../../assets/icon_close.svg';

const DemoPopup = ({ size, children, onClose, title, theme, height, type, onOffModal }) => {
  const $popup = useRef();
  const [position, setPosition] = useState('fixed');

  const handleClose = () => {
    if (onClose) onClose();
  };

  const handlePosition = () => {
    if (window.innerHeight < $popup.current.getBoundingClientRect().height) {
      setPosition('absolute');
    } else {
      setPosition('fixed');
    }
  };

  useEffect(() => {
    handlePosition();
  
    document.body.style.overflow = 'hidden';
    window.addEventListener('resize', handlePosition, false);
 
    return () => {
      document.body.style.overflow = '';
      window.removeEventListener('resize', handlePosition);
  
    };
  }, []);
 
  return (
    <PopupBackgroud>
      <PopupWrapper
        className="popup" theme={theme} size={size} position={position} ref={$popup}
        height={height}>
        {title && <PopupTitle>{title}</PopupTitle>}
        {theme !== 'confirm' && <CloseButton type="button" onClick={handleClose}><IconClose /></CloseButton>}
        {children}
      </PopupWrapper>
    </PopupBackgroud>
  );
};

export default DemoPopup;

const PopupBackgroud = styled.div`
  position: ${props => (props.position || 'fixed')};
  z-index: ${zIndex.popup};
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0,0,0,0.2);
  padding: 20px;
`;
const PopupWrapper = styled.div`
  position: absolute;
  z-index: ${zIndex.popup + 1};
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  /* height: ${props => (props.height === 'fit' ? '500px' : '300px')}; */
  width: ${props => (props.size === 'lg' ? '90%' : props.size === 'md' ? '480px' : '320px')};
  //this line 100 ->300
  min-width: 320px;
  min-height: 100px;
  padding: 30px;
  border-radius: 10px;
  background-color: #fff;
  /* box-shadow: -1px 8px 20px 0px rgb(255 255 255 / 34%); */
  box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
  overflow: auto;
  max-height: calc(100vh - 40px);
  max-width: 800px;
  @media only screen and (max-width: ${breakpoint.md}) {
    width: calc(100% - 40px);
  }
  .row-question{
    margin-top: 10px;
    width: 100%;
    height: 200px;
    overflow: visible;
  }
  ${props => (props.theme === 'confirm' && `
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-width: 260px;
    padding: 30px 20px;
    text-align: center;
    p {
      color: #666;
      font-size: 15px;
      font-weight: 600;
      margin: 0 auto;
      padding: 35px;
    }
    button + button {
      margin-left: 5px;
    }
  `)}
`;
const CloseButton = styled.button`
  position: absolute;
  z-index: ${zIndex.popup + 2};
  top: 20px;
  right: 20px;
  height: 30px;
  background: transparent;
  border: none;
  :hover svg {
    color: ${palette.main.default};
  }
  svg {
    width: 24px;
    height: 24px;
    :hover{
      color: ${palette.main.default};
    }
  }
`;

const PopupTitle = styled.div`
  margin-bottom: 20px;
  padding-top: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid #C4C4C4;
  color: ${palette.title};
  font-size: 20px;
  font-weight: 700;
`;

const PopupFormRowWrapper = styled.div`
  display: flex;
  align-items: center;
  font-family: 'Pretendard';
  /* flex-direction: column;
  justify-content: flex-start; */
  strong {
    display: block;
    color: ${palette.text.default};
    font-size: 16px;
    font-weight: 600;
    margin-right: 10px;
    width: 103px;
    em {
      color: red;
      font-style: norla;
    }
  }
  & + & {
    margin-top: 15px;
  }
  & > div.row-content {
    display: flex;
    margin-left: 3px;
    font-size: 14px;
    align-items: center;
    input {
      width: 100%;
      height: 34px;
      border-radius: 5px;
      text-indent: 5px;
      border: 1px solid #d1d1d1;
      color: ${palette.title};
    }
    input:read-only {
      border: 0;   
    }
    input:hover{
      border-color: #206EB6;
    }
  }
 
  div.row-content span.mark {
    margin: 0 8px;
    line-height: 32px;
    font-size: 20px;
  }
  div.row-content span.unit {
    margin: 0 8px;
    line-height: 40px;
    font-size: 14px;
  }
  div.row-content.specific {
    display: flex;
    flex-direction: column;
  }
  .row-btn{
    margin: 0 auto;
    display: flex;
    flex-direction: column;
}
  
  .row-two-btn{
    display: flex;
    margin: 0 auto;
    padding-top: 20px;
  }
 .row-question{
  font-size: 200px;
 }
  textarea {
    display: inline-flex;
    width: 100%;
    padding: 5px;
    border-radius: 5px;
    border: 1px solid black;
    overflow:visible;
    margin: 0 auto;
  }
  p {
    margin-top: 5px;
    color: black;
    font-size: 12px;
    
  }
 
  a {
    text-decoration: underline;
    color: ${palette.text.default};
    font-size: 14px;
    font-weight: 500;
    :hover{
      color: #206EB6;
    }
  }
  button + button {
    margin-left: 3px;
  }
  div.message {
    line-height: 28px;
    padding: 20px;
    font-size: 18px;
    text-align: center;
  }
  button{
  cursor: pointer;

  }


`;
export const PopupFormGuide = styled.div`
  div.form-guide {
    font-size: 14px;
    margin: 5px;
    span {
      display: block;
      font-weight: 600;
      color: red;
    }
  }
`;
export const FormRadioWrap = styled.div`
display: flex;
label {
  display: flex;
  align-items: center;
}
`;

export const PopupFormRow = ({ children, title }) => (
  <PopupFormRowWrapper>
    {title && <strong>{parse(title)}</strong>}
    {children}
  </PopupFormRowWrapper>
);