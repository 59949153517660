import moment from 'moment';
import React from 'react';
import styled from 'styled-components';
import { CiPlay1, CiVideoOn } from 'react-icons/ci';
import { AiOutlineDelete } from 'react-icons/ai';
import { BsDownload } from 'react-icons/bs';
import { defaultFlexCenter, fontSize, palette } from '../../modules/defines/style';
import i18n from '../../i18n';

function RecordingView({ data, handleDelete }) {
  const today = new Date();
  const lang = i18n.language;

  let langSort;
  let langFull;
    
  switch (lang) {
  case 'ko':
    langFull = moment(data.reg_date * 1000).format('YYYY-MM-DD HH:mm:ss');
    langSort = moment(data.reg_date * 1000).format('MM-DD HH:mm:ss');
    break;
  case 'en':
    langFull = moment(data.reg_date * 1000).format('MM/DD/YYYY HH:mm:ss');
    langSort = moment(data.reg_date * 1000).format('MM/DD HH:mm:ss');
    break;
  case 'ja':
    langFull = moment(data.reg_date * 1000).format('YYYY年MM月DD日 HH時MM分SS秒');
    langSort = moment(data.reg_date * 1000).format('MM月DD日 HH時MM分SS秒');
    break;
  default:
    break;
  }
  return (
    <StyledRecording>
      <div className="web_display">
        <div className="date">
          {today === data.reg_date
            ? '오늘'
            : moment(data.reg_date * 1000).format('YYYY') === '2023'
              ? langSort
              : langFull}
        </div>
        <div className="user_id">{ data.user_id}</div>
        <div className="audio">
          { !data.audio_url ? 'no Audio'
            : (
              <>
                <a
                  href={data.audio_url}
                  target="_blank" rel="noreferrer" title="Open new window">Play <CiPlay1 /> 
                </a>
                <a
                  href={data.audio_url}
                  download>Download <BsDownload />
                </a>
              </>
            )}
        </div>
        <div className="video"> 
          { !data.video_url ? 'no Video'
            : (
              <>
                <a
                  href={data.video_url}
                  target="_blank" rel="noreferrer" title="Open new window">Video <CiVideoOn /> 
                </a>
                <a
                  href={data.video_url}
                  download>Download <BsDownload />
                </a>
              </>
            )}
        </div>
        <div>
          <button type="button" onClick={() => handleDelete(data.idx)}><AiOutlineDelete style={{ fontSize:'16px' }} /></button>
        </div>
      </div>
      <div className="mobile_display">
        <div className="date">
          <span>Date </span>
          <span>
            {today === data.reg_date
              ? '오늘'
              : moment(data.reg_date * 1000).format('YYYY') === '2023'
                ? moment(data.reg_date * 1000).format('MM월 DD일')
                : moment(data.reg_date * 1000).format('YYYY년 MM월 DD일')}
          </span>
        </div>
        <div className="user_id">
          <span>User </span>
          <span>{data.user_id}</span>
        </div>
        <div className="audio">
          <span>
            Audio
          </span>
          <span>
            { !data.audio_url ? 'no Audio'
              : (
                <>
                  <a
                    href={data.audio_url}
                    target="_blank" rel="noreferrer" title="Open new window">Play <CiPlay1 /> 
                  </a>
                  <a
                    href={data.audio_url}
                    download>Download <BsDownload />
                  </a>
                </>
              )}
          </span>
        </div>
        <div className="video"> 
          <span>Video </span>
          <span>
            { !data.video_url ? 'no Video'
              : (
                <>
                  <a
                    href={data.video_url}
                    target="_blank" rel="noreferrer" title="Open new window">Video <CiVideoOn /> 
                  </a>
                  <a
                    href={data.video_url}
                    download>Download <BsDownload />
                  </a>
                </>
              )}
          </span>
        </div>
        <div className="delete">
          <button type="button" onClick={() => handleDelete(data.idx)}><AiOutlineDelete style={{ fontSize:'16px' }} /></button>
        </div>
      </div>
    </StyledRecording>
  );
}

export default RecordingView;

const StyledRecording = styled.div`
.web_display{
${defaultFlexCenter}
justify-content: space-between;
padding: 10px;
div{
    width: 25%;
    height: 40px;
    padding: 10px;
    font-size: ${fontSize.micro};
    color: ${palette.text.default};
    text-align: center;
    border-bottom: 1px solid ${palette.gray.bright};
    button{
    border: 0;
    color: #333;
    background-color: ${palette.opacity};
}
}
.audio, 
.video{
    ${defaultFlexCenter}
    a{
        ${defaultFlexCenter}
        font-size: ${fontSize.space};
        border:1px solid ${palette.gray.dark}; 
        border-radius:5px;
        padding:6px; 
        font-weight:400; 
        margin-right:10px; 
        color: ${palette.gray.dark};
        background-color: ${palette.white};
        :hover{
            border:1px solid ${palette.main.default}; 
            color: ${palette.main.default};
        }
    }
}
div:last-child{
    width: 40px;
}
}
.mobile_display{
  display: none;
}

@media screen and (max-width: 579px) and (min-width:230px) {
  background-color: ${palette.gray.boxColor};
.web_display{
  display: none;
}
.mobile_display{
  position: relative;
  display: block;
  padding: 20px;
flex-direction: column;
justify-content: center;
align-items: center;
border: 1px solid ${palette.gray.middle};
border-radius: 10px;
margin-bottom: 20px;
div{
  width: 100%;
  height: 40px;
  padding: 10px;
  ${defaultFlexCenter}
  justify-content: flex-start;
  span{
    color: ${palette.text.light};
  }
  span:first-child{
      margin-right: 20px;
      color: ${palette.black};
    }
}
.delete{
  display: block;
  width: 100%;
  position: absolute;
    bottom: 20px;
    right: 10px;
  button{
    
    border: 0;
    background-color: ${palette.opacity}; 
    }
}
.audio, 
.video{
    ${defaultFlexCenter}
    justify-content: flex-start;
    span{
      margin-right: 10px;
      ${defaultFlexCenter}
      a{
        font-size: ${fontSize.space};
        border:1px solid ${palette.gray.dark}; 
        border-radius:5px;
        padding:6px; 
        font-weight:400; 
        margin-right:10px; 
        color: ${palette.gray.dark};
        background-color: ${palette.white};
        :hover{
            border:1px solid ${palette.main.default}; 
            color: ${palette.main.default};
        }
    }
    }
    span:first-child{
      margin-right: 20px;
    }
}
div:last-child{
    width: 40px;
}


}
}
`;
