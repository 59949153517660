import { useState } from 'react';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import {
	defaultFlexCenter,
	fontSize,
	fontWeight,
	palette,
} from '../../modules/defines/style';

function DemoCategory() {
	const location = useLocation();
	const navigate = useNavigate();
	const [menu, setMenu] = useState('Audio');
	const [tab, setTab] = useState(false);
	const isNewDemoPage =
		location.pathname === '/demo/audio' ||
		location.pathname === '/demo/video' ||
		location.pathname === '/demo/telephone' ||
		location.pathname === '/demo/message';

	const isOldDemoPage =
		location.pathname === '/demo/legacy/audio' ||
		location.pathname === '/demo/legacy/video' ||
		location.pathname === '/demo/legacy/telephone';
	return (
		<>
			{isNewDemoPage && (
				<StyledTab>
					<div className="menu_box mobile">
						<button
							type="button"
							onClick={() => {
								setTab(prev => !prev);
							}}
						>
							{tab ? (
								<IoIosArrowUp
									style={{
										fontSize: '1.5rem',
										marginLeft: '10px',
										position: 'absolute',
										bottom: '8px',
										right: '20px',
									}}
								/>
							) : (
								<IoIosArrowDown
									style={{
										fontSize: '1.5rem',
										marginLeft: '10px',
										position: 'absolute',
										bottom: '8px',
										right: '20px',
									}}
								/>
							)}
							{location.pathname === '/demo/audio'
								? 'Audio'
								: location.pathname === '/demo/video'
								? 'Video'
								: location.pathname === '/demo/telephone'
								? 'Telephone'
								: location.pathname === '/demo/message'
								? 'Message'
								: menu}
						</button>
					</div>
					{tab && (
						<>
							<ul className="mobile">
								<li
									className={`${
										location.pathname === '/demo/audio' ? 'active_tab' : ''
									}`}
								>
									<button
										className={`${
											location.pathname === '/demo/audio' ? 'active' : ''
										}`}
										type="button"
										onClick={() => {
											navigate('/demo/audio');
											setMenu('Audio');
											setTab(false);
										}}
									>
										Audio
									</button>
								</li>

								<li
									className={`${
										location.pathname === '/demo/video' ? 'active_tab' : ''
									}`}
								>
									<button
										className={`${
											location.pathname === '/demo/video' ? 'active' : ''
										}`}
										type="button"
										onClick={() => {
											navigate('/demo/video');
											setMenu('Video');
											setTab(false);
										}}
									>
										Video
									</button>
								</li>

								<li
									className={`${
										location.pathname === '/demo/telephone' ? 'active_tab' : ''
									}`}
								>
									<button
										className={`${
											location.pathname === '/demo/telephone' ? 'active' : ''
										}`}
										type="button"
										onClick={() => {
											navigate('/demo/telephone');
											setMenu('Telephone');
											setTab(false);
										}}
									>
										Telephone
									</button>
								</li>

								<li
									className={`${
										location.pathname === '/demo/message' ? 'active_tab' : ''
									}`}
								>
									<button
										className={`${
											location.pathname === '/demo/message' ? 'active' : ''
										}`}
										type="button"
										onClick={() => {
											navigate('/demo/message');
											setMenu('Message');
											setTab(false);
										}}
									>
										Message
									</button>
								</li>
							</ul>
						</>
					)}
					<ul className="web">
						<li
							className={`${
								location.pathname === '/demo/audio' ||
								location.pathname === '/demo/audio' ||
								location.pathname === '/demo/audio-conference'
									? 'active_tab'
									: ''
							}`}
						>
							<button
								className={`${
									location.pathname === '/demo/audio' ? 'active' : ''
								}`}
								type="button"
								onClick={() => {
									navigate('/demo/audio');
								}}
							>
								Audio
							</button>
						</li>

						<li
							className={`${
								location.pathname === '/demo/video' ||
								location.pathname === '/demo/video-conference'
									? 'active_tab'
									: ''
							}`}
						>
							<button
								className={`${
									location.pathname === '/demo/video' ? 'active' : ''
								}`}
								type="button"
								onClick={() => {
									navigate('/demo/video');
								}}
							>
								Video
							</button>
						</li>

						<li
							className={`${
								location.pathname === '/demo/telephone' ? 'active_tab' : ''
							}`}
						>
							<button
								className={`${
									location.pathname === '/demo/telephone' ? 'active' : ''
								}`}
								type="button"
								onClick={() => {
									navigate('/demo/telephone');
								}}
							>
								Telephone
							</button>
						</li>
						<li
							className={`${
								location.pathname === '/demo/message' ? 'active_tab' : ''
							}`}
						>
							<button
								className={`${
									location.pathname === '/demo/message' ? 'active' : ''
								}`}
								type="button"
								onClick={() => {
									navigate('/demo/message');
								}}
							>
								Message
							</button>
						</li>
					</ul>
				</StyledTab>
			)}
			{isOldDemoPage && (
				<StyledTab>
					<div className="menu_box mobile">
						<button
							type="button"
							onClick={() => {
								setTab(prev => !prev);
							}}
						>
							{tab ? (
								<IoIosArrowUp
									style={{
										fontSize: '1.5rem',
										marginLeft: '10px',
										position: 'absolute',
										bottom: '8px',
										right: '20px',
									}}
								/>
							) : (
								<IoIosArrowDown
									style={{
										fontSize: '1.5rem',
										marginLeft: '10px',
										position: 'absolute',
										bottom: '8px',
										right: '20px',
									}}
								/>
							)}
							{location.pathname === '/demo/legacy' ||
							location.pathname === '/demo/legacy/audio'
								? 'Audio'
								: location.pathname === '/demo/legacy/video'
								? 'Video'
								: location.pathname === '/demo/legacy/telephone'
								? 'Telephone'
								: menu}
						</button>
					</div>
					{tab && (
						<>
							<ul className="mobile">
								<li
									className={`${
										location.pathname === '/demo/legacy/audio'
											? 'active_tab'
											: ''
									}`}
								>
									<button
										className={`${
											location.pathname === '/demo/legacy/audio' ? 'active' : ''
										}`}
										type="button"
										onClick={() => {
											navigate('/demo/legacy/audio');
											setMenu('Audio');
											setTab(false);
										}}
									>
										Audio
									</button>
								</li>

								<li
									className={`${
										location.pathname === '/demo/legacy/video'
											? 'active_tab'
											: ''
									}`}
								>
									<button
										className={`${
											location.pathname === '/demo/legacy/video' ? 'active' : ''
										}`}
										type="button"
										onClick={() => {
											navigate('/demo/legacy/video');
											setMenu('Video');
											setTab(false);
										}}
									>
										Video
									</button>
								</li>

								<li
									className={`${
										location.pathname === '/demo/legacy/telephone'
											? 'active_tab'
											: ''
									}`}
								>
									<button
										className={`${
											location.pathname === '/demo/legacy/telephone'
												? 'active'
												: ''
										}`}
										type="button"
										onClick={() => {
											navigate('/demo/legacy/telephone');
											setMenu('Telephone');
											setTab(false);
										}}
									>
										Telephone
									</button>
								</li>
							</ul>
						</>
					)}
					<ul className="web">
						<li
							className={`${
								location.pathname === '/demo/legacy/audio' ||
								location.pathname === '/demo/legacy/audio' ||
								location.pathname === '/demo/legacy/audio-conference'
									? 'active_tab'
									: ''
							}`}
						>
							<button
								className={`${
									location.pathname === '/demo/legacy/auio' ||
									location.pathname === '/demo/legacy/audio'
										? 'active'
										: ''
								}`}
								type="button"
								onClick={() => {
									navigate('/demo/legacy/audio');
								}}
							>
								Audio
							</button>
						</li>

						<li
							className={`${
								location.pathname === '/demo/legacy/video' ||
								location.pathname === '/demo/legacy/video-conference'
									? 'active_tab'
									: ''
							}`}
						>
							<button
								className={`${
									location.pathname === '/demo/legacy/video' ? 'active' : ''
								}`}
								type="button"
								onClick={() => {
									navigate('/demo/legacy/video');
								}}
							>
								Video
							</button>
						</li>

						<li
							className={`${
								location.pathname === '/demo/legacy/telephone'
									? 'active_tab'
									: ''
							}`}
						>
							<button
								className={`${
									location.pathname === '/demo/legacy/telephone' ? 'active' : ''
								}`}
								type="button"
								onClick={() => {
									navigate('/demo/legacy/telephone');
								}}
							>
								Telephone
							</button>
						</li>
					</ul>
				</StyledTab>
			)}
		</>
	);
}

export default DemoCategory;

const StyledTab = styled.section`
width: 100%;
padding: 0 3%;
padding-top: 140px;
margin-bottom: 100px;
.mobile{
  display: none;
}
.web{
  position: relative;
  ${defaultFlexCenter}
  justify-content: space-between;
  li{
    width: 100%;
    height: 42px;
    ${defaultFlexCenter}
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background-color: ${palette.gray.boxColor};
    border: 1px solid #eee;
    border-right: 0;
    border-bottom: 1px solid ${palette.gray.middle} ;
    button{
      width: 100%;
      color: ${palette.text.disabled};
      border: 0;
      background-color: ${palette.opacity};
      :hover{
        cursor: pointer;
      }
    }
  }
  li:last-child{
    border-right: 1px solid #eee;
  }
  .active_tab{
    border: 1px solid ${palette.gray.middle};
    border-bottom: 0;
    background-color: ${palette.white};
    button{
      width: 100%;
      font-size: ${fontSize.small};
      font-weight: ${fontWeight.semiBold};
      color: ${palette.main.default};
    }
  }
  .active_tab:last-child{
    border-right: 1px solid ${palette.gray.middle};
  }
}

@media screen and (max-width: 579px) and (min-width: 230px) {
  padding-top: 80px;
  margin-bottom: 60px;
  .web{
    display: none;
  }
  .menu_box{
    width: 100%;
    button{
      width: 100%;
      height: 42px;
      font-size: ${fontSize.small};
      color: ${palette.main.default};
      background-color: ${palette.white};
      border:1px solid ${palette.gray.middle};
    }
  }
  .mobile{
  display: block;
  position: relative;
  ${defaultFlexCenter}
  flex-direction: column;
  li{
    width: 100%;
    height: 42px;
    ${defaultFlexCenter}
    border-radius: 0;
    background-color: ${palette.gray.light};
    border:1px solid ${palette.gray.middle};
    border-top: 0;
    button{
      width: 100%;
      color: ${palette.text.disabled};
      border: 0;
      background-color: ${palette.opacity};
      :hover{
        cursor: pointer;
      }
    }
  }
  .active_tab{
    border-bottom: 1px solid ${palette.gray.middle};
    background-color: ${palette.white};
    button{
      width: 100%;
      font-size: ${fontSize.small};
      font-weight: ${fontWeight.default};
      color: ${palette.main.default};
    }
  }
}

}
`;