/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import Pagination from 'react-js-pagination';
import styled from 'styled-components';
import {
	StyledPagination,
	palette,
	fontWeight,
	defaultFlexCenter,
} from '../../modules/defines/style';
import myAxios from '../../modules/api/myAxios';

export default function TestKey() {
	const [currentPage, setCurrentPage] = useState(1);
	const [totalPages, setTotalPages] = useState(0);
	const [data, setData] = useState([]);
	const handlePageChange = currentPage => {
		setCurrentPage(currentPage);
	};
	const requestData = async () => {
		try {
			const response = await myAxios.get(
				`/superadmin/temp-auth/?page=${currentPage}&perPage=20`,
			);
			setData(response.data.data);
			setTotalPages(response.data.total);
		} catch (error) {
			console.log(error);
		}
	};
	useEffect(() => {
		requestData();
	}, [currentPage]);
	return (
		<div>
			<StyledContent>
				<section>
					<div className="title">
						<h3>TestKey</h3>
					</div>
					<StyledTable>
						<table className="rwd-table">
							<thead>
								<tr>
									<th>등록일</th>
									<th>만료일</th>
									<th>이메일</th>
									<th>서비스 ID</th>
									<th>서비스 키</th>
								</tr>
							</thead>
							<tbody>
								{data.map(item => {
									return (
										<tr key={item.idx}>
											<td data-th="등록일">
												{moment(item.reg_date * 1000).format(
													'YYYY-MM-DD HH:mm:ss',
												)}
											</td>
											<td data-th="만료일">
												{moment(item.expire_date * 1000).format(
													'YYYY-MM-DD HH:mm:ss',
												)}
											</td>

											<td data-th="이메일">{item.email}</td>
											<td data-th="서비스 ID">{item.service_id}</td>
											<td data-th="서비스 키">{item.service_key}</td>
										</tr>
									);
								})}
							</tbody>
						</table>
					</StyledTable>
					<StyledPagination>
						<Pagination
							activePage={currentPage}
							itemsCountPerPage={10}
							totalItemsCount={totalPages}
							pageRangeDisplayed={5}
							prevPageText="‹"
							nextPageText="›"
							onChange={handlePageChange}
						/>
					</StyledPagination>
				</section>
			</StyledContent>
		</div>
	);
}
const StyledContent = styled.div`
width: 100%;
padding-top: 120px;
section{
    padding-left: 240px;
    .title{
    width: 100%;
    margin-bottom: 50px;
    border-bottom: 1px solid ${palette.gray.middle};
    h3{
      margin-bottom: 20px;
      font-size: 1.75rem;
      font-weight: ${fontWeight.semiBold};
    }
  }
    article{
      margin-bottom: 50px;
    ${defaultFlexCenter}
    justify-content: flex-start;
    flex-wrap: wrap;
    span{
        font-size: 1.25rem;
        font-weight: 500;
    }
    button{
      ${defaultFlexCenter}
      justify-content: flex-start;
      border: 0;
      font-size: 1.25rem;
      color: #333;
      background-color: ${palette.opacity};
      :hover{
        cursor: pointer;
      }
    }
  }
}
@media screen and (min-width: 600px){
section{
   min-height: 800px; 
}
}

@media screen and (max-width:979px) and (min-width: 580px) {
section{
    padding:0 5%;
  article{
    ${defaultFlexCenter}
    margin-right: 0;
  }
}
}

@media screen and (max-width:579px) and (min-width: 230px) {
padding-top: 80px;
section{
    padding: 0 5%;
    .title{
    h3{
      margin-bottom: 30px;
      font-size: 1.5rem;
      font-weight: ${fontWeight.medium};
    }
  }
  article{
    margin-bottom: 20px;
    ${defaultFlexCenter}
    justify-content: flex-start;
    flex-wrap: wrap;
    .card{
      width: 100%;
      ${defaultFlexCenter}
      justify-content: center;
      flex-wrap: wrap;
    }
  }
}

}
`;
const StyledTable = styled.div`.rwd-table {
  width: 100%;
  margin: auto;
  min-width: 300px;
  max-width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
  margin-top: 20px;

}

.rwd-table tr:first-child {
  background-color: #f9f9f9;

}

.rwd-table tr {
  background-color: #f0f0f0;

}

.rwd-table tr:nth-child(odd):not(:first-child) {
  background-color: #f9f9f9;
}

.rwd-table th {
  display: none;
  text-align: center;
  font-size: 14px;
}

.rwd-table td {
  display: block;
  font-size: 12px;
}

.rwd-table td:first-child {
  margin-top: 20px;
}

.rwd-table td:last-child {
  margin-bottom: 20px;
}

.rwd-table td:before {
  content: attr(data-th) ": ";
  font-weight: bold;
  width: 130px;
  display: inline-block;
  color: #333;
}

.rwd-table th,
.rwd-table td {
  text-align: left;
}

.rwd-table {
  color: #333;
  border-radius: .4em;
  overflow: hidden;
}

.rwd-table tr {
  border-color: #bfbfbf;
}

.rwd-table th,
.rwd-table td {
  margin-left: 10px;
  padding: .3em 1em;
}
.rwd-table thead {
  background-color: #ebebeb;
  font-size: 16px;
  border-bottom: 1px solid #d1d1d1;
}
.rwd-table{
  .total-usage td{
    font-weight: 600;
  }
}
@media screen and (max-width: 601px) {
  .rwd-table tr:nth-child(2) {
    border-top: none;
  }
  .rwd-table tr {
    border-bottom: 1px solid #d1d1d1;
  }
  .rwd-table tr:last-child {
    border: none;
  }

}
@media screen and (min-width: 600px) {
  .rwd-table tr:hover:not(:first-child) {
    background-color: #d8e7f3;
  }
  .rwd-table td:before {
    display: none;
  }
  .rwd-table th,
  .rwd-table td {
    display: table-cell;
    padding: .25em .5em;
  }
  .rwd-table th:first-child,
  .rwd-table td:first-child {
    padding-left: 0;
  }
  .rwd-table th:last-child,
  .rwd-table td:last-child {
    padding-right: 0;
  }
  .rwd-table th,
  .rwd-table td {
    padding: 1em !important;
  }
}`;
