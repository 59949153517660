import React from 'react';
import Dropdown from '../DropDown';

export default function IssueTab({ handleLanguage }) {
	return (
		<>
			<Dropdown handleLanguage={handleLanguage}></Dropdown>
		</>
	);
}
