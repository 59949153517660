import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Pagination from 'react-js-pagination';
import moment from 'moment';
import CDRTab from './CDRTab';
import {
	palette,
	fontWeight,
	defaultFlexCenter,
	StyledPagination,
} from '../../modules/defines/style';
import { roomType } from '../../modules/defines/enums';
import myAxios from '../../modules/api/myAxios';
import { formatSecond } from '../utill/formatSecond';

export default function RealTimeCDR() {
	const [currentPage, setCurrentPage] = useState(1);
	const [totalPages, setTotalPages] = useState(0);
	const [data, setData] = useState([]);
	const currentDate = new Date();
	const currentMonth = currentDate.getMonth();
	const yearOptions = [new Date().getFullYear(), new Date().getFullYear() - 1];
	const [selectedYear, setSelectedYear] = useState(
		new Date().getFullYear().toString(),
	);
	const handlePageChange = currentPage => {
		setCurrentPage(currentPage);
	};
	const recentMonths = [
		(currentMonth - 2 + 12) % 12, // 2달 전
		(currentMonth - 1 + 12) % 12, // 1달 전
		currentMonth, // 현재월
	].reverse(); // 최근 순 정렬
	const options = recentMonths.map(month => {
		const monthString = `00${(month + 1).toString()}`.slice(-2);
		return (
			<option key={monthString} value={monthString}>
				{monthString}
			</option>
		);
	});
	// 현재 월에 대한 초기값 설정
	const [selectedMonth, setSelectedMonth] = useState(
		`00${(currentMonth + 1).toString()}`.slice(-2),
	);
	const requestData = async () => {
		try {
			// ** 추후에 year 선택하도록 추가
			const response = await myAxios.get(
				`/superadmin/cdr/?page=${currentPage}&perPage=20&month=${selectedMonth}&year=${selectedYear}`,
			);
			setData(response.data.data);
			setTotalPages(response.data.total);
		} catch (error) {
			console.log(error);
		}
	};
	useEffect(() => {
		requestData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentPage, selectedMonth, selectedYear]);
	return (
		<div>
			<StyledContent>
				<section>
					<div className="title">
						<h3>CDR</h3>
					</div>
					<div className="tab-wrap">
						<CDRTab />
						<StyledSelect>
							<div className="select">
								<select
									name="month"
									id="month"
									value={selectedMonth}
									onChange={e => {
										console.log(e.target.value);
										setSelectedMonth(e.target.value);
										setCurrentPage(1);
										console.log(selectedMonth, '선택월');
									}}
								>
									<option value="">기간 선택</option>
									{options.map(option => (
										<option key={option.key} value={option.key}>
											{`${option.key} 월`}
										</option>
									))}
								</select>
								<div style={{ marginRight: 6 }}></div>

								<select
									name="year"
									id="year"
									value={selectedYear}
									onChange={e => {
										setSelectedYear(e.target.value);
										setCurrentPage(1);
									}}
								>
									<option value="">년도 선택</option>
									{yearOptions.map(year => (
										<option key={year} value={year}>
											{`${year}년`}
										</option>
									))}
								</select>
							</div>
						</StyledSelect>
					</div>

					<StyledTable>
						<table className="rwd-table">
							<thead>
								<tr>
									<th>idx</th>
									<th>시작 시간</th>
									<th>종료 시간</th>
									<th>기업명</th>
									<th>서비스 ID</th>
									<th>사용자 ID</th>
									<th>룸 타입</th>
									<th>오디오</th>
									<th>비디오</th>
									<th>데이터</th>
									<th>사용 시간</th>
									<th>과금</th>
								</tr>
							</thead>
							<tbody>
								{data.map(item => {
									return (
										<tr key={item.idx}>
											<td data-th="idx">{item.idx}</td>
											<td data-th="시작 시간">
												{moment(item.start_date * 1000).format(
													'YYYY-MM-DD HH:mm:ss',
												)}
											</td>
											<td data-th="종료 시간">
												{moment(item.end_date * 1000).format(
													'YYYY-MM-DD HH:mm:ss',
												)}
											</td>
											<td data-th="기업명">{item.agency_name}</td>
											<td data-th="서비스 ID">{item.service_id}</td>

											<td data-th="사용자 ID">
												{item.user_id !== null && item.user_id.length > 10
													? `${item.user_id.slice(0, 10)}...`
													: item.user_id}
											</td>

											<td data-th="룸 타입">
												{/* {item.cdr_room_type} */}
												{roomType[item.room_type]}
											</td>
											<td data-th="오디오 사용량">
												{item.audio_usage === 0
													? 0
													: formatSecond(item.audio_usage, 1)}
											</td>
											<td data-th="비디오 사용량">
												{item.video_usage === 0
													? 0
													: formatSecond(item.video_usage, 1)}
											</td>
											<td data-th="데이터 사용량">
												{item.data_usage === 0
													? 0
													: formatSecond(item.data_usage, 1)}
											</td>

											<td data-th="사용 시간">{item.duration}</td>
											<td data-th="과금">
												{`${parseFloat(item.fee, 10).toLocaleString()}원`}
											</td>
										</tr>
									);
								})}
							</tbody>
						</table>
					</StyledTable>
					<StyledPagination>
						<Pagination
							activePage={currentPage}
							itemsCountPerPage={20}
							totalItemsCount={totalPages}
							pageRangeDisplayed={10}
							prevPageText="‹"
							nextPageText="›"
							onChange={handlePageChange}
						/>
					</StyledPagination>
				</section>
			</StyledContent>
		</div>
	);
}

const StyledContent = styled.div`
width: 100%;
padding-top: 120px;
section{
    padding-left: 240px;
    .title{
    width: 100%;
    margin-bottom: 50px;
    border-bottom: 1px solid ${palette.gray.middle};
    h3{
      margin-bottom: 20px;
      font-size: 1.75rem;
      font-weight: ${fontWeight.semiBold};
    }
  }
  .tab-wrap{
    display: flex;
    justify-content: flex-start;
  }
    article{
      margin-bottom: 50px;
    ${defaultFlexCenter}
    justify-content: flex-start;
    flex-wrap: wrap;
    span{
        font-size: 1.25rem;
        font-weight: 500;
    }
    button{
      ${defaultFlexCenter}
      justify-content: flex-start;
      border: 0;
      font-size: 1.25rem;
      color: #333;
      background-color: ${palette.opacity};
      :hover{
        cursor: pointer;
      }
    }
  }
}
@media screen and (min-width: 600px){
section{
   min-height: 800px; 
}
}

@media screen and (max-width:979px) and (min-width: 580px) {
section{
    padding:0 5%;
  article{
    ${defaultFlexCenter}
    margin-right: 0;
  }
}
}

@media screen and (max-width:579px) and (min-width: 230px) {
padding-top: 80px;
section{
    padding: 0 5%;
    .title{
    h3{
      margin-bottom: 30px;
      font-size: 1.5rem;
      font-weight: ${fontWeight.medium};
    }
  }
  article{
    margin-bottom: 20px;
    ${defaultFlexCenter}
    justify-content: flex-start;
    flex-wrap: wrap;
    .card{
      width: 100%;
      ${defaultFlexCenter}
      justify-content: center;
      flex-wrap: wrap;
    }
  }
}

}
`;
const StyledTable = styled.div`.rwd-table {
  width: 100%;
  margin: auto;
  min-width: 300px;
  max-width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
  margin-top: 20px;

}

.rwd-table tr:first-child {
  background-color: #f9f9f9;

}

.rwd-table tr {
  background-color: #f0f0f0;

}

.rwd-table tr:nth-child(odd):not(:first-child) {
  background-color: #f9f9f9;
}

.rwd-table th {
  display: none;
  text-align: center;
  font-size: 14px;
}

.rwd-table td {
  display: block;
  font-size: 12px;
}

.rwd-table td:first-child {
  margin-top: 20px;
}

.rwd-table td:last-child {
  margin-bottom: 20px;
}

.rwd-table td:before {
  content: attr(data-th) ": ";
  font-weight: bold;
  width: 130px;
  display: inline-block;
  color: #333;
}

.rwd-table th,
.rwd-table td {
  text-align: left;
}

.rwd-table {
  color: #333;
  border-radius: .4em;
  overflow: hidden;
}

.rwd-table tr {
  border-color: #bfbfbf;
}

.rwd-table th,
.rwd-table td {
  margin-left: 10px;
  padding: .3em 1em;
}
.rwd-table thead {
  background-color: #ebebeb;
  font-size: 16px;
  border-bottom: 1px solid #d1d1d1;
}
.rwd-table{
  .total-usage td{
    font-weight: 600;
  }
}
@media screen and (max-width: 601px) {
  .rwd-table tr:nth-child(2) {
    border-top: none;
  }
  .rwd-table tr {
    border-bottom: 1px solid #d1d1d1;
  }
  .rwd-table tr:last-child {
    border: none;
  }

}
@media screen and (min-width: 600px) {
  .rwd-table tr:hover:not(:first-child) {
    background-color: #d8e7f3;
  }
  .rwd-table td:before {
    display: none;
  }
  .rwd-table th,
  .rwd-table td {
    display: table-cell;
    padding: .25em .5em;
  }
  .rwd-table th:first-child,
  .rwd-table td:first-child {
    padding-left: 0;
  }
  .rwd-table th:last-child,
  .rwd-table td:last-child {
    padding-right: 0;
  }
  .rwd-table th,
  .rwd-table td {
    padding: 1em !important;
  }
}`;

const StyledSelect = styled.div`
.select{
 
  display: flex;
  justify-content: flex-end;
}
select{
 height: 34px;
 width: 100px;
 border: none;
 border-bottom: 1px solid #ccc;
 font-size: 15px;
 cursor: pointer;
 :focus{
  outline: none;
 }
}
`;