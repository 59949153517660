// Loading.js
import React from 'react';
import { LoadingBackground } from '../modules/defines/style';
import spinner from '../assets/spinner.gif';

export default function Loading() {
	return (
		<LoadingBackground>
			{/* <LoadingText>잠시만 기다려 주세요.</LoadingText> */}
			<img src={spinner} alt="로딩중" width="5%" />
		</LoadingBackground>
	);
}
