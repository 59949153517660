import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { BsPlusCircleFill } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import moment from 'moment';
import { Helmet } from 'react-helmet-async';
import {
	defaultFlexCenter,
	fontWeight,
	palette,
} from '../../modules/defines/style';
import AddVoucher from '../../components/Billing/AddVoucher';
import API from '../../modules/api/api';
import myAxios from '../../modules/api/myAxios';
import { roleState, userState } from '../../recoil';
import TableList from '../../components/admin/TableList';
import { activeType, expiredType } from '../../modules/defines/enums';
import Popup, { PopupFormRow } from '../../components/ui/Popup';

function Voucher() {
	const { t } = useTranslation();
	const [data, setData] = useState([]);
	const [agencyName, setAgencyName] = useState('');
	// eslint-disable-next-line no-unused-vars
	const [user, setUser] = useRecoilState(userState);
	// eslint-disable-next-line no-unused-vars
	const [role, setRole] = useRecoilState(roleState);
	const [add, setAdd] = useState(false);
	const handleRefresh = () => {
		requestData();
	};
	const [popupState, setPopupState] = useState({
		display: false,
		data: null,
	});
	const navigate = useNavigate();
	useEffect(() => {
		authUser();
		if (data.length === 0) {
			requestData();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// get data
	const requestData = () => {
		API.getVoucherList()
			.then(response => {
				setData(response.data.data);
			})
			.catch(error => {
				console.log(error);
			});
	};
	const authUser = async () => {
		try {
			const response = await myAxios.get('/users/info');
			if (response.data.result === 'success') {
				if (response.data.data.role === 'yet')
					navigate('/wait', { replace: true });
				if (response.data.data.role !== 'yet') {
					setUser(response.data.data.agency_name);
					setAgencyName(response.data.data.agency_name);
					setRole(response.data.data.role);
				}
			}
		} catch (error) {
			if (error.response.status === 401) {
				navigate('/login', { replace: true });
			}
			return error;
		}
	};
	const handleClickRow = row => {
		setPopupState(prev => ({
			...prev,
			type: 'rowdata',
			display: true,
			data: row,
		}));
	};
	const handleClosePopup = () => {
		setPopupState(prev => ({ ...prev, display: false }));
	};
	return (
		<>
			<Helmet>
				<title> {t('helmet.voucher.title')}</title>
				<meta charset="utf-8" />
				<meta name="description" content={t('helmet.voucher.desc')} />
				<meta property="og:title" content={t('helmet.voucher.ogTitle')} />
				<meta property="og:description" content={t('helmet.voucher.ogDesc')} />
				<meta
					property="og:url"
					content="https://omnitalk.io/console/billing/voucher"
				/>
				<meta
					property="twitter:title"
					content={t('helmet.voucher.twitterTitle')}
				/>
				<meta
					property="twitter:description"
					content={t('helmet.voucher.twitterDesc')}
				/>
				<meta name="robots" content="noindex" />
			</Helmet>
			<StyledCard>
				<section>
					<div className="title">
						<h3>Voucher</h3>
					</div>
					<article>
						<button
							type="button"
							onClick={() => {
								setAdd(true);
							}}
						>
							<BsPlusCircleFill
								style={{
									color: '#FA5734',
									fontSize: '1.25rem',
									marginRight: '10px',
								}}
							/>
							{t('console.voucher.register')}
						</button>
					</article>

					{data.length === 0 ? (
						<div />
					) : (
						<>
							<TableList
								data={[
									{
										width: '25% ',
										title: t('console.voucher.date'),
										type: 'element',
										element:
											navigator.language.slice(0, 2) === 'en' ||
											sessionStorage.getItem('lang') === 'en'
												? props =>
														moment(props.reg_date * 1000).format(
															'MM/DD/YYYY HH:MM',
														)
												: props =>
														moment(props.reg_date * 1000).format(
															'YYYY-MM-DD HH:MM',
														),
									},
									{
										width: '20%',
										title: t('console.voucher.bank'),
										type: 'element',
										element: props => `${props.bank_name}`,
									},
									{
										width: '20%',
										title: t('console.voucher.holder'),
										type: 'element',
										element: props => `${props.depositor}`,
									},
									{
										width: '20%',
										title: t('console.voucher.credit'),
										type: 'element',
										element: props =>
											(
												parseInt(props.credit, 10) + parseInt(props.support, 10)
											).toLocaleString(),
									},
									{
										width: '20%',
										title: t('console.voucher.fee'),
										type: 'element',
										element: props =>
											parseInt(props.credit_usage, 10).toLocaleString(),
									},
									{
										width: '20%',
										title: t('console.voucher.status'),
										type: 'element',
										element: props => expiredType[props.expired],
									},
									{
										width: '20%',
										title: t('console.voucher.approval'),
										type: 'element',
										element: props => activeType[props.status],
									},
								]}
								lists={data}
								onClick={handleClickRow}
							/>
							{popupState.display && popupState.data && (
								<Popup
									title={t('console.voucher.popup.title')}
									onClose={handleClosePopup}
									size="md"
								>
									<PopupFormRow title={t('console.voucher.popup.approve')}>
										<div className="row-content">
											{popupState.data.status === 1
												? t('console.voucher.approved')
												: t('console.voucher.unapproved')}
										</div>
									</PopupFormRow>
									<PopupFormRow title={t('console.voucher.popup.expired')}>
										<div className="row-content">
											{popupState.data.expired === 1
												? t('console.voucher.expired')
												: t('console.voucher.normal')}
										</div>
									</PopupFormRow>
									<PopupFormRow title={t('console.voucher.popup.usage')}>
										<div className="row-content">
											{parseInt(
												popupState.data.credit_usage,
												10,
											).toLocaleString()}
											원
										</div>
									</PopupFormRow>
									<PopupFormRow title={t('console.voucher.popup.date')}>
										<div className="row-content">
											{navigator.language.slice(0, 2) === 'en'
												? moment(popupState.data.reg_date * 1000).format(
														'MM/DD/YYYY HH:MM',
												  )
												: moment(popupState.data.reg_date * 1000).format(
														'YYYY-MM-DD HH:MM',
												  )}
										</div>
									</PopupFormRow>
									<PopupFormRow title={t('console.voucher.popup.cfmdate')}>
										<div className="row-content">
											{popupState.data.confirm_date === null
												? 'N/A'
												: navigator.language.slice(0, 2) === 'en'
												? moment(popupState.data.confirm_date * 1000).format(
														'MM/DD/YYYY HH:MM',
												  )
												: moment(popupState.data.confirm_date * 1000).format(
														'YYYY-MM-DD HH:MM',
												  )}
										</div>
									</PopupFormRow>
									<PopupFormRow title={t('console.voucher.popup.support')}>
										<div className="row-content">{popupState.data.support}</div>
									</PopupFormRow>

									<PopupFormRow title={t('console.voucher.popup.credit')}>
										<div className="row-content">{popupState.data.credit}</div>
									</PopupFormRow>
									<PopupFormRow title={t('console.voucher.popup.memo')}>
										<div
											className="row-content"
											style={{ width: '200px', marginLeft: '0px' }}
										>
											{popupState.data.memo}
										</div>
									</PopupFormRow>
									<PopupFormRow title={t('console.voucher.popup.bank')}>
										<div className="row-content">
											{`${popupState.data.bank_name} | ${popupState.data.depositor}`}
										</div>
									</PopupFormRow>
								</Popup>
							)}
						</>
					)}
				</section>
				{add && (
					<StyledModal>
						<AddVoucher
							setAdd={setAdd}
							requestData={requestData}
							agencyName={agencyName}
							handleRefresh={handleRefresh}
							t={t}
						/>
					</StyledModal>
				)}
			</StyledCard>
		</>
	);
}

export default Voucher;

const StyledCard = styled.div`
width: 100%;
padding-top: 120px;
section{
    padding-left: 240px;
    .title{
    width: 100%;
    margin-bottom: 50px;
    border-bottom: 1px solid ${palette.gray.middle};
    h3{
      margin-bottom: 20px;
      font-size: 1.75rem;
      font-weight: ${fontWeight.semiBold};
    }
  }
    article{
      margin-bottom: 50px;
    ${defaultFlexCenter}
    justify-content: flex-start;
    flex-wrap: wrap;
    span{
        font-size: 1.25rem;
        font-weight: 500;
    }
    button{
      ${defaultFlexCenter}
      justify-content: flex-start;
      border: 0;
      font-size: 1.25rem;
      color: #333;
      background-color: ${palette.opacity};
      :hover{
        cursor: pointer;
      }
    }
  }
}
@media screen and (min-width: 600px){
section{
  min-height: 800px;
}
}

@media screen and (max-width:979px) and (min-width: 580px) {
section{
    padding:0 5%;
  article{
    ${defaultFlexCenter}
    margin-right: 0;
  }
}
}

@media screen and (max-width:579px) and (min-width: 230px) {
padding-top: 80px;
section{
    padding: 0 5%;
    .title{
    h3{
      margin-bottom: 30px;
      font-size: 1.5rem;
      font-weight: ${fontWeight.medium};
    }
  }
  article{
    margin-bottom: 20px;
    ${defaultFlexCenter}
    justify-content: flex-start;
    flex-wrap: wrap;
    .card{
      width: 100%;
      ${defaultFlexCenter}
      justify-content: center;
      flex-wrap: wrap;
    }
  }
}

}
`;

const StyledModal = styled.div`
width: 100%;
height: 100%;
position: fixed;
top: 0;
left: 0;
background-color: rgba(0,0,0,0.4);
z-index: 100;
`;