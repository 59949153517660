import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';

const { persistAtom } = recoilPersist();
export const userState = atom({
  key: 'userState',
  default: null,
  effects_UNSTABLE: [persistAtom]
});

export const googleUserState = atom({
  key: 'googleUserState',
  default: null,
  effects_UNSTABLE: [persistAtom]
});

export const roleState = atom({
  key: 'role',
  default: null,
  effects_UNSTABLE: [persistAtom]
});