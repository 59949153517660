import moment from 'moment';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState, Fragment } from 'react';
import styled from 'styled-components';
import { FaSearchPlus } from 'react-icons/fa';
import { IoIosArrowDown } from 'react-icons/io';
import { Helmet } from 'react-helmet-async';
import { formatSecond } from '../../components/utill/formatSecond';
import {
	defaultFlexCenter,
	fontSize,
	fontWeight,
	palette,
} from '../../modules/defines/style';
import InvoiceModal from '../../components/Billing/InvoiceModal';
import myAxios from '../../modules/api/myAxios';

function Invoice() {
	const { t } = useTranslation();
	const [show, setShow] = useState(false);
	const [agencyName, setAgencyName] = useState('');
	const date = new Date();
	const today = date.getMonth();
	const [month, setMonth] = useState(`00${today.toString()}`.slice(-2));
	const [unpaid, setUnpaid] = useState('');
	const [data, setData] = useState([]);
	const [serviceData, setServiceData] = useState([]);
	const [dayData, setDayData] = useState([]);
	const [startDate, setStartDate] = useState('');
	const [endDate, setEndDate] = useState('');
	const [total, setTotal] = useState([]);
	const [isShow, setIsShow] = useState(false); // 메뉴의 초기값을 false로 설정

	const toggleMenu = () => {
		setIsShow(isShow => !isShow); // on,off 개념 boolean
	};

	useEffect(() => {
		const requestData = async () => {
			const response = await myAxios.get('/billing/agency-monthly/3');
			if (response.data.cost.billingByAgency.length === 0) return;
			setData(response.data.cost.billingByAgency);
			setAgencyName(response.data.cost.agency_name);
			if (response.data.cost.unpaid === 1) setUnpaid(t('console.unpaid1'));
			if (response.data.cost.unpaid === 0) setUnpaid(t('console.unpaid0'));
			const filteredData = response.data.cost.billingByAgency.filter(
				date =>
					moment(date.billing_start_date * 1000).format('MM') ===
					`00${today.toString()}`.slice(-2),
			);
			if (
				navigator.language.slice(0, 2) === 'en' ||
				sessionStorage.getItem('lang') === 'en'
			) {
				setStartDate(
					moment(filteredData[0].billing_start_date * 1000)
						.add(1, 'months')
						.format('MM/DD/YYYY'),
				);
				setEndDate(
					moment(filteredData[0].billing_start_date * 1000)
						.add(1, 'months')
						.add(7, 'days')
						.format('MM/DD/YYYY'),
				);
			} else {
				setStartDate(
					moment(filteredData[0].billing_start_date * 1000)
						.add(1, 'months')
						.format('YYYY-MM-DD'),
				);
				setEndDate(
					moment(filteredData[0].billing_start_date * 1000)
						.add(1, 'months')
						.add(7, 'days')
						.format('YYYY-MM-DD'),
				);
			}
		};
		const requestServiceData = async () => {
			const response = await myAxios.get('/billing/service-monthly/3');
			setServiceData(response.data.data);
		};
		const requestDayData = async () => {
			const response = await myAxios.get('/billing/day/90');
			setDayData(response.data.data);
		};
		const requestTotalData = async () => {
			const response = await myAxios.get('/billing/invoice/3');
			setTotal(response.data.invoice);
		};
		requestData();
		requestServiceData();
		requestDayData();
		requestTotalData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	const monthChange = e => {
		setMonth(e.target.value);
		const filteredData = data.filter(
			date =>
				moment(date.billing_start_date * 1000).format('MM') === e.target.value,
		);
		setStartDate(
			moment(filteredData[0].billing_start_date * 1000)
				.add(1, 'months')
				.format('YYYY-MM-DD'),
		);
		setEndDate(
			moment(filteredData[0].billing_start_date * 1000)
				.add(1, 'months')
				.add(7, 'days')
				.format('YYYY-MM-DD'),
		);
	};

	if (data === null) {
		return <div>Loading...</div>;
	}
	return (
		<>
			<Helmet>
				<title> {t('helmet.billing.title')}</title>
				<meta charset="utf-8" />
				<meta
					name="keywords"
					content="WebRTC, SIP, CPAAS, communication, cloud-based, integration, solution, provider,WebRTC CPAAS,SIP CPaaS,WebRTC SIP integration,Cloud-based WebRTC,Cloud-based SIP,WebRTC and SIP solution,WebRTC and SIP provider,옴니톡,옴니스토리,비대면통신,커뮤니케이션플랫폼,메타버스,통신,통신서비스"
				/>
				<meta name="description" content={t('helmet.billing.desc')} />
				<meta property="og:title" content={t('helmet.billing.ogTitle')} />
				<meta property="og:description" content={t('helmet.billing.ogDesc')} />
				<meta
					property="og:url"
					content="https://omnitalk.io/console/billing/invoice"
				/>
				<meta
					property="twitter:title"
					content={t('helmet.billing.twitterTitle')}
				/>
				<meta
					property="twitter:description"
					content={t('helmet.billing.twitterDesc')}
				/>
				<meta name="robots" content="noindex" />
			</Helmet>
			<StyledCard>
				<section>
					<div className="title">
						<h3 style={{ marginBottom: '0px' }}>Invoice</h3>
						<h4 style={{ marginBottom: '20px', color: '#666' }}>
							{t('console.invoice.desc')}
						</h4>
					</div>

					<article>
						<div className="location">
							{data.map((date, i) => {
								if (
									moment(date.billing_start_date * 1000).format('MM') !== month
								)
									return null;
								return (
									<p key={i}>
										<span>
											{t('console.invoice.period')} :{' '}
											{navigator.language.slice(0, 2) === 'ko' ||
											navigator.language.slice(0, 2) === 'ja'
												? `${moment(date.billing_start_date * 1000).format(
														'YYYY-MM-DD',
												  )} ~ ${moment(date.billing_end_date * 1000).format(
														'YYYY-MM-DD',
												  )}`
												: `${moment(date.billing_start_date * 1000).format(
														'MM/DD/YYYY',
												  )} ~ ${moment(date.billing_end_date * 1000).format(
														'MM/DD/YYYY',
												  )}`}
										</span>
									</p>
								);
							})}
						</div>
						<div className="view">
							<h3>
								<label htmlFor="month" />
								<select
									className="select"
									name="month"
									id="month"
									value={month}
									onChange={monthChange}
								>
									<option>{t('console.invoice.periodSelection')} </option>
									{data &&
										data.map((date, i) => (
											<option
												key={i}
												value={moment(date.billing_start_date * 1000).format(
													'MM',
												)}
											>
												{moment(date.billing_start_date * 1000).format('MM')}
											</option>
										))}
								</select>
								{t('console.invoice.amount')}
								{data.map((date, i) => {
									if (
										moment(date.billing_start_date * 1000).format('MM') !==
										month
									)
										return null;
									return (
										<>
											<b key={i}>
												{`${parseInt(date.total_pay, 10).toLocaleString()}`}원
											</b>
										</>
									);
								})}
							</h3>

							<div className="billing-view">
								<span className="point">{unpaid}</span>
								<span>{t('console.invoice.information')} </span>
								<button
									style={{
										border: 'none',
										background: 'transparent',
										cursor: 'pointer',
									}}
									type="button"
									onClick={() => {
										setShow(true);
									}}
								>
									<FaSearchPlus
										style={{
											color: '#666',
											border: 'none',
											fontSize: '1.25rem',
										}}
									/>
								</button>
							</div>
							{/* 기존 rwd-table <article classname="bottom"> 으로 감싸져있었음 */}
							<table className="rwd-table">
								<thead>
									<tr>
										<th>Service ID</th>
										<th>Audio</th>
										<th>Video</th>
										<th>Data</th>
										<th>{t('console.invoice.usage')}</th>
										<th>{t('console.invoice.Fee')}</th>
									</tr>
								</thead>
								<tbody>
									{serviceData &&
										serviceData.flat().map((list, i) => {
											if (
												moment(list.billing_start_date * 1000).format('MM') !==
												month
											)
												return null;
											return (
												<>
													<Fragment key={i}>
														<tr key={i}>
															<td data-th="Service ID">
																{list.billing_service_id}
															</td>
															<td data-th="Audio">
																{list.audio === 0
																	? 0
																	: formatSecond(list.audio, 1)}
															</td>
															<td data-th="Video">
																{list.video === 0
																	? 0
																	: formatSecond(list.video, 1)}
															</td>
															<td data-th="Data">
																{list.data === 0
																	? 0
																	: formatSecond(list.data, 1)}
															</td>
															<td data-th="사용량">
																{`${formatSecond(
																	parseInt(list.audio, 10) +
																		parseInt(list.video, 10) +
																		parseInt(list.data, 10),
																)}`}
															</td>
															<td data-th="요금">
																{`${parseInt(
																	list.total_pay,
																	10,
																).toLocaleString()}원`}
															</td>
														</tr>
													</Fragment>
												</>
											);
										})}
									{total &&
										total.map((list, i) => {
											if (moment(list.billing_date).format('MM') !== month)
												return null;
											return (
												<>
													<Fragment key={i}>
														<tr key={i} className="total-info">
															<td data-th="총 합계">
																{t('console.invoice.total')}
															</td>
															<td data-th="Audio Total">
																{list.audio_usage === 0
																	? 0
																	: formatSecond(list.audio_usage, 1)}
															</td>
															<td data-th="Video Total">
																{list.video_usage === 0
																	? 0
																	: formatSecond(list.video_usage, 1)}
															</td>
															<td data-th="Data Total">
																{list.data_usage === 0
																	? 0
																	: formatSecond(list.data_usage, 1)}
															</td>
															<td data-th="사용량 Total">
																{`${formatSecond(
																	parseInt(list.audio_usage, 10) +
																		parseInt(list.video_usage, 10) +
																		parseInt(list.data_usage, 10),
																)}`}
															</td>
															<td data-th="요금 Total">
																{`${parseInt(
																	list.total_cost,
																	10,
																).toLocaleString()}원`}
															</td>
														</tr>
													</Fragment>
												</>
											);
										})}
								</tbody>
							</table>
						</div>
					</article>

					<div className="daily-usage-box">
						<h3
							style={{
								textAlign: 'left',
								alignSelf: 'flex-start',
								fontSize: '20px',
								fontWeight: '600',
							}}
						>
							{t('console.invoice.usageTitle')}
						</h3>
						<button type="button" onClick={() => toggleMenu()}>
							<IoIosArrowDown />
						</button>
					</div>

					{isShow && (
						<article>
							<table className="rwd-table">
								<thead>
									<tr>
										<th>Use Date</th>
										<th>Service ID</th>
										<th>Audio</th>
										<th>Video</th>
										<th>Data</th>
										<th>사용량</th>
										<th>요금</th>
									</tr>
								</thead>
								<tbody>
									{dayData &&
										dayData.map((list, i) => {
											if (
												moment(list.day_start_date * 1000).format('MM') !==
												month
											)
												return null;
											return (
												<>
													<Fragment key={i}>
														<tr key={i}>
															<td data-th="Use Date">
																{navigator.language.slice(0, 2) === 'en'
																	? moment(list.day_start_date * 1000).format(
																			'MM/DD/YYYY',
																	  )
																	: moment(list.day_start_date * 1000).format(
																			'YYYY-MM-DD',
																	  )}
															</td>
															<td data-th="Service ID">
																{list.day_service_id}
															</td>
															<td data-th="Audio">
																{list.day_audio_usage === 0
																	? 0
																	: formatSecond(list.day_audio_usage, 1)}
															</td>
															<td data-th="Video">
																{list.day_video_usage === 0
																	? 0
																	: formatSecond(list.day_video_usage, 1)}
															</td>
															<td data-th="Data">
																{list.day_data_usage === 0
																	? 0
																	: formatSecond(list.day_data_usage, 1)}
															</td>
															<td data-th="사용량">
																{`${formatSecond(
																	parseInt(list.day_audio_usage, 10) +
																		parseInt(list.day_video_usage, 10) +
																		parseInt(list.day_data_usage, 10),
																)}`}
															</td>
															<td data-th="요금">
																{`${parseInt(
																	list.day_total_pay,
																	10,
																).toLocaleString()}원`}
															</td>
														</tr>
													</Fragment>
												</>
											);
										})}
									{/* show accumulated each row's data in tfoot tag only one line */}
								</tbody>
							</table>
						</article>
					)}
					<div className="bottom-space" />
				</section>
				{show && (
					<StyledModal>
						<InvoiceModal
							setShow={setShow}
							startDate={startDate}
							endDate={endDate}
							month={month}
							unpaid={unpaid}
							agencyName={agencyName}
						/>
					</StyledModal>
				)}
			</StyledCard>
		</>
	);
}

export default Invoice;

const StyledCard = styled.div`
width: 100%;
padding-top: 120px;
section{
    padding-left: 240px;
    min-height: 800px;
    .title{
    width: 100%;
    h3{
      margin-bottom: 65px;
      font-size: 1.75rem;
      color: #333;
      font-weight: ${fontWeight.semiBold};
      select{
        color: #222;
      }
    }
    h4{
      font-size: 20px;
    }
  }
  article{
    ${defaultFlexCenter}
    flex-direction: column;
    margin-bottom: 20px;
    .location{
      width: 100%;
      height: fit-content;
      padding-left: 20px;
      background-color: ${palette.gray.bright};
      border-top: 1px solid #d7d7d7 ;
      border-left:  1px solid #d7d7d7 ;
      border-right:  1px solid #d7d7d7 ;
      border-radius: 5px 5px 0px 0px;
      p{
        span{
          font-size: ${fontSize.micro};
          color: ${palette.text.default};
        }
      }
    }
    .view{
      width: 100%;
      background-color: #fff;
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      flex-direction: column;
      border-left:  1px solid #d7d7d7 ;
      border-right:  1px solid #d7d7d7 ;
      border-bottom:  1px solid #d7d7d7 ;
      border-radius: 0px 0px 5px 5px;
      h3{
        font-size: ${fontSize.medium};
        margin-right: 20px;
        padding-top: 20px;
        select{
          font-size: ${fontSize.medium};
          margin-right: 8px;
          font-size: 17px;
          width: 50px;
        }
        b{
          color: ${palette.main.default};
          padding-left: 20px;
          font-size: 26px;
        }
      }
      .point{
            color: ${palette.main.default};
            font-weight: 500;
            margin-left: 2px;
            margin-right: 6px;
          }
      .total{
        float: right;
        margin-right: 40px;
        padding-bottom: 20px;
        div{
          width: 100%;
          b{
            font-size: ${fontSize.large};
            font-weight: ${fontWeight.medium};
            color: ${palette.main.default};
          }
      
        }
        .total_info{
          ${defaultFlexCenter}
          justify-content: flex-start;
          div{
            width: 160px;
            h4{
              font-size: ${fontSize.small};
              font-weight: ${fontWeight.regular};
              color: ${palette.text.light};
            }
          }
          div:nth-child(2){
            width: 80px;
          }
        }
      }
    }
  .billing-view{
    display: flex;
    padding-right: 15px;
    margin-bottom: 10px;
    flex-direction: row-reverse;
  }
  }
  .bottom{
    width: 100%;
    ${defaultFlexCenter}
    flex-direction: row;
    justify-content: space-around;
    flex-direction: column;
    div{
      width: 460px;
      height: 390px;
      padding: 0 30px;
      background-color: ${palette.gray.light};
      border-top: 1px solid ${palette.gray.middle};
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      box-shadow: ${palette.shadow};
      h3{
        width: 100%;
        padding-bottom: 20px;
        border-bottom: 1px solid ${palette.gray.middle};
        align-self: flex-start;
        border-bottom:1px solid #d1d1d1;
      }
      ul{
        li{
          width: 100%;
          height: 50px;
          ${defaultFlexCenter}
          justify-content: space-between;
          border-bottom: 1px solid ${palette.gray.bright};
          span{
            width: 100px;
            text-align: center;
            font-size: ${fontSize.micro};
            color: ${palette.text.light};
          }
          span:nth-child(1){
            text-align: start;
          }
          .point{
            color: ${palette.main.default};
          }
        }
        li:last-child{
          border: 0;
        }
      }
    }
    .info{
      p{
        width: 100%;
        height: 50px;
        margin: 0;
        color: ${palette.text.light};
          ${defaultFlexCenter}
          justify-content: space-between;
        border-bottom: 1px solid ${palette.gray.bright};
        span{
          font-size: ${fontSize.micro};
        }
      }
      ul{
        li{
          span{
            width: 50%;
            text-align: end;
          }
        }
      }
    }
    .detail {
      height: fit-content;
    }
    tr.total-info{
      font-weight: 700;
    }
  }
  .daily-usage-box{
    display: flex;
    button{
      background-color: transparent;
      border: none;
      cursor: pointer;
    }
    margin-bottom: 10px;
  }
  .bottom-space{
    margin-bottom: 30px;
  }
/* table style ----- */
.rwd-table {
  width: 95%;
  margin: auto;
  min-width: 300px;
  max-width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
  margin-top: 20px;
}

.rwd-table tr:first-child {
  background-color: #f9f9f9;

}

.rwd-table tr {
  background-color: #f0f0f0;
}

.rwd-table tr:nth-child(odd):not(:first-child) {
  background-color: #f9f9f9;
}

.rwd-table th {
  display: none;
}

.rwd-table td {
  display: block;
  font-size: 14px;
}

.rwd-table td:first-child {
  margin-top: 20px;
}

.rwd-table td:last-child {
  margin-bottom: 20px;
}

.rwd-table td:before {
  content: attr(data-th) ": ";
  font-weight: bold;
  width: 130px;
  display: inline-block;
  color: #333;
}

.rwd-table th,
.rwd-table td {
  text-align: left;
}

.rwd-table {
  color: #333;
  border-radius: .4em;
  overflow: hidden;
}

.rwd-table tr {
  border-color: #bfbfbf;
}

.rwd-table th,
.rwd-table td {
  margin-left: 10px;
  padding: .3em 1em;
}
.rwd-table thead {
  background-color: #ebebeb;
  font-size: 16px;
  border-bottom: 1px solid #d1d1d1;
}
.rwd-table{
  .total-usage td{
    font-weight: 600;
  }
}
@media screen and (max-width: 601px) {
  .rwd-table tr:nth-child(2) {
    border-top: none;
  }
  .rwd-table tr {
    border-bottom: 1px solid #d1d1d1;
  }
  .rwd-table tr:last-child {
    border: none;
  }

}
@media screen and (min-width: 600px) {
  .rwd-table tr:hover:not(:first-child) {
    background-color: #d8e7f3;
  }
  .rwd-table td:before {
    display: none;
  }
  .rwd-table th,
  .rwd-table td {
    display: table-cell;
    padding: .25em .5em;
  }
  .rwd-table th:first-child,
  .rwd-table td:first-child {
    padding-left: 0;
  }
  .rwd-table th:last-child,
  .rwd-table td:last-child {
    padding-right: 0;
  }
  .rwd-table th,
  .rwd-table td {
    padding: 1em !important;
  }
}
/* table style end ----- */
}

@media screen and (max-width:1200px) and (min-width:980px) {
width: 100%;
padding: 120px 5% 0 0;
section{
  article{
    .view{
      .total{
        .total_info{
          div{
            width: 140px;
          }
          div:first-child{
            width: 120px;
          }
          div:nth-child(2){
            width: 20px;
            margin-right: 30px;
          }
        }
      }
    }  
   
  }
  .bottom{
    width: 100%;
    div{
      width: 48%;
    }
    .detail {
      height: fit-content;
    }
  }
}
}

@media screen and (max-width: 979px) and (min-width: 580px) {
section{
    padding:0 5%;
  article{
    .view{
      width: 100%;
      background-color: ${palette.gray.light};
      h3{
        padding-left: 50px;
        font-size: ${fontSize.regular};
        select{
          margin-right: 10px;
          font-size: 17px;
          width: 50px;
        }
      }
      .total{
        width: 90%;
        .total_info{
          ${defaultFlexCenter}
          justify-content: space-around;
        }
      }
    }
  }
  .bottom{
    width: 100%;
    ${defaultFlexCenter}
    flex-direction: column;
    div{
      width: 100%;
      margin-bottom: 20px;
    }
  }
}
}

@media screen and (max-width: 579px) and (min-width: 230px) {
  padding-top:80px;
section{
    padding:0 5%;
    .title{
      h3{
        margin-bottom: 50px;
        font-weight: ${fontWeight.medium};
      }
    }
  article{
    .view{
      width: 100%;
      background-color: ${palette.gray.light};
      h3{
        padding-left: 50px;
        font-size: ${fontSize.regular};
        select{
          margin-right: 10px;
          font-size: ${fontSize.medium};
          font-size: 17px;
          width: 50px;
        }
      }
      .total{
        width: 100%;
        padding: 0 20px;
        .total_info{
          ${defaultFlexCenter}
          justify-content: space-around;
        }
      }
    }
  }
  .bottom{
    width: 100%;
    ${defaultFlexCenter}
    flex-direction: column;
    div{
      width: 100%;
      margin-bottom: 20px;
    
    }
  }
}
}

@media screen and (max-width: 460px) and (min-width: 230px){
  section {
    .title{
      
      h3 {
        font-size: 20px;
      }
      h4{
        font-size: 16px;
        margin-top: 10px;
      }
    }
  article{
    .view{
      display: flex;
      align-items: center;
      h3{
        b{
        font-size: 19px;
        padding-left: 5px;
      }
      }
    
    }
  }
  }
}
`;
const StyledModal = styled.div`
width: 100%;
height: 100%;
position: fixed;
top: 0;
left: 0;
background-color: rgba(0,0,0,0.4);
z-index: 100;
`;