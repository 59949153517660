import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { AiOutlineCopy } from 'react-icons/ai';
import { RxEyeOpen, RxEyeClosed } from 'react-icons/rx';
import { TbCheck } from 'react-icons/tb';
import CopyToClipboard from 'react-copy-to-clipboard';
import { defaultFlexCenter, fontSize, fontWeight, palette } from '../../modules/defines/style';

const ServiceKey = ({ keyValue }) => {
  const [hideKey, setHideKey] = useState(true);
  const [copyBtn, setCopyBtn] = useState(false);
  const keyInput = useRef();
  const container = useRef();
  
  const handleKey = () => {
    setHideKey(!hideKey);
  };
    
  const handleCopy = () => {
    setCopyBtn(true);
  };
    
  return (
    
    <KeyWrap ref={container}>
      <div className="input_box">
        <input name="serviceKey" type={hideKey ? 'password' : 'text'} ref={keyInput} value={keyValue} readOnly />
        {hideKey === true
          ? (
            <p className="icon">
              <RxEyeOpen onClick={handleKey} style={{ fontSize: '1rem', marginRight: '10px', cursor: 'pointer' }} />
            </p>
          ) : (
            <p className="icon">
              <RxEyeClosed onClick={handleKey} style={{ fontSize: '1rem', marginRight: '10px', cursor: 'pointer' }} />
            </p>
          )}
        <p className="copy_icon">
          <CopyToClipboard text={keyValue}>
            <button type="button">{ copyBtn === true ? <TbCheck /> : <AiOutlineCopy onClick={handleCopy} />}</button>
          </CopyToClipboard>
        </p>  
      </div>
      <div className="copied">
        {copyBtn && <span>Service-key copied!</span>}
      </div>
    </KeyWrap>
  );
};
export default ServiceKey;

const KeyWrap = styled.div`
width: 240px;
height: 40px;
position: relative;
    .input_box{
        ${defaultFlexCenter}
        justify-content: flex-start;
        input{
            border: 0;
            background-color: ${palette.opacity};
        }
        button{
            border: 0;
            background-color: ${palette.opacity};
            :hover{
              cursor: pointer;
            }
        }
    }
    .copied{
        position: absolute;
        bottom: -10px;
        right: 0;
        color:${palette.main.default};
        :hover{
          cursor: pointer;
        }
        span{
        font-size: ${fontSize.micro};
        }
    }

@media screen and (max-width:579px) and (min-width:230px) {
width: 100%;
position: relative;
  .input_box{
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    input{
      position: absolute;
      top: 10px;
      left: 10px;
      color: ${palette.text.light};
        }
    .icon{
      position: absolute;
      top: -6px;
      left: 130px;
    }
    .copy_icon{
      position: absolute;
      top: -10px;
      left: 150px;
      button{
        font-weight: ${fontWeight.light};
        color: ${palette.text.light};
      }
      
    }
        button{
            border: 0;
            background-color: ${palette.opacity};
            :hover{
              cursor: pointer;
            }
        }
    }
    .copied{
      position: absolute;
      top: -10px;
      left: 180px;
      color:${palette.main.default};
        :hover{
          cursor: pointer;
        }
        span{
        font-size: ${fontSize.micro};
        }
    }

    }
`;
