
function NotFound() {
	return (
		<>
			<main className="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
				<div className="text-center">
					<p className="text-center font-semibold text-main-50">404</p>
					<h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">
						Not Found
					</h1>
					<p className="mt-6 text-base leading-7 text-gray-600">
						죄송합니다. 요청하신 페이지를 찾을 수 없습니다.
					</p>
					<div className="mt-10 flex items-center justify-center gap-x-6">
						<a
							href="/"
							className="rounded-md bg-main-50 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-main-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
						>
							메인으로 돌아가기
						</a>
						{/* <a href="/contact" className="text-sm font-semibold text-gray-900 hover:text-main-50">
              Contact support 
              <span aria-hidden="true">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2.0" stroke="currentColor" class="ml-2 inline-block w-4 h-4">
               <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
              </svg>
            </span>
            </a> */}
					</div>
				</div>
			</main>
		</>
	);
}
export default NotFound;

