import React, { useState } from 'react';
import styled from 'styled-components';
import { GrClose } from 'react-icons/gr';
import {
	defaultFlexCenter,
	defaultInput,
	fontSize,
	palette,
} from '../../modules/defines/style';
import API from '../../modules/api/api';
import myAxios from '../../modules/api/myAxios';
import Loading from '../Loading';

function AddServiceId({ setOpenModal, requestData, t }) {
	const [isLoading, setIsLoading] = useState(false);
	const [errMSg, setErrMsg] = useState('');
	const [formData, setFormData] = useState({
		domain: '',
		memo: '',
		service: '0',
	});

	const handleFormChange = e => {
		setFormData(prev => ({ ...prev, [e.target.name]: e.target.value }));
	};

	const handleRadioChange = name => {
		setFormData(prev => ({ ...prev, [name.target.name]: name.target.value }));
	};

	const handleRequestAdd = async () => {
		try {
			setIsLoading(true);
			const response = await myAxios.post('/users/service', {
				domain: formData.domain,
				service: parseInt(formData.service, 10),
				memo: formData.memo,
			});
			if (response.status === 200) {
				setOpenModal(false);
				setFormData('');
				requestData();
			}
		} catch (error) {
			console.log(error); //Request failed with status code 400
			if (error.response.status === 400) {
				const errormessage = error.response.data.message;
				const trimErrMessage = errormessage.substring(15);
				setErrMsg(trimErrMessage);
			}
		} finally {
			setIsLoading(false);
		}
	};
	return (
		<>
			{isLoading && <Loading className="w-8"></Loading>}
			<StyledModal>
				<div className="title">
					<h1>Service ID</h1>
					<button
						type="button"
						onClick={() => {
							setOpenModal(false);
						}}
					>
						<GrClose style={{ fontSize: '1.25rem' }} />
					</button>
				</div>

				<div className="input_box">
					<label htmlFor="domain">{t('console.modal.add.domain')}</label>
					<input
						type="text"
						id="domain"
						name="domain"
						placeholder="https://domain:port"
						maxLength="128"
						value={formData.domain}
						onChange={handleFormChange}
					/>
				</div>
				<div className="input_box">
					<label htmlFor="memo">{t('console.modal.add.memo')}</label>
					<input
						type="text"
						id="memo"
						name="memo"
						maxLength="100"
						value={formData.memo}
						onChange={handleFormChange}
					/>
				</div>
				<div className="radio">
					<h3>{t('console.modal.add.radio')}</h3>
					<input
						type="radio"
						name="service"
						value="0"
						checked={formData.service === '0'}
						onChange={handleRadioChange}
					/>
					<label htmlFor="service">Web</label>
					<input
						type="radio"
						name="service"
						value="1"
						checked={formData.service === '1'}
						onChange={handleRadioChange}
					/>
					<label htmlFor="service">App</label>
				</div>
				<div className="flex justify-center items-center">
					<span className="mb-4 font-bold text-red-500">{errMSg}</span>
				</div>
				<div className="button">
					{formData.domain === '' || formData.memo === '' ? (
						<button
							type="button"
							onClick={handleRequestAdd}
							disabled
							className="disabled"
						>
							{t('console.modal.add.button')}
						</button>
					) : (
						<button type="button" onClick={handleRequestAdd}>
							{t('console.modal.add.button')}
						</button>
					)}
				</div>
			</StyledModal>
		</>
	);
}

export default AddServiceId;

const StyledModal = styled.div`
width: 465px;
height: 530px;
padding: 30px;
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%,-50%);
border-radius:10px;
background-color: ${palette.gray.light};
.title{
    ${defaultFlexCenter}
    justify-content: space-between;
    margin-bottom: 20px;
    border-bottom: 1px solid ${palette.gray.middle};
    h1{
    font-size: ${fontSize.medium};
    }
    button{
        border: 0;
        background-color: ${palette.opacity};
    }
}
.input_box{
    width: 100%;
    margin-bottom: 20px;
    label{
        display: inline-block;
        width: 100%;
        line-height: 40px;
    }
    input{
        ${defaultInput}
        width: 100%;
    }
}
.radio{
    margin-bottom: 20px;
    label{
        margin-right: 20px;
    }
}
.button{
    ${defaultFlexCenter}
    button{
        width: 164px;
        height: 42px;
        background-color: ${palette.main.default};
        border: 0;
        border-radius: 5px;
        color: ${palette.white};
        :hover{
          cursor: pointer;
        }
    }
        .disabled{
        width: 164px;
        height: 42px;
        border-radius: 5px;
        background-color: rgba(255,83,29,0.5);
        font-size: 1rem;
        color: white;
        }
}

@media screen and (max-width:579px) and (min-width:230px) {
width: 90%;
}
`;
