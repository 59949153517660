import { useTranslation } from 'react-i18next';

function Wait() {
	const { t } = useTranslation();
	// TODO: wait page layout
	return (
		<section className="min-h-[70vh]">
			<main className="grid  place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8 translate-y-3/4 ">
				<div className="text-center">
					<h1 className="mt-4 text-3xl font-bold tracking-tight text-main-50 sm:text-5xl">
						Welcome !
					</h1>
					<p className="mt-6  leading-10 text-black text-3xl text-center">
						{t('wait.pOne')}
						<br />
						{t('wait.pTwo')}
					</p>
					<div className="mt-10 flex items-center justify-center gap-x-6">
						<a
							href="/"
							className="rounded-md bg-main-50 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-main-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
						>
							메인으로 돌아가기
						</a>
						<a
							href="/contact"
							className="text-sm font-semibold text-gray-900 hover:text-main-50"
						>
							Contact support
							<span aria-hidden="true">
								<svg
									xmlns="http://www.w3.org/2000/svg"
									fill="none"
									viewBox="0 0 24 24"
									stroke-width="2.0"
									stroke="currentColor"
									class="ml-2 inline-block w-4 h-4"
								>
									<path
										stroke-linecap="round"
										stroke-linejoin="round"
										d="M8.25 4.5l7.5 7.5-7.5 7.5"
									/>
								</svg>
							</span>
						</a>
					</div>
				</div>
			</main>
		</section>
	);
}
export default Wait;

