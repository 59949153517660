import { useTranslation } from 'react-i18next';

export const COPORATION = 1;
export const COMPANY = 2;
export const PREFOUNDER = 3;

export function CompanyDistribution(startup) {
  const { t } = useTranslation();
  switch (startup) {
  case COPORATION: return t('console.util.coporation');
  case COMPANY: return t('console.util.company');
  case PREFOUNDER: return t('console.util.prefounder');
  default: return 'N/A';
  }
}

// export default companyDistribution;