import { CALL_TYPE, TRACK_TYPE } from "../public-types/common";

export const SYSTEM = {
    SDK: 'JS',
    VER: '2.0.3',
    MAX_USER_CNT: 32
}

export interface DeviceInfo {
    deviceId: string;
    label: string;
}

export interface IceCandidate {
    type: string;
    candidate?: string;
    sdpMLineIndex?: number;
    sdpMid?: string;
    completed?: boolean;
}

export interface CallInfo {
    cmd: string;
    session: string;
    track: TRACK_TYPE;
    call_type: CALL_TYPE;
    caller: string;
    callee: string;
}