import React, { useEffect, useRef, useState } from 'react';
import BreadCrumb from '../BreadCrumb';
import TimeLine from './TimeLine';
import ChangeLogDetail from './ChangeLogDetail';
import { useRecoilValue } from 'recoil';
import { roleState } from '../../recoil';
import Loading from '../Loading';
import TextEditor from '../community/TextEditor';
import Popup, { PopupFormRow } from '../ui/Popup';
import myAxios from '../../modules/api/myAxios';
import Button from '../admin/Button';
import PopupLoading from '../PopupLoading';
import { StyledPagination } from '../../modules/defines/style';
import Pagination from 'react-js-pagination';
import { useMediaQuery } from 'react-responsive';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
export default function History({ language }) {
	const { t } = useTranslation();

	const isSmallScreen = useMediaQuery({ maxWidth: 640 });
	const [openEditor, setOpenEditor] = useState(false);
	const role = useRecoilValue(roleState);

	const [content, setContent] = useState('');
	const [data, setData] = useState('');
	const [formData, setFormData] = useState(null);
	const editorRef = useRef();
	const [loading, setLoading] = useState(false);
	const [isValidErrOn, setIsVallidErrOn] = useState(false);
	const [reason, setReason] = useState('');
	const [currentPage, setCurrentPage] = useState(1);
	const [totalPages, setTotalPages] = useState(0);
	const [selectedLanguageIndex, setSelectedLanguageIndex] = useState(0);
	const [selectedLanguage, setSelectedLanguage] = useState('');
	const handleLanguage = selectedIndex => {
		setSelectedLanguageIndex(selectedIndex);
		setCurrentPage(1);
	};
	const options = [
		'Typescript',
		'ReactNative',
		'Flutter',
		'iOS',
		'Android',
		'Unity',
	];
	const [formValues, setFormValues] = useState({
		idx: 0,
		lang: '',
		contents: '',
		writer: '',
	});
	const handleClosePopup = () => {
		setPopupState(prev => ({
			...prev,
			display: false,
			type: null,
			data: null,
		}));
	};
	const [confirm, setConfirm] = useState({
		display: false,
		type: null,
	});

	const [popupState, setPopupState] = useState({
		display: false,
		type: '',
		data: null,
	});
	const handleClickRow = row => {
		setPopupState(prev => ({
			...prev,
			display: true,
			type: 'rowdata',
			data: row,
		}));
		setFormValues({
			idx: row.idx,
			lang: row.lang,
			contents: row.contents,
			writer: row.writer,
			version: row.version,
		});
	};
	const handleFormChange = e => {
		setFormValues(prev => ({ ...prev, [e.target.name]: e.target.value }));
	};
	const handleModify = async () => {
		setLoading(true);
		try {
			const response = await myAxios.patch(
				`/superadmin/changelog/${popupState.data.idx}`,
				{ ...formValues },
			);
			if (response.data.result === 'success') {
				setLoading(false);
				setConfirm(prev => ({ ...prev, display: false }));
				setPopupState(prev => ({ ...prev, display: false, data: null }));
			}
		} catch (error) {
			setLoading(false);
			setConfirm(prev => ({ ...prev, display: false }));
		}
	};
	const handleDelete = async () => {
		setLoading(true);
		try {
			const response = await myAxios.delete(
				`/superadmin/changelog/${popupState.data.idx}`,
			);
			if (response.data.result === 'success') {
				setLoading(false);
				setConfirm(false);
				setPopupState(prev => ({ ...prev, display: false, data: null }));
			}
		} catch (error) {
			setLoading(false);
		}
	};

	const onChange = () => {
		setContent(editorRef.current.getInstance().getHTML());
	};

	const onOffEditor = () => {
		setOpenEditor(false);
	};
	const onSelect = e => {
		setFormData(prev => ({ ...prev, [e.target.name]: e.target.value }));
	};

	useEffect(() => {
		handleDataRequest(selectedLanguageIndex, currentPage);
	}, [selectedLanguageIndex, currentPage]);

	const handleDataRequest = async (tabIndex, currentPage) => {
		try {
			let url = '';
			switch (tabIndex) {
				case 0:
					url = `/superadmin/changelog?page=${currentPage}&perPage=5`;
					setSelectedLanguage('All');
					break;
				case 1:
					url = `/superadmin/changelog?lang=typescript&page=${currentPage}&perPage=5`;
					setSelectedLanguage('Typescript');
					break;

				case 2:
					url = `/superadmin/changelog?lang=reactnative&page=${currentPage}&perPage=5`;
					setSelectedLanguage('ReactNative');
					break;
				case 3:
					url = `/superadmin/changelog?lang=flutter&page=${currentPage}&perPage=5`;
					setSelectedLanguage('Flutter');
					break;
				case 4:
					url = `/superadmin/changelog?lang=ios&page=${currentPage}&perPage=5`;
					setSelectedLanguage('iOS');
					break;
				case 5:
					url = `/superadmin/changelog?lang=android&page=${currentPage}&perPage=5`;
					setSelectedLanguage('Android');
					break;
				case 6:
					url = `/superadmin/changelog?lang=unity&page=${currentPage}&perPage=5`;
					setSelectedLanguage('Unity');
					break;

				default:
					url = `/superadmin/changelog?page=${currentPage}&perPage=5`;
					setSelectedLanguage('All');
			}

			const response = await myAxios.get(url);
			if (response.data.result === 'success') {
				setData(response.data.data);
				setTotalPages(response.data.total);
			}
		} catch (error) {
			console.log(error);
		}
	};

	function validateObject(formData) {
		if (formData.contents === undefined || formData.contents.trim() === '') {
			return { result: false, reason: '내용을 입력해 주세요.' }; // category 속성이 유효하지 않음
		}
		console.log('second');
		if (formData.lang === undefined) {
			return { result: false, reason: '언어 카테고리를 선택해 주세요.' }; // category 속성이 유효하지 않음
		}

		if (formData.writer === undefined) {
			return { result: false, reason: '작성자를 입력해 주세요.' }; // category 속성이 유효하지 않음
		}

		if (formData.version === undefined) {
			return { result: false, reason: '버전정보를 입력해 주세요.' }; // category 속성이 유효하지 않음
		}

		return true; // 모든 속성이 유효함
	}

	const sendReq = async () => {
		console.log('요청을 보냅니다.');
		setIsVallidErrOn(false);
		const updatedFormData = { ...formData, contents: content };
		const isValid = validateObject(updatedFormData);
		console.log(isValid.reason);
		if (isValid.result === false) {
			setIsVallidErrOn(true);
			setReason(isValid.reason);
			setLoading(false);
		} else {
			setLoading(true);

			try {
				await myAxios.post('/superadmin/changelog', updatedFormData);
				window.location.replace('/history');
				setLoading(false);
			} catch (error) {
				console.log(error);
			}
		}
	};

	const handlePageChange = currentPage => {
		setCurrentPage(currentPage);
	};
	const doesNeedPagination = language => {
		if (language === 'All') {
			setTotalPages(data.total);
		}
	};

	useEffect(() => {
		doesNeedPagination();
	});

	return (
		<>
			<Helmet>
				<title>{t('helmet.history.title')}</title>
				<meta charset="utf-8" />
				<meta
					name="keywords"
					content="WebRTC, SIP, CPAAS, communication, cloud-based, integration, solution, provider,WebRTC CPAAS,SIP CPaaS,WebRTC SIP integration,Cloud-based WebRTC,Cloud-based SIP,WebRTC and SIP solution,WebRTC and SIP provider,옴니톡,옴니스토리,비대면통신,커뮤니케이션플랫폼,메타버스,통신,통신서비스"
				/>
				<meta name="description" content={t('helmet.history.desc')} />
				<meta property="og:title" content={t('helmet.history.ogTitle')} />
				<meta property="og:description" content={t('helmet.history.ogDesc')} />
				<meta property="og:url" content="https://omnitalk.io/history" />
				<link rel="canonical" href="https://omnitalk.io/history/" />

				<meta
					property="twitter:title"
					content={t('helmet.history.twitterTitle')}
				/>
				<meta
					property="twitter:card"
					content={t('helmet.history.twitterCard')}
				/>
				<meta
					property="twitter:description"
					content={t('helmet.history.twitterDesc')}
				/>
				<meta name="robots" content="index" />
				<meta name="title" content={t('helmet.history.title')}></meta>
				<meta name="author" content="omnitalk"></meta>
				<meta property="og:type" content="website" />
			</Helmet>
			<section className="max-w-[1200px] mx-auto px-[5%] min-h-[70vh]">
				<BreadCrumb />
				{/* <h2 className="font-bold xs:mt-7 text-3xl text-left px-8 mt-10 dark:text-white">Changelog</h2>  */}
				<h2 className="xs:mt-7 font-bold lg:text-3xl text-left xs:px-2  xs:text-xl sm:text-2xl lg:px-8 lg:mt-10 dark:text-white">
					History
				</h2>

				<p className=" xs:px-2 xs:text-sm  lg:px-8 text-left text-gray-700 sm:text-base md:text-lg sm:text-md dark:text-white">
					{t('helmet.history.desc')}
				</p>

				<div className="container-box ">
					<div className="max-w-full m-auto flex xs:pt-5 xs:px-2 md:p-20   lg:p-20 pt-10 dark:text-white">
						{isSmallScreen ? (
							<></>
						) : (
							<div className=" min-w-[150px] max-w-[150px] md:mr-4 lg:mr-20">
								<TimeLine />
							</div>
						)}

						{/* ============================================= */}
						{openEditor === false && role !== 'admin' ? (
							<div className="w-full">
								<ChangeLogDetail
									selectedLanguage={selectedLanguage}
									data={data}
									handleLanguage={handleLanguage}
								/>
								{language === 'All' ? (
									<StyledPagination>
										<Pagination
											activePage={currentPage}
											itemsCountPerPage={5}
											totalItemsCount={totalPages}
											pageRangeDisplayed={5}
											prevPageText="‹"
											nextPageText="›"
											onChange={handlePageChange}
										/>
									</StyledPagination>
								) : null}
							</div>
						) : openEditor === false && role === 'admin' ? (
							<div className="w-full ">
								<ChangeLogDetail
									selectedLanguage={selectedLanguage}
									data={data}
									handleLanguage={handleLanguage}
									handleClickRow={handleClickRow}
								/>
								{language === 'All' ? (
									<StyledPagination>
										<Pagination
											activePage={currentPage}
											itemsCountPerPage={5}
											totalItemsCount={totalPages}
											pageRangeDisplayed={5}
											prevPageText="‹"
											nextPageText="›"
											onChange={handlePageChange}
										/>
									</StyledPagination>
								) : null}
								<div className="flex items-center max-w-3xl m-auto mt-10 justify-end">
									<button
										className="bg-main-50 p-3 text-white font-medium text-sm rounded-sm"
										type="button"
										onClick={() => {
											setOpenEditor(prev => !prev);
										}}
									>
										글쓰기
									</button>
								</div>
							</div>
						) : openEditor === true && role === 'admin' ? (
							<div className="w-full ">
								<TextEditor
									type="changelog"
									isPatching={false}
									editorRef={editorRef}
									onChange={onChange}
									onSelect={onSelect}
									onClose={onOffEditor}
									reason={reason}
									isValidErrOn={isValidErrOn}
								/>
								<div className="flex items-center max-w-3xl m-auto mt-10 justify-end">
									<button
										className="bg-main-50 p-3  text-white font-medium text-sm rounded-sm"
										type="button"
										onClick={() => {
											sendReq(prev => !prev);
										}}
									>
										글등록하기
									</button>
								</div>
								{loading && <Loading />}
							</div>
						) : null}

						{role === 'admin' && openEditor === true ? <></> : null}

						{/* ============================================ */}
					</div>
					{popupState.display &&
						popupState.data &&
						popupState.type === 'rowdata' && (
							<Popup title="상세 보기" onClose={handleClosePopup} size="md">
								{loading && <PopupLoading />}
								<PopupFormRow title="lang">
									<div className="row-content">
										<select
											name="lang"
											id="language-select"
											onChange={handleFormChange}
											value={popupState.data.lang}
										>
											{options.map((item, index) => (
												<option key={index} value={item}>
													{item}
												</option>
											))}
										</select>
									</div>
								</PopupFormRow>
								<PopupFormRow title="version">
									<div className="row-content">
										<input
											onChange={handleFormChange}
											type="text"
											name="version"
											placeholder={`${popupState.data.version}`}
											maxLength="10"
										></input>
									</div>
								</PopupFormRow>
								<PopupFormRow title="writer">
									<div className="row-content">
										<input
											onChange={handleFormChange}
											type="text"
											name="writer"
											placeholder={`${popupState.data.writer}`}
											maxLength="10"
										></input>
									</div>
								</PopupFormRow>
								<PopupFormRow title="contents">
									<div className="row-content">
										<input
											onChange={handleFormChange}
											type="text"
											name="contents"
											placeholder={`${popupState.data.contents}`}
											maxLength="100"
										></input>
									</div>
								</PopupFormRow>
								<PopupFormRow>
									<div className="row-two-btn">
										<Button
											color="blue"
											onClick={() =>
												setConfirm(prev => ({
													...prev,
													display: true,
													type: 'delete',
												}))
											}
										>
											삭제
										</Button>
										<Button
											color="blue"
											disabled={
												popupState.data.lang === formValues.lang &&
												popupState.data.version === formValues.version &&
												popupState.data.writer === formValues.writer &&
												popupState.data.contents === formValues.contents
											}
											onClick={() =>
												setConfirm(prev => ({
													...prev,
													display: true,
													type: 'modify',
												}))
											}
										>
											수정
										</Button>
									</div>
								</PopupFormRow>
							</Popup>
						)}
					{confirm.display && confirm.type === 'delete' ? (
						<Popup
							theme="confirm"
							onClose={() => setConfirm(prev => ({ ...prev, display: false }))}
						>
							<p>정말 삭제하시겠습니까?</p>
							<div>
								<Button color="red" onClick={handleDelete}>
									예
								</Button>
								<Button
									color="blue"
									onClick={() =>
										setConfirm(prev => ({ ...prev, display: false }))
									}
								>
									아니오
								</Button>
							</div>
						</Popup>
					) : null}
					{confirm.display && confirm.type === 'modify' ? (
						<Popup
							theme="confirm"
							onClose={() => setConfirm(prev => ({ ...prev, display: false }))}
						>
							<p>정말 수정하시겠습니까?</p>
							<div>
								<Button color="red" onClick={handleModify}>
									예
								</Button>
								<Button
									color="blue"
									onClick={() =>
										setConfirm(prev => ({ ...prev, display: false }))
									}
								>
									아니오
								</Button>
							</div>
						</Popup>
					) : null}
				</div>
			</section>
		</>
	);
}

