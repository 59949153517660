import myAxios from './myAxios';

const API = {
	// todo: status: -1
	login: async body => {
		try {
			const response = await myAxios.post('/auth/signin', body);
			if (response.status === 201 && response.data.status === 1)
				return { result: 'logined', role: response.data.role };
			if (response.status === 201 && response.data.status === 0) {
				return { result: 'wait' };
			}
		} catch (error) {
			return { result: 'inconsistency' };
		}
	},
	emailCheck: async () => {
		try {
			const { data } = await myAxios.post('/users/signup', {
				headers: {
					'Content-Type': 'applicaion/json',
				},
			});
			return data;
		} catch (error) {
			return error;
		}
	},

	signUp: async body => {
		try {
			const { data } = await myAxios.post('/users/signup', body, {
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			});
			return data;
		} catch (error) {
			return error;
		}
	},

	posting: async body => {
		try {
			const { data } = await myAxios.post('/superadmin/bulletin', body, {
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			});
			return data;
		} catch (error) {
			return error;
		}
	},

	postingFile: async body => {
		try {
			const { data } = await myAxios.post('/superadmin/bulletin', body);
			return data;
		} catch (error) {
			return error;
		}
	},
	postingChangelog: async body => {
		try {
			const { data } = await myAxios.post('/superadmin/changelog', body, {
				headers: {
					'Content-Type': 'applicaion/json',
				},
			});
			return data;
		} catch (error) {
			return error;
		}
	},

	getInfo: async () => {
		try {
			const response = await myAxios.get('/users/info');
			if (response.data.result === 'success') return response.data.data;
		} catch (error) {
			if (error.response.status === 401) return { result: 401 };
			return error;
		}
	},

	modifyPwd: async body => {
		try {
			const response = await myAxios.patch('/users/password', body);
			return response;
		} catch (error) {
			return error;
		}
	},
	resetPwd: async body => {
		try {
			const response = await myAxios.post('/users/password', body);
			return response;
		} catch (error) {
			return error;
		}
	},

	modifyInfo: async body => {
		try {
			const { data } = await myAxios.patch('/users', body, {
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			});
			return data;
		} catch (error) {
			return error;
		}
	},

	getServiceList: async () => {
		try {
			const response = await myAxios.get('/users/servicecard');
			return response;
		} catch (error) {
			return error;
		}
	},
	addServiceId: async body => {
		try {
			const response = await myAxios.post('/users/service', body);
			return response;
		} catch (error) {
			return error;
		}
	},
	deleteServiceId: async () => {
		try {
			const response = await myAxios.delete('/users/service');
			return response;
		} catch (error) {
			return error;
		}
	},

	getWebHookList: async () => {
		try {
			const response = await myAxios.get('/users/webhook');
			return response;
		} catch (error) {
			return error;
		}
	},
	addWebHook: async body => {
		try {
			const response = await myAxios.post('/users/webhook', body);
			return response;
		} catch (error) {
			return error;
		}
	},
	getRecordList: async () => {
		try {
			const response = await myAxios.get('/users/recording');
			return response;
		} catch (error) {
			return error;
		}
	},
	getInvoiceList: async () => {
		try {
			const response = await myAxios.get('/billing/invoice');
			return response;
		} catch (error) {
			return error;
		}
	},
	getVoucherList: async () => {
		try {
			const response = await myAxios.get('/voucher');
			return response;
		} catch (error) {
			return error;
		}
	},
	addVoucher: async body => {
		try {
			const response = await myAxios.post('/voucher', body);
			return response;
		} catch (error) {
			return error;
		}
	},
	addContactUs: async body => {
		try {
			const response = await myAxios.post('/contactus', body);
			return response;
		} catch (error) {
			return error;
		}
	},
	addAdminVoucher: async body => {
		try {
			const response = await myAxios.post('/superadmin/voucher', body);
			return response;
		} catch (error) {
			return error;
		}
	},

	getAgency: async () => {
		try {
			const response = await myAxios.get('/superadmin/agencies');
			return response;
		} catch (error) {
			return error;
		}
	},
};

export default API;