import { TRACK_TYPE } from "../public-types/common";
import { IceCandidate } from "../types/common";
import OmniWebsocket from "../websocket/websocket-manager";
import adapter from 'webrtc-adapter'

export default class WebRTCCommon {
    protected ws: OmniWebsocket;
    protected session: string;
    protected turn_id: string;
    protected turn_secret: string;
    protected track: TRACK_TYPE;
    protected pc: RTCPeerConnection;

    constructor(ws: OmniWebsocket, session: string, track: TRACK_TYPE, turn_id: string, turn_secret: string) {
        this.ws = ws;
        this.session = session;
        this.track = track;
        this.turn_id = turn_id;
        this.turn_secret = turn_secret;
        this.pc = this.newPeerConnection();
    }

    closePeerConnection() {
        this.pc.close();
    }

    stopMediaStream(stream?: MediaStream) {
        if (stream) {
            stream.getTracks().forEach(track => {
                track.stop();
            });
        }
    }

    newPeerConnection() {
        const config: RTCConfiguration = {
            iceServers: [
                {
                    urls: 'stun:stun.l.google.com:19302'
                },
                {
                    urls: `stun:omnitalk.io`
                },
                {
                    urls: `turn:omnitalk.io`,
                    username: this.turn_id,
                    credential: this.turn_secret
                }
            ]
        };
        const pc = new RTCPeerConnection(config);

        // for debug
        // pc.oniceconnectionstatechange = e => {
        //     console.log(`oniceconnectionstatechange - ${pc.iceConnectionState}`);
        // }
        // pc.onconnectionstatechange = e => {
        //     console.log(`onconnectionstatechange - ${pc.connectionState}`);
        // }
        return pc;
    }

    async createOffer(offerToReceiveVideo: boolean, offerToReceiveAudio: boolean): Promise<RTCSessionDescriptionInit> {
        return new Promise(async (resolve, reject) => {
            try {
                if (!this.pc) {
                    reject('not found peer connection');
                    return;
                }
                const options: RTCOfferOptions = {
                    offerToReceiveAudio,
                    offerToReceiveVideo
                }
                const offer = await this.pc.createOffer(options);
                resolve(offer)
            } catch (err) {
                reject(err);
            }
        });
    }

    async createAnswer(): Promise<RTCSessionDescriptionInit> {
        return new Promise(async (resolve, reject) => {
            try {
                if (!this.pc) {
                    reject('not found peer connection');
                    return;
                }
                const offer = await this.pc.createAnswer();
                resolve(offer)
            } catch (err) {
                reject(err);
            }
        });
    }

    async setLocalDescription(jsep: RTCSessionDescriptionInit): Promise<void> {
        return new Promise(async (resolve, reject) => {
            try {
                if (!this.pc) {
                    reject('not found peer connection');
                    return;
                }
                await this.pc.setLocalDescription(jsep);
                resolve()
            } catch (err) {
                reject(err);
            }
        });
    }


    async setRemoteDescription(jsep: RTCSessionDescriptionInit): Promise<void> {
        return new Promise(async (resolve, reject) => {
            try {
                if (!this.pc) {
                    reject('not found peer connection');
                    return;
                }
                await this.pc.setRemoteDescription(jsep);
                resolve();
            } catch (err) {
                reject(err);
            }
        });
    }

    async trickleHandler(subscribe?: string) {
        this.pc.onicecandidate = async e => {
            let candidate: IceCandidate = {
                type: 'candidate'
            }
            if (e.candidate) {
                const regex = /\d{10} 10(?:\.\d{1,3}){3}|\d{10} 172(?:\.\d{1,3}){3}|\d{10} 192(?:\.\d{1,3}){3}/
                const find = regex.exec(e.candidate.candidate);
                if(find) {
                    // matched candidate pattern 10.x.x.x or 192.x.x.x
                    return;
                } else {
                    candidate.candidate = e.candidate.candidate;
                    candidate.sdpMid = e.candidate.sdpMid ? e.candidate.sdpMid : "0";
                    candidate.sdpMLineIndex = e.candidate.sdpMLineIndex ? e.candidate.sdpMLineIndex : 0;
                }
            } else {
                // if ( adapter.browserDetails.browser === 'safari') { // safari candidate 수집 결과 확인 필요
                    // return;
                // }
                candidate.completed = true;
            }
            await this.ws.requestTrickle(this.track, candidate, subscribe);
        }
    }
    
}