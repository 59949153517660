import React, { useEffect, useState } from 'react';
import Pagination from 'react-js-pagination';
import myAxios from '../../modules/api/myAxios';
import { StyledPagination } from '../../modules/defines/style';
import BreadCrumb from '../BreadCrumb';
import IssueContents from './IssueContents';
import IssueTab from './IssueTab';
import RepositoryList from './RepositoryList';
import { useMediaQuery } from 'react-responsive';
import { Helmet } from 'react-helmet-async';
import TimeLine from './TimeLine';
import { useTranslation } from 'react-i18next';
import LatestIssue from './LatestIssue';
export default function Issue() {
	const isSmallScreen = useMediaQuery({ maxWidth: 640 });
	const { t } = useTranslation();

	const [currentPage, setCurrentPage] = useState(1);
	const [totalPages, setTotalPages] = useState(0);
	const [data, setData] = useState(null);
	const [selectedLanguageIndex, setSelectedLanguageIndex] = useState(0);
	const [selectedLanguage, setSelectedLanguage] = useState('');
	const handlePageChange = currentPage => {
		setCurrentPage(currentPage);
	};

	const handleLanguage = selectedIndex => {
		setSelectedLanguageIndex(selectedIndex);
		setCurrentPage(1);
	};

	useEffect(() => {
		handleDataRequest(selectedLanguageIndex, currentPage);
	}, [selectedLanguageIndex, currentPage]);

	const handleDataRequest = async (tabIndex, currentPage) => {
		try {
			let url = '';
			switch (tabIndex) {
				case 0:
					url = `/superadmin/issue?page=${currentPage}&perPage=5`;
					setSelectedLanguage('All');
					break;
				case 1:
					url = `/superadmin/issue?lang=javascript&page=${currentPage}&perPage=5`;
					setSelectedLanguage('Javascript');

					break;
				case 2:
					url = `/superadmin/issue?lang=typescript&page=${currentPage}&perPage=5`;
					setSelectedLanguage('Typescript');

					break;
				case 3:
					url = `/superadmin/issue?lang=reactnative&page=${currentPage}&perPage=5`;
					setSelectedLanguage('ReactNative');

					break;
				case 4:
					url = `/superadmin/issue?lang=flutter&page=${currentPage}&perPage=5`;
					setSelectedLanguage('Flutter');

					break;
				case 5:
					url = `/superadmin/issue?lang=ios&page=${currentPage}&perPage=5`;
					setSelectedLanguage('iOS');

					break;
				case 6:
					url = `/superadmin/issue?lang=unity&page=${currentPage}&perPage=5`;
					setSelectedLanguage('Unity');

					break;
				default:
					url = `/superadmin/issue?page=${currentPage}&perPage=5`;
					setSelectedLanguage('All');
			}

			const response = await myAxios.get(url);
			console.log('response is', response);
			if (response.data.result === 'success') {
				setData(response.data.data);
				setTotalPages(response.data.total);
			}
		} catch (error) {
			console.log(error);
		}
	};

	return (
		<>
			<Helmet>
				<title>{t('helmet.issue.title')}</title>
				<meta charset="utf-8" />
				<meta
					name="keywords"
					content="WebRTC, SIP, CPAAS, communication, cloud-based, integration, solution, provider,WebRTC CPAAS,SIP CPaaS,WebRTC SIP integration,Cloud-based WebRTC,Cloud-based SIP,WebRTC and SIP solution,WebRTC and SIP provider,옴니톡,옴니스토리,비대면통신,커뮤니케이션플랫폼,메타버스,통신,통신서비스"
				/>
				<meta name="description" content={t('helmet.issue.desc')} />
				<meta property="og:title" content={t('helmet.issue.ogTitle')} />
				<meta property="og:description" content={t('helmet.issue.ogDesc')} />
				<meta property="og:url" content="https://omnitalk.io/issue" />
				<meta
					property="twitter:title"
					content={t('helmet.issue.twitterTitle')}
				/>
				<meta property="twitter:card" content={t('helmet.issue.twitterCard')} />
				<meta
					property="twitter:description"
					content={t('helmet.issue.twitterDesc')}
				/>
				<meta name="robots" content="index" />
				<meta name="title" content={t('helmet.issue.title')}></meta>
				<meta name="author" content="omnitalk"></meta>
				<meta property="og:type" content="website" />
			</Helmet>
			<section className=" max-w-[1200px] mx-auto px-[5%]">
				<BreadCrumb />
				<h2 className="xs:mt-7 font-bold lg:text-3xl text-left xs:px-2  xs:text-xl sm:text-2xl lg:px-8 lg:mt-10 dark:text-white">
					Issue
				</h2>
				<p className=" xs:px-2 xs:text-sm  lg:px-8 text-left text-gray-700 sm:text-base md:text-lg sm:text-md dark:text-white">
					{t('issue.desc')}

					<br />
					{t('issue.desc2')}
				</p>
				<div className="max-w-full m-auto flex xs:pt-5 xs:px-2 md:p-20   lg:p-20 pt-10 dark:text-white">
					{/* ========TimeLine & Repolist 모바일 감추기 */}
					{isSmallScreen ? (
						<></>
					) : (
						<div className=" min-w-[150px] max-w-[150px] md:mr-4 lg:mr-20">
							<LatestIssue></LatestIssue>
							<RepositoryList></RepositoryList>
						</div>
					)}
					{/* ========TimeLine & Repolist 모바일 감추기 */}

					<div className="w-full">
						<div className="flex justify-start align-baseline mb-5">
							<span className=" text-left mt-0 text-xl font-[600]  mb-0 text-gray-900">
								{selectedLanguage}
							</span>
							<IssueTab className="" handleLanguage={handleLanguage} />
						</div>
						<IssueContents data={data} />
						<div className="mt-6">
							<StyledPagination>
								<Pagination
									activePage={currentPage}
									itemsCountPerPage={5}
									totalItemsCount={totalPages}
									pageRangeDisplayed={5}
									prevPageText="‹"
									nextPageText="›"
									onChange={handlePageChange}
								/>
							</StyledPagination>
						</div>
					</div>
				</div>
				{/* ---------- */}
			</section>
		</>
	);
}
