import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import { HelmetProvider } from 'react-helmet-async';
import App from './App';
import './index.css';
import MyPage from './page/MyPage';
import Main from './page/Main';
import Pricing from './page/Pricing';
import Login from './page/Login';
import Signup from './page/Signup';
import SignupGoogle from './page/SignupGoogle';
import Recording from './page/console/Recording';
import Invoice from './page/console/Invoice';
import ServiceId from './page/console/ServiceId';
import WebHook from './page/console/WebHook';
import Voucher from './page/console/Voucher';
import Docs from './page/Docs';
import PrivacyPolicy from './page/PrivacyPolicy';
import Terms from './page/Terms';
import Telephone from './components/demo/telephone/Telephone';
import NotFound from './page/NotFound';
import Wait from './page/Wait';
import Agency from './page/admin/Agency';
import AdminContactUs from './page/admin/AdminContactUs';
import AdminVoucher from './page/admin/AdminVoucher';
import Audio from './components/demo/audio/Audio';
import Video from './components/demo/video/Video';
import RealTimeCDR from './components/admin/RealTimeCDR';
import DailyCDR from './components/admin/DailyCDR';
import TestKey from './page/admin/TestKey';
import './i18n';
import Issue from './components/developer/Issue';
import Notice from './components/community/Notice';
import { FAQ } from './components/community/FAQ';
import NoticeView from './components/community/NoticeView';
import History from './components/developer/History';
import Demo from './page/Demo';
import Message from './components/demo/message/Message';
import CDRBilling from './components/Billing/CDRBilling';

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    children: [
      { index: true, path: '/', element: <Main /> },
      { path: '/docs', element: <Docs /> },
      { path: '/faq', element: <FAQ /> },
      { path: '/issue', element: <Issue /> },
      { path: '/notice', element: <Notice /> },
      { path: '/notice/:idx', element: <NoticeView /> },
      { path: '/history', element: <History language="All"/> },
      { path: '/demo', element: <Demo /> },
      { path: '/demo/audio', element: <Audio /> },
      { path: '/demo/video', element: <Video /> },
      { path: '/demo/telephone', element: <Telephone /> },
      { path: '/demo/message', element: <Message /> },
      { path: '/pricing', element: <Pricing /> },
      { path: '/console/my-page', element: <MyPage /> },
      { path: '/console/service/service-id', element: <ServiceId /> },
      { path: '/console/service/web-hook', element: <WebHook /> },
      { path: '/console/service/recording', element: <Recording /> },
      { path: '/console/billing/invoice', element: <Invoice /> },
      { path: '/console/billing/voucher', element: <Voucher /> },
      { path: '/console/billing/daily', element: <CDRBilling /> },
      { path: '/console/admin/agency', element: <Agency /> },
      { path: '/console/admin/voucher', element: <AdminVoucher /> },
      { path: '/console/admin/contact', element: <AdminContactUs /> },
      { path: '/console/admin/testkey', element: <TestKey /> },
      { path: '/console/admin/cdr/daily', element: <DailyCDR /> },
      { path: '/console/admin/cdr/realtime', element: <RealTimeCDR /> },
      { path: '/login', element: <Login /> },
      { path: '/signup', element: <Signup /> },
      { path: '/signup/google', element: <SignupGoogle /> },
      { path: '/terms/privacy-policy', element: <PrivacyPolicy /> },
      { path: '/terms', element: <Terms /> },
      { path: '/*', element: <NotFound /> },
      { path: '/wait', element: <Wait /> },
    ]
  }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <HelmetProvider>
    <RecoilRoot>
      <RouterProvider router={router} />
    </RecoilRoot>
  </HelmetProvider>
);

