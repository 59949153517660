import React from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import {
	bannerBackground,
	fontSize,
	fontWeight,
	palette,
} from '../modules/defines/style';
import thumbnail from '../assets/thumbnail-rn-docs.png';
import BreadCrumb from '../components/BreadCrumb';
import ios from '../assets/docs-ios.png';
import android from '../assets/docs-android.png';

function Docs() {
	const { t } = useTranslation();
	return (
		<>
			<Helmet>
				<title>{t('helmet.docs.title')}</title>
				<meta name="title" content={t('helmet.docs.title')}></meta>
				<meta charset="utf-8" />
				<meta
					name="keywords"
					content="javascript,typescript,unity,unreal,flutter,ios,react-native,react,jsx,rn,js,ts,dart,api,api-reference,document,sdk,sdk-guide"
				/>
				<meta name="description" content={t('helmet.docs.desc')} />
				<meta name="robots" content="index" />
				<meta name="author" content="omnitalk"></meta>
				<meta property="og:type" content="website" />
				<meta property="og:title" content={t('helmet.docs.ogTitle')} />
				<meta property="og:description" content={t('helmet.docs.ogDesc')} />
				<meta property="og:url" content="https://omnitalk.io/docs" />
				<link rel="canonical" href="https://omnitalk.io/docs/" />

				<meta property="twitter:card" content={t('helmet.docs.twitterCard')} />
				<meta
					property="twitter:description"
					content={t('helmet.docs.twitterDesc')}
				/>
			</Helmet>
			<StyledDoc>
				<section className="max-w-[1200px] mx-auto px-[5%]">
					<BreadCrumb />
					<StyledDoc>
						<article className="docs min-h-[70vh]">
							<h2 className="xs:mt-7 font-bold lg:text-3xl text-left xs:px-2  xs:text-xl sm:text-2xl sm:px-8 lg:mt-10 dark:text-white">
								Docs (2.0 version)
							</h2>
							<p className=" xs:px-2  xs:text-sm sm:px-8 text-left text-gray-700   md:text-lg sm:text-md mb-0 dark:text-white md:mb-10 lg:mb-[80px]">
								{t('docs.title')}
							</p>
							<div className="container">
								<div className="docs_contents">
									<Link
										target="_blank"
										rel="noreferrer"
										to="https://docs.omnitalk.io/javascript"
									>
										<div className="service_container">
											<img
												src="https://user-images.githubusercontent.com/120351058/212619452-9d371611-075c-4f34-8b59-ceaea360afb2.png"
												alt="code-img-javascript"
											/>
											<div className="card_container">
												<h4>JavaScript</h4>
												<p>{t('docs.card.js')}</p>
											</div>
										</div>
									</Link>
									{/* javascript */}
									<Link
										target="_blank"
										rel="noreferrer"
										to="https://docs.omnitalk.io/typescript"
									>
										<div className="service_container">
											<img
												src="https://user-images.githubusercontent.com/99234582/220812209-02d0cd62-50e9-42be-bfde-201cd5b463a9.png"
												alt="code-img-typescript"
											/>
											<div className="card_container">
												<h4>TypeScript</h4>
												<p>{t('docs.card.ts')}</p>
											</div>
										</div>
									</Link>
									<Link
										target="_blank"
										rel="noreferrer"
										to="https://docs.omnitalk.io/react-native"
									>
										<div className="service_container">
											<img src={thumbnail} alt="code-img-react-native" />
											<div className="card_container">
												<h4>React Native</h4>
												<p>{t('docs.card.rn')}</p>
											</div>
										</div>
									</Link>
									<Link
										target="_blank"
										rel="noreferrer"
										to="https://docs.omnitalk.io/flutter"
									>
										<div className="service_container">
											<img
												src="https://user-images.githubusercontent.com/125844802/233940421-6e01ae3d-15cf-470e-ad87-d3919b9d30d5.png"
												alt="code-img-flutter"
											/>
											<div className="card_container">
												<h4>Flutter</h4>
												<p>{t('docs.card.flutter')}</p>
											</div>
										</div>
									</Link>
									<Link
										target="_blank"
										rel="noreferrer"
										to="https://docs.omnitalk.io/ios"
									>
										<div className="service_container">
											<img src={ios} alt="code-img-ios" />
											<div className="card_container">
												<h4>iOS</h4>
												<p>{t('docs.card.ios')}</p>
											</div>
										</div>
									</Link>
									<Link
										target="_blank"
										rel="noreferrer"
										to="https://docs.omnitalk.io/android"
									>
										<div className="service_container">
											<img src={android} alt="code-img-android" />
											<div className="card_container">
												<h4>Android</h4>
												<p>{t('docs.card.android')}</p>
											</div>
										</div>
									</Link>
									<Link
										target="_blank"
										rel="noreferrer"
										to="https://omnitalk.io/docs/legacy/unity/precondition"
									>
										<div className="service_container">
											<img
												src="https://user-images.githubusercontent.com/125844802/229668428-43efa89f-914d-4caf-a8bf-dd1cd9454538.png"
												alt="code-img-unity"
											/>
											<div className="card_container">
												<h4>Unity</h4>
												<p>{t('docs.card.unity')}</p>
											</div>
										</div>
									</Link>

									<div className="ts">
										<p className="preparing">
											<strong>REST API</strong>
											<strong>{t('docs.card.rnPreparing')}</strong>
										</p>
										<div className="service_container">
											<div className="card_container">
												<h4>REST API</h4>
												<p>{t('docs.card.restapi')}</p>
											</div>
										</div>
										{/* </Link> */}
									</div>
								</div>
							</div>
						</article>
					</StyledDoc>
					<div className="mb-20"></div>
				</section>
			</StyledDoc>
		</>
	);
}

export default Docs;




const StyledDoc = styled.div`
section{
  padding: 0 5%;
.title{
    margin-bottom: 80px;
    border-bottom: 1px solid ${palette.gray.middle};
    h1{
      font-weight: ${fontWeight.regular};
    }
    p{
      font-size:${fontSize.regular};
    }
  }
article{
  margin: 0 auto;
  text-align: center;
  line-height: 32px;
    h3{
      text-align: start;
      font-size: ${fontSize.medium};
      font-weight: ${fontWeight.medium};
    }
    img{
      width: 100%;
      padding:0 5% ;
    }
  }
.docs{
  h3{
    margin-bottom:50px;
  }
  .container{
    width: 100%;
    .docs_contents{
        display: grid;
        padding-bottom: 10px;
        grid-template-columns: 300px 300px 300px;
        grid-template-rows: 260px 260px;
        justify-content: center;
        gap:20px;
      ::-webkit-scrollbar{
        display: none;
      }
    .service_container,
    .ts{
        width: 300px;
        height: 260px;
        margin-right: 20px;
        position: relative;
        box-shadow: ${palette.shadow};
        border-radius:10px;
        background-color: ${palette.gray.middle};
        h4{
          margin: 20px 0 10px 20px;
          font-size: 1.35rem;
          font-weight:${fontWeight.regular};
        }
        p{
          width: 280px;
          margin: 0 auto;
          margin-left: 20px;
          color: ${palette.text.default};
          font-size: 15px;
          line-height: 22px;
          font-weight: ${fontWeight.regular};
          text-align: left;
        }
        img{
          position: absolute;
          top: 20px;
          left: 50%;
          transform: translateX(-50%);
          width: 90%;
          padding: 0;
          border-radius:10px;
        }
        .card_container{
          position:absolute;
          width: 100%;
          height: 50%;
          /* padding-left: 20px; */
          left: 0;
          bottom: 0;
          text-align: start;
          background-color: ${palette.white};
          border-bottom-left-radius:10px;
          border-bottom-right-radius:10px;
          box-shadow: ${palette.shadow};
          z-index: 10;
        }
        .preparing{
          position: absolute;
          top: 0;
          left: 0;
          width: 300px;
          height: 260px;
          margin: 0;
          background-color: rgba(0,0,0,0.7);
          border-radius: 5px;
          z-index: 20;
            strong{
              width: 100%;
              text-align: center;
              position: absolute;
              top: 160px;
              left: 50%;
              transform: translate(-50%,-50%);
              font-size: 1.25rem;
              font-weight: ${fontWeight.medium};
              color: ${palette.white};
            }
            strong:first-child{
              width: 100%;
              text-align: center;
              position: absolute;
              top: 100px;
              left: 50%;
              transform: translate(-50%,-50%);
              font-size: 2.25rem;
              font-weight: ${fontWeight.regular};
              color: ${palette.white};
            }
        }
    }
    }
  }
}
}
.banner{
    position: relative;
    width: 100%;
    height: 450px;
    margin-bottom: 125px;
    background: center / cover no-repeat  url('https://images.unsplash.com/photo-1608600712992-03e5325d94c8?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTg0fHxvbmxpbmUlMjBtZWV0aW5nfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=800&q=60');
  .background{
    ${bannerBackground}
  }
  }

  @media screen and (max-width: 1200px) and (min-width: 945px){
section{
  .docs{
    .container{
      margin: 0 auto;
      .docs_contents{
        h4{
          margin: 0;
        }
        display: grid;
        padding-top: 20px;
        padding-left: 10px;
        padding-bottom: 10px;
        grid-template-columns: 280px 280px 280px;
        grid-template-rows: 200px 200px;
        justify-content: center;
        gap:30px;
        .service_container {
          width: 280px;
          height: 200px;
          .card_container{
            h4{
              margin: 0;
              font-size: 18px;
              margin-left:15px;
              margin-top: 10px;
            }
            p{
              width: 250px;
              margin: 0;
              margin-left:15px;
              font-size: 14px;
            }
          }
        }
        .ts{
        width: 280px;
        height: 200px;
        margin-right: 20px;
        position: relative;
        box-shadow: ${palette.shadow};
        border-radius:10px;
        background-color: ${palette.gray.middle};
        .preparing{
          position: absolute;
          top: 0;
          left: 0;
          width: 280px;
          height: 200px;
          margin: 0;
          background-color: rgba(0,0,0,0.7);
          border-radius: 5px;
          z-index: 20;
        strong:first-child{
          font-size: 28px;
        }
        strong{
          font-size: 18px;
        }
    }
      }
    }
  }
}
  }
}

@media screen and (max-width: 944px) and (min-width: 731px){
section{
  .docs{
    .container{
      .docs_contents{
        h4{
          margin: 0;
        }
        display: grid;
        padding-top: 20px;
        padding-left: 10px;
        padding-bottom: 10px;
        grid-template-columns: 300px 300px;
        grid-template-rows: 240px 240px;
        justify-content: center;
        gap:50px;
        .service_container {
          width: 330px;
          height: 240px;
          .card_container{
            h4{
              margin: 0;
              font-size: 20px;
              margin-left:15px;
              margin-top: 10px;
            }
            p{
              margin: 0;
              margin-left:15px;
              font-size: 16px;
            }
          }
        }
        .ts{
        width: 300px;
        height: 240px;
        margin-right: 20px;
        position: relative;
        box-shadow: ${palette.shadow};
        border-radius:10px;
        background-color: ${palette.gray.middle};
     
        .preparing{
          position: absolute;
          top: 0;
          left: 0;
          width: 330px;
          height: 240px;
          margin: 0;
          background-color: rgba(0,0,0,0.7);
          border-radius: 5px;
          z-index: 20;
        strong:first-child{
          font-size: 28px;
        }
        strong{
          font-size: 18px;
        }
    }
      }
    }
  }
}
  }
}
@media screen and (max-width: 730px) and (min-width: 580px){
section{
  .docs{
    .container{
      margin: 0 auto;
      .docs_contents{
        h4{
          margin: 0;
        }
        display: grid;
        padding-top: 20px;
        padding-left: 10px;
        padding-bottom: 10px;
        grid-template-columns: 270px 270px;
        grid-template-rows: 220px 220px;
        justify-content: center;
        gap:30px;
        .service_container {
          width: 270px;
          height: 230px;
          .card_container{
            h4{
              margin: 0;
              font-size: 18px;
              margin-left:15px;
              margin-top: 10px;
            }
            p{
              width: 240px;
              margin: 0;
              margin-left:15px;
              font-size: 14px;
            }
          }
        }
        .ts{
        width: 270px;
        height: 230px;
        margin-right: 20px;
        position: relative;
        box-shadow: ${palette.shadow};
        border-radius:10px;
        background-color: ${palette.gray.middle};
     
        .preparing{
          position: absolute;
          top: 0;
          left: 0;
          width: 270px;
          height: 230px;
          margin: 0;
          background-color: rgba(0,0,0,0.7);
          border-radius: 5px;
          z-index: 20;
        strong:first-child{
          font-size: 28px;
        }
        strong{
          font-size: 18px;
        }
    }
      }
    }
  }
}
  }
}
@media screen and (max-width:579px) and (min-width: 230px) {
width: 100%;
  section{
    padding: 0 5%;
    .title{
      margin-bottom: 50px;
      border-bottom: 1px solid ${palette.gray.middle};
      h1{
        font-size: 1.75rem;
      }
      p{
        font-size: ${fontSize.micro};
      }
    }
    .docs{
      margin-bottom: 50px;
        h3{
          font-size: 1.1rem;
          margin-bottom: 0;
        }
        .container{
        overflow: auto;
    margin: 0 auto;

          .docs_contents{
            /* background-color: red; */
            /* width: 640px; */
            margin-top: 20px;
            height: fit-content;
            grid-template-columns: 280px;
            grid-template-rows: 240px;
            gap: 30px;
            .service_container,
            .ts{
              display: flex;
              gap: 20px;
              width: 280px;
              height: 240px;
              margin-right: 0px;
              border-radius:5px;
              h4{
                margin: 10px 0 0 10px;
                font-size: 1.2rem;
                font-weight:${fontWeight.medium};
              }
              p{
                margin: 0 10px;
                font-size: ${fontSize.micro};
                font-size: 1rem;
                padding-left: 10px;
                font-weight: ${fontWeight.regular};
                color: ${palette.text.light};
              }
              img{
                position: absolute;
                top: 10px;
                left: 50%;
                transform: translateX(-50%);
                width: 90%;
                border-radius:5px;
              }
              .card_container{
                border-bottom-left-radius:5px;
                border-bottom-right-radius:5px;
                h4{
                  padding-left: 10px;
                  font-size: 16px;
                }
                p{
                  width: 240px;
                  font-size: 14px;
                }
              }
              .preparing{
          position: absolute;
          top: 0;
          left: 0;
          width: 280px; 
          height: 240px;
          margin: 0;
          background-color: rgba(0,0,0,0.7);
          border-radius: 5px;
          z-index: 20;
            strong{
              width: 100%;
              text-align: center;
              position: absolute;
              /* top: 120px; */
              left: 50%;
              transform: translate(-50%,-50%);
              font-size: 1rem;
              font-weight: ${fontWeight.medium};
              color: ${palette.white};
            }
            strong:first-child{
              width: 100%;
              text-align: center;
              position: absolute;
              /* top: 80px; */
              left: 50%;
              transform: translate(-50%,-50%);
              font-size: 2rem;
              font-weight: ${fontWeight.regular};
              color: ${palette.white};
            }
        }
      }
          }
        }
    }
    .container::-webkit-scrollbar{
      display: none;
    }
  }
  .banner{
    position: relative;
    width: 100%;
    height: 260px;
    margin-bottom: 50px;
    background: center / cover no-repeat  url('https://images.unsplash.com/photo-1608600712992-03e5325d94c8?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTg0fHxvbmxpbmUlMjBtZWV0aW5nfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=800&q=60');
  .background{
    ${bannerBackground}
  }
  }
}

`;