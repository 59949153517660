import adapter from "webrtc-adapter";
import { RESOLUTION, TRACK_TYPE } from "../public-types/common";
import { CALL_STATE } from "../types/enum";
import OmniWebsocket from "../websocket/websocket-manager";
import WebRTCCommon from "./webrtc-common";
import { IResponsePublish, IResponseScreenShare } from "../types/response";
import { IceCandidate } from "../types/common";

export default class ClientPublisher extends WebRTCCommon {
    public VIDEO_TAG?: HTMLVideoElement;

    private videoInputChoiceId?: string;
    private videoStream?: MediaStream;

    private resolutionWidth = 720; // default SD
    private resolutionHeight = 480; // default SD 

    constructor(ws: OmniWebsocket, session: string, turn_id: string, turn_secret: string) {
        super(ws, session, TRACK_TYPE.VIDEO, turn_id, turn_secret);
        this.trickleHandler();
    }

    async publish(tag_id?: string): Promise<IResponsePublish> {
        return new Promise(async (resolve, reject) => {
            try {
                const stream = await this.makeVideo(tag_id);
                if (stream) {
                    this.videoStream = stream;
                    this.videoStream.getTracks().forEach(async track => {
                        this.pc.addTrack(track, stream);
                      });
                }
                const offer = await this.createOffer(true, false);
                await this.setLocalDescription(offer);
                const publishResult = await this.ws.requestPublish(TRACK_TYPE.VIDEO, offer, this.resolutionWidth * this.resolutionHeight);
                await this.setRemoteDescription(publishResult.jsep);
                this.renderLocalVideo();
                resolve(publishResult);
            } catch (err) {
                reject(err);
            }
        });
    }

    async makeVideo(tag_id?: string): Promise<MediaStream> {
        return new Promise(async (resolve, reject) => {
            try {
                let localVideoElement: HTMLVideoElement | undefined;
                if (tag_id) {
                    localVideoElement = document.querySelector(`video#${tag_id}`) as HTMLVideoElement;
                } else {
                    localVideoElement = document.querySelector('video#Omnitalk-LocalVideo-0') as HTMLVideoElement;
                }
                if (!localVideoElement) {
                    reject(`Notfound local video tag, id: ${tag_id ? tag_id : 'Omnitalk-LocalVideo-0'}`);
                    return;
                }
                this.VIDEO_TAG = localVideoElement;
                const constraints = {
                    video: {
                        deviceId: this.videoInputChoiceId ? { exact: this.videoInputChoiceId } : undefined,
                        width: { exact: this.resolutionWidth },
                        height: { exact: this.resolutionHeight }
                    },
                    audio: false
                };
                
                const stream = await navigator.mediaDevices.getUserMedia(constraints);

                // Support Resolution
                // const videoTrack = stream.getVideoTracks()[0];
                // const capabilty = videoTrack.getCapabilities();
                // console.log(`Supported resolution: ${capabilty.width?.max} x ${capabilty.height?.max}`);
                resolve(stream);
            } catch (err) {
                reject(err);
            }
        });
	}

    async screenShare(tag_id?: string): Promise<IResponseScreenShare> {
        return new Promise(async (resolve, reject) => {
            try {
                const stream = await this.makeScreen(tag_id);
                if (stream) {
                    this.videoStream = stream;
                    this.videoStream.getTracks().forEach(async track => {
                        this.pc.addTrack(track, stream);
                      });
                }
                const offer = await this.createOffer(true, false);
                await this.setLocalDescription(offer);
                const publishResult = await this.ws.requestScreenShare(TRACK_TYPE.VIDEO, offer, this.resolutionWidth * this.resolutionHeight);
                await this.setRemoteDescription(publishResult.jsep);
                this.renderLocalVideo();
                resolve(publishResult);
            } catch (err) {
                reject(err);
            }
        });
    }

    makeScreen(tag_id?: string): Promise<MediaStream> {
        return new Promise(async (resolve, reject) => {
            try {
                // if (SessionID == undefined) return;
                if ((navigator.mediaDevices && 'getDisplayMedia' in navigator.mediaDevices)) {
                    let screenOptionsElement = document.querySelector('#Omnitalk-ScreenOptions') as HTMLFieldSetElement;
                    if (screenOptionsElement == undefined) {
                        let x = document.createElement("FIELDSET");
                        x.id = "Omnitalk-ScreenOptions";
                        x.style.display ='none';
                        document.body.appendChild(x);

                        let y = document.createElement("SELECT");
                        y.id = "Omnitalk-DisplaySurface";
                        x.appendChild(y);

                        let z1 = document.createElement("OPTION");
                        z1.setAttribute("value", "default");
                        z1.setAttribute("selected", "");
                        y.appendChild(z1);

                        let z2 = document.createElement("OPTION");
                        z2.setAttribute("value", "browser");
                        y.appendChild(z2);

                        let z3 = document.createElement("OPTION");
                        z3.setAttribute("value", "window");
                        y.appendChild(z3);

                        let z4 = document.createElement("OPTION");
                        z3.setAttribute("value", "monitor");
                        y.appendChild(z4);
                    }

                    if (adapter.browserDetails.browser === 'chrome' && adapter.browserDetails.version && adapter.browserDetails.version >= 107) {
                        // See https://developer.chrome.com/docs/web-platform/screen-sharing-controls/
                        const screenOptions = document.getElementById('Omnitalk-ScreenOptions') as HTMLFieldSetElement;
                        screenOptions.style.display = 'block';
                    } else if (adapter.browserDetails.browser === 'firefox') {
                        // Polyfill in Firefox.
                        // See https://blog.mozilla.org/webrtc/getdisplaymedia-now-available-in-adapter-js/
                        const shim = adapter.browserShim as any;
                        shim.shimGetDisplayMedia(window, 'screen');
                    }

                    const constraints = { video: true, audio: false } as any; // todo // const constraints = { video: { width: 1280, height: 720, frameRate: 10 }, audio: false } as any; // todo
                    const preferredDisplaySurface = document.getElementById('Omnitalk-DisplaySurface') as HTMLSelectElement;
                    const displaySurface = preferredDisplaySurface.options[preferredDisplaySurface.selectedIndex].value;
                    if (displaySurface !== 'default') {
                        //options.video = {displaySurface};
                        constraints.video = {displaySurface};
                    }

                    const stream = await navigator.mediaDevices.getDisplayMedia(constraints);

                    let localScreenElement: HTMLVideoElement | undefined;
                    if (tag_id) {
                        localScreenElement = document.querySelector(`video#${tag_id}`) as HTMLVideoElement;
                    } else {
                        localScreenElement = document.querySelector('video#Omnitalk-LocalScreen-0') as HTMLVideoElement;
                    }
                    if (!localScreenElement) {
                        reject(`Notfound local video tag, id: ${tag_id ? tag_id : 'Omnitalk-LocalScreen-0'}`);
                        return;
                    }
                    this.VIDEO_TAG = localScreenElement;
                    /*
                    stream.getVideoTracks()[0].addEventListener('ended', () => {
                        console.log('The user has ended sharing the screen');
                        preferredDisplaySurface.disabled = false;
                    });
                    */
                    resolve(stream);
                } else { 
                    reject('getDisplayMedia is not supported');
                    return;
                }
            } catch (err) {
                reject(err);
            }
        });
	}

    async setResolution(resolution: RESOLUTION, call_state: CALL_STATE): Promise<void> {
        return new Promise(async (resolve, reject) => {
            try {
                switch (resolution) {
                    case RESOLUTION.QVGA:
                        this.resolutionWidth = 320;
                        this.resolutionHeight = 240;
                        break;
                    case RESOLUTION.VGA:
                        this.resolutionWidth = 640;
                        this.resolutionHeight = 480;
                        break;
                    case RESOLUTION.SD:
                        this.resolutionWidth = 720;
                        this.resolutionHeight = 480;
                        break;
                    case RESOLUTION.HD:
                        this.resolutionWidth = 1280;
                        this.resolutionHeight = 720;
                        break;
                    case RESOLUTION.FHD:
                        this.resolutionWidth = 1920;
                        this.resolutionHeight = 1080;
                        break;
                    case RESOLUTION.TWO_K:
                        this.resolutionWidth = 2560;
                        this.resolutionHeight = 1440;
                        break;
                    case RESOLUTION.FOUR_K:
                        this.resolutionWidth = 2840;
                        this.resolutionHeight = 2160;
                        break;
                    default:
                        this.resolutionWidth = 640;
                        this.resolutionHeight = 480;
                }

                // console.log(`Resolution settings: ${this.resolutionWidth} x ${this.resolutionHeight}`);

                if (this.videoStream) {
                    // Apply Resolution
                    const videoTrack = this.videoStream.getVideoTracks()[0];
                    const newConstraints: MediaTrackConstraints = {
                        deviceId: this.videoInputChoiceId ? this.videoInputChoiceId : undefined,
                        width: { ideal: this.resolutionWidth }, 
                        height: { ideal: this.resolutionHeight }
                    };
                    await videoTrack.applyConstraints(newConstraints)
                    const settings = videoTrack.getSettings();
                    // console.log(`Apply new resolution: ${settings.width} x ${settings.height}`);
                }
                if (call_state == CALL_STATE.CONNECT_STATE) {
                    await this.ws.requestResolution(this.resolutionWidth * this.resolutionHeight)
                }
                resolve();
            } catch (err) {
                reject(err);
            }
        });
	}

    async setVideoDevice(deviceId: string): Promise<void> {
        return new Promise(async (resolve, reject) => {
            try {
                if (!this.videoStream) {
                    this.videoInputChoiceId = deviceId;
                } else {
                    const constraints = {
                        'video': {
                            deviceId: { exact: deviceId },
                            width: { exact: this.resolutionWidth },
                            height: { exact: this.resolutionHeight }
                        },
                        'audio': false
                    };
                    const stream = await navigator.mediaDevices.getUserMedia(constraints);
                    if (this.videoStream) {
                        this.videoStream.getTracks().forEach(track => {
                            track.stop();
                        });
                    }
                    this.videoStream = stream;
                    if (this.pc) {
                        const [videoTrack] = stream.getVideoTracks();
                        const senders = this.pc.getSenders();
                        senders.forEach(async sender => {
                            let track = sender.track;
                            if(track?.kind === 'video') {
                                sender.replaceTrack(videoTrack);
                            }
                        });
                    }
                    this.renderLocalVideo()
                }
                resolve();
            } catch (err) {
                reject(err);
            }
        });
	}

    renderLocalVideo() {
        if (this.VIDEO_TAG && this.videoStream) {
            this.VIDEO_TAG.setAttribute("playsinline", "");
            this.VIDEO_TAG.setAttribute("autoplay", "");
            this.VIDEO_TAG.setAttribute("controls", "false");
            this.VIDEO_TAG.setAttribute("data-onair", "true");
            this.VIDEO_TAG.setAttribute("data-session", this.session);
            this.VIDEO_TAG.srcObject = this.videoStream;
        }
    }

    setLocalVideoMute(toggle: boolean) {
        if (this.videoStream) {
            const videoTracks = this.videoStream.getVideoTracks();
            videoTracks.forEach(track => {
                track.enabled = !toggle;
            });
        }
    }

    freeResources() {
        this.closePeerConnection();
        this.stopMediaStream(this.videoStream)
        if (this.VIDEO_TAG) {
            this.VIDEO_TAG.srcObject = null;
            this.VIDEO_TAG.removeAttribute('data-onair');
            this.VIDEO_TAG.removeAttribute('data-session');
        }
    }
    
}