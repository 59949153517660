import sha256 from 'js-sha256';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import _ from 'lodash';
import DaumPostcode from 'react-daum-postcode';
import { AiFillHome } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Helmet } from 'react-helmet-async';
import myAxios from '../modules/api/myAxios';
import API from '../modules/api/api';
import {
	MOBILE_REGEX,
	PWD_REGEX,
	EMAIL_REGEX,
	PHONE_REGEX,
	BUSINESS_CODE_REGEX,
} from '../components/utill/regex';
import {
	defaultFlexCenter,
	signinUpBtn,
	defaultInput,
	fontSize,
	fontWeight,
	pagePaddingTop,
	palette,
	disabledBtn,
} from '../modules/defines/style';
import Loading from '../components/Loading';
// todo: 메일 인증 번호 전송 후, 인증 실패할 경우 재인증 활성화 + 회원가입 클릭후 폼데이터 유지

const initData = {
	startup: '3',
	manager_name: '',
	manager_phone0: '',
	manager_phone1: '',
	manager_phone2: '',
	manager_email: '',
	passwd: '',
	passwd_confirm: '',
	agency_name: '',
	homepage: '',
	address_code: '',
	address: '',
	address_detail: '',
	business_phone0: '',
	business_phone1: '',
	business_phone2: '',
	business_code0: '',
	business_code1: '',
	business_code2: '',
	intro: '',
	agree: false,
	file: null,
};

function Signup() {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [emailErr, setEmailErr] = useState('');
  const [formData, setFormData] = useState(initData);
  const [errors, setErrors] = useState('');
  const [displayPostcode, setDisplayPostCode] = useState(false);
  const [filename, setFilename] = useState(null);
  const [isComplete, setComplete] = useState(false);
  const [confirmValue, setConfirmValue] = useState('');
  const [emailCheckNum, setEmailCheckNum] = useState(''); // 서버의 6자리 이메일인증번호
  const [emailDisable, setEmailDisabled] = useState(false); // 인증번호확인이 되면 true로 변경
  const [emailDesc, setEmailDesc] = useState(false); // 인증번호전송이 클릭되었을때 나오는 인증번호 입력창
  const [emailConfirmDesc, setEmailConfirmDesc] = useState('');// 인증번호가일치합니다 or 일치하지않습니다.
  const [isChecked, setIsChecked] = useState(false);

  const handleChange = (e) => {
    setDisabled(false);
    setErrors('');
    setFormData(prev => ({ ...prev, [e.target.name] : e.target.value }));
  };
  const handleChangeRadio = (name) => {
    setFormData(prev => ({ ...prev, [name.target.name]: name.target.value }));
  };
  
  const handleChangeFile = (e) => {
    setFilename('');
  
    const file = e.target.files[0];
    setFormData(prevState => ({ ...prevState, file }));
    // setFormData(prev => ({ ...prev, [e.target.name]: file }));
    setFilename(file.name);
  };
  const handleAddressCompleted = (data) => {
    setDisplayPostCode(false);
    setFormData(prev => ({ ...prev, address_code: data.zonecode, address:data.roadAddress }));
  };
  const handleConfirm = (e) => {
    setConfirmValue(e.target.value);
  };
  const setAgreeInfo = (name) => {
    setIsChecked(!isChecked);
    setFormData(prev => ({ ...prev, [name.target.name]:  isChecked }));
  };

  /**
 * 최초에 이메일 보내는 함수
 * @returns 
 */
  const sendingEmail = () => {
    const emailValid = EMAIL_REGEX.test(formData.manager_email);
    if (!emailValid) return setEmailErr(t('error.errEmail'));
    if (emailValid) {
      setEmailErr('');
      setEmailDesc(true);
      myAxios.post(`/users/signup/${formData.manager_email}`, {
        'Content-Type': 'applicaion/json',
      })
        .then((res) => {
          if (res.data.result === 'success') {
            setEmailConfirmDesc(t('error.errEmailCfm'));
            setEmailCheckNum(_.cloneDeep(res.data.data));
          }
          //* *******!!!!!!!!!!!!!! fail인경우에 케이스 나눠서 처리 */
          //! !! fix this
          if (res.data.result === 'fail') {
            setEmailConfirmDesc(t('error.errEmailCfmFail'));
          }

        })
        .catch((error) => {
          if (error.response.data.result === 'fail')
            setEmailConfirmDesc(t('error.errEmailCfmFail'));
          return error;
        });
    }
  };
  const compareEmail = () => {
    // 서버에이메일 요청, -> 응답받은 6자리코드 저장
    const convertConfirmValue = Number(confirmValue);
    const result = (convertConfirmValue === emailCheckNum);
  
    if (result) {
      setEmailConfirmDesc(t('error.codeCfm'));
      setEmailDisabled(true);
    } else {
      setEmailConfirmDesc(t('error.codeCfmFail'));
    }    
  };

  const handleSubmit = async(e) => {
    e.preventDefault(t('error.privacy'));
    if (isChecked === false) 
      return setErrors('');
    // emailDisabled true => 이메일 인증 완료
    delete formData.agree;
    if (formData.passwd !== formData.passwd_confirm)
      return setErrors(t('error.passwordOne'));
    if (PWD_REGEX.test(formData.passwd) === false)
      return setErrors(t('error.passwordOne'));
    if (!isEmailValidate()) 
      return setErrors(t('error.email'));
    if (!isAllInput()) 
      return setErrors(t('error.checkAll'));
    const mobileValid = MOBILE_REGEX.test(`${formData.manager_phone0}-${formData.manager_phone1}-${formData.manager_phone2}`);
    if (!mobileValid) return setErrors(t('error.phone'));
    if (formData.startup !== '3') {
      const businessPhoneValid = PHONE_REGEX.test(`${formData.business_phone0}-${formData.business_phone1}-${formData.business_phone2}`);
      if (!businessPhoneValid) return setErrors(t('error.tel'));
      const businessCodeValid = BUSINESS_CODE_REGEX.test(`${formData.business_code0}-${formData.business_code1}-${formData.business_code2}`);
      if (!businessCodeValid) return setErrors(t('error.businessCode'));
      
    }
    // 정제된 데이터 저장
    settingFinalData(formData); 
    setIsLoading(true);
    await API.signUp(formData).then((response) => {
      if (response.result === 'success') {
        setIsLoading(false);
        return setComplete(true);
      }
    }).catch((error) => {
      console.log(error);
      if (error.statusCode === 400) {
        setIsLoading(false);
        setErrors(t('error.businessCode'));
      }
      return error;
    });
  
  };

  /**
   * 유효성 검사 -----------------------------------------
   * @returns 
   */
  
  const findNull = (clonedData) => {
    const validArray = Object.values(clonedData);
    const isEmpty = function(item) {
      if (item === '' || item === null) return true;
      else return false;
    };
    return validArray.some(isEmpty);
  };

  /**
 * 1. 모두 입력되었는지
 * @returns 
 */
  const isAllInput = () => {
    const cloneData = _.cloneDeep(formData);
    delete cloneData.homepage; // hompage는 필수입력이 아니므로 예비창업자, 개인사업자 모두 삭제
    if (cloneData.startup === '3') {
      delete cloneData.business_code0;
      delete cloneData.business_code1;
      delete cloneData.business_code2;
      delete cloneData.business_phone0;
      delete cloneData.business_phone1;
      delete cloneData.business_phone2;
      delete cloneData.file;
      delete cloneData.address_code;
      delete cloneData.address_detail;
      delete cloneData.address;
    }
    const isEmpty = (findNull(cloneData));
    if (isEmpty === true) return false;
    else return true;
  };
  const settingFinalData = (formData) => {
    const cloneData = _.cloneDeep(formData);
    formData.manager_phone = `${cloneData.manager_phone0}-${cloneData.manager_phone1}-${cloneData.manager_phone2}`;
    delete formData.manager_phone0;
    delete formData.manager_phone1;
    delete formData.manager_phone2;
    delete formData.business_code0;
    delete formData.business_code1;
    delete formData.business_code2;
    delete formData.business_phone0;
    delete formData.business_phone1;
    delete formData.business_phone2;
    delete formData.passwd_confirm;
    delete formData.agree;
    delete formData.address_detail;
    
    formData.startup = Number(formData.startup);
    formData.passwd = sha256(formData.passwd);
    //   // 공통으로 처리할  양식
    if (formData.startup === 3) {
      delete formData.address_code;
      delete formData.address;
    } else {
      formData.business_phone = `${cloneData.business_phone0}-${cloneData.business_phone1}-${cloneData.business_phone2}`;
      formData.business_code = `${cloneData.business_code0}-${cloneData.business_code1}-${cloneData.business_code2}`;
      formData.address = `${cloneData.address}${cloneData.address_detail}`;
    }
  };
  /**
 * 2. 이메일 인증 되었는지
 * @returns 
 */
  const isEmailValidate = () => {
    if (emailDisable === false) {
      
      return false;
    }
    return true; // email 인증을 먼저 진행해 주세요
  };
 
  // FIXME: 서버요청 추가

  useEffect(() => {
    setDisabled(false);
    setErrors('');
    setFormData(initData);
    setDisplayPostCode(false);
    setFilename('');
    setComplete(false);
  }, []);

  if (isComplete) return (
    <StyledSignup>
      <Helmet><title>{t('helmet.signup.title')}</title></Helmet>
      <section>
        <h1>Completed!</h1>
        <p>
          {t('signup.complete.one')}<br />
          {t('signup.complete.two')}
        </p>
        <div className="home_btn">
          <Link to="/" style={{ color: '#FA5734' }}> <AiFillHome /> {t('signup.complete.main')}  </Link>
        </div>
      </section>
    </StyledSignup>
  );

  return (
   
    <StyledSignup>
      <Helmet>
        	<title>{t('helmet.signup.title')}</title>
				<meta charset="utf-8" />
				<meta name="title" content={t('helmet.signup.title')}></meta>
				<meta name="author" content="omnitalk"></meta>
				<meta
					name="keywords"
					content="WebRTC, SIP, CPaaS, communication, cloud-based, integration, solution, provider,WebRTC CPAAS,SIP CPaaS,WebRTC SIP integration,Cloud-based WebRTC,Cloud-based SIP,WebRTC and SIP solution,WebRTC and SIP provider,옴니톡,옴니스토리,비대면통신,커뮤니케이션플랫폼,메타버스,통신,통신서비스"
				/>
				<meta name="description" content={t('helmet.signup.desc')} />
				<meta property="og:title" content={t('helmet.signup.ogTitle')} />
				<meta property="og:description" content={t('helmet.signup.ogDesc')} />
				<meta property="og:url" content="https://omnitalk.io/signup" />
				<link rel="canonical" href="https://omnitalk.io/signup/" />
				<meta property="og:type" content="website" />
				<meta property="twitter:title" content={t('helmet.signup.title')} />
				<meta property="twitter:card" content={t('helmet.signup.ogCard')} />
				<meta property="twitter:description" content={t('helmet.signup.desc')} />
				<meta name="robots" content="index" />
      </Helmet>
      <section>
        {isLoading && (<Loading />)}
        <h1>{t('signup.title')}</h1>
        <span style={{ display: 'flex', justifyContent: 'center', fontWeight: '600' }}>{t('signup.desc')}</span>
        <p className="border_line"> </p>
       
        <form encType="multipart/form-data">
          <p style={{ fontWeight: '600', fontSize: '14px', marginBottom:'10px' }}><span style={{ color: 'red' }}>* </span>{t('signup.desc2')}</p>
          <div className="checkbox_radio">
            <input 
              onChange={handleChangeRadio}
              type="radio" name="startup" value="3" 
              checked={formData.startup === '3'}
            />
            <label htmlFor="startup"> {t('signup.prefounder')} </label>
            <input
              type="radio" name="startup" value="2" 
              checked={formData.startup === '2'}
              onChange={handleChangeRadio} />
            <label htmlFor="startup"> {t('signup.company')} </label>
            <input
              type="radio" name="startup" value="1" 
              checked={formData.startup === '1'}
              onChange={handleChangeRadio} />
            <label htmlFor="startup"> {t('signup.coporation')} </label>
          </div>
          <div 
            className="input_box"
            style={{ display: 'flex', flexDirection: 'column' }}>
            <label htmlFor="manager_email"><span style={{ color: 'red', marginTop:'1px' }}>* </span>{t('signup.email')}</label>
            {/* 인증번호 전송 */}
            <div className="input_box-wrap">
              <input 
                disabled={emailDisable}
                type="email" name="manager_email"
                value={formData.manager_email} 
                placeholder={t('signup.emailInput')}
                onChange={handleChange} />
             
              {formData.manager_email !== '' ? (
                <button 
                  type="button"
                  id="disable_btn"
                  style={{ fontSize: '16px' }}
                  onClick={sendingEmail} 
                  className={emailDesc ? 'disabled' : 'able'}
                  disabled={emailDesc}
                >{t('signup.sendNum')}
                 
                </button>
              ) : (
                <button
                  id="send_email_btn"
                  style={{ marginTop:'0px' }}
                  type="button"
                  onClick={sendingEmail} 
                  className="disabled"
                  disabled
                >{t('signup.sendNum')}
                </button>
              )}
            </div>
            {emailErr === '' ? (<></>) : (<span style={{ fontSize: '14px', color: 'red', fontWeight:'500', marginTop:'3px' }}>{emailErr}</span>)}
          </div>
          <div className="email_confirm_box" style={{ display: 'flex' }}>
            <input 
              className={`description ${!emailDesc ? 'hide-description' : ''}`}
              style={{ color: 'black', marginTop: '0px' }}
              type="text"  
              maxLength={6}
              onChange={handleConfirm}
              name="confirmMum"
              disabled={emailDisable}
              placeholder={t('signup.sendNumInput')} />
            
            <button 
              id="confirm_btn"
              disabled={emailDisable}
              className={`description ${!emailDesc ? 'hide-description' : ''}  ${emailDisable ? 'disabled' : ''}  `}
              style={{ color: 'white', fontSize: '16px', border: 'none', marginTop: '0px' }}
              type="button" onClick={compareEmail}>
              {t('signup.confirmNum')}
            </button> 
          </div>
          <span className={`description-text ${!emailDesc ? 'hide-description' : ''}`}>{emailConfirmDesc}</span>   
          <div className="input_box">
            <label htmlFor="passwd"><span style={{ color: 'red' }}>* </span>{t('signup.password')}</label>
            <input
              type="password" name="passwd" value={formData.passwd} placeholder={t('signup.pwInput')}
              maxLength="20" disabled={disabled} onChange={handleChange} />
          </div>
          <div className="input_box">
            <label htmlFor="passwd_confirm"><span style={{ color: 'red' }}>* </span>{t('signup.confirmPw')}</label>
            <input
              type="password" name="passwd_confirm" value={formData.passwd_confirm} placeholder={t('signup.confirmPwInput')}
              maxLength="20" disabled={disabled} onChange={handleChange} />
          </div>
          <div className="input_box">
            <label htmlFor="manager_name"><span style={{ color: 'red' }}>* </span>{t('signup.name')}</label>
            <input
              type="text" name="manager_name" value={formData.manager_name} 
              maxLength="30" disabled={disabled} onChange={handleChange}
            />
          </div>
          <div className="manager_phone">
            <label htmlFor="manager_phone"><span style={{ color: 'red' }}>* </span>{t('signup.phone')}</label>
            <input
              type="text" name="manager_phone0" value={formData.manager_phone0} 
              maxLength="3" disabled={disabled} onChange={handleChange} />
            <span>-</span>
            <input
              type="text" name="manager_phone1" value={formData.manager_phone1} 
              maxLength="4" disabled={disabled} onChange={handleChange} />
            <span>-</span>
            <input
              type="text" name="manager_phone2" value={formData.manager_phone2} 
              maxLength="4" disabled={disabled} onChange={handleChange} />
          </div>

          <div className="input_box">
            <label htmlFor="agency_name"><span style={{ color: 'red' }}>* </span>{t('signup.team')}</label>
            <input
              type="text" name="agency_name" value={formData.agency_name}
              disabled={disabled} onChange={handleChange} />
          </div>
          
          {formData.startup !== '3' && (
            <>
              <div className="address_box">
                <label htmlFor="address_code"> <span style={{ color: 'red' }}>* </span>{t('signup.address')}</label>
                <input
                  type="text" readOnly name="address_code" value={formData.address_code} placeholder={t('signup.addressCode')}
                  maxLength="6" onClick={() => setDisplayPostCode(true)} />
                <input type="text" readOnly name="address" value={formData.address} onClick={() => setDisplayPostCode(true)} />
                <input
                  type="text" name="address_detail" value={formData.address_detail} placeholder={t('signup.addressDetail')}
                  disabled={disabled} onChange={handleChange} />
              </div>

              <div className="business_phone">
                <label htmlFor="business_phone"><span style={{ color: 'red' }}>* </span>{t('signup.companyPhone')}</label>
                <input
                  type="text" name="business_phone0" value={formData.business_phone0} 
                  maxLength="3" disabled={disabled} onChange={handleChange} />
                <span>-</span>
                <input
                  type="text" name="business_phone1" value={formData.business_phone1} 
                  maxLength="4" disabled={disabled} onChange={handleChange} />
                <span>-</span>
                <input
                  type="text" name="business_phone2" value={formData.business_phone2} 
                  maxLength="4" disabled={disabled} onChange={handleChange} />
              </div>
              
              <div className="business_code">
                <label htmlFor="business_code"><span style={{ color: 'red' }}>* </span>{t('signup.businessCode')}</label>
                <input
                  type="text" name="business_code0" value={formData.business_code0}
                  maxLength="3" disabled={disabled} onChange={handleChange} />
                <span>-</span>
                <input
                  type="text" name="business_code1" value={formData.business_code1} 
                  maxLength="2" disabled={disabled} onChange={handleChange} />
                <span>-</span>
                <input
                  type="text" name="business_code2" value={formData.business_code2} 
                  maxLength="5" disabled={disabled} onChange={handleChange} />
              </div>

              <div className="file_box">
                <p style={{ fontWeight: '500', marginBottom: '0px', marginTop:'0px' }}><span style={{ color: 'red' }}>* </span>{t('signup.file')}</p>
                <div className="file_container">
                 
                  <input
                    type="file" id="file" name="file"
                    onChange={handleChangeFile} />
                  <input type="text" name="file" readOnly value={filename} placeholder="png, jpg, pdf" />
                  <label style={{ cursor: 'pointer' }} htmlFor="file">{t('signup.selectFile')}</label>
                </div>
              </div>
              
            </>
          )}

          <div className="input_box">
            <label htmlFor="intro"><span style={{ color: 'red' }}>* </span>{t('signup.memo')}</label>
            <input
              type="text" name="intro" value={formData.intro} placeholder={t('signup.memoInput')}
              maxLength="50" onChange={handleChange} />
          </div>
          <div className="input_box">
            <label htmlFor="homepage">{t('signup.homepage')}</label>
            <input
              type="text" name="homepage" value={formData.homepage}
              disabled={disabled} onChange={handleChange} />
          </div>
          <span style={{ textAlign: 'center', color: 'red', fontWeight: '500' }}>{errors}</span>
          <div className="checkbox">
            <input
              style={{ cursor:'pointer' }} type="checkbox" name="agree" 
              onChange={setAgreeInfo} 
              disabled={isChecked} />
            <label htmlFor="agree">{t('signup.cfm')}</label>
          </div>
          <div className="clause ">
            <button type="button" onClick={() => window.open('/terms', '_blank')}>{t('signup.term')}</button>
           <div className="border-r-[1.5px] h-4 mr-1 ml-1 w-1 border-black"></div>
            <button type="button" onClick={() => window.open('/terms/privacy-policy', '_blank')}>{t('signup.privacy')}</button>
          </div>
        
          <button
            style={{ cursor:'pointer' }}
            type="submit" className="signup_btn" onClick={handleSubmit}>{t('signup.button')}
          </button>
          
        </form>
        {displayPostcode && (
          <Popup onClose={() => setDisplayPostCode((prev) => !prev)}>
            <div className="container">
              <button
                style={{ backgroundColor: 'white', cursor:'pointer' }}
                onClick={() => setDisplayPostCode((prev) => !prev)} type="button">✖
              </button>
              <DaumPostcode onComplete={handleAddressCompleted} />
            </div>
          </Popup>
        )}
     
      </section>

    </StyledSignup>
  );
}

export default Signup;

const StyledSignup = styled.div`
${pagePaddingTop}
section{
  font-family: 'Pretendard';
  max-width: 410px;
  margin: 0 auto;
  color: ${palette.text.default};

  .description-text{
    color: red;
    font-size: 15px;
    text-align: center;
    font-weight: 500;
  }
  .able {
    ${signinUpBtn}
    background-color: ${palette.main.vivid};
    width: 100%;
    margin-top: 20px;
  }
  
  .disabled{
    ${disabledBtn}
    margin-top: 20px;
    width: 50px;
    border: 0px;
  }
  button{
    background-color: ${palette.main.vivid};
    font-family: 'Pretendard';
    font-weight: ${fontWeight.bold};
    font-size: ${fontSize.regular};
    cursor: pointer;
  }
  input[type=radio] {
    cursor: pointer;
  }
  h1{
    font-size:1.625rem;
    color: ${palette.main.default};
    font-weight: ${fontWeight.bold};
    text-align: center;
  }
  p{
    font-size: 1.75rem;
    margin-bottom: 30px;
  }
  p:nth-child(3){
    font-size: 1.25rem;
    margin-bottom: 50px;
    color: ${palette.text.default};
  }
  .border_line{
    width: 100%;
    height: 1px;
    background-color: ${palette.gray.middle};
  }
  .checkbox_radio{
    width: 100%;
    margin: 0 auto;
    label{
      font-size: 15px;
    }
  }
  form{
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    .input_box{
      margin-bottom: 16px;
    label{
      font-size: ${fontSize.small};
      font-weight: ${fontWeight.regular};
      line-height: 20px;
      color:#3e4042;
    }
    input{
      ${defaultInput}
      width: 100%;
    }
    p > span {
      color: blue;
    }
    }
    
    .manager_phone,
    .business_phone,
    .business_code{
      width: 100%;
      margin-bottom: 20px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      align-items: center;
      label{
        display: block;
        width: 100%;
        font-size: ${fontSize.small};
        font-weight: ${fontWeight.medium};
        line-height: 30px;
      }
      input{
        width: 30%;
        height: 42px;
        border: 1px solid #C4C4C4;
        border-radius: 5px;
        padding: 0;
        ::placeholder{
          font-size: 1rem;
          padding-left:10px;
}
      }
    }
    .confirmEmail {
      display: inline-flex;
    }
    .description {
      margin-top: 4px;
      margin-bottom: 4px;
      font-size: 12px;
      color: red;
      ${defaultInput}
      width: 100%;
    }
     button.description{
      color: red;
     }
    .hide-description {
      display: none;
    }
    .show-description {
      color: red;
    }
    /* .showConfirmBox{
      display: none;
    } */
    input.description,
    .address_box{
      width: 100%;
      margin-bottom: 10px;
      label{
        display: block;
        width: 100%;
        font-size: ${fontSize.small};
        font-weight: ${fontWeight.semiBold};
        line-height: 30px;
      }
      input{
        height: 48px;
        margin-bottom:6px;
        border: 1px solid #d1d1d1;
        border-radius: 5px;
      }
      input:nth-child(3){
        width: 402px;
        margin-left: 6px;
      }
      input:last-child{
        width: 100%;
      }
    }
    .file_box{
        width: 556px;
      margin-bottom: 20px;
        p{
          margin: 0 0 10px 0;
          font-size: ${fontSize.small};
          font-weight: ${fontWeight.semiBold};
          line-height: 30px;
        }
      .file_container{
        ${defaultFlexCenter}
        justify-content: flex-start;
        label{
        width: 70px;
        height: 32px;
        font-size: 14px;
        text-align: center;
        ${defaultFlexCenter}
        color: ${palette.white};
        background-color: ${palette.main.default};
        border-radius: 5px;
      }
      input[type=file]{
        display: none;
      }
      input{
        ${defaultInput}
        width: 310px;
        margin-bottom: 0;
      }
      }
    }
    .signup_btn{
      width: 274px;
      height: 42px;
      margin: 0 auto;
      margin-bottom: 180px;
      background-color: ${palette.main.default};
      border: 0;
      border-radius: 5px;
      color: ${palette.white};
      font-size: ${fontSize.regular};
    }
  }
  .checkbox{
    padding-top: 150px;
    ${defaultFlexCenter}
    input{
      width: 16px;
      margin: 0;
      margin-right: 6px;
    }
  }
  .clause{
      ${defaultFlexCenter}
      padding-top: 18px;
      margin-bottom: 50px;
      button{
        border: 0;
        /* border-right: 1px solid ${palette.gray.middle}; */
        background-color: ${palette.opacity};
        color: ${palette.text.light};
      }
      button:nth-child(2){
        border: 0;
      }
    }
    .home_btn{
      margin-bottom: 80px;
    }

    #confirm_btn{
    width: 200px;
    margin-left: 5px;
    margin-top: 0px;
    margin-bottom: 0px;
  }
}
@media screen and (max-width: 2400px ) and (min-width: 630px) {
  .input_box-wrap {
    display: flex;
  }
  #send_email_btn{
    width: 200px; 
    margin-left: 5px;
    margin-top: 0;
   
  }
  #disable_btn{
    width: 200px;
    margin-left: 5px;
    margin-top: 0;
  }
  form {
    input.description{
      margin-top: 0px;
    }
  }
  #confirm_btn{
    width: 200px;
    margin-left: 5px;
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .email_confirm_box{
    display: flex;
  
  }
  section{
    margin: 0 auto;
    .able{
      margin-left: 5px;
      margin-top: 0;
      width: 200px;
    }
    .disabled{
      margin-top: 400px;
    }
    .discription{
      width: 200px;
    }
  
  }
  button {
    .description {
      width: 200px;
    }
    font-size: 16px;
  }
  
}

@media screen and (min-width: 580px) {
padding: 100px 5% 0 5%;
section{
  margin: 0 auto;
  h1{
    font-size: 1.625rem;
    font-weight: ${fontWeight.medium};
    color: ${palette.main.default};
  }
  .disabled{
    ${disabledBtn}
    margin-top: 20px;
    width: 200px;
    border: 0px;
  }
  p{
    font-size: 1.5rem;
    margin-bottom: 20px;
  }
  p:nth-child(3){
    font-size: 1rem;
    margin-bottom: 50px;
    color: ${palette.text.default};
  }
  .border_line{
    width: 100%;
    height: 1px;
    background-color: ${palette.gray.middle};
  }
  [type="radio"] {
  vertical-align: middle;
  appearance: none;
  border: max(2px, 0.1em) solid #dee2e6;
  border-radius: 50%;
  width: 1.25em;
  height: 1.25em;
  transition: border 0.5s ease-in-out;
}
[type="radio"]:checked {
  border: 0.4em solid ${palette.main.default};
}


  .checkbox_radio{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin: 0 auto;
    margin-top: 14px;
    margin-bottom: 10px;
    label{
      font-weight: ${fontWeight.regular};
      font-size: 15px;
      padding-left:5px;
      padding-right: 5px;
    }
    input {
      margin: 0;
    }
  }
  form{
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    .input_box-wrap{
      display: flex;
    }
    #send_email_btn{
    width: 200px; 
    margin-left: 5px;
    margin-top: 0;
   
  } 
   #confirm_btn{
    width: 200px;
    margin-left: 5px;
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 16px;
  }
  .email_confirm_box{
    display: flex;
  
  }

    .input_box{
      margin-bottom: 16px;
    label{
      font-size: ${fontSize.small};
      font-weight: ${fontWeight.medium};
      line-height: 30px;
    }
    input{
      ${defaultInput}
      width: 100%;
      ::placeholder{
        font-size: 14px;
      }
    }
    }
    .manager_phone,
    .business_phone,
    .business_code{
      width: 100%;
      margin-bottom: 20px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      align-items: center;
      label{
        display: block;
        width: 100%;
        font-size: ${fontSize.small};
        font-weight: ${fontWeight.medium};
        line-height: 30px;
      }
      input{
        width: 30%;
        height: 48px;
        border: 1px solid #d1d1d1;
        border-radius: 5px;
        ::placeholder{
          font-size: 1rem;
          padding-left: 10px;
          color: #d1d1d1;
}
      }
    }
    .address_box{
      width: 100%;
      margin-bottom: 20px;
      label{
        display: block;
        width: 100%;
        font-size: ${fontSize.small};
        font-weight: ${fontWeight.medium};
        line-height: 30px;
      }
      input{
        width: 30%;
        height: 48px;
        margin-bottom:6px;
        border: 1px solid #d1d1d1;
        border-radius: 5px;
      }
      input:nth-child(3){
        width: 68%;
        margin-left: 2%;
      }
      input:last-child{
        width: 100%;
      }
      input::placeholder {
        padding-left: 10px;
      }
    }
    .file_box{
        width: 100%;
      margin-bottom: 20px;
        p{
          margin: 0 0 10px 0;
          font-size: ${fontSize.small};
          font-weight: ${fontWeight.semiBold};
          line-height: 30px;
        }
      .file_container{
        ${defaultFlexCenter}
        justify-content: flex-start;
        label{
          width: 70px;
        height: 32px;
        font-size: 14px;
        text-align: center;
        ${defaultFlexCenter}
        color: ${palette.white};
        background-color: ${palette.main.default};
        border-radius: 5px;
      }
      input[type=file]{
        display: none;
      }
      input{
        ${defaultInput}
        width: 330px;
        margin-bottom: 0;
        margin-right:6px;
      }
      }
    }
    .signup_btn{
      width: 274px;
      height: 42px;
      margin: 0 auto;
      margin-bottom: 180px;
      background-color: ${palette.main.default};
      border: 0;
      border-radius: 5px;
      color: ${palette.white};
      font-size: ${fontSize.regular};
    }
  }
  .checkbox{
    padding-top: 40px;
    ${defaultFlexCenter}
    input{
      width: 16px;
      margin: 0;
      margin-right: 6px;
    }
  }
  .clause{
      ${defaultFlexCenter}
      margin-bottom: 50px;
      button{
        border: 0;
        /* border-right: 1px solid ${palette.gray.middle}; */
        background-color: ${palette.opacity};
        font-size: 14px;
        color: ${palette.text.light};
      }
      button:nth-child(2){
        border: 0;
      }
    }
  .home_btn{
    margin-bottom: 80px;
  }
}
}

@media screen and (max-width: 579px) and (min-width: 230px) {
padding: 80px 5% 0 5%;
section{

 
  .input_box-wrap{
      display: flex;
    }
    #send_email_btn{
    width: 200px; 
    margin-left: 5px;
    margin-top: 0;
   
  }
  #disable_btn{
    width: 200px; 
    margin-left: 5px;
    margin-top: 0;
   
  }
  .able{
    width: 100%
  }
  
  h1{
    font-size: 1.5rem;
    margin-bottom: 30px;
    font-weight: ${fontWeight.medium};
  }
  p{
    font-size: 1.1rem;
    margin-bottom: 10px;
  }
  p:nth-child(3){
    font-size: 14px;
    margin-bottom: 30px;
    color: ${palette.text.light};
  }
  .border_line{
    width: 100%;
    height: 1px;
    margin-bottom: 50px;
    background-color: ${palette.gray.middle};
  }
  .checkbox_radio{
    width: 100%;
    margin: 0 auto;
    margin-bottom: 30px;
    label{
      font-size: 15px;
      font-weight: ${fontWeight.regular};
    }
  }
  form{
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    .input_box{
      margin-bottom: 16px;
    label{
      font-size: ${fontSize.small};
      font-weight: ${fontWeight.semiBold};
      line-height: 30px;
    }
    input{
      ${defaultInput}
      width: 100%;
      ::placeholder{
        font-size: 14px;
      }
    }
    }
    .manager_phone,
    .business_phone,
    .business_code{
      width: 100%;
      margin-bottom: 16px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      align-items: center;
      label{
        display: block;
        width: 100%;
        font-size: ${fontSize.small};
        font-weight: ${fontWeight.semiBold};
        line-height: 30px;
      }
      input{
        width: 30%;
        height: 42px;
        border: 1px solid #C4C4C4;
        border-radius: 5px;
        ::placeholder{
          font-size: 1rem;
          padding-left: 10px;
}
      }
    }
    .address_box{
      width: 100%;
      margin-bottom: 10px;
      label{
        display: block;
        width: 100%;
        font-size: ${fontSize.small};
        font-weight: ${fontWeight.semiBold};
        line-height: 30px;
      }
      input{
        width: 30%;
        height: 48px;
        margin-bottom:6px;
        border: 1px solid #d1d1d1;
        border-radius: 5px;
      }
      input:nth-child(3){
        width: 68%;
        margin-left: 2%;
      }
      input:last-child{
        width: 100%;
      }
      input::placeholder {
        padding-left: 10px;
        color: #d1d1d1;
      }
    }
    .file_box{
        width: 100%;
      margin-bottom: 20px;
        p{
          margin: 0 0 10px 0;
          font-size: ${fontSize.small};
          font-weight: ${fontWeight.semiBold};
          line-height: 30px;
        }
      .file_container{
        ${defaultFlexCenter}
        justify-content: flex-start;
        label{
        width: 100px;
        height: 32px;
        font-size: 14px;
        text-align: center;
        ${defaultFlexCenter}
        color: ${palette.white};
        background-color: ${palette.main.default};
        border-radius: 5px;
      }
      input[type=file]{
        display: none;
      }
      input{
        ${defaultInput}
        width: 100%;
        margin-bottom: 0;
        margin-right: 6px;
      }
      }
    }
    .signup_btn{
      width: 274px;
      height: 42px;
      margin: 0 auto;
      margin-bottom: 180px;
      background-color: ${palette.main.default};
      border: 0;
      border-radius: 5px;
      color: ${palette.white};
      font-size: ${fontSize.regular};
    }
  }
  .checkbox{
    padding-top: 30px;
    ${defaultFlexCenter}
    input{
      width: 16px;
      margin: 0;
      margin-right: 6px;
    }
    label{
    }
  }

  [type="radio"] {
  vertical-align: middle;
  appearance: none;
  border: max(2px, 0.1em) solid #dee2e6;
  border-radius: 50%;
  width: 1.25em;
  height: 1.25em;
  transition: border 0.5s ease-in-out;
}
[type="radio"]:checked {
  border: 0.4em solid ${palette.main.default};
}
  .checkbox_radio{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin: 0 auto;
    margin-top: 14px;
    margin-bottom: 10px;
    label{
      font-weight: ${fontWeight.regular};
      font-size: 15px;
      padding-left:5px;
      padding-right: 5px;
    }
    input {
      margin: 0;
    }
  }
  .clause{
      ${defaultFlexCenter}
      margin-bottom: 50px;
      button{
        border: 0;
        /* border-right: 1px solid ${palette.gray.middle}; */
        background-color: ${palette.opacity};
        color: ${palette.text.light};
        font-size: 14px;
      }
      button:nth-child(2){
        border: 0;
      }
    }
    .home_btn{
      margin-bottom: 80px;
    }
}
}

`;
const Popup = styled.div`
width: 100vw;
height: 100vh;
position: fixed;
left: 0;
top: 0;
background-color: ${palette.white};
.container{
  width: 420px;
  position: fixed;
left: 50%;
top: 50%;
transform: translate(-50%,-50%);
}
button {
  margin-left: 390px;
  background-color: white;
  margin-bottom: 20px;
  width: 30px;
  font-size: 20px;
  color: #666666;
  background-color: transparent;
  border: none;
  margin-bottom: 30px;
}
`;
