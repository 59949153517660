import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import styled from 'styled-components';
import { BsPlusCircleFill } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { roleState, userState } from '../../recoil';
import API from '../../modules/api/api';
import {
	defaultFlexCenter,
	fontSize,
	fontWeight,
	palette,
} from '../../modules/defines/style';
import ServiceView from '../../components/Service/ServiceView';
import AddServiceId from '../../components/Service/AddServiceId';
import DeleteModal from '../../components/ui/DeleteModal';
import myAxios from '../../modules/api/myAxios';
import BASE_URL from '../../config';
import ModifyModal from '../../components/Service/ServiceModifyModal';

const deleteState = {
	display: false,
	type: '',
	data: {},
};
const modifyState = {
	display: false,
	type: '', // delete | add
	data: {},
};
function ServiceId() {
  const { t, i18n } = useTranslation();
  // TODO: 권한이 없는 유저는 돌려보낼 것 
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(deleteState);
  const [modifyModal, setModifyModal] = useState(modifyState);
  const [modify, setModify] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [user, setUser] = useRecoilState(userState);
  // eslint-disable-next-line no-unused-vars
  const [role, setRole] = useRecoilState(roleState);

  // if (user === null || role === null) return <Navigate to="/" replace />;
  // if (role === 'yet') return <Navigate to="/wait" replace />;

  useEffect(() => {
    
    authUser();
    if (data.length === 0) {
      requestData();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // get data
  const requestData = () => {
    API.getServiceList().then(response => {
      setData(response.data.data[0].service);
    }).then(error => {
      console.log(error);
    });
  };
  const authUser = async() => {
    try {
      const response = await myAxios.get('/users/info');
      if (response.data.result === 'success') {
        if (response.data.data.role === 'yet')
          navigate('/wait', { replace: true });
        if (response.data.data.role !== 'yet') {
          setUser(response.data.data.agency_name);
          setRole(response.data.data.role);
        }
      }
    } catch (error) {
      if (error.response.status === 401) {
        navigate('/login', { replace: true });
      }
    }
  };

  const handleModify = (data) => {
    setModifyModal({
      display: true,
      type: 'modify',
      data
    });
  };
  const handleChildSuccess = () => {
    requestData();
  };
 
  const handleDelete = (idx) => {
    setDeleteModal({
      display: true,
      type: 'delete',
      idx,
    });
  };

  const handleModalConfirm = () => {
    if (deleteModal.type === 'delete') {
      myAxios.delete(`${BASE_URL}/users/service/${deleteModal.idx}`)
        .then(response => {
          if (response.status === 200) {
            setDeleteModal(false);
            requestData();
          }
        }, error => {
          console.log(error);
        });
    }
  };

  return (
    <StyledCard>
      <Helmet>
        <title>{t('helmet.serviceId.title')}</title>
        <meta charset="utf-8" />
        <meta name="description" content={t('helmet.serviceId.desc')} />
        <meta property="og:title" content={t('helmet.serviceId.ogTitle')} />
        <meta property="og:description" content={t('helmet.serviceId.ogDesc')} />
        <meta property="og:url" content="https://omnitalk.io/console/service/service-id" />
        <meta property="twitter:title" content={t('helmet.serviceId.twitterTitle')} />
        <meta property="twitter:description" content={t('helmet.serviceId.twitterDesc')} />
        <meta name="robots" content="noindex" />
      </Helmet>
      <section>
        <div className="title">
          <h3>Service ID</h3>
        </div>
        <article>
          <BsPlusCircleFill style={{ color: '#FA5734', fontSize: '20px', marginRight:'10px' }} />
          <button
            type="button"
            onClick={() => {
              setOpenModal(true);
            }}> Add
          </button>
        </article>
        <article>
          {data.length === 0 ? (
            <span />
          ) : (
            <>
        
              { data.map((card, i) => {
                return (
                  <StyledCardView key={i}>
                    <ServiceView
                      key={i}
                      data={card} handleModify={handleModify}
                      modify={modify} setModify={setModify}
                      i18n={i18n} />
                    <div className="button">
                      <button style={{ color: `${palette.main.default}`, fontWeight: '500', marginRight: '2px' }} type="button" onClick={() => handleDelete(card.idx)}>{t('console.button.del')}</button>
                      <button type="button" onClick={() => handleModify(card)}>{t('console.button.edit')}</button>
                    </div>
                  </StyledCardView>
                );
              })}
            </>
          )}
        </article>
      </section>
      {openModal && (
        <StyledModal>
          <AddServiceId setOpenModal={setOpenModal} requestData={requestData} t={t} />
        </StyledModal>
      )}
      {modifyModal.display === true && (
        <StyledModal>
          <ModifyModal
            onChildSuccess={handleChildSuccess} selectedData={modifyModal} handleModify={handleModalConfirm} setModifyModal={setModifyModal} t={t}
            text="WebHook" />
        </StyledModal>
      )}
      {deleteModal.display === true && (
        <StyledModal>
          <DeleteModal handleDelete={handleModalConfirm} setDeleteModal={setDeleteModal} t={t} text="Service ID" />
        </StyledModal>
      )}
    </StyledCard>
  );
}

export default ServiceId;

const StyledCard = styled.div`
width: 100%;
padding-top: 120px;
section{
    min-height: 800px;
    padding-left: 240px;
    .title{
    width: 100%;
    margin-bottom: 50px;
    border-bottom: 1px solid ${palette.gray.middle};
    h3{
      margin-bottom: 20px;
      font-size: 1.75rem;
      font-weight: ${fontWeight.semiBold};
    }
  }
  article{
    margin-bottom: 50px;
    ${defaultFlexCenter}
    justify-content: flex-start;
    flex-wrap: wrap;
    button{
        font-size: 1.25rem;
        background-color: ${palette.opacity};
        border: 0;
        color: #333;
        :hover{
          cursor: pointer;
        }
    }
    .card{
      width: 100%;
      ${defaultFlexCenter}
      justify-content: flex-start;
      flex-wrap: wrap;
    }
  }
}

@media screen and (max-width:979px) and (min-width: 580px) {
section{
    padding:0 5%;
  article{
    ${defaultFlexCenter}
    margin-right: 0;
  }
}
}

@media screen and (max-width:579px) and (min-width: 230px) {
padding-top: 80px;
section{
    padding: 0 5%;
    .title{
    h3{
      margin-bottom: 30px;
      font-size: 1.5rem;
      font-weight: ${fontWeight.medium};
    }
  }
  article{
    margin-bottom: 20px;
    ${defaultFlexCenter}
    justify-content: flex-start;
    flex-wrap: wrap;
    .card{
      width: 100%;
      ${defaultFlexCenter}
      justify-content: center;
      flex-wrap: wrap;
    }
  }
}

}
`;

const StyledModal = styled.div`
width: 100%;
height: 100%;
position: fixed;
top: 0;
left: 0;
background-color: rgba(0,0,0,0.4);
z-index: 100;

`;

const StyledCardView = styled.div`
position: relative;
.button{
  position: absolute;
  bottom: 50px;
  right: 50px;
  button{
    color: #999;
    font-size: ${fontSize.micro};
    :hover{
      cursor: pointer;
      color: ${palette.main.default};
    }
  }
}

@media screen and (max-width:579px) and (min-width: 230px) {
  width: 100%;
.button{
  position: absolute;
  bottom: 50px;
  right: 20px;
  button{
    font-size: ${fontSize.micro};
    :hover{
      cursor: pointer;
      color: ${palette.main.default};
    }
  }
}
}
`;