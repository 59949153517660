import React, { useEffect, useState } from 'react';
import myAxios from '../../modules/api/myAxios';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Viewer } from '@toast-ui/react-editor';

export default function TimeLine() {
	const [latestChangeData, setLatestChangeData] = useState(null);

	useEffect(() => {
		requestData();
	}, []);

	const requestData = async () => {
		try {
			const response = await myAxios.get('/superadmin/changelog');
			const sliceData = response.data.data.slice(0, 4);
			setLatestChangeData(sliceData);
		} catch (error) {}
	};
	const { t } = useTranslation();

	return (
		<>
			<span className="text-sm lg:text-lg font-[600] ">Latest Changes</span>

			<ol className="relative border-l border-gray-200 max-w-[150px] mt-5 ">
				{latestChangeData !== null &&
					latestChangeData.map((data, index) => {
						const startIdx = data.contents.indexOf('<p>');
						const endIdx = data.contents.indexOf('</p>');
						const subs = data.contents.substring(startIdx, endIdx + 4);
						return (
							<li className="mb-10 ml-4" key={index}>
								<div className="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -left-1.5 border border-white " />
								<strong>
									<Viewer initialValue={subs} className="font-bold"></Viewer>
								</strong>
								<time className="mb-1 text-sm font-normal leading-none text-gray-700">
									{moment(data.reg_date * 1000).format('YYYY-MM-DD ')}
								</time>
								<br />
								<span className="mb-1 text-sm font-normal leading-none text-gray-700">
									{data.lang}
								</span>
							</li>
						);
					})}
				{latestChangeData === null && (
					<li className="mb-10 ml-4">
						<div className="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -left-1.5 border border-white " />
						<p className="mb-4 text-sm font-normal text-black ">
							{t('history.nodata')}
							<br />
						</p>
					</li>
				)}
			</ol>
		</>
	);
}
