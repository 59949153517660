import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';

export default function DeveloperFirst() {
	const { t } = useTranslation();
	const [activeIndex, setActiveIndex] = useState(0);
	const isSmallScreen = useMediaQuery({ maxWidth: 640 }); // Set the breakpoint value according to your requirements
	const tabContArr = [
		{
			tabTitle: (
				<button
					className={
						activeIndex === 0
							? ` border-b-2 mr-2 border-main-50 text-main-50 font-bold dark:text-white focus:outline-none   md:text-md  lg:text-[1.25rem] ${
									isSmallScreen ? 'text-sm mr-1' : ''
							  }`
							: `mr-2 dark:text-gray-200 focus:outline-none  lg:text-[1.25rem] text-[#333333] ${
									isSmallScreen ? 'text-sm mr-1' : ''
							  }`
					}
					type="button"
					onClick={() => tabClickHandler(0)}
				>
					Javascript
				</button>
			),
			tabCont: (
				<div className="dark:text-white  ">
					<div className="flex flex-col  xs:text-xs md:text-sm lg:text-lg ">
						<code className="dark:text-white ">
							<code className="text-amber-400">Omnitalk</code>.
							<code className="text-amber-300">Omnitalk</code>.
							<code className="text-blue-300">sdkInit</code>
							<code className="text-blue-400">(</code>
							<code className="text-lime-300">
								"KFWI-37FW-NEA1-118D","18f3fkj"
							</code>
							<code className="text-blue-400">)</code>;
						</code>
						<code className="">
							<code className="text-purple-300">const </code>sdk ={' '}
							<code className="text-amber-300">Omnitalk</code>.
							<code className="text-amber-300">Omnitalk</code>.
							<code className="text-blue-300">getInstance</code>
							<code className="text-blue-400">(</code>
							<code className="text-blue-400">)</code>;
						</code>
						<code className="dark:text-white">
							<code className="text-blue-300">await</code> sdk.createSession
							<code className="text-blue-400">(</code>
							<code className="text-blue-400">)</code>;
						</code>
						<code className="dark:text-white">
							<code className="text-purple-300">const</code> room ={' '}
							<code className="text-blue-300">await</code> sdk.createRoom
							<code className="text-blue-400">(</code>
							<code className="text-lime-300">"videoroom"</code>
							<code className="text-blue-400">)</code>;
						</code>
						<code className="dark:text-white">
							<code className="text-blue-300">await</code> sdk.joinRoom
							<code className="text-blue-400">(</code>
							<code className="text-lime-300">room.room_id</code>
							<code className="text-blue-400">)</code>;
						</code>
						<code className="dark:text-white">
							<code className="text-blue-300">await</code> sdk.publish
							<code className="text-blue-400">(</code>
							<code className="text-blue-400">)</code>;
						</code>
					</div>
				</div>
			),
		},
		{
			tabTitle: (
				<button
					className={
						activeIndex === 1
							? ` border-b-2 mr-2 border-main-50 text-main-50 font-bold dark:text-white focus:outline-none   md:text-md  lg:text-[1.25rem] ${
									isSmallScreen ? 'text-sm' : ''
							  }`
							: `mr-2 dark:text-gray-200 focus:outline-none  lg:text-[1.25rem] text-[#333333] ${
									isSmallScreen ? 'text-sm' : ''
							  }`
					}
					type="button"
					onClick={() => tabClickHandler(1)}
				>
					Typescript
				</button>
			),
			tabCont: (
				<div className="dark:text-white  ">
					<div className="flex flex-col  xs:text-xs md:text-sm lg:text-lg ">
						<div className="flex flex-col  xs:text-xs md:text-sm lg:text-lg ">
							<code className="dark:text-white ">
								<code className="text-amber-300">Omnitalk</code>.
								<code className="text-blue-300">sdkInit</code>
								<code className="text-blue-400">(</code>
								<code className="text-lime-300">
									"KFWI-37FW-NEA1-118D","18f3fkj"
								</code>
								<code className="text-blue-400">)</code>;
							</code>
							<code className="">
								<code className="text-purple-300">const </code>sdk ={' '}
								<code className="text-amber-300">Omnitalk</code>.
								<code className="text-blue-300">getInstance</code>
								<code className="text-blue-400">(</code>
								<code className="text-blue-400">)</code>;
							</code>
							<code className="dark:text-white">
								<code className="text-blue-300">await</code> sdk.createSession
								<code className="text-blue-400">(</code>
								<code className="text-blue-400">)</code>;
							</code>
							<code className="dark:text-white">
								<code className="text-purple-300">const</code> room ={' '}
								<code className="text-blue-300">await</code> sdk.createRoom
								<code className="text-blue-400">(</code>
								<code className="text-lime-300">"videoroom"</code>
								<code className="text-blue-400">)</code>;
							</code>
							<code className="dark:text-white">
								<code className="text-blue-300">await</code> sdk.joinRoom
								<code className="text-blue-400">(</code>
								<code className="text-lime-300">room.room_id</code>
								<code className="text-blue-400">)</code>;
							</code>
							<code className="dark:text-white">
								<code className="text-blue-300">await</code> sdk.publish
								<code className="text-blue-400">(</code>
								<code className="text-blue-400">)</code>;
							</code>
						</div>
					</div>
				</div>
			),
		},
		{
			tabTitle: (
				<button
					className={
						activeIndex === 2
							? ` border-b-2 mr-2 border-main-50 text-main-50 font-bold dark:text-white focus:outline-none   md:text-md  lg:text-[1.25rem] ${
									isSmallScreen ? 'text-sm' : ''
							  }`
							: `mr-2 dark:text-gray-200 focus:outline-none  lg:text-[1.25rem] text-[#333333] ${
									isSmallScreen ? 'text-sm' : ''
							  }`
					}
					type="button"
					onClick={() => tabClickHandler(2)}
				>
					ReactNative
				</button>
			),
			tabCont: (
				<div className="dark:text-white  ">
					<div className="flex flex-col  xs:text-xs md:text-sm lg:text-lg ">
						<code className="dark:text-white ">
							<code className="text-amber-400">Omnitalk</code>.
							<code className="text-blue-300">sdkInit</code>
							<code className="text-yellow-300">(</code>
							<code className="text-lime-200">
								"KFWI-37FW-NEA1-118D","18f3fkj"
							</code>
							<code className="text-yellow-300">)</code>;
						</code>
						<code className="dark:text-white ">
							<code className="text-purple-300">const</code> sdk ={' '}
							<code className="text-amber-400">Omnitalk</code>.
							<code className="text-blue-300">getInstance</code>
							<code className="text-yellow-400">(</code>
							<code className="text-yellow-400">)</code>;
						</code>
						<code className="dark:text-white">
							<code className="text-cyan-200">await</code> sdk.createSession
							<code className="text-yellow-400">(</code>
							<code className="text-yellow-400">)</code>;
						</code>
						<code className="dark:text-white">
							<code className="text-purple-300">const</code> room ={' '}
							<code className="text-cyan-200">await</code> sdk.createRoom
							<code className="text-yellow-400">(</code>
							<code className="text-lime-200">"videoroom"</code>
							<code className="text-yellow-400">)</code>;
						</code>
						<code className="dark:text-white">
							<code className="text-cyan-200">await</code> sdk.joinRoom
							<code className="text-yellow-400">(</code>
							<code className="text-lime-200">room_id</code>
							<code className="text-yellow-400">)</code>;
						</code>
						<code className="dark:text-white">
							<code className="text-cyan-200">await</code> sdk.publish
							<code className="text-yellow-400">(</code>
							<code className="text-lime-200">localRenderer</code>
							<code className="text-yellow-400">)</code>;
						</code>
					</div>
				</div>
			),
		},
		{
			tabTitle: (
				<button
					className={
						activeIndex === 3
							? ` border-b-2 mr-2 border-main-50 text-main-50 font-bold dark:text-white focus:outline-none   md:text-md  lg:text-[1.25rem] ${
									isSmallScreen ? 'text-sm' : ''
							  }`
							: `mr-2 dark:text-gray-200 focus:outline-none  lg:text-[1.25rem] text-[#333333] ${
									isSmallScreen ? 'text-sm' : ''
							  }`
					}
					type="button"
					onClick={() => tabClickHandler(3)}
				>
					Flutter
				</button>
			),
			tabCont: (
				<div className="dark:text-white  ">
					<div className="flex flex-col  xs:text-xs md:text-sm lg:text-lg ">
						<code className="dark:text-white ">
							<code className="text-sky-300">omnitalk</code> ={' '}
							<code className="text-lime-200">Omnitalk </code>
							<code className="text-fuchsia-200">(</code>
							<code className="text-teal-300">
								"KFWI-37FW-NEA1-118D","18f3fkj"
							</code>
							<code className="text-fuchsia-200">)</code>;
						</code>
						<code className="dark:text-white">
							<code className="text-amber-100">await </code>
							<code className="text-sky-300">sdk</code>.createSession
							<code className="text-fuchsia-200">(</code>
							<code className="text-fuchsia-200">)</code>;
						</code>
						<code className="dark:text-white">
							<code className="text-amber-100">await </code>
							<code className="text-sky-300">sdk</code>.createRoom
							<code className="text-fuchsia-200">(</code>
							<code className="text-teal-200">room_type</code>
							<code className="text-fuchsia-200">)</code>;
						</code>
						<code className="dark:text-white">
							<code className="text-amber-100">await</code>{' '}
							<code className="text-sky-300">sdk</code>.joinRoom
							<code className="text-fuchsia-200">(</code>
							<code className="text-teal-200">room_id: roomId</code>
							<code className="text-fuchsia-200">)</code>;
						</code>
						<code className="dark:text-white">
							<code className="text-amber-100">await</code>{' '}
							<code className="text-sky-300">omnitalk</code>.publish
							<code className="text-fuchsia-200">(</code>
							<code className="text-teal-200">localRenderer: localVideo</code>
							<code className="text-fuchsia-200">)</code>;
						</code>
					</div>
				</div>
			),
		},
		{
			tabTitle: (
				<button
					className={
						activeIndex === 4
							? ` border-b-2 mr-2 border-main-50 text-main-50 font-bold dark:text-white focus:outline-none   md:text-md  lg:text-[1.25rem] ${
									isSmallScreen ? 'text-sm' : ''
							  }`
							: `mr-2 dark:text-gray-200 focus:outline-none  lg:text-[1.25rem] text-[#333333] ${
									isSmallScreen ? 'text-sm' : ''
							  }`
					}
					type="button"
					onClick={() => tabClickHandler(4)}
				>
					iOS
				</button>
			),
			tabCont: (
				<div className="dark:text-white ">
					<div className="flex flex-col  xs:text-xs md:text-sm lg:text-lg ">
						<code className="dark:text-white">
							<code className="text-violet-300"> OmniTalk</code>.
							<code className="text-green-300">sdkInit</code>
							<code className="">(</code>
							<code className="text-teal-200">
								"KFWI-37FW-NEA1-118D","18f3fkj"
							</code>
							<code className="">)</code>
						</code>
						<code className="dark:text-white">
							<code className="text-rose-300">let</code> sdk =
							<code className="text-violet-300"> OmniTalk</code>.
							<code className="text-green-300">getInstance</code>
							<code className="">(</code>
							<code className="">)</code>
						</code>
						<code className="dark:text-white">
							<code className="text-rose-300">try </code>
							<code className="text-blue-300">await</code> sdk.
							<code className="text-green-300">createSession</code>
							()
						</code>
						<code className="dark:text-white ">
							<code className="text-rose-300">let </code> room ={' '}
							<code className="text-rose-300">try </code>{' '}
							<code className="text-blue-300">await</code> sdk.
							<code className="text-green-300">createRoom</code>
							(roomType: VIDEO_ROOM)
						</code>
						<code className="dark:text-white">
							<code className="text-rose-300">try </code>{' '}
							<code className="text-blue-300">await</code> sdk.
							<code className="text-green-300">joinRoom</code>
							(roomId: room.roomId)
						</code>
						<code className="dark:text-white">
							<code className="text-rose-300">try </code>{' '}
							<code className="text-blue-300">await</code> sdk.
							<code className="text-green-300">publish</code>
							(view: view)
						</code>
					</div>
				</div>
			),
		},
		{
			tabTitle: (
				<button
					className={
						activeIndex === 5
							? ` border-b-2 mr-2 border-main-50 text-main-50 font-bold dark:text-white focus:outline-none   md:text-md  lg:text-[1.25rem] ${
									isSmallScreen ? 'text-sm' : ''
							  }`
							: `mr-2 dark:text-gray-200 focus:outline-none  lg:text-[1.25rem] text-[#333333] ${
									isSmallScreen ? 'text-sm' : ''
							  }`
					}
					type="button"
					onClick={() => tabClickHandler(5)}
				>
					Android
				</button>
			),
			tabCont: (
				<div className="dark:text-white ">
					<div className="flex flex-col  xs:text-xs md:text-sm lg:text-lg ">
						<code className="dark:text-white">
							<code className="text-[#c678DD]">Omnitalk</code>.
							<code className="text-[#e5c07b]">sdkInit</code>
							<code className="">(</code>
							<code className="text-[#61afef]">"KFWI-37FW-NEA1-118D"</code>
							<code className="">,</code>
							<code className="text-[#61afef]">"18f3fkj"</code>
							<code className="">,</code>
							<code className="text-[#98c379]">applicationContext</code>
							<code className="">)</code>;
						</code>
						<code className="dark:text-white">
							<code className="text-[#c678DD]">val </code>
							<code className="">sdk </code>
							<code className="">&#61; </code>
							<code className="text-[#c678DD]">Omnitalk</code>.
							<code className="text-[#e5c07b]">getInstance</code>
							<code className="">(</code>
							<code className="">)</code>;
						</code>
						<code className="dark:text-white">
							<code className="">sdk</code>.
							<code className="text-[#e5c07b]">createSession</code>
							<code className="">(</code>
							<code className="text-[#98c379]">
								audioIn, audioOut, sourceImage
							</code>
							<code className="">)</code>
						</code>
						<code className="dark:text-white">
							<code className="text-[#c678DD]">val </code>
							<code>room </code>
							<code>&#61; </code>
							<code className="">sdk</code>.
							<code className="text-[#e5c07b]">createRoom</code>
							<code className="">(</code>
							<code className="text-white">roomType</code>
							<code className=""> = </code>
							<code className="text-white">VIDEOROOM_TYPE.videoroom</code>
							<code className="">)</code>;
						</code>
						<code className="dark:text-white">
							<code className="">sdk</code>.
							<code className="text-[#e5c07b]">joinRoom</code>
							<code className="">(</code>
							<code className="text-white">room.roomId</code>
							<code className="">)</code>;
						</code>
						<code className="dark:text-white">
							<code className="">sdk</code>.
							<code className="text-[#e5c07b]">publish</code>
							<code className="">(</code>
							<code className="text-white">localView</code>
							<code className=""> = </code>
							<code className="text-white">view</code>
							<code className="">)</code>;
						</code>
					</div>
				</div>
			),
		},
		{
			tabTitle: (
				<button
					className={
						activeIndex === 6
							? ` border-b-2 mr-2 border-main-50 text-main-50 font-bold dark:text-white focus:outline-none   md:text-md  lg:text-[1.25rem] ${
									isSmallScreen ? 'text-sm' : ''
							  }`
							: `mr-2 dark:text-gray-200 focus:outline-none  lg:text-[1.25rem] text-[#333333] ${
									isSmallScreen ? 'text-sm' : ''
							  }`
					}
					type="button"
					onClick={() => tabClickHandler(6)}
				>
					Unity
				</button>
			),
			tabCont: (
				<div className="dark:text-white ">
					<div className="flex flex-col  xs:text-xs md:text-sm lg:text-lg ">
						<code className="dark:text-white">
							<code className="text-cyan-400">Omnitalk </code>
							omnitalk = new Omnitalk
							<code className="text-fuchsia-300">(</code>
							<code className="text-blue-300">
								"KFWI-37FW-NEA1-118D","18f3fkj"
							</code>
							<code className="text-fuchsia-300">)</code>;
						</code>
						<code className="dark:text-white">
							<code className="text-amber-100">await</code> omnitalk.
							<code className="text-teal-300">CreateSession</code>
							<code className="text-fuchsia-300">(</code>
							<code className="text-fuchsia-300">)</code>;
						</code>
						<code className="dark:text-white">
							<code className="text-amber-100">await</code> omnitalk.
							<code className="text-teal-300">MakeDevice</code>
							<code className="text-fuchsia-300">(</code>
							<code className="text-blue-300">
								audioIn, audioOut, sourceImage
							</code>
							<code className="text-fuchsia-300">)</code>;
						</code>
						<code className="dark:text-white">
							<code className="text-amber-100">await</code> omnitalk.
							<code className="text-teal-300">JoinRoom</code>
							<code className="text-fuchsia-300">(</code>
							<code className="text-fuchsia-300">)</code>;
						</code>

						<code className="dark:text-white">
							<code className="text-amber-100">await</code> omnitalk.
							<code className="text-teal-300">Publish</code>
							<code className="text-fuchsia-300">(</code>
							<code className="text-blue-300">"videocall", false</code>
							<code className="text-fuchsia-300">)</code>;
						</code>
					</div>
				</div>
			),
		},
	];

	const tabClickHandler = index => {
		setActiveIndex(index);
	};

	return (
		<article className="lg:mt-20 max-w-full  h-auto ml-auto mr-auto bg-[#f9fafb] dark:bg-[#121212] xs:px-5 sm:px-0">
			<div className=" xs:pt-12   sm:pt-20 lg:pt-[9rem] ">
				<p className=" xs:mt-0 mb-0 text-center font-black dark:text-white sm:text-xl md:text-2xl lg:text-[3rem] lg:leading-[3rem] ">
					{t('main.code.desc')}
				</p>
				<p className="xs:mt-0 mb-0 lg:mt-4 text-center font-black dark:text-white sm:text-xl md:text-2xl lg:text-[3rem] lg:leading-[3rem] ">
					{t('main.code.desc2')}
				</p>
			</div>
			<div className="flex flex-col  items-center ">
				<span className="xs:text-sm sm:text-base xs:mt-2 lg:mt-8 xs:mb-8 lg:mb-[7rem] md:text-base  self-center lg:text-[1.375rem] text-[#323232] font-semi-bold dark:text-white ">
					{t('main.code.desc3')}
				</span>

				{/* =================== */}
				{/* Responsive rendering */}

				{isSmallScreen ? (
					<div>
						<Scroll>
							{tabContArr.map((section, index) => {
								return (
									<div key={index} className="flex lg:text-base font-bold mr-2">
										{section.tabTitle}
									</div>
								);
							})}
						</Scroll>
					</div>
				) : (
					<div>
						<div className="flex items-center justify-center mb-4">
							{tabContArr.map((section, index) => {
								return (
									<div
										key={index}
										className="flex lg:text-base font-bold mr-8 "
									>
										{section.tabTitle}
									</div>
								);
							})}
						</div>
					</div>
				)}

				{/* =============== */}
				<div className="dark:border-[#2d2d2d]   xs:w-full xs:mt-3 sm:w-[60%] md:w-[82%] lg:max-w-[65rem] h-70 rounded-lg p-6 lg:mt-3 lg:mb-[9rem] bg-[#2d2d2d]">
					<div className="flex flex-col text-white  ">
						<div className="flex mb-8">
							<div className="w-3 h-3 mr-2 bg-red-500 rounded-full" />
							<div className="w-3 h-3 mr-2 bg-amber-400 rounded-full" />
							<div className="w-3 h-3 bg-green-500 rounded-full" />
						</div>

						{isSmallScreen ? (
							/* Show small screen contents */
							<div>
								<div className="font-xs">{tabContArr[activeIndex].tabCont}</div>
							</div>
						) : (
							/* Show large screen contents */
							<div>{tabContArr[activeIndex].tabCont}</div>
						)}

						{/* ===============terminal responsive ============= */}
					</div>
				</div>
				<div className="xs:pb-12 sm:pb-20"></div>
			</div>
		</article>
	);
}

const Scroll = styled.div`
  display: flex;
  max-width: 280px;
  overflow-x: scroll;
  overflow-y: hidden;

  ::-webkit-scrollbar {
    width: 10px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: transparent;
    /* background: linear-gradient(90deg, #e84117 0%, #fbbcc5 100%); */
    border-radius: 999px;
  }
  ::-webkit-scrollbar-track {
    /* background-color: #ccc; */
    background-color: transparent;

    border-radius: 10px;
    /* box-shadow: inset 0px 0px 5px white; */
  }
`;
