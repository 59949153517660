import { CALL_TYPE, MESSAGE_ACTION, TRACK_TYPE, VIDEOROOM_TYPE } from "./common";

export enum OmniEvent {
    LEAVE_EVENT = 'LEAVE_EVENT', // 참가자 퇴장시
    KICKOUT_EVENT = 'KICKOUT_EVENT', // 다른 참가자가 퇴장 당했을 시
    BROADCASTING_EVENT = 'BROADCASTING_EVENT', // 참가자 영상 송출시 발생 이벤트
    RINGBACK_EVENT = 'RINGBACK_EVENT', // 전화 발신 이벤트 ( 발신자 기준 )
    RINGING_EVENT = 'RINGING_EVENT', // 전화 수신 이벤트 ( 착신자 기준 )
    CONNECTED_EVENT = 'CONNECTED_EVENT', // 참가자 음성 송출시 발생 이벤트
    SCREEN_SHARE_EVENT = 'SCREEN_SHARE_EVENT', // screen share 생성 이벤트
    SCREEN_UNSHARE_EVENT = 'SCREEN_UNSHARE_EVENT', // screen share 삭제 이벤트
    MUTE_EVENT = 'MUTE_EVENT', // mute 이벤트
    UNMUTE_EVENT = 'UNMUTE_EVENT', // unmute 이벤트
    MESSAGE_EVENT = 'MESSAGE_EVENT' // 채팅 이벤트
}

export interface EventLeave {
    cmd: OmniEvent;
    session: string;
}

export interface EventKickOut {
    cmd: OmniEvent;
    session: string;
    room_type: VIDEOROOM_TYPE;
    call_type: CALL_TYPE;
}

export interface EventBroadcast {
    cmd: OmniEvent;
    session: string;
    user_id: string;
    room_type: VIDEOROOM_TYPE;
    call_type: CALL_TYPE;
}

export interface EventRinging {
    cmd: OmniEvent;
    session: string;
    user_id: string;
    caller: string;
    callee: string;
    room_type: VIDEOROOM_TYPE;
    call_type: CALL_TYPE;
}

export interface EventRingBack {
    cmd: OmniEvent;
    session: string;
    caller: string;
    callee: string;
    call_type: CALL_TYPE;
}

export interface EventConnected {
    cmd: OmniEvent;
    session: string;
    user_id: string;
    room_type: VIDEOROOM_TYPE;
    call_type: CALL_TYPE;
}


export interface EventScreenShare {
    cmd: OmniEvent;
    session: string;
    user_id: string;
    room_type: VIDEOROOM_TYPE;
    call_type: CALL_TYPE;
}

export interface EventScreenUnshare {
    cmd: OmniEvent;
    session: string;
    user_id: string;
    room_type: VIDEOROOM_TYPE;
    call_type: CALL_TYPE;
}

export interface EventMute {
    cmd: OmniEvent;
    session: string;
    track: TRACK_TYPE;
}

export interface EventUnmute {
    cmd: OmniEvent;
    session: string;
    track: TRACK_TYPE;
}

export interface EventMessage {
    cmd: OmniEvent;
    timestamp: number;
    session: string;
    action: MESSAGE_ACTION;
    user_id: string;
    user_name: string;
    message?: string;
}