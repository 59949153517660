import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { GrClose } from 'react-icons/gr';
import { defaultFlexCenter, fontSize, palette, defaultInput } from '../../modules/defines/style';
import API from '../../modules/api/api';
import myAxios from '../../modules/api/myAxios';
import PopupLoading from '../PopupLoading';

function ModifyModal({ onChildSuccess, selectedData, setModifyModal, t }) {
  const [serviceIdList, setServiceIdList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const initFormValues = {
    service_id: selectedData.data.service_id,
    url: selectedData.data.url,
    hook_type: selectedData.data.hook_type,
    idx: selectedData.data.idx
  };
  const [formData, setFormData] = useState(initFormValues);
  useEffect(() => {
    requestServiceList();
  }, []);
  const handleFormChange = (e) => {
    setFormData(prev => ({ ...prev, [e.target.name]: e.target.value }));
  };
    
  const requestServiceList = () => {
    API.getServiceList({
    }).then(response => {
      setServiceIdList(response.data.data[0].service);
      
    }, error => {
      console.log(error);
    });

  };

  const handleRequestModify = async() => {
    setErrorMsg('');
    try {
      setIsLoading(true);
      const response = await myAxios.patch('/users/webhook', formData);
      if (response.data.result === 'success') {
      
        setIsLoading(false);
        onChildSuccess();
        setModifyModal(false);
      }
    } catch (error) {
      setIsLoading(false);
      // setModifyModal(false);
      setErrorMsg(t('error.webhookErr'));
    }
  };
  
  return (
    <StyledModal>
      {isLoading && (
        <PopupLoading />
      )}
      <div className="title">
        <h1>Web Hook</h1>
        <button
          type="button" onClick={() => {
            setModifyModal(false);
          }}><GrClose style={{ fontSize:'1.25rem' }} />
        </button>
      </div>
      <div className="select_box">
        <label htmlFor="service_id">Service ID</label>
        <select
          className="select"
          name="service_id" id="service_id"
          onChange={(e) => setFormData(prev => ({ ...prev, service_id: e.target.value }))}
          value={formData.service_id} 
        >
          
          <option>{t('console.modal.add.hookOp1')}</option>
          {serviceIdList.map((service) => (
            <option
              id="serviceId"
              key={service.service_id}
              value={service.service_id}
            >
              {service.service_id}
            </option>
          ))}
        </select>
      </div>
      <div className="select_box">
        <label htmlFor="hook_type">Hook Type</label>
        <select
          className="select"
          name="hook_type" id="hook_type"
          onChange={(e) => setFormData(prev => ({ ...prev, hook_type: e.target.value }))}
          value={formData.hook_type} 
        >
          <option>{t('console.modal.add.hookOp2')}</option>
          <option
            id="hook_type"
            value="record"
          >RECORD
          </option>
          <option
            id="hook_type"
            value="cdr"
          >CDR
          </option>
          <option
            id="hook_type"
            value="room"
          >ROOM
          </option>
        </select>
      </div>
      <div className="select_box">
        <label htmlFor="url">Url</label>
        <input
          type="text" name="url" maxLength="100"
          placeholder="https://"
          value={formData.url} onChange={handleFormChange}
        />
        
      </div>
      <span className="error">{errorMsg}</span>
      <div className="button">
        {formData.url === ''
          ? (
            <button
              type="button" onClick={handleRequestModify}
              disabled
              className="disabled"
            >{t('console.modal.modify.button')}
            </button>
          ) : (
            <button
              type="button" onClick={handleRequestModify}
            >{t('console.modal.modify.button')}
            </button>
          )}
          
      </div>
      
    </StyledModal>
  );
}

export default ModifyModal;

const StyledModal = styled.div`
width: 465px;
height: 530px;
padding: 30px;
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%,-50%);
background-color: ${palette.white};
border-radius:10px;
background-color: ${palette.gray.light};
.title{
    ${defaultFlexCenter}
    justify-content: space-between;
    margin-bottom: 20px;
    border-bottom: 1px solid ${palette.gray.middle};
    h1{
    font-size: ${fontSize.medium};
    }
    button{
        border: 0;
        background-color: ${palette.opacity};
    }
}
.select_box{
    width: 100%;
    margin-bottom: 20px;
    label{
        display: inline-block;
        width: 100%;
        line-height: 40px;
    }
    input{
        ${defaultInput}
        width: 100%;
    }
    .select{
      width: 100%;
      height: 48px;
      background-color: ${palette.white};
      border
      ::-ms-expand { 
        display: none;
      }
      .option{
        background-color: #fff;
        color: #fff;
        padding: 3px 0;
        font-size: 1rem;
      }
      }
   
}
.error{
  display: flex;
  justify-content: center;
        color: red;
        font-weight: 500;
        text-align: center;
        margin: 0 auto;
        margin-bottom: 10px;
      }
.button{
    ${defaultFlexCenter}
    button{
        width: 164px;
        height: 42px;
        background-color: ${palette.main.default};
        border: 0;
        border-radius: 5px;
        font-size: 1rem;
        color: ${palette.white};
        :hover{
          cursor: pointer;
        }
    }
    .disabled{
        width: 164px;
        height: 42px;
        border-radius: 5px;
        background-color: rgba(255,83,29,0.5);
        font-size: 1rem;
        color: white;
        }
}

@media screen and (max-width:579px) and (min-width:230px) {
width: 90%;
}
`;
