import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { fontWeight, palette } from '../modules/defines/style';

function Terms() {
	const navigate = useNavigate();
	const { t } = useTranslation();
	return (
		<StyledTerms>
			<Helmet>
				<title>{t('helmet.terms.title')}</title>
				<meta charset="utf-8" />
				<meta name="title" content={t('helmet.terms.title')}></meta>
				<meta name="author" content="omnitalk"></meta>
				<meta
					name="keywords"
					content="WebRTC, SIP, CPaaS, communication, cloud-based, integration, solution, provider,WebRTC CPAAS,SIP CPaaS,WebRTC SIP integration,Cloud-based WebRTC,Cloud-based SIP,WebRTC and SIP solution,WebRTC and SIP provider,옴니톡,옴니스토리,비대면통신,커뮤니케이션플랫폼,메타버스,통신,통신서비스"
				/>
				<meta name="description" content={t('helmet.terms.desc')} />
				<meta property="og:title" content={t('helmet.terms.ogTitle')} />
				<meta property="og:description" content={t('helmet.terms.ogDesc')} />
				<meta property="og:url" content="https://omnitalk.io/terms" />
				<link rel="canonical" href="https://omnitalk.io/terms/" />
				<meta property="og:type" content="website" />
				<meta property="twitter:title" content={t('helmet.terms.title')} />
				<meta property="twitter:card" content={t('helmet.terms.ogCard')} />
				<meta property="twitter:description" content={t('helmet.terms.desc')} />
				<meta name="robots" content="index" />
			</Helmet>
			<section>
				<h1>
					<span>OMNI</span>TALK 서비스 이용 약관
				</h1>
				<div className="terms_tab">
					<button type="button" className="active">
						이용약관
					</button>
					<button
						type="button"
						onClick={() => navigate('/terms/privacy-policy')}
					>
						개인정보처리방침
					</button>
				</div>
			</section>
			<section className="contents_container">
				<article>
					<div className="container">
						<h4>제1장 총직</h4>

						<h5>제 1 조 (목적)</h5>
						<p>
							본 약관은 주식회사 옴니스토리(이하 <strong>‘회사’</strong>)가
							제공하는 옴니톡 서비스를 이용함에 있어, 회사와 서비스를 이용하고자
							하는 회원(이하 <strong>‘회원’</strong>) 사이의 권리, 의무 및
							책임사항을 규정함을 목적으로 합니다.
						</p>

						<h5>제 2 조 (약관의 효력 및 변경)</h5>
						<ol>
							<li>
								본 약관의 내용은 옴니스토리 웹사이트(
								<a href="https://omnitalk.io" target="_blank" rel="noreferrer">
									https://omnitalk.io
								</a>
								)에 게시하거나 기타의 방법으로 공지하고, 본 약관에 동의한 회원
								모두에게 그 효력이 발생합니다.
							</li>
							<li>
								회사는 필요한 경우 관련 법령을 위배하지 않는 범위 내에서 본
								약관, 운영정책 또는 규칙(이하 <strong>‘세부지침’</strong>)을
								변경할 수 있습니다. 약관이 변경되는 경우 회사는 변경사항을
								시행일자 7일 전부터 회원에게 옴니톡 계정 또는 웹사이트를 통해
								공지하는 것을 원칙으로 하며, 피치 못하게 회원에게 불리한
								내용으로 변경할 경우에는 그 시행일자 30일 전부터 공지하거나
								옴니톡 계정에 등록된 이메일 주소로 이메일 발송, 서비스 내
								전자쪽지 발송, 서비스 내 알림 메시지를 띄우는 방법 등으로
								개별적으로 알려 드리겠습니다.
							</li>
							<li>
								회사가 전항에 따라 공지 또는 통지를 하면서 공지 또는
								통지일로부터 개정약관 시행일 7일 후까지 거부의사를 표시하지
								아니하면 승인한 것으로 본다는 뜻을 명확하게 고지하였음에도
								회원의 의사표시가 없는 경우에는 변경된 약관을 승인한 것으로
								봅니다. 회원이 개정약관에 동의하지 않을 경우 회원은 이용계약을
								해지할 수 있습니다.
							</li>
						</ol>

						<h5>제 3 조 (약관 외 준칙)</h5>
						<ol>
							<li>
								본 약관에 규정되지 않은 사항에 대해서는 관련 법령, 상관습,
								세부지침 및 회원과 회사 간 체결한 별도의 계약 내용에 따릅니다.
							</li>
							<li>
								본 약관과 세부지침 또는 회원과 회사 간의 별도 계약의 내용이
								상충될 경우 별도의 계약, 세부지침, 본 약관의 순서로 우선하여
								적용됩니다.
							</li>
						</ol>

						<h5>제 4 조 (용어의 정의)</h5>
						<p>본 약관에서 사용하는 용어의 정의는 다음과 같습니다.</p>

						<ol>
							<li>
								옴니톡 서비스: 회사가 제공하는 WebRTC기반의 통화/ 방송/
								그룹통화/ 화상회의 솔루션 및 플랫폼 서비스와 PSTN 기반의 전화
								착/발신 및 SMS 서비스등을 말합니다(이하 ‘옴니톡’).
							</li>
							<li>
								회원: 본 약관에 동의하고 옴니톡 서비스 및 부가서비스를 이용하는
								법인 또는 개인사업자를 말합니다.
							</li>
							<li>
								옴니톡 계정: 옴니톡 서비스 웹 콘솔에 로그인하기 위한 정보를
								의미하며, 회원 인증, 회원정보 변경, 회원 가입 및 탈퇴, 서비스
								시크릿확인, 서비스 이용의 일시적 중지 또는 서비스 이용량 관리
								등에 사용됩니다.
							</li>
							<li>
								비밀번호: 회원이 설정한 옴니톡 계정과 일치되는 회원임을 확인하고
								비밀보호를 위해 회원 자신이 정한 문자, 숫자 또는 부호의 조합을
								의미합니다.
							</li>
							<li>
								무료서비스: 회원이 옴니톡 서비스를 이용함에 있어서 별도의 비용을
								지급하지 않고도 사용할 수 있는 서비스를 의미합니다.
							</li>
							<li>
								유료서비스: 회원이 옴니톡 서비스를 이용함에 있어서 별도의 비용을
								지급하고 사용하는 서비스를 의미합니다.
							</li>
							<li>
								애플리케이션: 옴니톡 서비스를 이용하여 회원이 만든 소프트웨어를
								의미합니다.
							</li>
							<li>
								옴니톡 SDK(Software Development Kit): 애플리케이션에 삽입되는
								소프트웨어 모듈로서 애플리케이션의 원활한 개발을 위해 회사가
								회원에게 제공하는 개발도구를 의미합니다.
							</li>
							<li>
								옴니톡 샘플코드(Sample Code): 옴니톡 SDK를 이용하여 회사가 만든
								애플리케이션 예시를 의미합니다.
							</li>
							<li>
								서비스 아이디: 개별 애플리케이션마다 부여하는 애플리케이션 식별
								정보를 의미합니다.
							</li>
							<li>
								서비스 시크릿: 서비스 아이디의 소유를 확인하기 위한 정보를
								의미합니다.
							</li>
							<li>
								최종이용자: 옴니톡 서비스를 통해 회원이 제공하는 애플리케이션를
								이용하는 자를 말합니다.
							</li>
							<li>
								이용자 정보: 회원 및 최종이용자가 회사의 정보통신자원에 저장하는
								정보(「국가정보화기본법」 제3조 제1호에 따른 정보, 개인의
								경우에는 개인정보와 신용정보를 포함합니다.)로서 회원이 소유 또는
								관리하는 정보를 말합니다.
							</li>
							<li>
								멤버: 회원으로부터 아이디를 부여 받아 회원의 관리 하에 옴니톡
								서비스를 이용하는 자를 의미하며, 회원으로부터 부여받는 권한에
								따라 관리자, 일반 멤버 등으로 구분됩니다.
							</li>
						</ol>

						<h4>제2장 이용계약의 체결</h4>

						<h5>제 5 조 (옴니톡 계정 생성 및 관리)</h5>
						<ol>
							<li>
								옴니톡 계정 이용 신청은 옴니톡 웹사이트 회원가입 화면에서
								이용신청자가 옴니톡 계정 정보에 일정 정보를 입력하는 방식으로
								이루어집니다.
							</li>
							<li>
								본 조 제 1 항의 이용 신청에 있어 회사는 이용신청자에게 이용 신청
								판단에 필요한 상담 및 정보제공을 요청할 수 있습니다.
							</li>
							<li>
								옴니톡 이용 계약은 이용신청자가 본 약관의 내용에 대하여 동의를
								한 다음 이용신청을 하고 회사가 이러한 신청에 대한 완료를
								신청절차 상에 표시함으로써 체결됩니다.
							</li>
							<li>
								회사는 이용신청자의 신청에 대하여 별도의 승낙 절차를 두지
								않습니다. 다만, 회사는 다음 각 호의 경우에는 그 사유가 해소되지
								않는 이상 이용 계약이 체결된 후라도 이용 계약을 해지하거나
								옴니톡 계정 이용을 중지시키는 등 적절한 제한을 할 수 있습니다.
								<ol>
									<li>
										회사가 본 약관 또는 세부지침에 의해 이용신청자의 옴니톡
										계정을 삭제하였던 경우
									</li>
									<li>
										회사가 본 조 제 2 항의 상담 및 정보제공을 요청하였으나
										이용신청자가 합리적 이유 없이 이를 거부한 경우
									</li>
									<li>
										옴니톡 계정 생성 시 필요한 정보를 입력하지 않거나 허위의
										정보를 입력한 경우
									</li>
									<li>이용신청자가 미성년자인 경우</li>
									<li>개인정보가 유출될 우려가 있는 경우</li>
									<li>반사회적 또는 미풍양속에 어긋나는 경우</li>
									<li>
										회사 및 타 회원 또는 그 직원이나 관계자로 오인될 우려가 있는
										경우
									</li>
									<li>제공 서비스 설비 용량에 현실적인 여유가 없는 경우</li>
									<li>
										서비스 제공을 위한 기술적인 부분에 문제가 있다고 판단되는
										경우
									</li>
									<li>
										기타 회사가 재정적, 기술적으로 필요하다고 인정하는 경우
									</li>
									<li>
										회사로부터 회원자격 정지조치 등을 받은 회원이 그 조치기간에
										이용계약을 임의로 해지하고 재이용을 신청하는 경우
									</li>
									<li>
										기타 관련 법령에 위배되거나 세부지침 등 회사가 정한 기준에
										반하는 경우
									</li>
								</ol>
							</li>
							<li>
								회원은 옴니톡 계정을 직접 만들 수도 있고, 고용주 또는 교육기관과
								같은 관리자가 회원에게 옴니톡 계정을 배정할 수도 있습니다.
								관리자가 배정한 옴니톡 계정을 사용하고 있는 경우, 별도의 약관
								또는 추가 약관이 적용될 수 있으며 관리자가 회원의 계정에
								액세스하거나 계정을 해지할 수 있습니다.
							</li>
							<li>
								일부 서비스는 계정가입을 통해 서비스 시크릿을 발급받고 서비스
								시크릿을 통해서 서비스를 이용합니다. 회원은 이 서비스 시크릿을
								각별히 주의하여 관리해야하며 이를 통해 이루어지는 활동에 대한
								책임이 있습니다.
							</li>
							<li>
								옴니톡 서비스는 무료서비스인 Developer 상품과 유료서비스인
								Startup / Basic / Premium 상품으로 구분되며, 상세한 사항은
								옴니톡 웹사이트를 통해 확인할 수 있습니다. 회원의 상품 선택에
								따라 회사가 회원에게 제공하는 서비스의 상세 내용 및 제공 수준이
								결정됩니다.
							</li>
							<li>
								회원이 유료서비스를 이용하기 위해서는 회원을 대표하는 구성원이
								관리자 서비스에서 유료서비스 결제를 위한 결제 정보를 입력하거나
								유료서비스 이용에 대한 별도의 계약을 체결해야 합니다.
							</li>
						</ol>

						<h5>제 6 조 (회원 정보의 제공 및 관리)</h5>
						<ol>
							<li>
								회원은 본 약관 및 세부지침 등에 의해 회사에 정보를 제공하여야
								하는 경우에는 진실되고 적법한 정보를 제공하여야 하며, 허위 또는
								불법 정보 제공으로 인해 발생한 불이익에 대해서는 보호받지
								못합니다.
							</li>
							<li>
								회원의 옴니톡 계정에 관한 관리책임은 회원에게 있으며, 이를
								제3자가 이용하도록 하여서는 안됩니다. 회사는 제3자가 회원의
								옴니톡 계정 등을 무단으로 사용하는 것을 막기 위하여 비밀번호
								입력 및 추가적인 본인 확인 절차를 거치도록 할 수 있습니다.
							</li>
							<li>
								회원은 옴니톡 계정이 도용되거나 제3자가 사용하고 있음을 인지한
								경우에는 회사에게 알려주시기 바라며, 회사는 무단 사용을 막기
								위한 방법을 회원에게 안내하도록 하겠습니다.
							</li>
							<li>
								제 3 항의 경우에 해당 회원이 회사에 그 사실을 통지하지 않거나
								통지 후 회사의 안내에 따르지 않아 발생한 불이익에 대하여 회사는
								책임을 부담하지 않습니다.
							</li>
							<li>
								회원은 옴니톡 계정을 통하여 회원 정보를 열람하고 수정할 수
								있습니다. 다만, 옴니톡 서비스의 제공 및 관리를 위해 필요한
								옴니톡 계정, 도메인, 전화번호, 단말기 식별번호, 기타
								본인확인정보 등 일부 정보는 수정이 불가능할 수 있으며, 수정 또는
								추가하는 경우에는 본인 확인 절차가 필요할 수 있습니다. 회원이
								이용 신청 시 알려주신 내용에 변동이 있을 때, 직접 수정하시거나
								이메일, 고객센터를 통하여 회사에 알려 주시기 바랍니다.
							</li>
							<li>
								멤버는 옴니톡 계정 또는 옴니톡 웹사이트를 통하여 자신의 정보를
								수정할 수 있습니다. 다만, 회사 또는 회원이 허용하지 않는 정보의
								경우 수정할 수 없습니다.
							</li>
							<li>
								회원이 회원 정보를 적시에 수정하지 않아 발생하는 문제에 대하여
								회사는 책임을 부담하지 아니합니다.
							</li>
						</ol>

						<h4>제3장 옴니톡 서비스 이용</h4>

						<h5>제7조 (라이선스 제공의 범위)</h5>
						<ol>
							<li>
								회사는 회원에게 옴니톡 서비스의 일부로 제공하는 옴니톡 SDK와
								옴니톡 샘플코드를 사용할 수 있도록 라이선스를 제공합니다. 회원은
								회사가 제공한 대로 옴니톡 서비스를 이용해야합니다. 회원은 회사의
								서면허가를 받은 경우를 제외하고, 옴니톡 또는 이에 포함된
								소프트웨어의 일부를 복사, 수정, 배포, 판매, 양도, 담보제공 또는
								대여할 수 없으며, 소프트웨어를 역설계하거나 소스 코드의 추출을
								시도하는 등 옴니톡 서비스를 복제, 분해 또는 모방하거나 기타
								변형하는 일체의 행위를 할 수 없습니다.
							</li>
							<li>
								옴니톡에서 사용되는 일부 소프트웨어는 오픈소스 라이선스에 따라
								제공될 수 있습니다. 오픈소스 라이선스에는 본 약관의 일부 규정에
								우선하는 규정이 있을 수 있습니다.
							</li>
						</ol>

						<h5>제 8 조 (애플리케이션을 통한 제3자에 대한 서비스 제공)</h5>
						<ol>
							<li>
								회원은 자신의 영업을 위하여 옴니톡 서비스를 응용하여
								애플리케이션을 만들어 제3자에게 서비스를 제공 할 수 있습니다.
								회사는 제3자에게 제공되는 회원의 서비스에 책임을 지지 않습니다.
								회원은 관계법령에 의거하여 제3자에 대한 서비스를 운영하고 정당한
								계약을 통해 제3자의 권익을 보호하여야 합니다.
							</li>
							<li>
								본 약관에 의거하여 옴니톡 운영이 회원의 제3자 제공 서비스에
								영향을 준다 하더라도 회사는 어떠한 책임도 없음을 고지합니다.
								회원이 제3자에게 제공하는 서비스는 본 약관에 의거하여 이용중지,
								해약, 손해배상등의 조치가 취해질 수 있으며, 이러한 조치가 설사
								회원의 제3자 제공 서비스에 영향을 준다 하더라도 회사는 책임이
								없음을 고지합니다. 회원은 자신의 영업 목적이 아닌 제3자의 사업을
								위하여 애플리케이션을 만들 경우 회사의 동의를 구해야합니다.
							</li>
						</ol>

						<h5>제 9 조 (콘텐츠·데이터의 소유와 이용 )</h5>
						<ol>
							<li>
								회원은 옴니톡 서비스를 통하여 콘텐츠를 업로드, 제출, 저장, 전송,
								수신할 수 있으며 이에 대해 회원이 보유한 지식재산권 등은
								회원에게 귀속됩니다.
							</li>
							<li>
								회원이 옴니톡 서비스를 통해 회원의 콘텐츠를 업로드, 제출, 저장,
								전송 또는 수신하는 경우 회원은 회사(및 회사의 협력사)에게 이러한
								콘텐츠를 사용, 저장, 복제, 수정, 이차적 저작물(회원의 콘텐츠가
								본 서비스를 통해 더 잘 구현되도록 회사가 생성하는 번역본,
								개작본, 또는 수정본으로 인해 발생되는 것) 제작, 전달, 공개,
								공개적으로 실연, 공개적으로 게시 및 배포할 수 있는 전 세계적인
								라이선스를 제공하게 됩니다. 본 라이선스에서 회원이 부여하는
								권리는 회사가 옴니톡 서비스를 운영, 홍보 및 개선하고 새로운
								서비스를 개발하기 위한 제한적인 목적으로 사용됩니다. 본
								라이선스는 회원이 옴니톡 서비스의 사용을 중지 또는 종료한 후에도
								존속됩니다(예: 회원이 작성한 회사 게시판의 질의 정보). 회원은
								옴니톡에 제출한 콘텐츠에 대해 회사에 라이선스를 부여하기 위해
								필요한 권리를 보유해야 합니다.
							</li>
							<li>
								회사는 회원에게 유용한 서비스 기능을 제공할 목적으로 회원의
								콘텐츠를 분석합니다. 이러한 분석은 회원의 콘텐츠 전송, 수신,
								저장의 경우에 한하여 수행됩니다.
							</li>
							<li>
								회사가 회원의 콘텐츠를 사용 및 저장하는 방법에 대해 좀 더 자세한
								정보가 필요한 경우, 옴니톡 서비스 개인정보처리방침 또는 특정
								서비스에 대한 추가약관을 참조하시기 바랍니다. 회원이 서비스에
								대한 의견이나 제안을 제출하는 경우 회사는 회원에 대한 어떤
								추가적인 의무를 부담하지 않고 회원의 의견이나 제안을 사용할 수
								있습니다.
							</li>
							<li>
								옴니톡 서비스에 대한 저작권 등 지식재산권은 회사에 귀속됩니다.
								회원이 옴니톡 서비스를 이용한다고 해서 옴니톡 서비스 또는
								액세스하는 콘텐츠의 지식재산권을 소유하게 되는 것은 아닙니다.
								옴니톡 서비스에 표시된 제3자 소유의 콘텐츠의 경우 콘텐츠
								소유자로부터 허가를 받거나 달리 법률에 따라 허용되는 경우를
								제외하고, 회원이 이를 사용할 수 없습니다. 본 약관은 회원에게
								옴니톡 서비스에 사용된 브랜드나 로고를 사용할 권리를 부여하지
								않습니다. 옴니톡 서비스 내에 또는 옴니톡 서비스와 함께 게재된
								어떠한 법적 고지도 삭제하거나 가리거나 변경하지 마십시오.
							</li>
						</ol>

						<h5>제 10 조 (회원의 의무)</h5>
						<ol>
							<li>
								회원이 옴니톡 서비스를 이용할 때 아래 각 호의 행위는 하여서는 안
								됩니다.
								<ol>
									<li>
										이용 신청 또는 변경 시 허위 사실을 기재하거나, 다른 회원의
										계정 등 및 비밀번호를 도용, 부정하게 사용하거나, 다른 사람의
										명의를 사용하거나 명의자의 허락 없이 문자메시지(SMS) 인증
										등을 수행하는 행위
									</li>
									<li>
										타인으로 가장하는 행위 및 타인과의 관계를 허위로 명시하는
										행위
									</li>
									<li>
										회사 또는 타 회원의 직원이나 관계자로 가장하여 글을
										게시하거나 이메일, 메시지 등을 발송하는 행위
									</li>
									<li>회사가 게시한 정보를 변경하는 행위</li>
									<li>
										자기 또는 타인에게 재산상의 이익을 주거나 타인에게 손해를
										가할 목적으로 허위의 정보를 유통시키는 행위
									</li>
									<li>
										외설, 또는 폭력적인 메시지, 화상, 음성, 기타 공서양속에
										반하는 정보를 옴니톡 서비스를 이용하여 복제, 전송, 방송,
										공유하는 행위
									</li>
									<li>
										관련 법령에 의하여 그 전송 또는 게시가 금지되는 정보(컴퓨터
										프로그램 포함)의 복제, 전송 또는 게시 행위
									</li>
									<li>회사 또는 제3자의 저작권 등 기타 권리를 침해하는 행위</li>
									<li>
										회사 또는 제3자의 명예를 손상시키거나 업무를 방해하는 행위
									</li>
									<li>
										옴니톡 서비스의 운영을 고의로 방해하거나 안정적 운영을
										방해할 수 있는 정보 및 수신자의 명시적인 수신거부의사에
										반하여 광고성 정보 또는 스팸을 전송하는 행위
									</li>
									<li>
										회사의 동의 없이 서비스 또는 이에 포함된 소프트웨어의 일부를
										복사, 수정, 배포, 판매, 양도, 대여, 담보제공하거나 타인에게
										그 이용을 허락하는 행위와 소프트웨어를 역설계하거나 소스
										코드의 추출을 시도하는 등 서비스를 복제, 분해 또는
										모방하거나 기타 변형하는 행위
									</li>
									<li>
										컴퓨터 소프트웨어, 하드웨어, 전기통신 장비의 정상적인 가동을
										방해, 파괴할 목적으로 고안된 소프트웨어 바이러스, 기타 다른
										컴퓨터 코드, 파일, 프로그램을 포함하고 있는 자료를
										게시하거나 이메일, 메시지 등으로 발송하는 행위
									</li>
									<li>
										회사의 동의 없이 영리 목적으로 옴니톡 서비스를 사용하는 행위
									</li>
									<li>기타 불법적이거나 부당한 행위</li>
								</ol>
							</li>
							<li>
								회원은 관련 법령, 별도의 계약, 본 약관, 세부지침 및 옴니톡
								서비스와 관련하여 회사가 알린 공지사항 및 통지사항 등을
								준수하여야 하며, 기타 회사의 업무를 방해하는 행위를 하여서는
								안됩니다.
							</li>
							<li>
								회원은 멤버가 본 조 제 1 항 내지 제 2 항의 의무를 준수하도록
								관리·감독할 책임을 부담하며, 관리자가 멤버의 위반 행위를 알게 된
								경우 본 약관·세부지침 또는 관련 법령에서 정한 조치를 취할 의무를
								부담합니다.
							</li>
							<li>
								미성년자를 관리자 등 회원을 대표·대리하는 자로 지정할 수
								없습니다. 이를 위반하여 미성년자를 지정함으로 인해 발생한 분쟁,
								손해 등에 대하여 회사는 어떠한 책임도 부담하지 않습니다.
							</li>
							<li>
								본 조 제 3 항의 경우 회사는 위반행위를 한 멤버에 대해 이용제한을
								하는 등 관리자의 권한 일부를 직접 행사할 수 있으며, 멤버의
								위반행위는 회원의 위반행위로 봅니다.
							</li>
							<li>
								회원은 멤버의 개인정보처리자로서 개인정보보호법 등 관련 법령이
								정한 바에 따라 멤버의 개인정보를 보호하여야 합니다.
							</li>
							<li>
								회원은 유료서비스를 신청하기 전에 반드시 회사가 제공하는 상품의
								이용조건과 거래조건을 확인한 후 신청하여야 합니다. 이를 확인하지
								않고 유료서비스를 신청하여 발생한 모든 손실, 손해에 대한 책임은
								회사의 귀책사유가 인정되지 않는 한 회원이 부담합니다.
							</li>
							<li>
								유료서비스를 이용하는 회원은 납부기한 내에 이용요금을 납부해야
								합니다.
							</li>
						</ol>

						<h5>제 11 조 (회사의 의무)</h5>
						<ol>
							<li>
								회사는 관련 법령과 본 약관이 금지하거나 미풍양속에 반하는 행위를
								하지 않으며, 계속적이고 안정적으로 옴니톡 서비스를 제공하기
								위하여 최선을 다하여 노력합니다.
							</li>
							<li>
								회사는 회원이 안전하게 옴니톡 서비스를 이용할 수 있도록
								개인정보보호법 및 관련 법령이 정하는 바에 따라 보안 시스템 등을
								갖추고 회원의 개인정보를 보호하기 위해 노력합니다.
							</li>
							<li>
								개인정보의 보호 및 이용에 대해서는 관련 법령 및 회사의
								개인정보처리방침이 적용됩니다. 다만, 회사의 공식 웹사이트 이외의
								링크사이트에 대해서는 회사의 개인정보처리방침이 적용되지
								않습니다.
							</li>
							<li>
								회사는 옴니톡 서비스 이용과 관련하여 발생하는 회원의 불만 또는
								피해구제요청을 적절하게 처리할 수 있도록 필요한 인력 및 시스템을
								구비합니다.
							</li>
							<li>
								회사는 옴니톡 서비스 이용과 관련하여 회원으로부터 제기된
								의견이나 불만이 정당하다고 인정할 경우에는 이를 처리하여야
								합니다. 회원이 제기한 의견이나 불만사항에 대해서는 게시판을
								활용하거나 이메일 등을 통하여 회원에게 처리과정 및 결과를 전달할
								수 있습니다.
							</li>
						</ol>

						<h5>제 12 조 (서비스의 이용 및 중지)</h5>
						<ol>
							<li>
								회원은 옴니톡 내에서 적용되는 모든 정책을 준수해야 하며,
								옴니톡의 오용을 삼가시기 바랍니다. 예를 들어 옴니톡을 방해하거나
								옴니톡이 안내하는 API 및 가이드 외에 다른 방법을 사용하여
								액세스를 시도하지 않아야 합니다. 회원은 오직 법률상 허용되는
								범위에서만 옴니톡을 이용할 수 있습니다. 회원이 본 약관이나
								정책을 준수하지 않거나 회사가 회원와 관련된 부정행위를 조사하고
								있는 경우, 옴니톡 제공이 일시 중지 또는 중단될 수 있습니다.
							</li>
							<li>
								옴니톡은 회사가 소유하지 않은 일부 콘텐츠를 표시할 수 있습니다.
								그러한 콘텐츠에 대해서는 제공한 주체가 단독으로 책임지게 됩니다.
								회사는 콘텐츠의 위법성 여부 또는 본 서비스 정책 위반 여부를
								판단하기 위해 콘텐츠를 검토할 수 있으며, 콘텐츠가 옴니톡 정책
								또는 법률에 위반된다고 합리적으로 판단하는 경우 이를 삭제하거나
								게시를 거부할 수 있습니다. 이는 회사가 반드시 콘텐츠를
								검토한다는 의미가 아닙니다.
							</li>
							<li>
								옴니톡의 일부 서비스는 회원이 제3자들간에 콘텐츠 연결(다수의
								제3자들간의 연결 포함)을 수행하도록 할 수 있습니다. 이러한
								연결에서 회사는 연결 내용이나 콘텐츠를 살펴보지 않습니다. 이러한
								연결에서 회사는 연결 방식이나 연결 품질등 기능 확인을 위한
								내용을 수집할 수 있습니다. 이러한 연결에서 사용 또는 발생되는
								콘텐츠의 저작권과 사용은 회원와 제3자간 약정, 계약에 의거하며
								회사와는 어떠한 법률적 관련이 없음을 고지합니다. 단, 본 약관에
								의거하여 본 약관을 벗어나는 본 서비스 이용시 이용중지, 해약,
								손해배상등의 조치가 취해질 수 있습니다.
							</li>
							<li>
								옴니톡 이용과 관련하여 회사는 회원에게 본 서비스 고지, 관리
								메시지 및 기타 정보를 발송할 수 있습니다. 회원은 메시지 수신을
								거부할 수 있습니다.
							</li>
						</ol>

						<h5>제 13 조 (서비스의 변경 및 내용수정)</h5>
						<ol>
							<li>
								회사는 옴니톡 서비스를 365 일, 24 시간 쉬지 않고 제공하기 위하여
								최선의 노력을 다합니다. 다만, 아래 각 호의 경우 옴니톡 서비스의
								전부 또는 일부를 제한/중단할 수 있습니다.
								<ol>
									<li>
										옴니톡 서비스용 설비의 유지·보수, 업그레이드, 서비스 진단
										등을 위한 정기/임시 점검 또는 사전에 공지/통지한 후 옴니톡
										서비스를 중단한 경우
									</li>
									<li>
										정전, 제반 설비의 장애 또는 이용량의 폭주 등으로 정상적인
										옴니톡 서비스 이용에 지장이 있는 경우
									</li>
									<li>
										회원의 시스템에서 발생한 사고의 확산을 방지하기 위해 옴니톡
										서비스를 중단하는 경우
									</li>
									<li>
										관계사와의 계약 종료, 정부의명령/규제 등 회사의 제반
										사정으로 옴니톡 서비스를 유지할 수 없는 경우
									</li>
									<li>
										회사의 분할, 합병, 영업양도, 영업의 폐지, 수익 악화 등
										회사의 경영상 중대한 필요에 의한 경우
									</li>
									<li>
										기타 천재지변, 국가비상사태 등 불가항력적 사유가 있는 경우
									</li>
								</ol>
							</li>
							<li>
								회사는 옴니톡 서비스를 지속적으로 개선하고자 노력하고 있습니다.
								회사는 운영상, 경영상, 기술상의 필요에 따라 서비스 전부 또는
								일부 내용을 변경하거나 중지하거나 완전히 종료할 수 있습니다.
							</li>
							<li>
								옴니톡 서비스의 내용, 이용방법 등에 변경이 있는 경우에는 변경
								7일 전에 서비스 웹사이트에 해당 사실을 공지하고, 서비스를
								종료하는 경우에는 종료 30일 전에 해당 사실을 회원에게
								통지합니다. 다만, 사전에 공지할 수 없는 부득이한 사정이 있는
								경우에는 사후에 지체 없이 이를 공지할 수 있습니다.
							</li>
							<li>
								회사는 고의, 중과실이 없는 한 서비스의 변경, 내용수정으로 인하여
								회원이 입은 손해에 대해 어떠한 책임도 지지 않습니다.
							</li>
						</ol>

						<h5>제 14 조 (이용제한)</h5>
						<ol>
							<li>
								회사는 다음 각호의 경우 사전통보 없이 회원에 대하여 즉각적인
								서비스 이용해지나 서비스의 전부, 일부를 제한할 수 있습니다.
								<ol>
									<li>
										본 약관 제5조 제4항 각호 중 어느 하나의 사유가 발생한 경우
										또는 회원의 옴니톡 계정 생성 시 본 약관 제5조 제4항 각호 중
										어느 하나의 사유가 있었던 것으로 판명된 경우
									</li>
									<li>
										회원이 정당한 이유 없이 옴니톡 계정, 서비스 아이디 또는
										서비스 시크릿을 제3자와 공유한 경우
									</li>
									<li>
										회원이 회사가 게시한 정보를 무단으로 변경, 복제, 유통,
										조작하거나 상업적으로 이용한 경우
									</li>
									<li>
										회원이 회사와 기타 제3자의 지식재산권, 초상권 등 기타 권리를
										침해한 경우
									</li>
									<li>
										회원이 회사의 동의한 범위를 초과하여 영리를 목적으로
										서비스를 사용하거나 서비스 또는 회사의 브랜드를 모방, 도용한
										경우
									</li>
									<li>
										회원이 회사 및 기타 제3자의 명예를 손상하거나 업무를 방해한
										경우
									</li>
									<li>
										회원이 회사의 승인을 받지 않고 다른 이용자의 개인 정보를
										수집, 저장, 유포, 게시한 경우
									</li>
									<li>
										회원이 관련 법령에 의하여 그 전송 또는 게시가 금지되는
										정보(컴퓨터프로그램 포함)를 복제, 전송 또는 게시한 경우
									</li>
									<li>
										회원이 회사의 서비스 운영을 고의로 방해하거나 서비스의
										안정적 운영을 방해할 수 있는 행위를 한 경우
									</li>
									<li>
										회원이 회사의 직원이나 운영자를 가장하거나 사칭 또는 타인의
										명의를 도용하여 글을 게시하거나 E-mail을 발송한 경우
									</li>
									<li>회원이 프로그램의 버그를 악용한 경우</li>
									<li>
										회원이 외설 또는 폭력적인 메시지, 화상, 음성, 기타
										미풍양속에 반하는 정보를 서비스에 공개 또는 게시한 경우
									</li>
									<li>유료서비스 이용 회원이 이용요금을 납부하지 않는 경우</li>
									<li>
										회원이 기타 사회의 안녕질서, 공서양속을 저해하거나, 저해할
										목적으로 서비스를 이용하거나, 불법적, 부당한 행위 및 관계
										법령에 어긋나는 행위를 한 경우
									</li>
								</ol>
							</li>
							<li>
								회사가 본 조 제1항에 따라 회원의 서비스 이용을 제한하는 경우,
								회원은 이로 인한 손해배상을 회사에 청구할 수 없습니다.
							</li>
							<li>
								회사가 본 조 제1항에 따라 회원의 서비스 이용을 제한하는 경우, 그
								사유와 이용정지 기간, 이의신청 방법을 서면, 전자우편 또는 이에
								준하는 방법으로 해당 회원에게 통지해야 합니다.
							</li>
							<li>
								회사는 본 조 제1항과 관련된 사유가 해소되었다고 판단될 때에는
								지체없이 이용 제한을 해제합니다.
							</li>
							<li>
								회사는 본 조에 따른 서비스 이용 제한 기간 중 발생한 이용요금을
								회원에게 청구할 수 있습니다.
							</li>
							<li>
								회사는 본 조 제1항과 관련하여 이용제한 이후 제한 사유가 계속해서
								존재하는 경우, 회사는 서비스 계약을 해지할 수 있습니다. 이때
								회사는 본 약관에 명시된 방법에 따라 회원에게 통지합니다.
							</li>
						</ol>

						<h4>제4장 이용자 정보의 보호</h4>

						<h5>제15조 (이용자 정보의 보호와 관리)</h5>

						<p>
							회사는 관련 법령이 정하는 바에 따라 이용자 정보를 보호합니다.
							이용자 정보의 보호 및 이용에 대해서는 관련 법령 및 별도로 고지하는
							개인정보 처리방침이 적용됩니다.
						</p>

						<h4>제5장 이용계약의 해지 등</h4>

						<h5>제 16 조 (회원에 의한 이용계약의 해지)</h5>
						<ol>
							<li>
								회원이 옴니톡 서비스 이용을 더 이상 원치 않는 때에는 언제든지
								서비스 내 제공되는 메뉴 등을 이용하여 이용계약의 해지 신청을 할
								수 있으며, 회사는 지체 없이 처리하겠습니다.
							</li>
							<li>
								회원은 옴니톡 서비스 중에서 일부 부가서비스만을 선택적으로
								해지하실 수 있으며, 이 경우에는 옴니톡 계정은 해지되지 않고
								남아있게 됩니다.
							</li>
							<li>
								옴니톡 서비스 이용 계약 해지 시 회원의 개인정보 및 데이터 삭제에
								관해서는 개인정보처리방침 및 세부지침을 따릅니다.
							</li>
							<li>
								부가서비스 이용 종료 시점에 향후 일정기간 부가서비스의 재이용에
								제한이 있을 수 있다는 별도 안내가 있는 경우 해당 안내에 따라
								부가서비스의 재이용에 일정한 시간적 제한이 있을 수 있는 점
								유의하여 주시기 바랍니다.
							</li>
						</ol>

						<h5>제 17 조 (회원의 손해배상)</h5>
						<ol>
							<li>
								회원은 다음과 같은 사유로 분쟁이 발생하는 경우 회사 및 회사의
								임직원, 대리인, 파트너 등을 면책시키고 방어하며, 이들이 피해를
								입지 않도록 하는데 동의합니다.
								<ol>
									<li>회원이 본 약관, 세부지침, 관련 법령을 위반하는 경우</li>
									<li>
										회사가 본 약관, 세부지침, 관련 법령의 위반으로 의심되는
										사항을 조사하거나 위반이 발생한 것으로 판단하여 취하는 조치
									</li>
								</ol>
							</li>
							<li>
								회원이 회사에 대하여 손해를 배상하게 되는 경우 손해배상의
								범위에는 회사의 법률 비용, 경비 및 기타 손해를 포함하되 이에
								한정되지 않습니다.
							</li>
						</ol>

						<h5>제 18 조 (회사의 손해배상 및 책임 제한)</h5>
						<ol>
							<li>
								회사는 회사의 과실로 인하여 회원이 손해를 입게 될 경우 본 약관
								및 관련 법령에 따라 회원의 손해를 배상하겠습니다. 다만 아래 각
								호의 사유에 의한 손해에 대해서는 책임을 부담하지 않습니다. 또한
								법률상 허용되는 경우, 회사 및 판매자는 일실이익, 일실수입,
								망실자료, 재무적 손실, 간접 손해, 특별 손해, 결과적 손해, 징계적
								손해, 또는 징벌적 손해에 대한 책임을 부담하지 않습니다. 법률상
								허용되는 한도 내에서, 묵시적 보증을 포함하여 본 약관에 따른
								청구와 관련하여 회사 및 판매자의 총 책임은 회원이 옴니톡 서비스
								사용을 위해 회사에 지급한 금원(또는 회사의 선택에 따라 회원에게
								본 서비스를 다시 제공하는 것)으로 제한됩니다.
								<ol>
									<li>무료서비스의 이용으로 인한 손해</li>
									<li>
										회원의 시스템 보안 관리 소홀 등 회원의 귀책사유로 인해 본
										서비스 이용의 장애로 인한 손해
									</li>
									<li>
										회원와 회원의 애플리케이션 사용자의 컴퓨터 환경으로 인하여
										발생하는 문제로 인한 손해 또는 회원의 애플리케이션의 문제로
										인하여 개인정보가 유출되어 발생한 손해 등 회원의
										관리영역에서 발생한 사정으로 인한 손해
									</li>
									<li>
										기간통신 사업자가 전기통신서비스를 중지하거나 정상적으로
										제공하지 아니하여 발생하는 손해 및 기타 회사의 귀책사유가
										없는 네트워크 환경으로 인하여 발생하는 문제
									</li>
									<li>
										제3자가 불법적으로 회사의 서버에 접속·이용하거나, 회사
										서버에 대한 전송 또는 회사 서버로부터의 전송을 방해하거나,
										악성 프로그램을 전송 또는 유포함으로써 발생하는 손해
									</li>
									<li>
										천재지변 또는 이에 준하는 불가항력(정전, 제반 설비의 장애
										혹은 이용량 폭주로 인해 정상적인 서비스 이용에 지장을 주는
										상황 등)으로 인해 본 서비스를 제공할 수 없게 됨으로 인한
										손해
									</li>
									<li>
										기타 회사의 고의 또는 과실이 없는 사유로 인해 발생한 손해
									</li>
								</ol>
							</li>
							<li>
								회사는 회원와 제3자 상호 간 서비스를 매개로 발생한 분쟁에 대해
								개입할 의무가 없으며, 이에 따른 손해에 대해서도 배상할 책임이
								없습니다. 만일 회원과 분쟁 중인 제3자가 회사를 상대로 이의를
								제기할 경우, 회원은 자신의 비용과 책임으로 회사를 면책시켜야
								합니다.
							</li>
							<li>
								회사는 옴니톡을 매개로 회원이 제작한 애플리케이션의 이용 혹은
								그러한 애플리케이션과 연계된 서비스와 관련하여 게재한 정보,
								자료, 사실의 신뢰도, 정확성 등의 내용에 관하여는 책임을 지지
								않습니다. 또한 회원의 애플리케이션을 통해 획득한 자료로 인한
								제3자의 손해 등에 대해서도 책임을 지지 않습니다.
							</li>
							<li>
								회사는 옴니톡 화면에서 링크, 배너 등을 통하여 연결된 제3자와
								회원 간에 이루어진 거래에 개입하지 않으며, 해당 거래에 대하여
								책임을 지지 않습니다.
							</li>
							<li>
								회원은 이용신청 시 기재한 사항이 변경되었을 경우에는 즉시
								변경사항을 최신의 정보로 수정하여야 합니다. 수정하지 않은 정보로
								인하여 발생하는 손해는 회원이 부담하며, 회사는 이에 대하여
								책임을 지지 않습니다.
							</li>
							<li>
								옴니톡은 회원의 애플리케이션 사용 환경 등에 따라 정상적으로
								동작하지 않거나 회사가 예견하지 못한 오류가 존재할 수 있으며,
								회원의 애플리케이션 사용 환경 등에 따른 옴니톡의 사용 불능으로
								인하여 회원에게 발생한 손해에 대하여 책임을 지지 않습니다.
							</li>
						</ol>

						<h5>제 19 조 (멤버의 동의 등)</h5>
						<ol>
							<li>
								회원은 본 약관, 개인정보처리방침 적용에 대해 멤버들의 동의를
								얻고 있다는 것을 보장합니다.
							</li>
							<li>
								회원은 멤버의 초대 여부 및 멤버의 이용권한의 범위를 자유롭게
								결정할 수 있으며, 이로 인한 회원과 멤버 간 또는 회원과 제3자 간
								분쟁 발생 시 회사는 책임을 지지 않습니다.
							</li>
							<li>
								관리자는 허가된 권한의 범위 안에서 멤버의 데이터에 접근할 수
								있습니다. 단, 회원은 멤버가 옴니톡 계정 이용을 시작하기 전에
								멤버로부터 관리자가 위의 멤버의 데이터에 접근할 수 있고 데이터를
								모니터링, 이용 또는 공개할 수 있다는 점에 대하여 동의를 받아야
								합니다.
							</li>
							<li>
								본 조 제 3 항에 반하여 멤버의 일부 또는 전부가 동의를 하지 않는
								경우에도 이로 인한 회원과 멤버 간 분쟁 발생 시 회사는 책임을
								지지 않습니다.
							</li>
						</ol>

						<h5>제 20 조 (권리 불포기 조항)</h5>
						<p>
							회원의 본 약관 또는 세부지침의 위반과 관련하여 회사가 권리
							실행이나 조치를 취하지 않았다고 하더라도, 이후 결과적으로 또는
							유사하게 발생하는 행위에 대해 회사가 본 약관 및 세부지침의 권리 및
							해당 권리 실행을 포기하는 것으로 간주되지 않습니다.
						</p>

						<h5>제 21 조 (준거법 및 분쟁의 해결)</h5>
						<p>
							본 약관 또는 서비스는 대한민국법령에 의하여 규정되고 이행됩니다.
							서비스 이용과 관련하여 회사와 회원 간에 분쟁이 발생하면 이의
							해결을 위해 성실히 협의할 것입니다. 그럼에도 불구하고 해결되지
							않으면 민사소송법상의 관할법원에 소를 제기할 수 있습니다.
						</p>
					</div>
				</article>
			</section>
		</StyledTerms>
	);
}

export default Terms;

const StyledTerms = styled.div`
padding-top: 120px;
/* title */
section{
    padding: 0 5%;
    margin-bottom: 100px;
    h1{
      padding-bottom: 30px;
      margin-bottom: 80px;
      font-size: 2rem;
      font-weight: ${fontWeight.medium};
      border-bottom: 1px solid ${palette.gray.middle};
        span{
          color: ${palette.main.default};
        }
    }
    .terms_tab{
        width: 300px;
        margin-left: 5%;
        border-radius: 100px;
        border: 1px solid ${palette.gray.bright};
        background-color: ${palette.gray.bright};
        button{
            width: 50%;
            height: 42px;
            font-size: 1rem;
            color: ${palette.text.light};
            border: 0;
            border-radius: 100px;
            background-color:${palette.gray.bright};
        }
        .active{
            width: 50%;
            height: 42px;
            font-size: 1rem;
            color: ${palette.white};
            border: 0;
            border-radius: 100px;
            background-color:${palette.main.default};
        }
    }
}
/* section-contents */
.contents_container{
  margin-bottom: 0;
  article{
    padding: 0 5%;
    margin-bottom: 50px;
    .container{
      width: 100%;
      height: 400px;
      padding: 10px 5%;
      overflow: auto;
      border-radius: 10px;
      border: 1px solid ${palette.gray.middle};
      ::-webkit-scrollbar{
        display: none;
      }
      h4,
      h5{
        font-size: 16px;
        font-weight: ${fontWeight.semiBold};
        line-height: 24px;
      }
      /*  */
      p{
        margin: 0;
        font-size: 15px;
        line-height: 20px;
        color: ${palette.text.default};
        strong{
          font-size: 15px;
          font-weight: ${fontWeight.medium};
          color: ${palette.main.default};
        }
        a{
          padding: 0 8px;
          font-size: 15px;
          font-weight: ${fontWeight.medium};
          color: ${palette.main.default};
        }
      }
      ol{
        li{
        font-size: 15px;
        line-height: 20px;
        color: ${palette.text.default};
        strong,
        a{
          font-size: 15px;
          font-weight: ${fontWeight.medium};
          color: ${palette.main.default};
        }
        }
      }
    }
  }
}

@media screen and (max-width: 579px) and (min-width: 230px){
  padding-top: 100px;
  section{
    padding: 0 5%;
    margin-bottom: 50px;
    h1{
      padding-bottom: 30px;
      margin-bottom: 50px;
      font-size: 1.5rem;
      font-weight: ${fontWeight.medium};
      border-bottom: 1px solid ${palette.gray.middle};
        span{
          color: ${palette.main.default};
        }
    }
    .terms_tab{
        width: 100%;
        margin-left: 0;
        button{
            width: 50%;
            height: 42px;
            font-size: 0.95rem;
        }
        .active{
            width: 50%;
            height: 42px;
            font-size: 0.95rem;
            color: ${palette.white};
            border: 0;
            border-radius: 100px;
            background-color:${palette.main.default};
        }
    }
}
    .contents_container{
  margin-bottom: 0;
  article{
    padding: 0;
    margin-bottom: 50px;
}
    }
}
`;
