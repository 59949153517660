import React, { useState } from 'react';
import { GrClose } from 'react-icons/gr';
import styled from 'styled-components';
import API from '../../modules/api/api';
import { defaultFlexCenter, defaultInput, fontSize, palette } from '../../modules/defines/style';
import PopupLoading from '../PopupLoading';

function AddVoucher({ setAdd, handleRefresh, agencyName, t }) {

  const [formData, setFormData] = useState({
    agency_name: agencyName,
    bank_name: '',
    depositor: '',
    credit : 0,
    memo: ''      
  });
  const [error, setError] = useState('');
  const handleFormChange = (e) => {
    const value = e.target.name === 'credit' ? parseInt(e.target.value, 10) : e.target.value;
    setFormData(prev => ({ ...prev, [e.target.name]: value }));
  };
    
  const [isLoading, setIsLoading] = useState(false);
  // add
  const handleRequestAdd = () => {
    setIsLoading(true);
    API.addVoucher(formData).then(response => {
      if (response.data.result === 'success')
        setAdd(false);
      setIsLoading(false);
      handleRefresh();
      
    }).catch(error => {
      setIsLoading(false);
      console.log(error);
      setError(t('error.voucher'));
      handleRefresh();
    });
   
    // delay of 2 seconds
  
  };
  return (
    
    <StyledModal>
      {isLoading && <PopupLoading /> }
      <>
        <div className="title">
          <h1>Voucher</h1>
          <button
            type="button" onClick={() => {
              setAdd(false);
            }}><GrClose style={{ fontSize:'20px' }} />
          </button>
        </div>
      
        <div className="input_box">
          <label htmlFor="bank_name">{t('console.voucher.bank')}</label>
          <input
            type="text" id="bank_name" name="bank_name" maxLength="30"
            value={formData.bank_name || ''} onChange={handleFormChange} />
        </div>
        <div className="input_box">
          <label htmlFor="depositor">{t('console.voucher.holder')}</label>
          <input
            type="text" id="depositor" name="depositor" maxLength="30"
            value={formData.depositor || ''} onChange={handleFormChange} />
        </div>
        <div className="input_box">
          <label htmlFor="credit">{t('console.voucher.vat')}</label>
          <input
            type="text" id="credit" name="credit" maxLength="30"
            placeholder={t('console.modal.add.voucherPlaceholder')}
            value={formData.credit || ''} onChange={handleFormChange} />
        </div>
        <div className="input_box">
          <label htmlFor="memo">{t('console.voucher.memo')}</label>
          <input
            type="text" id="memo" name="memo" maxLength="100"
            value={formData.memo || ''} onChange={handleFormChange}
          />
        </div>
        <div className="form_guide">
          * {t('console.voucher.desc')}<br />
          <span>우리은행 1005-604-379303 (주)옴니스토리</span>
        </div>
        <span className="error">{error}</span>
        <div className="button">
          { formData.bank_name === '' || formData.depositor === '' || formData.credit === 0
            ? (
              <button
                type="button" onClick={handleRequestAdd}
                disabled
                className="disabled"
              >{t('console.voucher.button')}
              </button>
            ) : (
              <button
                type="button" onClick={handleRequestAdd}
              >{t('console.voucher.button')}
              </button>
            )}
        </div>
      </>
    </StyledModal>
  );
}

export default AddVoucher;

const StyledModal = styled.div`
width: 465px;
height: 630px;
padding: 30px;
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%,-50%);
border-radius:10px;
background-color: ${palette.gray.light};
.title{
    ${defaultFlexCenter}
    justify-content: space-between;
    margin-bottom: 20px;
    border-bottom: 1px solid ${palette.gray.middle};
    h1{
    font-size: ${fontSize.medium};
    }
    button{
        border: 0;
        background-color: ${palette.opacity};
    }
}
.input_box{
    width: 100%;
    label{
        display: inline-block;
        width: 100%;
        line-height: 40px;
    }
    input{
        ${defaultInput}
        width: 100%;
    }
}
.error{
  color: red;
  display: flex;
  justify-content: center;
  font-weight: 500;
  padding-bottom: 5px;
  font-size: 14px;
}
.form_guide{
    width: 380px;
    margin: 0 auto;
    padding-top: 20px;
    margin-bottom: 20px;
    font-size: ${fontSize.micro};
    color: ${palette.text.light};
}
.button{
    ${defaultFlexCenter}
    button{
        width: 164px;
        height: 42px;
        background-color: ${palette.main.default};
        border: 0;
        border-radius: 5px;
        color: ${palette.white};
        :hover{
          cursor: pointer;
        }
    }
        .disabled{
        width: 164px;
        height: 42px;
        border-radius: 5px;
        background-color: rgba(255,83,29,0.5);
        font-size: 1rem;
        color: white;
        }
}

@media screen and (max-width:579px) and (min-width:230px) {
width: 90%;
.form_guide{
    width: 90%;
    margin: 0 auto;
    padding-top: 20px;
    margin-bottom: 20px;
    font-size: ${fontSize.micro};
    color: ${palette.text.light};
}
}
`;
