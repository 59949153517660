import React from 'react';
import ChangeLogTable from './ChangeLogTable';
import Dropdown from '../DropDown';

export default function ChangeLogDetail({
	data,
	handleClickRow,
	handleLanguage,
	selectedLanguage,
}) {
	return (
		<>
			<div className=" ">
				<div className="max-w-3xl mx-auto flex items-center mb-5">
					<h2 className=" text-left mt-0 text-xl font-[600]  mb-0 text-gray-900   ">
						{selectedLanguage}
						{/* {language === undefined ? (<>All</>) : language} */}
					</h2>
					<Dropdown handleLanguage={handleLanguage}></Dropdown>
				</div>
				<ChangeLogTable
					selectedLanguage={selectedLanguage}
					data={data}
					handleClickRow={handleClickRow}
				/>
			</div>
		</>
	);
}
