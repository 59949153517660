import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { MdCheckBoxOutlineBlank as IconCheck, MdCheckBox as IconChecked, MdOutlineRadioButtonUnchecked as IconRadio, MdOutlineRadioButtonChecked as IconRadioSelected } from 'react-icons/md';
import { palette } from '../../modules/defines/style';

const FormWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  & + & {
    margin-left: 10px;
  }
`;
const FormBox = styled.div`
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  svg {
    width: 100%;
    height: 100%;
  }
`;
const Text = styled.div`
  padding-left: 5px;
  color: ${palette.text};
  font-size: 14px;
  white-space: nowrap;
`;

const FormCheck = ({ theme = 'check', // theme = 'check' | 'radio'
  name, value, children, checked, onChange }) => {
  const [state, setState] = useState(checked);
  const handleClick = () => {
    if (theme === 'radio' && state) return;
    setState(prev => !prev);
    onChange(name, theme === 'check' ? !state : value);
  };

  useEffect(() => {
    setState(checked);
  }, [checked]);

  return (
    <FormWrapper onClick={handleClick}>
      <FormBox theme={theme}>
        {theme === 'radio' ? (state ? <IconRadioSelected /> : <IconRadio />) : (state ? <IconChecked /> : <IconCheck />)}
      </FormBox>
      {children && (
        <Text>{children}</Text>
      )}
    </FormWrapper>
  );
};

export default FormCheck;