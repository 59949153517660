import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MdOutlineKeyboardArrowDown } from 'react-icons/md';
import { RiGlobalLine } from 'react-icons/ri';
import { SiGithub, SiVelog } from 'react-icons/si';
import { CustomIcon } from '../assets/icons/Icons';
import logo from '../assets/omnitalk.svg';

function Footer() {
	const { i18n } = useTranslation();
	const { t } = useTranslation();
	const [lang, setLang] = useState(false);

	const handleLangTab = () => {
		setLang(prev => !prev);
	};
	const locales = {
		ko: { title: '한국어 ' },
		en: { title: 'Eng' },
		ja: { title: '日本語' },
	};
	// const browserLang = navigator.language.slice(0, 2);
	// const i18nLang = sessionStorage.getItem('lang');

	return (
		<>
			<footer className="px-5 divide-y bg-white  max-w-7xl mx-auto lg:px-8 dark:bg-black">
				<div className="container flex flex-col justify-between py-10 mx-auto space-y-8 lg:flex-row lg:space-y-0">
					<div className="lg:w-1/6">
						<a
							rel="noopener noreferrer"
							href="/"
							className="flex justify-center space-x-3 lg:justify-start "
						>
							<img
								className="self-center w-24 h-4 "
								src={logo}
								alt="omnitalk-logo"
							/>
						</a>
					</div>
					<div className="grid grid-cols-2 text-sm gap-x-3 gap-y-8 lg:w-5/6 sm:grid-cols-4 lg:grid-cols-5">
						<div className="space-y-3">
							<h3 className="tracking-wide uppercase text-gray-700 font-[600] lg:text-base xs:text-sm  dark:text-white">
								Developer
							</h3>
							<ul className="space-y-1">
								<li>
									<a
										rel="noopener noreferrer"
										className="text-gray-500 font-medium  lg:text-base xs:text-sm"
										href="/docs"
									>
										Docs
									</a>
								</li>
								<li>
									<a
										rel="noopener noreferrer"
										className="text-gray-500 font-medium  lg:text-base xs:text-sm"
										href="/history"
									>
										History
									</a>
								</li>
								<li>
									<a
										rel="noopener noreferrer"
										className="text-gray-500  font-medium  lg:text-base xs:text-sm"
										href="/issue"
									>
										Issue
									</a>
								</li>
							</ul>
							<h3 className="tracking-wide uppercase  text-gray-700 font-[600] lg:text-base xs:text-sm dark:text-white">
								Pricing
							</h3>
							<ul className="space-y-1">
								<li>
									<a
										rel="noopener noreferrer"
										className="text-gray-500 font-medium  lg:text-base xs:text-sm"
										href="/pricing"
									>
										Pricing
									</a>
								</li>
							</ul>
						</div>
						<div className="space-y-3">
							<h3 className="tracking-wide uppercase text-gray-700 font-[600] lg:text-base xs:text-sm  dark:text-white">
								Community
							</h3>
							<ul className="space-y-1">
								<li>
									<a
										rel="noopener noreferrer"
										className="text-gray-500 font-medium   lg:text-base xs:text-sm"
										href="/notice"
									>
										Notice
									</a>
								</li>
								<li>
									<a
										rel="noopener noreferrer"
										className="text-gray-500 font-medium   lg:text-base xs:text-sm"
										href="/faq"
									>
										FAQ
									</a>
								</li>
								<li>
									<a
										rel="noopener noreferrer"
										className="text-gray-500 font-medium   lg:text-base xs:text-sm"
										href="/contact"
									>
										Contact us
									</a>
								</li>
							</ul>
						</div>
						<div className="space-y-3">
							<h3 className="tracking-wide uppercase text-gray-700 lg:text-base xs:text-sm font-[600] dark:text-white">
								Company
							</h3>
							<ul className="space-y-1">
								<a
									rel="noopener noreferrer"
									className="text-gray-500 font-medium   lg:text-base xs:text-sm"
									href="/assets/omnistory_company_intro.pdf"
								>
									About Company
								</a>
								<li>
									<a
										rel="noopener noreferrer"
										className="text-gray-500 font-medium   lg:text-base xs:text-sm"
										href="/terms/privacy-policy"
									>
										Privacy Policy
									</a>
								</li>
								<li>
									<a
										rel="noopener noreferrer"
										className="text-gray-500 font-medium   lg:text-base xs:text-sm"
										href="/terms"
									>
										Terms of Use
									</a>
								</li>
								<li></li>
							</ul>
						</div>
						<div className="space-y-3">
							<h3 className="tracking-wide text-gray-700 lg:text-base xs:text-sm font-[600] dark:text-white">
								Mailring X
							</h3>
							<ul className="space-y-1">
								<a
									target="_blank"
									rel="noopener noreferrer"
									href="https://apps.apple.com/kr/app/xphone-just-email-to-call/id6477368155"
									className="rounded-md  p-3 h-7 flex items-center bg-black border-[1px] border-[#374151] justify-start hover:bg-black/80 cursor-pointer text-white font-medium   text-sm w-[115px]"
								>
									<CustomIcon.apple className="w-4 h-4 mr-2" />
									App Store
								</a>
								<li>
									<a
										target="_blank"
										rel="noopener noreferrer"
										href="https://play.google.com/store/apps/details?id=com.emailphone.android&pcampaignid=web_share"
										className="rounded-md  p-3 h-7 flex items-center bg-black border-[1px] border-[#374151] justify-start hover:bg-black/80 cursor-pointer text-white font-medium   text-sm w-[115px]"
									>
										<CustomIcon.googleColor className="w-3 h-3 mr-2" />
										Play Store
									</a>
								</li>
								<li>
									<a
										target="_blank"
										rel="noopener noreferrer"
										href="https://www.xphone.com"
										className="rounded-md  p-3 h-7 flex items-center bg-black border-[1px] border-[#374151] justify-start hover:bg-black/80 cursor-pointer text-white font-medium   text-sm w-[115px]"
									>
										<RiGlobalLine className="w-3 h-3 mr-2" />
										Website
									</a>
								</li>
								<li></li>
							</ul>
						</div>
						<div className="space-y-3">
							<h3 className="tracking-wide uppercase text-bl lg:text-base xs:text-sm font-[600] dark:text-white">
								Social
							</h3>
							<div className="flex justify-start space-x-3 ">
								<a
									target="_blank"
									rel="noopener noreferrer"
									href="https://velog.io/@omnitalk"
									title="Velog"
									className="flex items-center p-1 dark:text-white hover:text-main-50"
								>
									<SiVelog alt="velog-logo" />
								</a>
								<a
									target="_blank"
									rel="noopener noreferrer"
									href="https://github.com/omnistory-labs/omnitalk.sdk"
									title="Github"
									className="flex items-center p-1 dark:text-white hover:text-main-50"
								>
									<SiGithub alt="github-logo" />
								</a>
							</div>
							<h3 className="tracking-wide uppercase py-3 text-gray-700 lg:text-base xs:text-sm font-[600] dark:text-white">
								language
								<button
									className="px-1 align-middle"
									type="button"
									onClick={handleLangTab}
								>
									<MdOutlineKeyboardArrowDown alt="facebook-logo" />
								</button>
								{lang && (
									<div className="flex py-1">
										{Object.keys(locales).map(locale => (
											<button
												className="text-xs lg:text-sm mr-2 hover:text-main-50"
												type="submit"
												key={locale}
												onClick={() => {
													i18n.changeLanguage(locale);
													setLang(false);
												}}
											>
												{locales[locale].title}
											</button>
										))}
									</div>
								)}
							</h3>
						</div>
					</div>
				</div>
				<div className="py-6 text-sm  leading-5">
					<span className="text-gray-600 h-fit inline-block text-xs lg:text-base dark:text-white">
						{t('footer.company')} | {t('footer.companyName')}
					</span>
					<div className="flex justify-between  h-[18px]">
						<span className="text-gray-600  text-xs lg:text-base font-normal dark:text-white">
							{t('footer.bussinessNumber')} | 462-87-02610
						</span>
						<span className="text-gray-400 h-fit inline-block  text-xs lg:text-base">
							© 2022 Omni Story Co., Ltd. All rights reserved.
						</span>
					</div>
					<div className="text-left leading-5 mt-2">
						<span className="text-gray-600  text-xs lg:text-base dark:text-white">
							{t('footer.email')} | support@xphone.com
						</span>
						<br />
						<span className="text-gray-600  text-xs lg:text-base dark:text-white">
							{t('footer.ceo')} | {t('footer.ceoName')}
						</span>
						<br />
					</div>
				</div>
			</footer>
		</>
	);
}

export default Footer;

