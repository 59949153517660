import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { breakpoint } from '../../modules/defines/style';
import { FaPhone } from 'react-icons/fa';
import OmnitalkLogo from '../../assets/omnitalk.svg';
import Timer from '../hook/Timer';
import useInterval from '../hook/useInterval';
import PhoneAskMessage, { PhoneHeader } from './PhoneAskMessage';
import QuitAudioCallIcon from './QuitAudioCallIcon';
import { Omnitalk } from 'omnitalk-ts-sdk';

Omnitalk.sdkInit('E6D1-7F84-2EC1-11B4', 'nSaPdhffMqOsqXJs');
const omnitalk = Omnitalk.getInstance();

export default function CallPopup() {
	let phoneScreen;
	const [phoneState, setPhoneState] = useState({
		display: false,
		screentype: null, // askMessage = 연결 물어보는 화면, calling = 연결 중 화면, QuitCalling = 연결 종료 화면
	});
	const [isRunning, setIsRunning] = useState(false);
	const [isQuit, setIsQuit] = useState(false);
	const sessionId = useRef(null);
	const navigate = useNavigate();
	const handleMainIconPhone = () => {
		setPhoneState(() => ({ display: true, screentype: 'askMessage' }));
	};
	const handleClose = () => {
		setPhoneState(() => ({ display: false, screentype: null }));
	};
	useInterval(
		() => {
			setIsQuit(() => false);
			navigate(0);
		},
		isQuit ? 2000 : null,
	);

	const handleReqAudioCall = async () => {
		try {
			setPhoneState(prev => ({ ...prev, screentype: 'calling' }));
			setIsRunning(() => !isRunning);
			const sessionResult = await omnitalk.createSession();
			await omnitalk.offerCall('sipcall', String(5000), true);
			sessionId.current = sessionResult.session;
		} catch (error) {
			console.log(error);
			// setPhoneState(prev => ({ ...prev, screentype: 'QuitCalling' }));
		}
	};
	const handleQuitAudioCall = async ID => {
		try {
			setIsRunning(() => !isRunning);
			omnitalk.leave();
			setPhoneState(prev => ({ ...prev, screentype: 'QuitCalling' }));
		} catch (error) {
			console.log(error);
			setPhoneState(prev => ({ ...prev, screentype: 'QuitCalling' }));
		} finally {
			setIsQuit(() => true);
			sessionId.current = null;
		}
	};
	useEffect(() => {
		if (omnitalk) {
			omnitalk.on('event', async msg => {
				switch (msg.cmd) {
					case 'LEAVE_EVENT':
						setIsRunning(() => !isRunning);
						setPhoneState(prev => ({ ...prev, screentype: 'QuitCalling' }));
						setIsQuit(() => true);
						sessionId.current = null;
						break;
					default:
						break;
				}
			});
			omnitalk.on('close', () => {
				console.log('close Event');
			});
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (phoneState.screentype === 'askMessage') {
		phoneScreen = (
			<PhoneAskMessage
				handleClick={() => {
					handleReqAudioCall();
				}}
				handleClose={() => {
					handleClose();
				}}
			/>
		);
	} else if (phoneState.screentype === 'calling') {
		phoneScreen = (
			<CallingWrapper>
				{isRunning ? <Timer /> : <div>00:00:00</div>}
				<QuitAudioCallIcon
					handleClick={() => {
						handleQuitAudioCall(sessionId.current);
					}}
				/>
			</CallingWrapper>
		);
	} else {
		phoneScreen = (
			<QuitAudioCall>
				<PhoneHeader>
					<span className="text-white text-3xl font-bold">
						상담이 종료되었습니다.
					</span>
				</PhoneHeader>
			</QuitAudioCall>
		);
	}
	return (
		<>
			{!phoneState.display && (
				<CallIconWrapper onClick={handleMainIconPhone}>
					<FaPhone />
				</CallIconWrapper>
			)}
			{phoneState.display && (
				<IphoneFrame>
					<div className="iphone">
						<div className="iphone-top">
							<span className="camera"></span>
							<span className="sensor"></span>
							<span className="speaker"></span>
						</div>
						<div className="top-bar"></div>
						<div className="iphone-screen">{phoneScreen}</div>
						<div className="buttons">
							<span className="on-off"></span>
							<span className="sleep"></span>
							<span className="up"></span>
							<span className="down"></span>
						</div>
						<div className="bottom-bar"></div>
						<div className="iphone-bottom">
							<span></span>
						</div>
					</div>
				</IphoneFrame>
			)}
		</>
	);
}
const IphoneFrame = styled.div`
.iphone {
  box-shadow: inset 0 0 3px 0 rgba(0,0,0,0.2), 0 0 0 1px #999, 0 0 30px 0px rgba(0,0,0,0.7);
  border: 5px solid #d9dbdc;
  background: #f8f8f8;
  padding: 15px;
  border-radius: 50px;
  height: 480px;
  width:300px;
  position: relative;
     position:fixed;
     bottom: 0;
     right: 0;
     z-index: 50;

  margin: 30px auto;  
  // Scale so that the iPhone fits in view
  transform: scale(.8);
}

.iphone-top {
  padding: 5px 110px 30px;
  position: relative; 
  .speaker {
    display: block;
    width: 50px;
    height: 6px;
    margin: 0 auto;
    border-radius: 6px;
    background: #292728;
  }
  
  .camera {
    display: hidden;

    margin: 0 auto;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    margin-bottom: 13px;
    background: #333;
  }
  
  .sensor {
    display: block;
    position: absolute;
    left: 80px;
    top: 5px;
    width: 10px;
    height: 10px;
    float: left;
    background: #333;
    border-radius: 50%;
  }
}

.top-bar, .bottom-bar {
  display: block;
  width: 423px;
  height: 15px;
  border: {
    left: 5px solid #BBB;
    right: 5px solid #BBB;
  }
  position: absolute;
  left: -5px;
}
.top-bar {
  top: 65px;
}

.bottom-bar {
  bottom: 65px;
}

.iphone-screen {
  background: #eee;
  border: 1px solid #fff;
  height: 330px;
  width: 265px;
  
  margin: 0 auto;
  border: 1px solid rgba(0,0,0,0.9);
  border-radius: 3px;
  overflow: hidden;
/* background-image: url("https://assets.codepen.io/285131/paintbg.jpg"); */
/* background-image: url(${OmnitalkLogo}); */

  background-size:70%;
  background-size: 17rem 20%;
    background-position: 50% 10%;
  background-repeat: no-repeat;
  img {
    width: 100%;
  }
}

.buttons {
  .on-off, .up, .down, .sleep {
    display: block;
    background: #CCC;
    position: absolute;
    border-radius: 2px 0px 0px 2px;
  }
  .on-off {
    height: 40px;
    width: 3px;
    top: 100px;
    left: -8px;
  }
  
  .up, .down, .sleep {
    height: 60px;
    width: 5px;
    left: -10px;
  }
  
  .up {
    top: 170px;
  }
  
  .down {
    top: 250px;
  }
  
  .sleep {
    left: auto;
    right: -10px;
    top: 170px;    
    border-radius: 0px 2px 2px 0px;
  }
}

.iphone-bottom {
  padding: 15px 0 0;
  span {
    display: block;
    margin: 0 auto;
    width: 58px;
    height: 58px;
    background: #ccc;
    border-radius: 50%;
    background: linear-gradient(135deg, #303233 0%, #b5b7b9 50%, #f0f2f2 69%, #303233 100%);
    position: relative;
    &:after {
      content: ""; 
      display: block;
      width: 50px;
      height: 50px;
      background: #fff;
      border-radius: 50%;
      position: absolute;
      left: 4px;
      top: 4px;
    }
  }
}
    
`
const CallIconWrapper = styled.div`
  position: fixed;
  bottom: 50px;
  right: 50px;
  width: 80px;
  height: 80px;  
  border-radius: 50%;
  color: white;
  padding: 20px;
  cursor: pointer;
  box-shadow: 0px 3px 16px 0px rgba();
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 90px 2px;
  z-index: 50;
  /* animation: bounce 1.3s infinite linear; */
  background: linear-gradient(34deg, #ff7d1a 0%, #892dba 100%);
   background: linear-gradient(365deg, rgba(253, 148, 88, 0.9) 0%, #FA5734 100%);
  svg {
    width: 100%;
    height: 100%;
    fill: #fff;
  }
  @media only screen and (max-width: ${breakpoint.lg}) {
    width: 60px;
    height: 60px;
    svg {
      width: 20px;
      height: 20px;
    }  
  }
  @keyframes bounce {
    0% {
      bottom: 50px;
    }
    50% {
      bottom: 45px;
    }
    70% {
      bottom: 60px;
    }
    100% {
      bottom: 50px;
    }
  }
  animation: hithere 1.7s ease infinite;
@keyframes hithere {
  30% { transform: scale(1.2); }
  40%, 60% { transform: rotate(-20deg) scale(1.2); }
  50% { transform: rotate(20deg) scale(1.2); }
  70% { transform: rotate(0deg) scale(1.2); }
  100% { transform: scale(1); }
}
`;

const CallingWrapper = styled.div`
  width: 100%;
  height: 100%;
`;

const QuitAudioCall = styled.div`
  width: 100%;
  height: 100%;
  background: linear-gradient(365deg, rgba(248, 128, 59, 0.9) 0%, #eb561b 100%);
`;