import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import styled from 'styled-components';
import DaumPostcode from 'react-daum-postcode';
import { Link } from 'react-router-dom';
import { BiErrorCircle } from 'react-icons/bi';
import API from '../../modules/api/api';
import { palette } from '../../modules/defines/style';
import Loading from '../Loading';
import * as cu from './ConsoleUtil';
import { PHONE_REGEX, BUSINESS_CODE_REGEX } from '../utill/regex';
// startup: 1=법인, 2=개인, 3=예비
function CompanyInfo({ data, requestData }) {
  const { t } = useTranslation();
  const clonedData = _.cloneDeep(data);
  const [loading, setLoading] = useState(false);
  const [filename, setFilename] = useState('');
  const [displayPostcode, setDisplayPostCode] = useState(false);
  const [selectedOption, setSelectedOption] = useState('3');
  const company = cu.CompanyDistribution(data.startup);
  const [modify, setModify] = useState(false);
  const [formData, setFormData] = useState('');
  const [businessPhoneErrors, setBusinessPhoneErrors] = useState('');
  const [businessCodeErrors, setBusinessCodeErrors] = useState('');
  const [inputValid, setInputValid] = useState(true);
  // 상세주소칸 ,Loading 더예쁘게 다듬기
  // FIXME: 취소 버튼

  const submitModify = (formData) => {
    delete formData.idx;
    delete formData.unpaid;
    delete formData.open_date;
    delete formData.refreshToken;
    delete formData.passwd;
    delete formData.business_file;
    const businessPhoneValid = PHONE_REGEX.test(`${formData.business_phone}`);
    const businessCodeValid = BUSINESS_CODE_REGEX.test(`${formData.business_code}`);
    if (!businessPhoneValid) {
      setBusinessPhoneErrors(t('error.tel'));
      // alert('입력값을 확인해주세요.');
      setModify(true);
      return;
    }
  
    if (!businessCodeValid) {
      setBusinessCodeErrors(t('error.businessCode'));
      // alert('입력값을 확인해주세요.');
      setModify(true);
      return;
    }

    if (data.startup === 3) {
      formData.startup = Number(selectedOption);
     
    } 
    setLoading(true);
    API.modifyInfo(formData).then(response => {
      setTimeout(() => {
        requestData();
        setLoading(false);
        setModify(false);
      }, 2000); // delay of 2 seconds
    }).catch(error => {
      setLoading(false);
      
    });
  };
  
  const handleFormData = (e) => {
    setFormData(prev => ({ ...prev, [e.target.name]: e.target.value }));
    const businessPhoneValid = PHONE_REGEX.test(`${e.target.name === 'business_phone' ? e.target.value : formData.business_phone}`);
    const businessCodeValid = BUSINESS_CODE_REGEX.test(`${e.target.name === 'business_code' ? e.target.value : formData.business_code}`);

    if (selectedOption !== '3') {
      const isValid = businessPhoneValid && businessCodeValid;
      setInputValid(isValid);
    }
    
  };
  const handleChangeRadio = (event) => {
    setSelectedOption(event.target.value);
    setFormData((prevFormData) => ({
      ...prevFormData,
      startup: event.target.value,
    }));
  };

  const handleChangeFile = (e) => {
    setFilename('');
    const file = e.target.files[0];
    setFormData(prevState => ({ ...prevState, file }));
    setFilename(file.name);
  };
  const handleAddressCompleted = (data) => {
    setDisplayPostCode(false);
    setFormData(prev => ({ ...prev, address_code: data.zonecode, address:data.roadAddress }));
  };
  
  const content = data.startup === cu.PREFOUNDER ? (
    modify ? (
      <>
    
        {selectedOption === '3' && (
          <>   
            <div className="modify_input">
              <label htmlFor="agency_name">{t('console.info.agencyName')}</label>
              <input
                type="text" value={data.agency_name} id="agency_name" name="agency_name"
                onChange={handleFormData}
                disabled />
            </div>
            <div className="modify_input">
              <label htmlFor="intro">{t('console.info.intro')}</label>
              <input
                type="text" name="intro" value={formData.intro} placeholder={t('console.introInput')}
                maxLength="50" onChange={handleFormData} />
            </div>
            <div className="modify_input">
              <label htmlFor="homepage">{t('console.info.homepage')}</label>
              <input
                type="text" name="homepage" value={formData.homepage}
                onChange={handleFormData} />
            </div>
          </>
        )}
        {selectedOption === '2' && (
          <>
            <div className="modify_input">
              <label htmlFor="agency_name">{t('console.info.agencyName')}</label>
              <input
                type="text" value={data.agency_name} id="agency_name" name="agency_name"
                onChange={handleFormData}
                disabled />
            </div>
            <div className="address_box">
              <label htmlFor="address_code">{t('console.info.address')}</label>
              <div className="address_input_box">
                <input
                  type="text" readOnly name="address_code" value={formData.address_code}
                  placeholder="우편 번호"
                  maxLength="6" onClick={() => setDisplayPostCode(true)} />
                <input type="text" readOnly name="address" value={formData.address} onClick={() => setDisplayPostCode(true)} />
              </div>
            </div>
            <div className="modify_input">
              <label htmlFor="business_phone">{t('console.info.phone')}</label>
              <input
                type="tel" name="business_phone" placeholder={t('console.phoneInput')} value={formData.business_phone}
                maxLength="13" onChange={handleFormData} />
            </div>
            <span style={{ textAlign: 'center', color: 'red', fontSize: '13px', fontWeight: '600' }}>{businessPhoneErrors}</span>
            <div className="modify_input">
              <label htmlFor="business_code">{t('console.info.businessCode')}</label>
              <input
                placeholder={t('console.telInput')}
                type="tel" name="business_code" value={formData.business_code}
                maxLength="12" onChange={handleFormData} />
            </div>
            <span style={{ textAlign: 'center', color: 'red', fontSize: '13px', fontWeight: '600' }}>{businessCodeErrors}</span>
       
            <div className="modify_input">
              <label htmlFor="intro">{t('console.info.intro')}</label>
              <input
                type="text" name="intro" value={formData.intro} placeholder={t('console.introInput')}
                maxLength="50" onChange={handleFormData} />
            </div>
            <div className="file_box">
              <p>{t('console.info.certificate')}</p>
              <div className="file_container">
                <label htmlFor="file">{t('console.info.file')}</label>
                <input
                  type="file" id="file" name="file" accept="image/png, image/jpg, .pdf"
                  onChange={handleChangeFile} />
                <input type="text" readOnly value={filename} placeholder={data.business_file !== '' ? data.business_file : 'png, jpg, pdf'} />
              </div>
            </div>
            <div className="modify_input">
              <label htmlFor="homepage">{t('console.info.homepage')}</label>
              <input
                type="text" name="homepage" value={formData.homepage}
                onChange={handleFormData} />
            </div>
            {displayPostcode && (
            // onClose : 주소클릭시 닫힘
              <Popup onClose={() => setDisplayPostCode((prev) => !prev)}>
                <div className="container">
                  <button
                    style={{ backgroundColor: 'white', cursor:'pointer' }}
                    onClick={() => setDisplayPostCode((prev) => !prev)} type="button">✖
                  </button>
                  <DaumPostcode onComplete={handleAddressCompleted} />
                </div>
              </Popup>
            )}
          </>
        )}
        {selectedOption === '1' && (
          <>
            <div className="modify_input">
              <label htmlFor="agency_name">{t('console.info.agencyName')}</label>
              <input
                type="text" value={data.agency_name} id="agency_name" name="agency_name"
                onChange={handleFormData}
                disabled />
            </div>
            <div className="address_box">
              <label htmlFor="address_code">{t('console.info.address')}</label>
              <div className="address_input_box">
                <input
                  type="text" readOnly name="address_code" value={formData.address_code}
                  placeholder={t('console.addressCode')}
                  maxLength="6" onClick={() => setDisplayPostCode(true)} />
                <input type="text" readOnly name="address" value={formData.address} onClick={() => setDisplayPostCode(true)} />
              </div>
            </div>
            <div className="modify_input">
              <label htmlFor="business_phone">{t('console.info.phone')}</label>
              <input
                placeholder={t('console.phoneInput')}
                type="tel" name="business_phone" value={formData.business_phone}
                maxLength="13" onChange={handleFormData} />
            </div>
            <span style={{ textAlign: 'center', color: 'red', fontSize: '13px', fontWeight: '600' }}>{businessPhoneErrors}</span>
            <div className="modify_input">
              <label htmlFor="business_code">{t('console.info.businessCode')}</label>
              <input
                placeholder={t('console.telInput')}
                type="tel" name="business_code" value={formData.business_code}
                maxLength="12" onChange={handleFormData} />
            </div>
            <span style={{ textAlign: 'center', color: 'red', fontSize: '13px', fontWeight: '600' }}>{businessCodeErrors}</span>
            <div className="modify_input">
              <label htmlFor="intro">{t('console.info.intro')}</label>
              <input
                type="text" name="intro" value={formData.intro} placeholder={t('console.introInput')}
                maxLength="50" onChange={handleFormData} />
            </div>
            <div className="file_box">
              <p>{t('console.info.certificate')}</p>
              <div className="file_container">
                <label htmlFor="business_file">{t('console.info.file')}</label>
                <input
                  type="file" id="file" name="file" accept="image/png, image/jpg, .pdf"
                  onChange={handleChangeFile} />
                <input type="text" readOnly value={filename} placeholder={data.business_file !== '' ? data.business_file : 'png, jpg, pdf'} />
              </div>
            </div>
            <div className="modify_input">
              <label htmlFor="homepage">{t('console.info.homepage')}</label>
              <input
                type="text" name="homepage" value={formData.homepage}
                onChange={handleFormData} />
            </div>
            {displayPostcode && (
            // onClose : 주소클릭시 닫힘
              <Popup onClose={() => setDisplayPostCode((prev) => !prev)}>
                <div className="container">
                  <button
                    style={{ backgroundColor: 'white', cursor:'pointer' }}
                    onClick={() => setDisplayPostCode((prev) => !prev)} type="button">✖
                  </button>
                  <DaumPostcode onComplete={handleAddressCompleted} />
                </div>
              </Popup>
            )}
          </>
        )}
        <div className="checkbox_radio">
          <label htmlFor="startup">{t('console.info.status')}</label>
          <div className="redio_box">
            <input
              type="radio"
              value="3"
              name="startup"
              checked={selectedOption === '3'}
              onChange={handleChangeRadio}
            />
            <label htmlFor="startup"> {t('console.util.prefounder')} </label>
      
            <input
              type="radio"
              value="2"
              name="startup"
              checked={selectedOption === '2'}
              onChange={handleChangeRadio}
            />
            <label htmlFor="startup"> {t('console.util.company')} </label>
            <input
              type="radio"
              value="1"
              name="startup"
              checked={selectedOption === '1'}
              onChange={handleChangeRadio}
            />
            <label htmlFor="startup"> {t('console.util.coporation')} </label>
          </div>
        </div>
        <div className="modify_input">
          <Link to="/contact">
            <span style={{ color: `${palette.main.default}`, fontSize: '14px', fontWeight: '500', textDecoration: 'underline' }}>
              <BiErrorCircle style={{ color: 'red', marginRight: '10px' }} />
              {t('console.util.withdrawal')}
            </span>
          </Link>
        </div>
      </>
    ) : (
      <>                
        <div className="input_box">
          <span className="label">{t('console.info.agencyName')}</span>
          <span>{data.agency_name}</span>
        </div>
        <div className="input_box">
          <span className="label">{t('console.info.intro')}</span>
          <span>{data.intro}</span>
        </div>
        <div className="input_box">
          <span className="label">{t('console.info.homepage')}</span>
          <span>
            {data.homepage === '' ? 'N/A' : <a href={data.homepage} target="_blank" rel="noreferrer">{data.homepage}</a>}
          </span>
        </div>
        <div className="input_box">
          <span className="label">{t('console.info.status')}</span>
          <span>{company}</span>
        </div>
      </>
    )
  ) 
    : (
      modify ? (
        <>         
          <div className="modify_input">
            <label htmlFor="agency_name">{t('console.info.agencyName')}</label>
            <input
              type="text" value={data.agency_name} id="agency_name" name="agency_name"
              onChange={handleFormData}
              disabled />
          </div>
          <div className="address_box">
            <label htmlFor="address_code">{t('console.info.address')}</label>
            <div className="address_input_box">
              <input
                type="text" readOnly name="address_code" value={formData.address_code}
                placeholder={t('console.addressCode')}
                maxLength="6" onClick={() => setDisplayPostCode(true)} />
              <input type="text" readOnly name="address" value={formData.address} onClick={() => setDisplayPostCode(true)} />
            </div>
          </div>
          <div className="modify_input">
            <label htmlFor="business_phone">{t('console.info.phone')}</label>
            <input
              type="tel" name="business_phone" value={formData.business_phone}
              maxLength="13" onChange={handleFormData} />
          </div>
          <span style={{ textAlign: 'center', color: 'red', fontSize: '13px', fontWeight: '600' }}>{businessPhoneErrors}</span>
          <div className="modify_input">
            <label htmlFor="business_code">{t('console.info.businessCode')}</label>
            <input
              disabled
              type="tel" name="business_code" value={formData.business_code}
              maxLength="12" onChange={handleFormData} />
          </div>
          <div className="modify_input">
            <label htmlFor="intro">{t('console.info.intro')}</label>
            <input
              type="text" name="intro" value={formData.intro} placeholder={t('console.introInput')}
              maxLength="50" onChange={handleFormData} />
          </div>
          <div className="file_box">
            <p style={{ width: '200px' }}>{t('console.info.certificate')}</p>
            <div className="modify_input">
              <div className="file_container">
                <label htmlFor="business_file">{t('console.info.file')}</label>
                <input
                  type="file" id="business_file" name="business_file" accept="image/png, image/jpg, .pdf"
                  onChange={handleChangeFile} />
                <input type="text" readOnly value={filename} placeholder={data.business_file !== '' ? data.business_file : 'png, jpg, pdf'} />
              </div>
            </div>
          </div>
          <div className="modify_input">
            <label htmlFor="homepage">{t('console.info.homepage')}</label>
            <input
              type="text" name="homepage" value={formData.homepage}
              onChange={handleFormData} />
          </div>
     
          {displayPostcode && (
            // onClose : 주소클릭시 닫힘
            <Popup onClose={() => setDisplayPostCode((prev) => !prev)}>
              <div className="container">
                <button
                  style={{ backgroundColor: 'white', cursor:'pointer' }}
                  onClick={() => setDisplayPostCode((prev) => !prev)} type="button">✖
                </button>
                <DaumPostcode onComplete={handleAddressCompleted} />
              </div>
            </Popup>
          )}
        
        </>
      ) : (
        <>
          <div className="input_box">
            <span className="label">{t('console.info.agencyName')}</span>
            <span>{data.agency_name}</span>
          </div>
                
          <div className="address_box input_box">
            <span className="label">{t('console.info.address')}</span>
            <span>{data.address_code}&nbsp;</span>
            <span>{data.address}</span>
          </div>
          <div className="input_box">
            <span className="label">{t('console.info.phone')}</span>
            <span>{data.business_phone}</span>
          </div>
          <div className="input_box">
            <span className="label">{t('console.info.businessCode')}</span>
            <span>{data.business_code}</span>
          </div>
                
          <div className="input_box">
            <span className="label">{t('console.info.intro')}</span>
            <span>{data.intro}</span>
          </div>
          <div className="input_box file_box">
            <span className="label">{t('console.info.certificate')}</span>
          
            <span>
              {data.business_file === null ? t('console.nonFile') : <a className="file_href" href={`https://omnitalk.io/uploads/${data.business_file}`} target="_blank" rel="noreferrer">{`사업자 등록증_${data.agency_name}`}</a>}
            </span>

          </div>
          <div className="input_box">
            <span className="label">{t('console.info.homepage')}</span>
            <span>
              {data.homepage === '' ? 'N/A' : <a href={data.homepage} target="_blank" rel="noopener noreferrer">{data.homepage}</a>}
            </span>

          </div>
          <div className="input_box">
            <span className="label">{t('console.info.status')}</span>
            <span>{company}</span>
          </div>
        </>
      )
    );
  useEffect(() => {
    setFormData(_.cloneDeep(data));
  }, []);
    
  return (
    <form encType="multipart/form-data">
      {content}
      {loading ? <Loading />
        : modify ? (
          <>
            <div className="modify_input button_type button-box">
              <button type="button" className={`${!inputValid ? 'disabledModify' : ''}`} disabled={!inputValid} onClick={() => { submitModify(formData); setModify(false); setFormData(''); }}>{t('console.modifyButton')}</button>
              <button id="button_cancle" type="button" onClick={() => { setModify(false); setFormData(''); }}>{t('console.button.cancle')}</button>
            </div>
            <div id="sign-out" className="modify_input sign-out">
              <Link to="/contact">
                <span style={{ color: `${palette.main.default}`, fontSize: '14px', fontWeight: '500', textDecoration: 'underline' }}>
                  <BiErrorCircle style={{ color: 'red', marginRight: '10px' }} />
                  {t('console.info.withdrawal')}
                </span>
              </Link>
            </div>
          </>
        ) : (
          <div className="button_type">
            <button
              type="submit" onClick={() => {
                setModify(true);
                setFormData(clonedData);
              }}>{t('console.button.change')}
            </button>
          </div>
        )}
    </form>
  );
}
export default CompanyInfo;

const Popup = styled.div`
width: 100vw;
height: 100vh;
position: fixed;
left: 0;
top: 0;
background-color: ${palette.white};
z-index: 10;
.container{
width: 420px;
padding-top: 100px;
position: fixed;
left: 50%;
top: 50%;
transform: translate(-50%,-50%);
button{
  position: absolute;
  top: 60px;
  right: 0;
  border: 0;
  background-color: ${palette.opacity};
  z-index: 12;
  cursor: pointer;
}
}


`;
