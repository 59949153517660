import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import {
	defaultFlexCenter,
	fontBox,
	fontSize,
	fontWeight,
	palette,
} from '../modules/defines/style';
import iconText from '../assets/icon_text.svg';
import Section04 from '../components/pricing/Section04';

function Pricing() {
	const { t } = useTranslation();
	const data = t('pricing.card', { returnObjects: true });

	return (
		<StyledPricing>
			<Helmet>
				<title>{t('helmet.pricing.title')}</title>
				<meta
					name="keywords"
					content="WebRTC, SIP, CPAAS, communication, cloud-based, integration, solution, provider,WebRTC CPAAS,SIP CPaaS,WebRTC SIP integration,Cloud-based WebRTC,Cloud-based SIP,WebRTC and SIP solution,WebRTC and SIP provider,옴니톡,옴니스토리,비대면통신,커뮤니케이션플랫폼,메타버스,통신,통신서비스"
				/>
				<meta name="description" content={t('helmet.pricing.desc')} />
				<meta property="og:title" content={t('helmet.pricing.ogTitle')} />
				<meta property="og:description" content={t('helmet.pricing.ogDesc')} />
				<meta property="og:url" content="https://omnitalk.io/pricing" />
				<meta
					property="twitter:title"
					content={t('helmet.pricing.twitterTitle')}
				/>
				<meta
					property="twitter:card"
					content={t('helmet.pricing.twitterCard')}
				/>
				<meta
					property="twitter:description"
					content={t('helmet.pricing.twitterDesc')}
				/>
				<meta name="robots" content="index" />
				<meta name="title" content={t('helmet.pricing.title')}></meta>
				<meta name="author" content="omnitalk"></meta>
				<meta property="og:type" content="website" />
				<link rel="canonical" href="https://omnitalk.io/pricing/" />
			</Helmet>

			{/* -------- */}
			<section className="py-6 bg-white dark:bg-black text-black dark:text-white">
				<div className="container mx-auto p-4 sm:p-10">
					<div className="mb-10 space-y-4 text-center">
						<h3 className="text-3xl lg:text-6xl font-bold mb-10">
							{t('pricing.head')}
						</h3>
						<p className="px-4 lg:text-[1.375rem] lg:max-w-[52rem] lg:leading-[2.5rem] ml-auto mr-auto xs:text-sm sm:text-base md:text-lg pt-3 pb-3 sm:px-8 lg:px-24 text-center">
							{t('pricing.desc')}
						</p>
						<img
							className="m-auto pb-10"
							src="https://user-images.githubusercontent.com/120351058/209781974-6d38e8aa-8dfc-44d1-9c3b-04b9a2590e0b.svg"
							alt="price-icon"
						/>
					</div>
					<div className="grid max-w-md grid-cols-1 gap-6 mx-auto auto-rows-fr lg:max-w-full lg:gap-2 xl:gap-6 lg:grid-cols-3">
						{data.map((item, index) => {
							return (
								<div
									className="relative z-0 flex flex-col items-center p-8  rounded-md   shadow-lg "
									key={index}
								>
									<span className="absolute top-0 px-6 pt-1 pb-2 font-medium rounded-b-lg bg-black dark:bg-main-50 text-white lg:text-lg ">
										{item.type}
									</span>
									<p className="flex items-center justify-center my-6 space-x-2 text-white font-bold">
										&nbsp;
									</p>
									<ul className="flex-1 space-y-2">
										<li className="flex items-center space-x-2">
											<svg
												xmlns="http://www.w3.org/2000/svg"
												viewBox="0 0 20 20"
												fill="currentColor"
												className="w-5 h-5 text-gray-400"
											>
												<path
													fillRule="evenodd"
													d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
													clipRule="evenodd"
												/>
											</svg>

											<span className="lg:text-base">{item.startfee}</span>
										</li>
										{item.voucher && (
											<li className="flex items-center space-x-2">
												<svg
													xmlns="http://www.w3.org/2000/svg"
													viewBox="0 0 20 20"
													fill="currentColor"
													className="w-5 h-5 text-gray-400"
												>
													<path
														fillRule="evenodd"
														d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
														clipRule="evenodd"
													/>
												</svg>
												<span>{item.voucher}</span>
											</li>
										)}

										<li className="flex items-center space-x-2">
											<svg
												xmlns="http://www.w3.org/2000/svg"
												viewBox="0 0 20 20"
												fill="currentColor"
												className="w-5 h-5 text-gray-400"
											>
												<path
													fillRule="evenodd"
													d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
													clipRule="evenodd"
												/>
											</svg>
											<span>{item.guide}</span>
										</li>
										<li className="flex items-center space-x-2">
											<svg
												xmlns="http://www.w3.org/2000/svg"
												viewBox="0 0 20 20"
												fill="currentColor"
												className="w-5 h-5 text-gray-400"
											>
												<path
													fillRule="evenodd"
													d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
													clipRule="evenodd"
												/>
											</svg>
											<span>{item.console}</span>
										</li>

										<li className="flex items-center space-x-2">
											<svg
												xmlns="http://www.w3.org/2000/svg"
												viewBox="0 0 20 20"
												fill="currentColor"
												className="w-5 h-5 text-gray-400"
											>
												<path
													fillRule="evenodd"
													d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
													clipRule="evenodd"
												/>
											</svg>
											<span>{item.support}</span>
										</li>
									</ul>
									<a
										href="/login"
										className="px-4 py-2 mt-4 font-semibold uppercase border rounded-lg md:mt-12 sm:py-3 sm:px-8  dark:text-white dark:border-none hover:text-white hover:bg-main-50"
									>
										Start
									</a>
								</div>
							);
						})}
					</div>
				</div>
			</section>
			{/* --- */}
			<div className="section02">
				<section>
					<div className="scroll_container">
						<ul>
							<li className="title">
								<dl>
									<dt className="lg:text-lg">{t('pricing.section2.title')}</dt>
									<dd className="lg:text-base">
										<strong>Audio</strong>
									</dd>
									<dd className="lg:text-base">
										<strong>HD Video</strong>
									</dd>
									<dd className="lg:text-base">
										<strong>FHD Video</strong>
									</dd>
									<dd className="lg:text-base">
										<strong>2K Video</strong>
									</dd>
								</dl>
							</li>
							<li className="contents">
								<dl>
									<dt>{t('pricing.section2.type')}</dt>
									<dd className="lg:text-base">16kHz ~ 48kHz</dd>
									<dd className="lg:text-base">{t('pricing.Less')}</dd>
									<dd className="lg:text-base">
										921,600px <br />~ 2,073,600px
									</dd>
									<dd className="lg:text-base">
										2,073,600px
										<br />~ 3,3,686,400px
									</dd>
								</dl>
								<dl>
									<dt>{t('pricing.section2.pricing')}</dt>
									<dd className="lg:text-base">
										{t('pricing.section2.pay1')} / {t('pricing.minute')}
									</dd>
									<dd className="lg:text-base">
										{t('pricing.section2.pay2')} / {t('pricing.minute')}
									</dd>
									<dd className="lg:text-base">
										{t('pricing.section2.pay3')} / {t('pricing.minute')}
									</dd>
									<dd className="lg:text-base">
										{t('pricing.section2.pay4')} / {t('pricing.minute')}
									</dd>
								</dl>
							</li>
						</ul>
					</div>
				</section>
			</div>

			<section className="section_container">
				<h3 className="lg:text-lg lg:text-bold">
					{t('pricing.section3.title')}
				</h3>
				<article>
					<div className="section_title" style={{ marginBottom: '20px' }}>
						<img src={iconText} alt="특수문자" />
						<h4>{t('pricing.section3.desc')}</h4>
					</div>
					<div className="section_contents">
						<h5 className="mt-6">{t('pricing.section3.sub1title')}</h5>
						<p>{t('pricing.section3.sub1desc')}</p>
					</div>
					<div className="section_contents">
						<h5 className="mt-6">{t('pricing.section3.sub2title')}</h5>
						<p>{t('pricing.section3.sub2desc')}</p>
					</div>
					<div className="section_contents">
						<h5 className="mt-6">{t('pricing.section3.sub3title')}</h5>
						<p>{t('pricing.section3.sub3descOne')}</p>
						<p>{t('pricing.section3.sub3descTwo')}</p>
					</div>
					<div className="section_contents">
						<h5 className="mt-6">{t('pricing.section3.sub4title')}</h5>
						<p>{t('pricing.section3.sub4desc')}</p>
					</div>
				</article>
			</section>

			<Section04 t={t} />
		</StyledPricing>
	);
}

export default Pricing;

const StyledPricing = styled.div`
section{
${defaultFlexCenter}

justify-content: space-between;
margin-bottom: 50px;
padding: 0 5%;
h1{
  font-weight: ${fontWeight.medium};
}
.scroll_container{
  width: 100%;
ul{
  width: 100%;
  ${defaultFlexCenter}
  flex-direction: column;
  .title{
    width: 100%;
    margin-bottom: 40px;
dl{
    width: 100%;
    ${defaultFlexCenter}
    dt{
      width: 20%;
      h3{
        font-size: ${fontSize.medium};
        font-weight: ${fontWeight.semiBold};
      }
      span{
        /* font-size: ${fontSize.micro}; */
      }
    }
    dd{
      width: 20%;
      text-align: center;
      strong{
        display: block;
        width: 120px;
        margin: 0 auto;
        line-height: 36px;
        margin-bottom: 10px;
        ${defaultFlexCenter}
        ${fontBox}
      }
      p{
        padding: 0 5%;
        padding-top: 15px;
        height: 80px;
        text-align: center;
        ${defaultFlexCenter}
      flex-direction: column;
      b{
        margin-bottom: 10px;
      }
      span{
        line-height: 24px;
      }
      }
    }
  }
  }
  .contents{
    width: 100%;
    padding-top: 40px;
    border-top:1px solid ${palette.gray.middle};
    dl{
      width: 100%;
      ${defaultFlexCenter}
      justify-content: space-between;
      dt{
        width: 20%;
        font-weight: ${fontWeight.semiBold};
        line-height: 80px;
      }
      dd{
        width: 20%;
        text-align: center;
        img{
          width: 24px;
        }
      }
    }
  }
}
}
}
.section02{
  width: 100%;
  background-color: ${palette.gray.light};
  section{
    display: flex;
    flex-direction:column;
h3{
  padding-left: 0;
  margin-bottom: 58px;
  text-align: left;
  font-size: ${fontSize.medium};
  font-weight: ${fontWeight.semiBold};
  }
  }
  
}
.section_container{
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 58px;
  h3{
  /* font-size: 1.375rem;
  font-weight: ${fontWeight.bold}; */
}
article{
  width: 100%;
.section_title{
  width: 100%;
  ${defaultFlexCenter}
h4{
  font-size: 22px;
  font-weight: ${fontWeight.regular};
}
img{
    width: 24px;
    margin-right: 10px;
  }
}
.section_contents{
  width: 100%;
  margin: 0 auto;
  margin-bottom: 30px;
  border-bottom:1px solid ${palette.gray.middle};
  h5{
    font-size: 22px;
    margin-bottom: 10px;
    font-weight: ${fontWeight.semiBold};
  }
  p{
    width: 1100px;
    line-height: 32px;
  }
}
}
}

@media screen and (max-width:1200px) and (min-width: 980px) {
section{
  padding: 0 5%;
}
.section02{
  h3{
    width: 100%;
  }
}
.section_container{
article{
  width: 100%;
.section_contents{
  width: 100%;
  margin: 0 auto;
  margin-bottom: 30px;
  border-bottom:1px solid ${palette.gray.middle};
  h5{
    font-size: 22px;
    font-weight: ${fontWeight.semiBold};
  }
  p{
    width: 800px;
    line-height: 32px;
  }
}
}
}
}

@media screen and (max-width:979px) and (min-width:580px) {
section{
position: relative;
padding: 0 5%;
  h1{
    position: absolute;
    font-size: 2.5rem;
    font-weight: ${fontWeight.medium};
    top: -80px;
    left: 5%;
  }
  img{
    width: 60%;
    padding-top: 80px;
    margin: 0 auto;
  }
  .scroll_container{
    padding-top: 40px;
    width: 100%;
    overflow: auto;
    ul{
      width: 880px;
      .title{
        margin-bottom: 20px;
        dl{
          align-items: baseline;
          dt{
            span{
            }
          }
          dd{
            margin: 0;
          }
        }
      }
      .contents{
        dl{
          dt{
            font-weight: ${fontWeight.medium};
            line-height: 50px;
          }
          dd{
            img{
              padding: 0;
            }
          }
        }
      }
  }
  }
  .scroll_container::-webkit-scrollbar{
    display: none;
  }
}
}
.section02{
  width: 100%;
  padding: 4.625rem 0 0.9375rem 0;
  margin-bottom: 80px;
  section{
h3{
    width: 100%;
    padding-left: 5%;
    font-size: 2rem;
    font-weight: ${fontWeight.medium};
  }
  }
}
.section_container{
  h3{
  padding-top: 0;
  margin-bottom: 50px;
  font-size: 2rem;
  font-weight: ${fontWeight.medium};
}
article{
  width: 100%;
.section_title{
h4{
  font-size: 2rem;
}
img{
    width: 24px;
    padding: 0;
    margin: 0;
    margin-right: 10px;
    display: flex;
    align-items: center;
  }
}
.section_contents{
  width: 100%;
  padding-top: 50px;
  margin: 0 auto;
  margin-bottom: 0;
  border-bottom:1px solid ${palette.gray.middle};
  h5{
    font-size: 1.25rem;
    font-weight: ${fontWeight.semiBold};
  }
  p{
    width: 100%;
    /* font-size: 1.1rem; */
    line-height: 1.65rem;
  }
}
}
}

@media screen and (max-width:579px) and (min-width:230px) {
section{
position: relative;
padding: 0 5%;
margin-bottom: 0;
  h1{
    font-size: 1.75rem;
    font-weight: ${fontWeight.medium};
    position: absolute;
    top: -80px;
    left: 5%;
  }
  img{
    width: 100%;
    padding-top: 30px;
  }
  .scroll_container{
    padding-top: 50px;
    width: 100%;
    overflow: auto;
ul{
  width: 720px;
  .title{
    width: 100%;
    margin-bottom: 30px;
dl{
    width: 100%;
    ${defaultFlexCenter}
    dt{
      width: 20%;
      h3{
        font-size: ${fontSize.regular};
        font-weight: ${fontWeight.semiBold};
      }
      span{
      }
    }
    dd{
      width: 20%;
      text-align: center;
      strong{
        font-weight: ${fontWeight.medium};
      }
      p{
        height: 80px;
        ${defaultFlexCenter}
      flex-direction: column;
      b{
        margin-bottom: 10px;
        font-weight: ${fontWeight.semiBold};
      }
      span{
        line-height: 24px;
      }
      }
    }
  }
  }
  .contents{
    width: 100%;
    padding-top: 10px;
    border-top:1px solid ${palette.gray.middle};
    margin-bottom: 30px;
    dl{
      width: 100%;
      ${defaultFlexCenter}
      justify-content: space-between;
      dt{
        width: 20%;
        font-weight: ${fontWeight.medium};
        line-height: 46px;
      }
      dd{
        width: 20%;
        text-align: center;
        color: ${palette.text.default};
        img{
          width: 18px;
        }
      }
    }
  }
}  }
  .scroll_container::-webkit-scrollbar{
    display: none;
  }
}
}
.section02{
  width: 100%;
  margin-bottom: 30px;
  section{
    h3{
    width: 100%;
    padding-left: 5%;
    margin-bottom: 0;
    font-size: 1.25rem;
    font-weight: ${fontWeight.medium};
  }
    .scroll_container{
      ul{
        .title{
          
          dl{
           display: flex;
         
            dd{
              strong{
                width: 100px;
                font-weight: ${fontWeight.regular};
                margin-bottom: 0;
              }
            }
          }
        }
        .contents{
          dl{
            dt{
              font-weight: ${fontWeight.regular};
            }
            dd{
              /* font-size: 14px; */
            }
          }
        }
      }
    }
  }
}
.section_container{
  margin-top: 6rem;
  margin-bottom: 30px;
  h3{
  padding-top: 0;
  font-size: 1.375rem;
  font-weight: ${fontWeight.semiBold};
}
article{
  width: 100%;
.section_title{
h4{
  font-size: 1.125rem;
  margin: 0;
  color: ${palette.text.default};
}
img{
    width: 18px;
    padding: 0;
    margin: 0;
    margin-right: 10px;
    display: flex;
    align-items: center;
  }
}
.section_contents{
  width: 100%;
  margin: 0 auto;
  margin-bottom: 0;
  padding-top: 0;
  border-bottom:1px solid ${palette.gray.middle};
  h5{
    font-size: 1.25rem;
    font-weight: ${fontWeight.regular};
  }
  p{
    width: 100%;
    margin: 0 0 20px 0;
    font-size: 16px;
    color: ${palette.text.default};
    line-height: 26px;
  }
}
}
}

`;

