import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { useRecoilValue } from 'recoil';
import BreadCrumb from '../BreadCrumb';
import myAxios from '../../modules/api/myAxios';
import Popup from '../ui/Popup';
import Loading from '../Loading';
import { roleState } from '../../recoil';
import TextEditor from './TextEditor';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';

export default function NoticeView() {
	const location = useLocation();
	const { t } = useTranslation();
	const notice = location.state;
	const [loading, setLoading] = useState(false);
	const [deleteModal, setDeleteModal] = useState(false);
	const [openEditor, setOpenEditor] = useState(false);
	const [formData, setFormData] = useState(null);
	const [content, setContent] = useState('');
	const [isValidErrOn, setIsVallidErrOn] = useState(false);
	const [errMsg, setErrMsg] = useState('');
	const onOffEditor = () => {
		setOpenEditor(false);
	};
	const editorRef = useRef(notice.contents);
	const role = useRecoilValue(roleState);
	const navigate = useNavigate();
	const handleClosePopup = () => {
		setDeleteModal(false);
	};
	const initFormData = () => {
		setFormData({
			category: notice.category,
			writer: notice.writer,
			title: notice.title,
			contents: notice.contents,
		});
	};
	function validateObject(formData) {
		if (formData === null) {
			return false; // formData가 null이면 유효하지 않음
		}
		if (
			formData.category === null ||
			typeof formData.category !== 'string' ||
			formData.category.trim() === ''
		) {
			return false; // category 속성이 유효하지 않음
		}

		if (
			formData.writer === null ||
			typeof formData.writer !== 'string' ||
			formData.writer.trim() === ''
		) {
			return false; // writer 속성이 유효하지 않음
		}

		if (
			formData.title === null ||
			typeof formData.title !== 'string' ||
			formData.title.trim() === ''
		) {
			return false; // title 속성이 유효하지 않음
		}

		return true; // 모든 속성이 유효함
	}
	useEffect(() => {
		initFormData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	const handleModify = async () => {
		setLoading(true);
		setIsVallidErrOn(false);
		console.log('contents', content);
		const updatedFormData = { ...formData, contents: content };

		const isValid = validateObject(updatedFormData);
		if (isValid) {
			await myAxios
				.patch(`/superadmin/bulletin/${notice.idx}`, updatedFormData)
				.then(response => {
					if (response.data.result === 'success') {
						setOpenEditor(false);
						setLoading(false);
						navigate('/notice');
					}
				})
				.catch(error => {
					console.log(error);
					setErrMsg(error);
					setLoading(false);
					setIsVallidErrOn(true);
					//* * todo reason 추가 */
				});
		} else {
			setIsVallidErrOn(true);
			setLoading(false);
		}
	};
	const onChange = () => {
		setContent(editorRef.current.getInstance().getHTML());
	};
	const onSelect = e => {
		setFormData(prev => ({ ...prev, [e.target.name]: e.target.value }));
	};

	const deleteNotice = async () => {
		setErrMsg('');
		setLoading(true);
		try {
			await myAxios.delete(`/superadmin/bulletin/${notice.idx}`).then(() => {
				setLoading(false);
				navigate('/notice');
			});
		} catch (error) {
			setLoading(false);
			setErrMsg('삭제에 실패하였습니다. 다시 시도해 주세요.');
			console.log(error.response.data);
		} finally {
			handleClosePopup();
			setLoading(false);
		}
	};

	return (
		<>
			<Helmet>
				<meta name="robots" content="noindex,nofollow" />
			</Helmet>
			<section className="max-w-[1200px] mx-auto px-[5%] min-h-[70vh]">
				<BreadCrumb current="Notice" />
				<h2 className="xs:mt-7 font-bold lg:text-3xl text-left xs:px-2  xs:text-xl sm:text-2xl sm:px-8 lg:mt-10 dark:text-white">
					Notice
				</h2>
				<p className=" xs:px-2  xs:text-sm sm:px-8 text-left text-gray-700  md:mb-10 lg:mb-[80px] md:text-lg sm:text-md dark:text-white">
					{t('notice.desc')}
				</p>
				<div className="px-8  max-w-[960px] w-full   ml-auto mr-auto mt-10 mb-20  ">
					{/* ---관리자--- */}
					{deleteModal && (
						<>
							<Popup onClose={handleClosePopup}>
								<span>정말 삭제 하시겠어요?</span>
								<div className="flex justify-center">
									<button
										className=" text-main-50 p-3 font-bold"
										type="button"
										onClick={() => deleteNotice()}
									>
										네
									</button>
									<button
										className="text-black p-3 font-bold"
										type="button"
										onClick={() => setDeleteModal(false)}
									>
										아니요
									</button>
								</div>
							</Popup>
							{loading && <Loading />}
						</>
					)}
					{role === 'admin' ? (
						<>
							<div className="text-red-600 font-semibold">{errMsg}</div>
							<div className="flex justify-end p-4">
								<button
									className="text-sm cursor-pointer hover:text-main-50"
									type="button"
									onClick={() => {
										setOpenEditor(true);
									}}
								>
									수정
								</button>
								<button
									onClick={() => setDeleteModal(true)}
									className="text-sm ml-3 text-red-600 cursor-pointer hover:text-main-50"
									type="button"
								>
									삭제
								</button>
							</div>
						</>
					) : null}
					{role === 'admin' && openEditor === true ? (
						<TextEditor
							notice={notice}
							type="notice"
							handleModify={handleModify}
							isPatching
							editorRef={editorRef}
							onChange={onChange}
							onSelect={onSelect}
							onClose={onOffEditor}
							isValidErrOn={isValidErrOn}
						/>
					) : null}

					{/* ---관리자--- */}
					{openEditor === false && (
						<>
							<div className="flex flex-row items-center  p-2  justify-between border-t-black border-t-2 border-b-2">
								<h3 className=" flex  mr-6   text-sm lg:text-base font-semibold px-10 text-center">
									{notice.title}
								</h3>
								<span className="flex text-[14px]   lg:text-base text-right px-8 ">
									{moment(notice.reg_date * 1000).format('YYYY.MM.DD')}
								</span>
							</div>
						</>
					)}
					{openEditor === false && (
						<div
							className="px-12 min-h-[400px] py-10 bg-gray-50 mb-10   lg:text-lg"
							dangerouslySetInnerHTML={{ __html: notice.contents }}
						/>
					)}
				</div>
			</section>
		</>
	);
}
