import { useTranslation } from 'react-i18next';
import { BsFillArrowRightCircleFill } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { defaultFlexCenter, fontSize, fontWeight, palette } from '../modules/defines/style';
import API from '../modules/api/api';
import ContactPopup from './main/ContactPopup';
import { useEffect, useState } from 'react';
function Aside() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const openNewWindow = () => {
    window.open('https://docs.omnitalk.io', '_blank');
  };
  const [displayContact, setDisplayContact] = useState(false);
  const [callBackMessage, setCallBackMessage] = useState('');
  const handleSubmit = (data) => {
    API.addContactUs({
      title: data.title,
      name: data.name,
      email: data.email,
      phone: `${data.phone0}-${data.phone1}-${data.phone2}`,
      position: data.position,
      question: data.question,
      status: 0
    }).then(response => {
      if (response.data.result === 'success') {
        setCallBackMessage('문의 사항이 정상적으로 접수되었습니다.<br />빠른시간내에 안내드리겠습니다. 감사합니다.');
      }
    }, error => {
      console.log(error);
    });
  };
  useEffect(() => {
    setCallBackMessage('');
  }, []);
  return (
    <StyledAside>
      <div className="aside_container  ">
        <dl>
          <dt className="dark:text-white">{t('aside.partnerTitle')}</dt>
          <dd >
            {/* <strong>{t('aside.partnerSubtitle')}</strong> */}
            <span className='text-black dark:text-white xs:text-sm sm:text-[15px] lg:text-base'>
              {t('aside.partnerTextOne')}<br />
              {t('aside.partnerTextTwo')}<br />
              {t('aside.partnerTextThree')}
            </span>
          </dd>
          <dd>
            <button
              type="button"
              className="text-black dark:text-white pr-3 "
              onClick={() => setDisplayContact(true) }>
              {t('aside.partnerButton')}
              <BsFillArrowRightCircleFill className="text-[#ea5f31] text-[1.5rem] font-bold ml-3 " />
            </button>
          </dd>
        </dl>
        <div className="contents_line"> </div>
        <dl>
          <dt className="dark:text-white">{t('aside.guideTitle')}</dt>
          <dd>
            <span className='text-black dark:text-white xs:text-sm sm:text-[15px] lg:text-base'>
            {t('aside.guideTextOne')}<br />
            {t('aside.guideTextTwo')}
            </span>
          </dd>
          <dd>
            <button
              type="button"
              className="text-black dark:text-white pr-3"
              onClick={openNewWindow}>
                
              {t('aside.guideButton')}
              <BsFillArrowRightCircleFill className="text-[#ea5f31] text-[1.5rem] font-bold ml-3 " />
            </button>
          </dd>
        </dl>
        <div className="contents_line"> </div>
        <dl>
          <dt className="dark:text-white">{t('aside.consoleTitle')}</dt>
          <dd>
          <span className='text-black dark:text-white xs:text-sm sm:text-[15px] lg:text-base'>

            {t('aside.consoleTextOne')}<br />
            {t('aside.consoleTextTwo')}
            </span>
          </dd>
          <dd>
            <button
              type="button"
              onClick={() => navigate('/pricing')}
              className="text-black dark:text-white pr-3"
            >
              {t('aside.consoleTextButton')}
              <BsFillArrowRightCircleFill className="text-[#ea5f31] text-[1.5rem] font-bold ml-3 " />
            </button>
          </dd>
        </dl>
      </div>
      {displayContact && <ContactPopup  onClose={() => setDisplayContact(false)} onSubmit={handleSubmit} callBackMessage={callBackMessage} />}
    </StyledAside>

  );
}

export default Aside;

const StyledAside = styled.section`
width: 100%;
margin-top: 144px;

.aside_container{
width: 100%;
display: flex;
justify-content: space-between;
margin: 0 auto;
padding-top: 0;
padding-bottom: 130px;
  dl{
    position: relative;
    height: 210px;
    margin: 0 40px;
    dt{
      font-size: ${fontSize.medium};
      font-weight: ${fontWeight.semiBold};
      margin-bottom: 24px;
      text-align: start;
    }
    dd{
      width: 260px;
      margin-bottom: 76px;
      font-size: ${fontSize.micro};
      color: ${palette.text.light};
      strong{
        width: 100%;
        display: block;
        margin-bottom:10px;
      }
      button{
        width: 100%;
        height: 40px;
        ${defaultFlexCenter}
        justify-content:space-between;
        position: absolute;
        left: 0;
        bottom: 0;
        border: 0;
        /* color: #222; */
        font-size: ${fontSize.regular};
        background-color: ${palette.opacity};
          :hover{
          cursor: pointer;
          }
        }
    }
}
.contents_line{
  height: 100px;
  border-right:1px solid ${palette.gray.dark};
}
}

@media screen and (max-width: 1200px) and (min-width:980px) {
width: 100%;
.aside_container{
width: 100%;
/* ${defaultFlexCenter} */
display: flex;
justify-content: space-between;
padding-bottom: 130px;
  dl{
    width: 200px;
    height: 200px;
    margin: 0 60px;
    dt{
      width: 90%;
      font-size: ${fontSize.medium};
      font-weight: ${fontWeight.semiBold};
      margin-bottom: 24px;
    }
    dd{
      width: 180px;
      margin-bottom: 76px;
}
}
}
}

@media screen and (max-width: 979px) and (min-width:580px) {
width: 100%;
.aside_container{
  width: 100%;
  display: flex;
justify-content: space-between;

  dl{
    width: 30%;
    height: 200px;
    margin: 0 20px;
    text-align: left;
    dt{
      font-size: 1.25rem;
      font-weight: ${fontWeight.medium};
      margin-bottom: 24px;
      text-align: left;
    }
    dd{
      width: 100%;
      font-size: 0.95rem;
      text-align: start;
      button{
        width: 80%;
        height: 40px;
        ${defaultFlexCenter}
        justify-content:space-between;
        position: absolute;
        right: 0;
        bottom: 0;
        border: 0;
        font-size: 1.1rem;
        background-color: ${palette.opacity};
          :hover{
          cursor: pointer;
          }
        }
    }
}
.contents_line{
  height: 120px;
  border-right:1px solid ${palette.gray.dark};
}
}
}

@media screen and (max-width: 579px) and (min-width: 230px) {
width: 100%;
padding: 0 5%;
.aside_container{
width: 100%;
${defaultFlexCenter}
flex-direction: column;
padding-bottom: 50px;
  dl{
    position: relative;
    width: 100%;
    height: 140px;
    padding: 5%;
    margin: 0;
    margin-bottom: 8px;
    border-radius: 10px;
    box-shadow: ${palette.shadow};
    background-color: ${palette.gray.boxColor};
    dt{
      font-size: ${fontSize.regular};
      font-size: 16px;
      font-weight: ${fontWeight.medium};
      margin-bottom: 10px;
    }
    dd{
      width: 100%;
      font-size: ${fontSize.micro};
      color: ${palette.text.light};
      margin-bottom: 0;
      button{
        width: 100%;
        height: 40px;
        margin: 0 auto;
        text-align: center;
        ${defaultFlexCenter}
        justify-content:flex-end;
        position: absolute;

        left: 0;
        bottom: 0;
        border: 0;
        font-size: 14px;
        color: #222;
        background-color: ${palette.opacity};
          :hover{
          cursor: pointer;
          }
        }
    }
}
.contents_line{
  display: none;
}
}
}

`;
