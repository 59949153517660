import React, { useEffect, useRef, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { Helmet } from 'react-helmet';
import myAxios from '../../modules/api/myAxios';
import TextEditor from './TextEditor';
import API from '../../modules/api/api';
import BreadCrumb from '../BreadCrumb';
import NoticeTable from './NoticeTable';
import Loading from '../Loading';
import { roleState } from '../../recoil';
import { useTranslation } from 'react-i18next';

//* * todo: 모바일 반응형 양옆 패딩 주기 */

export default function Notice() {
	const { t } = useTranslation();
	const [content, setContent] = useState('');
	const [sendEmail, setSendEmail] = useState(false);
	const [formData, setFormData] = useState(null);
	const [openEditor, setOpenEditor] = useState(false);
	const editorRef = useRef();
	const [notices, setNotices] = useState(null);
	const [currentPage, setCurrentPage] = useState(1);
	const [totalPages, setTotalPages] = useState(0);
	const [loading, setLoading] = useState(false);
	const role = useRecoilValue(roleState);
	const [isValidErrOn, setIsVallidErrOn] = useState(false);
	// const [file, setFile] = useState(null);
	const [isFileUpload, setIsFileUpload] = useState(false);
	const headers = {
		'Content-Type': 'multipart/form-data',
		encType: 'multipart/form-data', //필수 아님, 파일을 넣을거면 필수(e)
		processData: false,
	};
	const onUploadImage = async (blob, callback) => {
		const formData = new FormData();
		formData.append('file', blob);

		try {
			const result = await myAxios
				.post('/superadmin/bulletin', formData, {
					withCredentials: true,
					headers,
				})
				.then(res => console.log(res));
			const response = await myAxios.get('/superadmin/bulletin');
			const imagesrc = response.data.data[0].file;
			console.log(response.data.data[0].file);
			console.log(response);
			callback(`https://omnitalk.io/uploads/${imagesrc}`, blob.name);

			// callback(url, blob.name);
		} catch (error) {
			console.log(error);
		}

		return setIsFileUpload(true);
	};
	const onChangeSendEmail = e => {
		setSendEmail(e.target.checked);
		setFormData(prev => ({ ...prev, sendEmail: e.target.checked }));
	};

	const handlePageChange = currentPage => {
		setCurrentPage(currentPage);
	};
	function validateObject(formData) {
		if (formData === null) {
			return false; // formData가 null이면 유효하지 않음
		}
		if (
			formData.category === null ||
			typeof formData.category !== 'string' ||
			formData.category.trim() === ''
		) {
			return false; // category 속성이 유효하지 않음
		}

		if (
			formData.writer === null ||
			typeof formData.writer !== 'string' ||
			formData.writer.trim() === ''
		) {
			return false; // writer 속성이 유효하지 않음
		}

		if (
			formData.title === null ||
			typeof formData.title !== 'string' ||
			formData.title.trim() === ''
		) {
			return false; // title 속성이 유효하지 않음
		}

		return true; // 모든 속성이 유효함
	}

	const requestData = async () => {
		try {
			const response = await myAxios.get(
				`/superadmin/bulletin/?page=${currentPage}&perPage=10`,
			);
			setNotices(response.data.data);
			setTotalPages(response.data.total);
		} catch (error) {
			console.log(error);
		}
	};
	useEffect(() => {
		requestData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentPage]);
	const onChange = () => {
		setContent(editorRef.current.getInstance().getHTML());
	};
	const onSelect = e => {
		setFormData(prev => ({ ...prev, [e.target.name]: e.target.value }));
	};

	const sendReq = async () => {
		const fileData = new FormData();
		console.log('fileData', fileData);
		setLoading(true);
		setIsVallidErrOn(false);
		if (isFileUpload) {
			const updatedFormData = {
				...formData,
				contents: content,
				sendEmail,
			};
			const isValid = validateObject(updatedFormData);
			if (isValid) {
				const getResult = await myAxios.get('/superadmin/bulletin');
				const idx = getResult.data.data[0].idx;
				console.log(updatedFormData);
				await myAxios
					.patch(`/superadmin/bulletin/${idx}`, updatedFormData)
					.then(response => {
						console.log('notice response', response);
						setLoading(false);
						setOpenEditor(false);

						window.location.replace('/notice');
					})
					.catch(error => {
						console.log(error);
						setLoading(false);
						setIsVallidErrOn(true);
						//* * todo reason 추가 */
					});
			} else {
				setLoading(false);
				setIsVallidErrOn(true);
			}
		}
		if (isFileUpload === false) {
			const updatedFormData = {
				...formData,
				contents: content,
				sendEmail,
			};
			const isValid = validateObject(updatedFormData);
			if (isValid) {
				await API.posting(updatedFormData)
					.then(response => {
						if (response.result === 'success') {
							setOpenEditor(false);
							setLoading(false);
							window.location.replace('/notice');
						}
					})
					.catch(error => {
						setLoading(false);
						setIsVallidErrOn(true);
						console.log(error);
					});
			} else {
				setLoading(false);
				setIsVallidErrOn(true);
			}
		}
	};

	useEffect(() => {
		try {
			myAxios.get('/superadmin/bulletin').then(response => {
				setNotices(response.data.data);
			});
		} catch (error) {
			console.log(error);
		}
	}, []);

	const onOffEditor = () => {
		setOpenEditor(false);
	};

	return (
		<>
			<Helmet>
				<title>{t('helmet.notice.title')}</title>
				<meta charset="utf-8" />
				<meta
					name="keywords"
					content="WebRTC, SIP, CPAAS, communication, cloud-based, integration, solution, provider,WebRTC CPAAS,SIP CPaaS,WebRTC SIP integration,Cloud-based WebRTC,Cloud-based SIP,WebRTC and SIP solution,WebRTC and SIP provider,옴니톡,옴니스토리,비대면통신,커뮤니케이션플랫폼,메타버스,통신,통신서비스"
				/>
				<meta name="description" content={t('helmet.notice.desc')} />
				<meta property="og:title" content={t('helmet.notice.ogTitle')} />
				<meta property="og:description" content={t('helmet.notice.ogDesc')} />
				<meta property="og:url" content="https://omnitalk.io/notice" />
				<link rel="canonical" href="https://omnitalk.io/notice/" />
				<meta
					property="twitter:title"
					content={t('helmet.notice.twitterTitle')}
				/>
				<meta
					property="twitter:card"
					content={t('helmet.notice.twitterCard')}
				/>
				<meta
					property="twitter:description"
					content={t('helmet.notice.twitterDesc')}
				/>
				<meta name="robots" content="index" />
				<meta name="title" content={t('helmet.notice.title')}></meta>
				<meta name="author" content="omnitalk"></meta>
				<meta property="og:type" content="website" />
			</Helmet>

			<section className="max-w-[1200px] mx-auto px-[5%] min-h-[70vh]">
				<BreadCrumb current="/notice" />
				<h2 className="xs:mt-7 font-bold lg:text-3xl text-left xs:px-2  xs:text-xl sm:text-2xl sm:px-8 lg:mt-10 dark:text-white">
					Notice
				</h2>
				<p className=" xs:px-2  xs:text-sm sm:px-8 text-left text-gray-700  md:mb-10 lg:mb-[80px] md:text-lg sm:text-md dark:text-white">
					{t('notice.desc')}
				</p>
				<div>
					{notices !== null && openEditor === false ? (
						<NoticeTable
							notices={notices}
							handlePageChange={handlePageChange}
							currentPage={currentPage}
							totalPages={totalPages}
						/>
					) : null}
					{/* -------------------for admin */}
				</div>

				{role === 'admin' && openEditor === false ? (
					<div className="flex items-center max-w-3xl m-auto mt-10 justify-end">
						<button
							className="bg-main-50 p-3 text-white font-medium text-sm lg:text-base rounded-sm"
							type="button"
							onClick={() => {
								setOpenEditor(prev => !prev);
							}}
						>
							글쓰기
						</button>
					</div>
				) : null}
				{role === 'admin' && openEditor === true ? (
					<>
						<div className=" max-w-2xl m-auto mt-10">
							<TextEditor
								type="notice"
								isPatching={false}
								editorRef={editorRef}
								onChange={onChange}
								onSelect={onSelect}
								onClose={onOffEditor}
								isValidErrOn={isValidErrOn}
								onUploadImage={onUploadImage}
							/>
						</div>
						<div className="flex items-center max-w-2xl m-auto mt-10 justify-end ">
							<input
								id="sendEmail"
								className="bg-main-50 p-3 mr-2 text-white font-medium text-sm lg:text-base rounded-sm"
								type="checkbox"
								checked={sendEmail}
								onChange={onChangeSendEmail}
							/>
							<label htmlFor="sendEmail">이메일 발송</label>
							<button
								className="bg-main-50 p-3 ml-5 text-white font-medium text-sm lg:text-base rounded-sm"
								type="button"
								onClick={() => {
									sendReq(prev => !prev);
								}}
							>
								글등록하기
							</button>
							{loading && <Loading />}
						</div>
					</>
				) : null}
			</section>
		</>
	);
}
