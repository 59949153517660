import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import {
	defaultFlexCenter,
	palette,
	fontSize,
	fontWeight,
} from '../../modules/defines/style';

export default function CDRTab() {
	const location = useLocation();
	const navigate = useNavigate();
	// eslint-disable-next-line no-unused-vars
	const [menu, setMenu] = useState('RealTime');
	const [tab, setTab] = useState(false);
	return (
		<StyledTab>
			<div className="menu_box mobile">
				<button
					type="button"
					onClick={() => {
						setTab(prev => !prev);
					}}
				>
					{tab ? (
						<IoIosArrowUp
							style={{
								fontSize: '1.5rem',
								marginLeft: '10px',
								position: 'absolute',
								bottom: '8px',
								right: '20px',
							}}
						/>
					) : (
						<IoIosArrowDown
							style={{
								fontSize: '1.5rem',
								marginLeft: '10px',
								position: 'absolute',
								bottom: '8px',
								right: '20px',
							}}
						/>
					)}
					{location.pathname === '/console/admin/cdr' ||
					location.pathname === '/console/admin/cdr/realtime' ||
					location.pathname === '/console/billing/realtime'
						? 'RealTime'
						: location.pathname === '/console/admin/cdr/daily' ||
						  location.pathname === '/console/billing/daily'
						? 'Daily'
						: null}
				</button>
			</div>
			{tab && (
				<>
					<ul className="mobile">
						<li
							className={`${
								location.pathname === '/console/admin/cdr/realtime' ||
								location.pathname === '/console/billing/realtime'
									? 'active_tab'
									: ''
							}`}
						>
							<button
								className={`${
									location.pathname === '/console/admin/cdr/realtime' ||
									location.pathname === '/console/billing/realtime'
										? 'active'
										: ''
								}`}
								type="button"
								onClick={() => {
									if (location.pathname === '/console/admin/cdr/daily') {
										navigate('/console/admin/cdr/realtime');
									}
									if (location.pathname === '/console/billing/daily') {
										navigate('/console/billing/realtime');
									}
									setMenu('RealTime');
									setTab(false);
								}}
							>
								Real Time
							</button>
						</li>
						<li
							className={`${
								location.pathname === '/console/admin/cdr/daily' ||
								location.pathname === '/console/billing/daily'
									? 'active_tab'
									: ''
							}`}
						>
							<button
								className={`${
									location.pathname === '/console/admin/cdr/daily' ||
									location.pathname === '/console/billing/daily'
										? 'active'
										: ''
								}`}
								type="button"
								onClick={() => {
									if (location.pathname === '/console/admin/cdr/realtime') {
										navigate('/console/admin/cdr/daily');
									}
									if (location.pathname === '/console/billing/realtime') {
										navigate('/console/billing/daily');
									}
									setMenu('Daily');
									setTab(false);
								}}
							>
								Daily
							</button>
						</li>
					</ul>
				</>
			)}
			<ul className="web">
				<li
					className={`${
						location.pathname === '/console/admin/cdr/realtime' ||
						location.pathname === '/console/billing/realtime'
							? 'active_tab'
							: ''
					}`}
				>
					<button
						className={`${
							location.pathname === '/console/admin/cdr/realtime' ||
							location.pathname === '/console/billing/realtime'
								? 'active'
								: ''
						}`}
						type="button"
						onClick={() => {
							if (location.pathname === '/console/admin/cdr/daily') {
								navigate('/console/admin/cdr/realtime');
							}
							if (location.pathname === '/console/billing/daily') {
								navigate('/console/billing/realtime');
							}
						}}
					>
						실시간
					</button>
				</li>

				<li
					className={`${
						location.pathname === '/console/admin/cdr/daily' ||
						location.pathname === '/console/billing/daily'
							? 'active_tab'
							: ''
					}`}
				>
					<button
						className={`${
							location.pathname === '/console/admin/cdr/daily' ||
							location.pathname === '/console/billing/daily'
								? 'active'
								: ''
						}`}
						type="button"
						onClick={() => {
							if (location.pathname === '/console/admin/cdr/realtime') {
								navigate('/console/admin/cdr/daily');
							}
							if (location.pathname === '/console/billing/realtime') {
								navigate('/console/billing/daily');
							}
						}}
					>
						일별
					</button>
				</li>
			</ul>
		</StyledTab>
	);
}

const StyledTab = styled.div`
margin-bottom: 20px;
.mobile{
  display: none;
}
.web{

  position: relative;
  ${defaultFlexCenter}
  justify-content: flex-start;
  li{
    height: 34px;
    ${defaultFlexCenter}
    border-radius: 5px;
    margin-right: 10px;
    button{
      width: 100%;
      color: #a99585;
      border: 0;
      background-color: ${palette.opacity};
      :hover{
        cursor: pointer;
      }
    }
  }
  li:last-child{
    margin-right: 20px;
  }
  .active_tab{
    background-color: ${palette.main.bright};
    button{
      width: 100%;
      font-size: ${fontSize.small};
      font-weight: ${fontWeight.semiBold};
      color: #fff;
	  padding: 1rem;
    }
  }
  .active_tab:last-child{
  
  }
}

`;