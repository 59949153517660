import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import Pagination from 'react-js-pagination';
import { StyledPagination } from '../../modules/defines/style';
import { useTranslation } from 'react-i18next';

export default function NoticeTable({
	notices,
	currentPage,
	totalPages,
	handlePageChange,
}) {
	const { t } = useTranslation();
	return (
		<>
			{/* ----test */}
			<div className="px-8  max-w-[960px] w-full   ml-auto mr-auto mt-10 mb-20  ">
				<div className="table w-full     text-sm text-left text-gray-700 dark:text-white border-t-black border-t-2 ">
					<div className="table-header-group text-sm lg:text-base">
						<div className="table-row font-semibold text-center h-12 p-4 border-t-2 border-t-black ">
							<div className="table-cell align-middle border-b-black dark:border-b-gray-500 border-b-2">
								{t('notice.num')}
							</div>
							<div className="table-cell align-middle border-b-black dark:border-b-gray-500 border-b-2">
								{t('notice.category')}
							</div>
							<div className="table-cell align-middle border-b-black  dark:border-b-gray-500 border-b-2">
								{t('notice.title')}
							</div>
							<div className="table-cell align-middle border-b-black dark:border-b-gray-500 border-b-2">
								{t('notice.regDate')}
							</div>
						</div>
					</div>
					<div className="table-row-group ">
						{notices.map((notice, index) => {
							//* * todo mouse cursor pointer */
							const noticeLink = `/notice/${notice.idx}`; // Replace with the appropriate link for each notice
							return (
								<Link
									state={notice}
									to={noticeLink}
									key={index}
									className="table-row font-normal  text-sm lg:text-base text-black  dark:text-white text-center cursor-pointer w-fit  border-t-black-2 h-12 hover:bg-gray-50 dark:hover:text-gray-600 "
								>
									<div className="table-cell align-middle px-6 py-4 border-b border-gray-200  dark:border-slate-800 cursor-pointer">
										{notice.idx}
									</div>
									<div className="table-cell align-middle px-6 py-4 border-b border-gray-200 dark:border-slate-800 cursor-pointer">
										{notice.category}
									</div>
									<div className="table-cell align-middle px-6 py-4 border-b border-gray-200 dark:border-slate-800 cursor-pointer">
										{notice.title}
									</div>
									<div className="table-cell align-middle px-6 py-4 border-b border-gray-200 dark:border-slate-800 cursor-pointer">
										{moment(notice.reg_date * 1000).format('YYYY.MM.DD')}
									</div>
								</Link>
							);
						})}
					</div>
				</div>
			</div>
			{/* ------ */}
			<StyledPagination>
				<Pagination
					activePage={currentPage}
					itemsCountPerPage={10}
					totalItemsCount={totalPages}
					pageRangeDisplayed={10}
					prevPageText="‹"
					nextPageText="›"
					onChange={handlePageChange}
				/>
			</StyledPagination>
		</>
	);
}
