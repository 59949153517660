import { useState, useEffect } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import {  useSetRecoilState } from 'recoil';
import Pagination from 'react-js-pagination';
import SoftTab from '../../components/admin/SoftTab';
import TableList from '../../components/admin/TableList';
import Button from '../../components/admin/Button';
import FormCheck from '../../components/admin/FormCheck';
import { StyledDiv, StyledPagination } from '../../modules/defines/style';
import myAxios from '../../modules/api/myAxios';
import { activeType, expiredType } from '../../modules/defines/enums';
import { roleState } from '../../recoil';
import Popup, { PopupFormRow } from '../../components/ui/Popup';
import PopupLoading from '../../components/PopupLoading';

// import ErrorMS from 'components/common/ErrorMS';

const FormRadioWrap = styled.div`
	display: flex;
	label {
		display: flex;
		align-items: center;
	}
`;
// TODO: 권한이 없는 유저는 돌려보낼 것 , pagination, error msg, 수정 삭제 후, 안내멘트 + setcategory
const AdminVoucher = () => {
  const navigate = useNavigate();
  const setRole = useSetRecoilState(roleState);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const TabData = [
    { text: '전체', value: 0 },
    { text: '미승인', value: 1 },
    { text: '승인', value: 2 },
  ];
  // eslint-disable-next-line no-unused-vars
  const [errorMS, setErrorMS] = useState({ result:'success', reason: null });
  const [data, setData] = useState([]);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [popupState, setPopupState] = useState({
    display: false,
    data: null,
  });
  const [confirm, setConfirm] = useState({
    display: false,
    // type: null,
  });
  const [formValues, setFormValues] = useState({
    idx: '',
    credit: '',
    status: 0,
    memo: '',
    support: '',
    agency_name: '',
  });
  const requestData = async() => {
    try {
      const response = await myAxios.get(`/superadmin/voucher/?page=${currentPage}&perPage=10`);
      if (response.data.result === 'success') {
        setData(response.data.data);
        setTotalPages(response.data.total);
      }
    } catch (error) {
      console.log(error); 
    } 
  
  };
  useEffect(() => {
    authUser();
    switch (selectedTabIndex) {
    case 0: 
      requestData(currentPage);
      break;
    case 1: 
      requestUnAuthData(currentPage);
      break;
    case 2: 
      requestAuthData(currentPage);
      break;
    default:
      requestData(currentPage);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTabIndex, currentPage]);
  const authUser = async() => {
    try {
      const response = await myAxios.get('/users/info');
      if (response.data.data.role !== 'admin') {
        navigate('/login', { replace: true });
      } else {
        setRole('admin');
      }
      
    } catch (error) {
      if (error.response.status === 401) {
        navigate('/login', { replace: true });
      }
      return error;
    }
  };
  const requestUnAuthData = async (currentPage) => {
    try {
      const response = await myAxios.get(`/superadmin/voucher/?page=${currentPage}&perPage=10&status=0`);
      if (response.data.result === 'success') {
        setData(response.data.data);
        setTotalPages(response.data.total);

      }
    } catch (error) {
      console.log(error);
    }
  };
  const requestAuthData = async (currentPage) => {
    try {
      const response = await myAxios.get(`/superadmin/voucher/?page=${currentPage}&perPage=10&status=1`);
      if (response.data.result === 'success') {
        setData(response.data.data);
        setTotalPages(response.data.total);

      }
    } catch (error) {
      console.log(error);
    }
  };   

  const handleClickTab = (index) => {
    setSelectedTabIndex(index);
    setCurrentPage(1);
    switch (index) {
    case 0: 
      requestData(1);
      break;
    case 1: 
      requestUnAuthData(1);
      break;
    case 2: 
      requestAuthData(1);
      break;
    default:
      requestData(1);
    }
  };
  const handlePageChange = currentPage => {
    setCurrentPage(currentPage);
  };
  const handleClickRow = (row) => {
    setPopupState(prev => ({ ...prev, type:'rowdata', display: true, data: row }));
    setFormValues({
      voucherId: Number(row.idx),
      credit: Number(row.credit),
      agency: row.agency,
      status: Number(row.status),
      memo: row.memo,
      support: Number(row.support),
      agency_name: row.agency_name
    });
  };
    
  const handleUpdate = async() => {
  
    setIsLoading(true);
    try {
      const response = await myAxios.patch('/superadmin/voucher', { ...formValues });
      if (response.data.result === 'success') {
        setIsLoading(false);
        setPopupState(prev => ({ ...prev, display: false }));
        setErrorMS((prev) => ({ ...prev, result: response.result }));
        switch (selectedTabIndex) {
        case 0: 
          requestData(currentPage);
          break;
        case 1: 
          requestUnAuthData(currentPage);
          break;
        case 2: 
          requestAuthData(currentPage);
          break;
        default:
          requestData(currentPage);
        }
      } else {
        setErrorMS((prev) => ({ ...prev, result: response.result, reason: response.reason }));
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  
  };
    
  const handleDelete = async() => {
    setIsLoading(true);
    try {
      const response = await myAxios.delete(`/superadmin/voucher/${popupState.data.idx}`);
      if (response.data.result === 'success') {
        setIsLoading(false);
        setConfirm(false);
        setPopupState(prev => ({ ...prev, display: false }));
        switch (selectedTabIndex) {
        case 0: 
          requestData(currentPage);
          break;
        case 1: 
          requestUnAuthData(currentPage);
          break;
        case 2: 
          requestAuthData(currentPage);
          break;
        default:
          requestData(currentPage);
        }
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
    
  };
    
  const handleClosePopup = () => {
    setPopupState(prev => ({ ...prev, display: false }));
  };
    
  const handleRadioChange = (name, value) => {
    setFormValues(prev => ({ ...prev, [name]: Number(value) }));
  };
    
  const handleChange = (e) => {
    // if (e.target.value === '') {
    //   setFormValues(prev => ({ ...prev, [e.target.name]: popupState.data[e.target.name] }));
    // } else {
    //   setFormValues(prev => ({ ...prev, [e.target.name]: e.target.value }));
    // }
    if (e.target.name === 'support' || e.target.name === 'credit') {
      setFormValues(prev => ({ ...prev, [e.target.name]: Number(e.target.value) }));
    } else {
      setFormValues(prev => ({ ...prev, [e.target.name]: e.target.value }));
    }
  };
    
  return (
    <>
      
      <StyledDiv>
        <section>
          <div className="title">
            <h3>Voucher</h3>
          </div>
          <SoftTab list={TabData} selectedIndex={selectedTabIndex} onClick={handleClickTab} />
          <TableList
            data={[
              { width: '25% ', title: '등록일', type: 'element', element: (props) => moment(props.reg_date * 1000).format('YYYY-MM-DD HH:mm') },
              { width: '25% ', title: '기업명', type: 'element', element: (props) => props.agency_name },
              { width: '20%', title: '은행', type: 'element', element: (props) => `${props.bank_name}` },
              { width: '20%', title: '예금주', type: 'element', element: (props) => `${props.depositor}` },
              { width: '20%', title: '크레딧', type: 'element', element: (props) => (parseInt(props.credit, 10) + parseInt(props.support, 10)).toLocaleString() },
              { width: '20%', title: '이용요금', type: 'element', element: (props) => (parseInt(props.credit_usage, 10).toLocaleString()) },
              { width: '20%', title: '상태', type: 'element', element: (props) => expiredType[props.expired] },
              { width: '20%', title: '승인 여부', type: 'element', element: (props) => activeType[props.status] },
            ]}
            lists={data}
            onClick={handleClickRow}
          />
          <StyledPagination>
            <Pagination    
              activePage={currentPage}
              itemsCountPerPage={10}
              totalItemsCount={totalPages}
              pageRangeDisplayed={5}
              prevPageText="‹"
              nextPageText="›"
              onChange={handlePageChange}
            />
          </StyledPagination>
          {popupState.display && popupState.data && (
            <Popup title="상세 보기" onClose={(handleClosePopup)} size="md">
              {isLoading && (
                <PopupLoading />
              )}
              <PopupFormRow title="승인 여부">
                <div className="row-content">
                  <FormRadioWrap>
                    <FormCheck theme="radio" name="status" value="1" checked={formValues.status === 1} onChange={handleRadioChange}>
                      승인
                    </FormCheck>
                    <FormCheck theme="radio" name="status" value="0" checked={formValues.status === 0} onChange={handleRadioChange}>
                      미승인
                    </FormCheck>
                  </FormRadioWrap>
                </div>
              </PopupFormRow>
              <PopupFormRow title="상태">
                <div className="row-content">
                  {formValues.expired === 1 ? '만료' : '정상'}
                </div>
              </PopupFormRow>
              <PopupFormRow title="사용량">
                <div className="row-content">
                  {parseInt(popupState.data.credit_usage, 10).toLocaleString()}원
                </div>
              </PopupFormRow>
              <PopupFormRow title="등록일">
                <div className="row-content">
                  {moment(popupState.data.reg_date * 1000).format('YYYY-MM-DD HH:mm')}
                </div>
              </PopupFormRow>
              <PopupFormRow title="승인일">
                <div className="row-content">
                  {popupState.data.confirm_date === null ? '미승인' : moment(popupState.data.confirm_date * 1000).format('YYYY-MM-DD HH:mm')}
                </div>
              </PopupFormRow>
              <PopupFormRow title="크레딧">
                <div className="row-content">
                  <input
                    type="tel" name="credit" value={formValues.credit}
                    placeholder={popupState.data.credit}
                    onChange={handleChange} maxLength="30" />
                  <span className="unit">원</span>
                </div>
              </PopupFormRow>
              <PopupFormRow title="서포트">
                <div className="row-content">
                  <input
                    type="tel" name="support" value={formValues.support}
                    placeholder={popupState.data.support}
                    onChange={handleChange} maxLength="30" />
                  <span className="unit">원</span>
                </div>
              </PopupFormRow>
              <PopupFormRow title="메모">
                <div className="row-content" style={{ width: '200px', marginLeft: '0px' }}>
                  <input
                    type="text" name="memo" value={formValues.memo === null ? '' : formValues.memo}
                    placeholder={popupState.data.memo}
                    onChange={handleChange} maxLength="100" />
                </div>
              </PopupFormRow>
              <PopupFormRow title="은행 | 예금주">
                <div className="row-content">
                  {`${popupState.data.bank_name} | ${popupState.data.depositor}`}
                </div>
              </PopupFormRow>
              <PopupFormRow>
                <div className="row-two-btn">
                  <Button
                    disabled={popupState.data.status === formValues.status && popupState.data.credit === formValues.credit && popupState.data.memo === formValues.memo && popupState.data.support === formValues.support}
                    onClick={handleUpdate}>업데이트
                  </Button>
                  <Button color="blue" onClick={() => setConfirm(prev => ({ ...prev, display: true }))}>바우처 삭제</Button>
                </div>
              </PopupFormRow>
            </Popup>
          )}
    
          {confirm.display && (
            <Popup theme="confirm" onClose={() => setConfirm(prev => ({ ...prev, display: false }))}>
              <p>정말 삭제하시겠습니까?</p>
              <div>
                <Button color="red" onClick={handleDelete}>예</Button>
                <Button color="blue" onClick={() => setConfirm(prev => ({ ...prev, display: false }))}>아니오</Button>
              </div>
            </Popup>
          )}
        </section>
      </StyledDiv>
    </>
  );
    
};

export default AdminVoucher;
