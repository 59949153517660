import React, { useState } from 'react';
import styled from 'styled-components';
import { GrClose } from 'react-icons/gr';
import { defaultFlexCenter, fontSize, palette, defaultInput } from '../../modules/defines/style';
import myAxios from '../../modules/api/myAxios';
import PopupLoading from '../PopupLoading';

function ServiceModalModify({ onChildSuccess, selectedData, setModifyModal, t }) {
   
  const [isLoading, setIsLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [formData, setFormData] = useState({
    domain: selectedData.data.domain,
    memo:selectedData.data.memo,
    service: selectedData.data.service,
    serviceIdx: selectedData.data.idx
    // idx: selectedData.data.idx
  });
        
  const handleFormChange = (e) => {
    setFormData(prev => ({ ...prev, [e.target.name]: e.target.value }));
  };
        
  const handleRadioChange = (name) => {
    setFormData(prev => ({ ...prev, [name.target.name]: Number(name.target.value) }));
  };
        
  const handleRequestModify = async() => {
    setErrorMsg('');
    try {
      setIsLoading(true);
      const response = await myAxios.patch('/users/service', formData);
      if (response.data.result === 'success') {
        setIsLoading(false);
        onChildSuccess();
        setModifyModal(false);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      setErrorMsg(t('console.modal.modify.placeholderErr'));
    }
  };
  
  return (
    <StyledModal>
      {isLoading && (
        <PopupLoading />
      )}
      <div className="title">
        <h1>Service ID</h1>
        <button
          type="button" onClick={() => {
            setModifyModal(false);
          }}><GrClose style={{ fontSize:'1.25rem' }} />
        </button>
      </div>
          
      <div className="input_box">
        <label htmlFor="domain">{t('console.modal.add.domain')}</label>
        <input
          type="text" id="domain" name="domain" placeholder="https://" maxLength="30"
          value={formData.domain} onChange={handleFormChange} />
      </div>
      <div className="input_box">
        <label htmlFor="memo">{t('console.modal.add.memo')}</label>
        <input
          type="text" id="memo" name="memo" maxLength="100"
          value={formData.memo} onChange={handleFormChange}
        />
      </div>
      <div className="radio">
        <h3>{t('console.modal.add.radio')}</h3>
        <input type="radio" name="service" value="0" checked={formData.service === 0} onChange={handleRadioChange} />
        <label htmlFor="service">Web</label>
        <input type="radio" name="service" value="1" checked={formData.service === 1} onChange={handleRadioChange} />
        <label htmlFor="service">App</label>
      </div>
      <span className="error">{errorMsg}</span>
      <div className="button">
        { formData.domain === '' || formData.memo === ''
          ? (
            <button
              type="button" onClick={handleRequestModify}
              disabled
              className="disabled"
            >{t('console.modal.modify.button')}
            </button>
          ) : (
            <button
              type="button" onClick={handleRequestModify}
            >{t('console.modal.modify.button')}
            </button>
          )}
      </div>
          
    </StyledModal>
  );
}

export default ServiceModalModify;

const StyledModal = styled.div`
width: 465px;
height: 530px;
padding: 30px;
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%,-50%);
border-radius:10px;
background-color: ${palette.gray.light};
.title{
    ${defaultFlexCenter}
    justify-content: space-between;
    margin-bottom: 20px;
    border-bottom: 1px solid ${palette.gray.middle};
    h1{
    font-size: ${fontSize.medium};
    }
    button{
        border: 0;
        background-color: ${palette.opacity};
    }
}
.input_box{
    width: 100%;
    margin-bottom: 30px;
    label{
        display: inline-block;
        width: 100%;
        line-height: 40px;
    }
    input{
        ${defaultInput}
        width: 100%;
    }
}
.radio{
    margin-bottom: 30px;
    label{
        margin-right: 20px;
    }
    input:hover{
        cursor: pointer;
    }
}
.error{
  display: flex;
  justify-content: center;
        color: red;
        font-weight: 500;
        text-align: center;
        margin: 0 auto;
        margin-bottom: 10px;
      }
.button{
    ${defaultFlexCenter}
    button{
        width: 164px;
        height: 42px;
        background-color: ${palette.main.default};
        border: 0;
        border-radius: 5px;
        color: ${palette.white};
        :hover{
          cursor: pointer;
        }
    }
        .disabled{
        width: 164px;
        height: 42px;
        border-radius: 5px;
        background-color: rgba(255,83,29,0.5);
        font-size: 1rem;
        color: white;
        }
}

@media screen and (max-width:579px) and (min-width:230px) {
width: 90%;
}
`;
