import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import BreadCrumb from '../BreadCrumb';
import { Helmet } from 'react-helmet-async';
import iconPlusTab from '../../assets/icon_plus_tab.svg';
const Item = ({ title, children }) => {
	const [isOpen, setIsOpen] = useState(false);

	return (
		<div className="border-b ">
			<button
				type="button"
				aria-label="Open item"
				title="Open item"
				className="flex items-center justify-between w-full focus:outline-none"
				onClick={() => setIsOpen(!isOpen)}
			>
				<div className="flex items-center">
					<img className="w-4 h-4" src={iconPlusTab} alt="더보기" />
					<p className="p-2 xs:text-sm sm:text-base md:text-large font-medium dark:text-white lg:text-lg">
						{title}
					</p>
				</div>

				<svg
					viewBox="0 0 24 24"
					className={`w-3 text-gray-600 transform transition-transform duration-200 dark:text-white ${
						isOpen ? 'rotate-180' : ''
					}`}
				>
					<polyline
						fill="none"
						stroke="currentColor"
						strokeWidth="2"
						strokeLinecap="round"
						strokeMiterlimit="10"
						points="2,7 12,17 22,7"
						strokeLinejoin="round"
					/>
				</svg>
			</button>
			{isOpen && (
				<div className="p-4 pt-0">
					<p className="text-gray-700 text-sm md:text-large dark:text-white lg:text-base ">
						{children}
					</p>
				</div>
			)}
		</div>
	);
};
//* *todo translation */
export const FAQ = () => {
  const { t } = useTranslation();
  const data = t('community.faq.question', { returnObjects: true });
    
  return (
    <>
       <Helmet>
        <title>{t('helmet.faq.title')}</title>
		<meta charset="utf-8" />
		<meta name="robots" content="index" />
 		<meta name="title" content={t('helmet.faq.title')}></meta>
        <meta name="author" content="omnitalk"></meta>
        <meta name="keywords" content="WebRTC, SIP, CPAAS, communication, cloud-based, integration, solution, provider,WebRTC CPAAS,SIP CPaaS,WebRTC SIP integration,Cloud-based WebRTC,Cloud-based SIP,WebRTC and SIP solution,WebRTC and SIP provider,옴니톡,옴니스토리,비대면통신,커뮤니케이션플랫폼,메타버스,통신,통신서비스" />
        <meta name="description" content={t('helmet.faq.desc')} />
        <meta property="og:title" content={t('helmet.faq.ogTitle')} />
        <meta property="og:description" content={t('helmet.faq.ogDesc')} />
		<meta property="og:type" content="website" />
			  <meta property="og:url" content="https://omnitalk.io/faq" />
				<link rel="canonical" href="https://omnitalk.io/faq/" />
        <meta property="twitter:title" content={t('helmet.faq.twitterTitle')} />
        <meta property="twitter:card" content={t('helmet.faq.twitterCard')} />
        <meta property="twitter:description" content={t('helmet.faq.twitterDesc')} />
      </Helmet>
      <section className="max-w-[1200px] mx-auto px-[5%] min-h-[70vh]">
      <BreadCrumb />
           
            <h2 className="xs:mt-7 font-bold lg:text-3xl text-left xs:px-2  xs:text-xl sm:text-2xl sm:px-8 lg:mt-10 dark:text-white">FAQ</h2> 
              <p className=" xs:px-2  xs:text-sm sm:px-8 text-left text-gray-700  md:mb-10 lg:mb-[80px] md:text-lg sm:text-md  dark:text-white">
               {t('faq.desc')}
              </p>
          <div className="px-16 mb-20  text-sm lg:text-base">
            {data.map((data,index) => {
              return (
                  <Item  key={index} title={`${data.title}`}>
                    A.&nbsp;{data.text}<br/> {data.text2}<br/>{data.text3}
                  </Item>
              );
            })}
			  </div>
      </section>
    </>
  );
};