import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import styled from 'styled-components';
import Pagination from 'react-js-pagination';
import { Helmet } from 'react-helmet-async';
import RecordingView from '../../components/Service/RecordingView';
import DeleteModal from '../../components/ui/DeleteModal';
import API from '../../modules/api/api';
import {
	defaultFlexCenter,
	fontSize,
	fontWeight,
	palette,
	StyledPagination,
} from '../../modules/defines/style';
import myAxios from '../../modules/api/myAxios';
import i18n from '../../i18n';

const deleteState = {
	display: false,
	type: '', // delete | add
	data: {},
};
function Recording() {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [format, setFormat] = useState('');

	useEffect(() => {
		i18n.changeLanguage(navigator.language.slice(0, 2)).then(() => {
			moment.locale(i18n.language);
		});
	}, []);

	useEffect(() => {
		setTimeout(() => {
			switch (i18n.language) {
				case 'ko':
					setFormat(moment(data.reg_date * 1000).format('YYYY-MM-DD'));
					break;
				case 'en':
					setFormat(moment(data.reg_date * 1000).format('MM/DD/YYYY'));
					break;
				case 'ja':
					setFormat(moment(data.reg_date * 1000).format('YYYY年MM月DD日'));
					break;
				default:
					setFormat('');
			}
		}, 200);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [i18n.language]);

	const [data, setData] = useState([]);
	const [deleteModal, setDeleteModal] = useState(deleteState);
	const [currentPage, setCurrentPage] = useState(1);
	const [totalPages, setTotalPages] = useState(0);
	const handlePageChange = currentPage => {
		setCurrentPage(currentPage);
	};
	useEffect(() => {
		authUser();
		if (data.length === 0) {
			requestData();
		}
		setDeleteModal(deleteState);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleDelete = idx => {
		setDeleteModal({
			display: true,
			type: 'delete',
			idx,
		});
	};

	const authUser = () => {
		API.getInfo()
			.then(response => {
				if (response.status === 0) {
					navigate('/wait');
				}
			})
			.catch(error => {
				console.log(error);
				navigate('/');
			});
	};

	// get data

	const handleModalConfirm = () => {
		if (deleteModal.type === 'delete') {
			myAxios.delete(`/users/recording/${deleteModal.idx}`).then(
				response => {
					if (response.status === 200) {
						setDeleteModal(false);
						requestData();
					}
				},
				error => {
					console.log(error);
				},
			);
		}
	};

	// get data
	const requestData = () => {
		myAxios.get(`/users/recording?page=${currentPage}&perPage=10`);
		API.getRecordList({}).then(
			response => {
				setData(response.data.data);
				setTotalPages(response.data.total);
			},
			error => {
				console.log(error);
			},
		);
	};

	return (
		<>
			<Helmet>
				<title>{t('helmet.recording.title')}</title>
				<meta charset="utf-8" />
				<meta
					name="keywords"
					content="WebRTC, SIP, CPAAS, communication, cloud-based, integration, solution, provider,WebRTC CPAAS,SIP CPaaS,WebRTC SIP integration,Cloud-based WebRTC,Cloud-based SIP,WebRTC and SIP solution,WebRTC and SIP provider,옴니톡,옴니스토리,비대면통신,커뮤니케이션플랫폼,메타버스,통신,통신서비스"
				/>
				<meta name="description" content={t('helmet.recording.desc')} />
				<meta property="og:title" content={t('helmet.recording.title')} />
				<meta
					property="og:description"
					content={t('helmet.recording.ogTitle')}
				/>
				<meta property="og:site_name" content={t('helmet.recording.ogSite')} />
				<meta
					property="twitter:title"
					content={t('helmet.recording.twitterTitle')}
				/>
				<meta
					property="twitter:description"
					content={t('helmet.recording.twitterDesc')}
				/>
				<meta name="robots" content="noindex" />
			</Helmet>
			<StyledCard>
				<section>
					<div className="recording-title">
						<h3>Recording</h3>
					</div>
					<article>
						{data.length === 0 ? (
							<>
								<ul className="">
									<li>Date</li>
									<li>User</li>
									<li>Audio</li>
									<li>Video</li>
									<li> </li>
								</ul>
							</>
						) : (
							<>
								<ul className="modile_display_none">
									<li>Date</li>
									<li>User</li>
									<li>Audio</li>
									<li>Video</li>
									<li> </li>
								</ul>

								{data.map((list, i) => {
									return (
										<RecordingView
											key={i}
											data={list}
											handleDelete={handleDelete}
											i18n={i18n}
											format={format}
										/>
									);
								})}

								{deleteModal.display === true && (
									<StyledModal>
										<DeleteModal
											handleDelete={handleModalConfirm}
											setDeleteModal={setDeleteModal}
											text="Recording"
										/>
									</StyledModal>
								)}
								<StyledPagination>
									<Pagination
										activePage={currentPage}
										itemsCountPerPage={10}
										totalItemsCount={totalPages}
										pageRangeDisplayed={5}
										prevPageText="‹"
										nextPageText="›"
										onChange={handlePageChange}
									/>
								</StyledPagination>
							</>
						)}
					</article>
				</section>
			</StyledCard>
		</>
	);
}

export default Recording;

const StyledCard = styled.div`
width: 100%;
padding-top: 120px;
section{
  min-height: 800px;
    padding-left: 240px;
    button{
      cursor: pointer;
    }
    button:hover{
      color: ${palette.main.default};
    }
    .recording-title{
    width: 100%;
    margin-bottom: 50px;
    border-bottom: 1px solid ${palette.gray.middle};
    h3{
      margin-bottom: 20px;
      font-size: 1.75rem;
      font-weight: ${fontWeight.semiBold};
    }
  }
  article{
    background-color: ${palette.gray.light};
    ul{
      width: 100%;
      height: 60px;
    ${defaultFlexCenter}
    justify-content: space-between;
    border-top: 1px solid ${palette.gray.middle};
    border-bottom: 1px solid ${palette.gray.middle};
      li{
        width: 25%;
        font-size: ${fontSize.small};
        font-weight: ${fontWeight.medium};
        text-align: center;
      }
      li:last-child{
        width: 40px;
     }
    }
    margin-bottom: 60px;
  }
}
@media screen and (max-width:1200px) and (min-width:980px) {
padding: 120px 5% 0 0;
}

@media screen and (max-width:979px) and (min-width: 580px) {
  section{
    padding: 40px 5% 0 5%;
  }
  
}

@media screen and (max-width:579px) and (min-width: 230px) {
  padding-top: 80px;
  section{
    padding: 0 5%;
    .title{
    width: 100%;
    margin-bottom: 0;
    border: none;
    h3{
      font-size: 1.5rem;
      margin-bottom: 30px;
      font-weight: ${fontWeight.medium};
    }
  }
    article{
      background-color: ${palette.opacity};
    }
  }
  .modile_display_none{
    display: none;
  }
}
`;

const StyledModal = styled.div`
width: 100%;
height: 100%;
position: fixed;
top: 0;
left: 0;
background-color: rgba(0,0,0,0.4);
z-index: 100;
`;