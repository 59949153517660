import React from 'react';
import { useLocation } from 'react-router-dom';
import Dropdown from './DropDown';

export default function BreadCrumb({ currentURL, languageCrumb }) {
  const location = useLocation();
  const urlString = location.pathname.split('/');
  const firstCrumb = urlString[1] === 'notice' || urlString[1] === 'faq' ? 'Community' : 'Developer';
  const secondCrumb = urlString[1].replace(/^[a-z]/, char => char.toUpperCase());
  
  const tohref = `/${secondCrumb.replace(/^[A-Z]/, char => char.toLowerCase())}`;
  return (
    <>
      <nav className="flex mt-10 mx-auto max-w-7xl p-6  xs:p-0 xs:px-2 xs:mt-0 md:mt-5 lg:mt-10 sm:px-0 lg:px-8" aria-label="Breadcrumb">
        <ol className="inline-flex items-center space-x-1 md:space-x-3">
          <li className="inline-flex items-center">
            <a href="/" className="inline-flex items-center text-[15px] lg:text-base font-medium text-gray-700 hover:text-main-50  dark:text-white">
              <svg aria-hidden="true" className="w-4 h-4 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z" /></svg>
              Home
            </a>
          </li>
          <li>
            <div className="flex items-center">
              <svg aria-hidden="true" className="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" /></svg>
              <span className="ml-1 text-[15px] lg:text-base font-medium text-gray-700 dark:text-white ">{firstCrumb}</span>
            </div>
          </li>
          {currentURL === undefined ? (  
            <li aria-current="page">
              <div className="flex items-center">
                <svg aria-hidden="true" className="w-6 h-6 text-gray-400 " fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" /></svg>
                <a href={tohref} className="ml-1 text-[15px] lg:text-base font-bold text-gray-700 md:ml-2 hover:text-main-50 dark:text-white ">{secondCrumb === 'Faq' ? 'FAQ' : secondCrumb}</a>
              </div>
            </li>
          )
            : (
              <>
                <li aria-current="page">
                  <div className="flex items-center">
                    <svg aria-hidden="true" className="w-6 h-6 text-gray-400 " fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" /></svg>
                    <a href={tohref} className="ml-1 text-[15px] lg:text-base font-medium text-gray-700 md:ml-2 hover:text-main-50 ">{secondCrumb}</a>
                  </div>
                </li>
                <li aria-current="page">
                  <div className="flex items-center">
                    <svg aria-hidden="true" className="w-6 h-6 text-gray-400 " fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" /></svg>
                    <span className="ml-1 text-[15px] lg:text-base font-bold text-gray-700 md:ml-2  ">{languageCrumb}</span>
                    <Dropdown />
                  </div>
                </li>
                <li aria-current="page">
                  <div className="flex items-center" />
                </li>
              </>
            )}
          
        </ol>
      </nav>
    </>
  );
}
