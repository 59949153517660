import { TRACK_TYPE } from "../public-types/common";
import { IceCandidate } from "../types/common";
import { IResponsePublish } from "../types/response";
import OmniWebsocket from "../websocket/websocket-manager";
import WebRTCCommon from "./webrtc-common";

export default class ClientAudio extends WebRTCCommon {
    public AUDIO_TAG?: HTMLAudioElement;
    private audioInputChoiceId?: string;
    private audioStream?: MediaStream;

    constructor(ws: OmniWebsocket, session: string, turn_id: string, turn_secret: string) {
        super(ws, session, TRACK_TYPE.AUDIO, turn_id, turn_secret);
        this.trickleHandler();
    }

    async publish(): Promise<IResponsePublish> {
        return new Promise(async (resolve, reject) => {
            try {
                const stream = await this.makeAudio(true);
                if (stream) {
                    this.audioStream = stream;
                    this.audioStream.getTracks().forEach(async track => {
                        this.pc.addTrack(track, stream);
                      });
                }
                this.pc.ontrack = e => {
                    this.AUDIO_TAG!.srcObject = e.streams[0];
                }
                const offer = await this.createOffer(false, true);
                await this.setLocalDescription(offer);
                const publishResult = await this.ws.requestPublish(TRACK_TYPE.AUDIO, offer);
                await this.setRemoteDescription(publishResult.jsep);
                resolve(publishResult);
            } catch (err) {
                reject(err);
            }
        });
    }

    async makeAudio(getMedia: boolean): Promise<MediaStream | void> {
        return new Promise(async (resolve, reject) => {
            try {
                if (!this.AUDIO_TAG) {
                    let audioTag = document.createElement("AUDIO") as HTMLAudioElement;
                    audioTag.id = "Omnitalk-Audio-0";
                    audioTag.setAttribute("playsinline", "");
                    audioTag.setAttribute("autoplay", "");
                    audioTag.style.display ='none';
                    audioTag.volume = 0.8;
                    document.body.appendChild(audioTag);
                    this.AUDIO_TAG = audioTag;
                }
                if (getMedia) {
                    const constraints = { 
                        video: false,
                        audio: {
                            deviceId: this.audioInputChoiceId ? { exact: this.audioInputChoiceId } : 'default',
                            autoGainControl: false,
                            echoCancellation: true,
                            noiseSuppression: true,
                            latency: 0.01,
                            channelCount: 2
                        } 
                    };
                    const stream = await navigator.mediaDevices.getUserMedia(constraints);
                    resolve(stream)
                }
                resolve();
            } catch (err) {
                reject(err)
            }
        });
	}

    async setAudioDevice(deviceId: string): Promise<void> {
        return new Promise(async (resolve, reject) => {
            try {
                if (!this.audioStream) {
                    this.audioInputChoiceId = deviceId;
                } else {
                    const constraints = { 
                        video: false, 
                        audio: {
                            deviceId: { exact: deviceId },
                            autoGainControl: false,
                            echoCancellation: true,
                            noiseSuppression: true,
                            latency: 0.01,
                            channelCount: 2
                        }
                    };
                    const stream = await navigator.mediaDevices.getUserMedia(constraints);
                    if (this.audioStream) {
                        this.audioStream.getTracks().forEach(track => {
                            track.stop();
                        });
                    }
                    this.audioStream = stream;
                    if (this.pc) {
                        const [audioTrack] = stream.getAudioTracks();
                        const senders = this.pc.getSenders();
                        senders.forEach(async sender => {
                            let track = sender.track;
                            if(track?.kind === 'audio') {
                                sender.replaceTrack(audioTrack);
                            }
                        });
                    }
                }
                resolve();
            } catch (err) {
                reject(err);
            }
        });
	}

    freeResources() {
        this.closePeerConnection();
        this.stopMediaStream(this.audioStream)
    }
    
}