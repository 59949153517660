export enum CALL_STATE {
    NULL_STATE = "NULL_STATE",
	SESSION_STATE = "SESSION_STATE", // create session
	ACTIVE_STATE = "ACTIVE_STATE",  // join room
	PUBLISH_STATE = "PUBLISH_STATE", // start publish
	SUBSCRIBE_STATE = "SUBSCRIBE_STATE", // start subscribe
	CONNECT_STATE = "CONNECT_STATE", // success video publish
}

export enum REQ_CMD {
    CREATE_SESSION = 'WS_SESSION_REQ',
    SESSION_LIST = 'WS_SESSION_LIST_REQ',
    CREATE_ROOM = 'WS_CREATE_ROOM_REQ',
    DESTROY_ROOM = 'WS_DESTROY_ROOM_REQ',
    ROOM_LIST = 'WS_ROOM_LIST_REQ',
    JOIN_ROOM = 'WS_JOIN_ROOM_REQ',
    PARTI_LIST = 'WS_PARTI_LIST_REQ',
    PUBLISH_LIST = 'WS_PUBLISH_LIST_REQ',
    SCREEN_LIST = 'WS_SCREEN_LIST_REQ',
    PUBLISH = 'WS_PUBLISH_REQ',
    SCREEN_SHARE = 'WS_SCREEN_SHARE_REQ',
    SCREEN_UNSHARE = 'WS_SCREEN_UNSHARE_REQ',
    SUBSCRIBE = 'WS_SUBSCRIBE_REQ',
    SUBSCRIBE_COMP = 'WS_SUBSCRIBE_COMP',
    UNSUBSCRIBE = 'WS_UNSUBSCRIBE_REQ',
    OFFER_CALL = 'WS_OFFER_CALL_REQ',
    ANSWER_CALL = 'WS_ANSWER_CALL_REQ',

    MAKE_SIPNUM = 'WS_MAKE_SIPNUM_REQ',
    ACCEPT_CALL = 'WS_ACCEPT_CALL_REQ',
    REJECT_CALL = 'WS_REJECT_CALL_REQ',
    MUTE = 'WS_MUTE_REQ',
    UNMUTE = 'WS_UNMUTE_REQ',
    TRICKLE = 'WS_TRICKLE',
    
    RESOLUTION = 'WS_RESOLUTION_REQ',
    RESOLUTION_RPT = 'WS_RESOLUTION_RPT',
    MESSAGE = 'WS_MESSAGE_REQ',

    RES_HEARTBEAT = 'WS_HEARTBEAT_RSP',
    LEAVE = 'WS_LEAVE_REQ',
    KICK_OUT = 'WS_KICKOUT_REQ'
}

export enum RES_CMD {
    CREATE_SESSION = 'WS_SESSION_RSP',
    SESSION_LIST = 'WS_SESSION_LIST_RSP',
    CREATE_ROOM = 'WS_CREATE_ROOM_RSP',
    DESTROY_ROOM = 'WS_DESTROY_ROOM_RSP',
    ROOM_LIST = 'WS_ROOM_LIST_RSP',
    JOIN_ROOM = 'WS_JOIN_ROOM_RSP',
    PARTI_LIST = 'WS_PARTI_LIST_RSP',
    PUBLISH_LIST = 'WS_PUBLISH_LIST_RSP',
    SCREEN_LIST = 'WS_SCREEN_LIST_RSP',
    PUBLISH = 'WS_PUBLISH_RSP',
    SCREEN_SHARE = 'WS_SCREEN_SHARE_RSP',
    SCREEN_UNSHARE = 'WS_SCREEN_UNSHARE_RSP',
    SUBSCRIBE = 'WS_SUBSCRIBE_RSP',
    UNSUBSCRIBE = 'WS_UNSUBSCRIBE_RSP',
    OFFER_CALL = 'WS_OFFER_CALL_RSP',
    ANSWER_CALL = 'WS_ANSWER_CALL_RSP',

    MAKE_SIPNUM = 'WS_MAKE_SIPNUM_RSP',
    ACCEPT_CALL = 'WS_ACCEPT_CALL_RSP',
    REJECT_CALL = 'WS_REJECT_CALL_RSP',
    MUTE = 'WS_MUTE_RSP',
    UNMUTE = 'WS_UNMUTE_RSP',
    RESOLUTION = 'WS_RESOLUTION_RSP',
    MESSAGE = 'WS_MESSAGE_RSP',
    LEAVE = 'WS_LEAVE_RSP',
    KICK_OUT = 'WS_KICKOUT_RSP'
}

export enum EVENT_CMD {
    LEAVE_NOTI = 'WS_LEAVE_NOTI',
    KICKOUT_NOTI = 'WS_KICKOUT_NOTI',
    BROADCAST_NOTI = 'WS_BROADCAST_NOTI',
    RINGBACK_NOTI = 'WS_RINGBACK_NOTI',
    RINGING_NOTI = 'WS_RINGING_NOTI',
    CALL_CONNECTED_NOTI = 'WS_CALL_CONNECTED_NOTI',
    SCREEN_SHARE_NOTI = 'WS_SCREEN_SHARE_NOTI',
    SCREEN_UNSHARE_NOTI = 'WS_SCREEN_UNSHARE_NOTI',
    MUTE_NOTI = 'WS_MUTE_NOTI',
    UNMUTE_NOTI = 'WS_UNMUTE_NOTI',
    MESSAGE = 'WS_MESSAGE_NOTI'
    // DISPLAY_NOTI = 'WS_DISPLAY_NOTI',
    // SIP_CALL_RSP = 'WS_SIP_CALL_RSP',
    // CALL_TRYING = 'WS_CALL_TRYING',
    // AUDIO_MUTE_NOTI = 'WS_AUDIO_MUTE_NOTI',
    // AUDIO_UNMUTE_NOTI = 'WS_AUDIO_UNMUTE_NOTI',
    // VIDEO_MUTE_NOTI = 'WS_VIDEO_MUTE_NOTI',
    // VIDEO_UNMUTE_NOTI = 'WS_VIDEO_UNMUTE_NOTI',
    // CALL_RING_NOTI = 'WS_CALL_RING_NOTI',
    // ACCEPT_CALL_NOTI = 'WS_ACCEPT_CALL_NOTI',
    // REJECT_CALL_NOTI = 'WS_REJECT_CALL_NOTI',
    
    // MESSAGE_NOTI = 'WS_MESSAGE_NOTI'
}

export enum REQUEST_MESSAGE_ACTION {
    LIST = 'list',
    SEND = 'send',
    WHISPER = 'whisper',
}

