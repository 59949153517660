
export interface Room {
    subject: string;
    secret: boolean;
    room_type: VIDEOROOM_TYPE;
    room_id: string;
    count: number;
    start_date: number;
    end_date: number;
    reg_date: number;
    sip_support: boolean;
}

export interface Publisher {
    session: string;
    user_id: string;
    user_name: string;
    call_type: CALL_TYPE;
}

export interface ScreenInfo {
    session: string;
    user_id: string;
    user_name: string;
    call_type: CALL_TYPE;
}

export interface Participant {
    session: string;
    user_id: string;
    user_name: string;
    call_type: CALL_TYPE;
}

export interface SessionInfo {
    state: SESSION_STATE;
    user_id: string;
    call_type: CALL_TYPE
}

export enum TRACK_TYPE {
	AUDIO = 'audio',
	VIDEO = 'video',
}

export enum MESSAGE_ACTION {
    SEND = 'send',
    WHISPER = 'whisper',
    JOIN = 'join',
    LEAVE = 'leave'
}

export interface DeviceList {
    audioinput: Array<MediaDeviceInfo>,
    videoinput: Array<MediaDeviceInfo>
}

export enum VIDEOROOM_TYPE {
    AUDIO_CALL = 'audiocall',
    VIDEO_CALL = 'videocall',
    AUDIO_ROOM = 'audioroom',
    VIDEO_ROOM = 'videoroom',
    WEBINAR = 'webinar',
    SIP_CALL = 'sipcall',
}

export enum CALL_TYPE {
    VIDEO_CALL = 'videocall',
    AUDIO_CALL = 'audiocall',
    SIP_CALL = 'sipcall',
    UNKNOWN = 'unknown' 
}

export enum SESSION_STATE {
    ACTIVE = 'active',
    BUSY = 'busy'
    // DND = 'do not disturb '// todo
}

export enum RESOLUTION {
    QVGA = 'QVGA',
    VGA = 'VGA',
    SD = 'SD',
    HD = 'HD',
    FHD = 'FHD',
    TWO_K = '2K',
    FOUR_K = '4K'
}