import moment from 'moment';
import { useTranslation } from 'react-i18next';
// import { Viewer } from '@toast-ui/react-editor';
import { useRecoilValue } from 'recoil';
import { roleState } from '../../recoil';
import { useEffect, useState } from 'react';

export default function ChangeLogTable({
	selectedLanguage,
	data,
	handleClickRow,
}) {
	const { t } = useTranslation();
	const role = useRecoilValue(roleState);
	const [contents, setContents] = useState('');

	useEffect(() => {
		console.log(data);
		if (data) {
			setContents(data);
		}
		console.log(contents);
	}, [contents, data]);
	return (
		<>
			<div className="max-w-3xl mx-auto">
				<ol className="mt-3 divide-y divider-gray-200 ">
					{data &&
						data.map((data, index) => {
							return (
								<li key={index}>
									<div
										className="items-center block p-3 sm:flex hover:bg-gray-100 "
										onClick={() =>
											role === 'admin' ? handleClickRow(data) : null
										}
									>
										<div className="flex  items-start flex-col w-12 h-12 mb-3 mr-14 rounded-full  sm:mb-0">
											<span className="xs:text-md md:text-lg font-medium">
												{data.version}
											</span>
											<time className="text-gray-500 text-xs">
												{moment(data.reg_date * 1000).format('YYYY.MM.DD')}
											</time>
										</div>
										<div className="text-gray-600 dark:text-gray-400 w-full ">
											<div className="xs:text-sm sm:text-base font-normal flex justify-between flex-col">
												{/* <Viewer initialValue={data.contents}></Viewer> */}
												{data.contents && (
													// <Viewer
													// 	events={['load', 'change']}
													// 	initialValue={contents.contents}
													// />
													<div
														style={{ listStyle: 'disc' }}
														dangerouslySetInnerHTML={{ __html: data.contents }}
													></div>
												)}

												{/* {selectedLanguage === 'All' ? ( */}
												<div className="font-regular text-xs text-right text-gray-500 inline-block ml-auto bg-gray-100 px-1 py-1 rounded-lg">
													# {data.lang}
												</div>
												{/* ) : null} */}
											</div>
										</div>
									</div>
								</li>
							);
						})}
					{data.length === 0 && <> {t('history.nodata')}</>}
				</ol>
			</div>
		</>
	);
}
