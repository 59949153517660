import React from 'react';
import styled from 'styled-components';
import { defaultFlexCenter, fontWeight, palette } from '../../modules/defines/style';
import i18n from '../../i18n';

function DeleteModal({ setDeleteModal, handleDelete, text, t }) {
  return (
    <StyledModal>
      <div className="title">
        {i18n.language === 'en' || navigator.language.slice(0, 2) === 'en'
          ? (
            <>{t('console.modal.del.text')}<span>{text}?</span></>) 
          : (
            <><span>{text}</span>{t('console.modal.del.text')}</>)}
      </div>
      <div className="button">
        <button type="button" onClick={handleDelete}>{t('console.modal.del.delButton')}</button>
        <button type="button" onClick={() => setDeleteModal(false)}>{t('console.modal.del.cancleButton')}</button>
      </div>
    </StyledModal>
  );
}

export default DeleteModal;
const StyledModal = styled.div`
width: 400px;
height: 200px;
padding: 40px;
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%,-50%);
background-color: ${palette.gray.light};
${defaultFlexCenter}
flex-direction: column;
border-radius: 10px;
.title{
    padding-top: 30px;
    border: none;
    span{
        color: ${palette.main.default};
        font-weight: ${fontWeight.semiBold};
    }
}
.button{
    padding-top: 30px;
    button{
        padding: 10px;
        font-weight: ${fontWeight.semiBold};
        border: 0;
        background-color: ${palette.opacity};
        :hover{
            color: ${palette.main.default};
        }
    }
}

@media screen and (max-width: 579px) and (min-width: 230px) {
  width: 90%;

}
`;
