import { Fragment, useState } from 'react';
import { Dialog, Disclosure, Popover, Transition } from '@headlessui/react';
import {
	Bars3Icon,
	XMarkIcon,
	DocumentTextIcon,
	NewspaperIcon,
	QuestionMarkCircleIcon,
	PlayCircleIcon,
	ListBulletIcon,
	ChatBubbleLeftRightIcon,
	WrenchIcon,
	ArrowLeftOnRectangleIcon,
} from '@heroicons/react/24/outline';
import { VscGithubAlt } from 'react-icons/vsc';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import logo from '../assets/omnitalk.svg';
import API from '../modules/api/api';
import ContactPopup from './main/ContactPopup';
import { useRecoilState } from 'recoil';
import { roleState } from '../recoil';
import { useNavigate } from 'react-router-dom';

const developerCategory = [
	{
		name: 'Docs',
		description: 'Omnitalk SDK의 개발 문서를 제공합니다',
		href: '/docs',
		icon: DocumentTextIcon,
	},
	{
		name: 'Demo',
		description: '다양한 데모를 체험해 보세요',
		href: '/demo',
		icon: PlayCircleIcon,
	},
	{
		name: 'History',
		description: '옴니톡 변경사항',
		href: '/history',
		icon: ListBulletIcon,
	},
	{
		name: 'Issue',
		description: '옴니톡 SDK를 사용하면서 생기는 이슈를 알려주세요',
		href: '/issue',
		icon: VscGithubAlt,
	},
];

const communityCategory = [
  { name: 'Notice', description: '옴니톡 공지사항 입니다', href: '/notice', icon: NewspaperIcon },
  { name: 'FAQ', description: '자주 묻는 질문에 대한 답변입니다', href: '/faq', icon: QuestionMarkCircleIcon },
];

const contactUs = [
  { name: 'Contact Us', description: '자주 묻는 질문에 대한 답변입니다',  icon: ChatBubbleLeftRightIcon },
]
const userCategory = [
  { name: 'Console', description: '옴니톡 공지사항 입니다', href: '/console/service/service-id', icon: WrenchIcon },
  { name: 'Logout', description: '자주 묻는 질문에 대한 답변입니다', href: '/faq', icon: ArrowLeftOnRectangleIcon },
];

const consoleServiceCategory = [
  { name: 'Service', description: '옴니톡 공지사항 입니다', href: '/console/service/service-id', icon: WrenchIcon },
  { name: 'Billing', description: '자주 묻는 질문에 대한 답변입니다', href: '/console/billing/invoice', icon: ArrowLeftOnRectangleIcon },
  { name: 'Mypage', description: '자주 묻는 질문에 대한 답변입니다', href: '/console/my-page', icon: ArrowLeftOnRectangleIcon },
]
const adminCategory = [
  { name: 'Agency', description: '옴니톡 공지사항 입니다', href: '/console/admin/agency', icon: WrenchIcon },
  { name: 'Voucher', description: '자주 묻는 질문에 대한 답변입니다', href: '/console/admin/voucher', icon: ArrowLeftOnRectangleIcon },
  { name: 'CDR', description: '자주 묻는 질문에 대한 답변입니다', href: '/console/cdr', icon: ArrowLeftOnRectangleIcon },
  { name: 'Test Key', description: '자주 묻는 질문에 대한 답변입니다', href: '/console/test-key', icon: ArrowLeftOnRectangleIcon },
  { name: 'Contact Us', description: '자주 묻는 질문에 대한 답변입니다', href: '/console/contact', icon: ArrowLeftOnRectangleIcon },
]
function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function Header() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [displayContact, setDisplayContact] = useState(false);
  const [callBackMessage, setCallBackMessage] = useState('');
  const navigate = useNavigate();

  const [role, setRole] = useRecoilState(roleState);
  const handleLogout = () => {
    setRole(null);
    navigate('/');
  };

  const handleSubmit = (data) => {
    API.addContactUs({
      title: data.title,
      name: data.name,
      email: data.email,
      phone: `${data.phone0}-${data.phone1}-${data.phone2}`,
      position: data.position,
      question: data.question,
      status: 0
    }).then(response => {
      if (response.data.result === 'success') {
        setCallBackMessage('문의 사항이 정상적으로 접수되었습니다.<br />빠른시간내에 안내드리겠습니다. 감사합니다.');
      }
    }, error => {
      console.log(error);
    });
  };

  return (
    <header className="bg-white dark:bg-black sticky top-0 z-[100]">
      <nav className="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8 " aria-label="Global">
        <div className="flex lg:flex-1">
          <a href="/" className="-m-1.5 p-1.5" >
            <img className="h-8 w-24" src={logo} alt="omnitalk-log" />
          </a>
        </div>
        <div className="flex lg:hidden">
          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon className="h-6 w-6 dark:text-white" aria-hidden="true" />
          </button>
        </div>
        <Popover.Group className="hidden lg:flex lg:gap-x-12">
      
          <Popover className="relative">
            <Popover.Button className="flex hover:text-main-50 items-center gap-x-1  focus:outline-none focus:border-none text-base font-semibold leading-6 text-gray-900 dark:text-white">
              Developer
              <ChevronDownIcon className="h-5 w-5 flex-none text-gray-400 " aria-hidden="true" />
            </Popover.Button>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="z-[100] absolute top-full mt-3 w-screen max-w-[200px] overflow-hidden rounded-3xl bg-white  ring-1 ring-gray-900/5   dark:bg-black">
                <div className="p-4">
                  {developerCategory.map((item, index) => (
                    <div
                      key={item.name}
                      className={`group relative flex items-center gap-x-3 rounded-lg p-3 px-0 text-base leading-6  cursor-pointer ${
                        index !== 0 ? 'transition ease-out duration-200' : ''
                      }`}
                    >
                 
                      <div className="flex h-10 w-10 flex-none items-center justify-center rounded-lg ">
                        <item.icon className="h-5 w-5 text-gray-600 dark:text-gray-400  group-hover:text-main-50 " aria-hidden="true" />
                      </div>
                      <div className="flex-auto  ">
                        <a href={item.href}
                          className="block text-[16px] font-semibold text-gray-900 dark:text-white group-hover:text-main-50  ">
                          {item.name}
                          <span className="absolute inset-0 " />
                        </a>
                        {/* <p className="mt-1 text-gray-600">{item.description}</p> */}
                      </div>
                    </div>
                  ))}
                </div>
              </Popover.Panel>
            </Transition>
            
          </Popover>
          <Popover className="relative">
            <Popover.Button className="flex hover:text-main-50 focus:outline-none focus:border-none  items-center gap-x-1 text-base font-semibold leading-6 text-gray-900 dark:text-white">
              Community
              <ChevronDownIcon className="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />
            </Popover.Button>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="z-30  absolute top-full mt-3 w-screen max-w-[200px] overflow-hidden rounded-3xl bg-white  ring-1 ring-gray-900/5  dark:bg-black">
                <div className="p-4">
                  {communityCategory.map((item, index) => (
                    <div
                      key={item.name}
                      className={`group relative flex items-center gap-x-3 rounded-lg p-3 px-0 text-base leading-6  cursor-pointer ${
                        index !== 0 ? 'transition ease-out duration-200' : ''
                      }`}
                    >
                 
                      <div className="flex h-10 w-10 flex-none items-center justify-center rounded-lg ">
                        <item.icon className="h-5 w-5 text-gray-600 dark:text-gray-400 group-hover:text-main-50 " aria-hidden="true" />
                      </div>
                      <div className="flex-auto ">
                        <a href={item.href}
                          className="block text-[16px] font-semibold text-gray-900 dark:text-white group-hover:text-main-50 ">
                          {item.name}
                          <span className="absolute inset-0 " />
                        </a>
                        {/* <p className="mt-1 text-gray-600">{item.description}</p> */}
                      </div>
                    </div>
                  ))}
                  {/* ==================== contact us ==================== */}
                  {contactUs.map((item, index) => (
                    <div
                      key={item.name}
                      className={`group relative flex items-center gap-x-3 rounded-lg p-3 px-0 text-base leading-6  cursor-pointer ${
                        index !== 0 ? 'transition ease-out duration-200' : ''
                      }`}
                    >
                 
                      <div className="flex h-10 w-10 flex-none items-center justify-center rounded-lg ">
                        <item.icon className="h-5 w-5 text-gray-600 dark:text-gray-400 group-hover:text-main-50 " aria-hidden="true" />
                      </div>
                      <div className="flex-auto ">
                        <button className="block text-[16px] font-semibold text-gray-900 dark:text-white group-hover:text-main-50 "
                        onClick={() => setDisplayContact(true) }
                        >
                          {item.name}
                          <span className="absolute inset-0 " />
                        </button>
                      </div>
                    </div>
                  ))}
                  {/* ==================== contact us ==================== */}
                </div>
              </Popover.Panel>
            </Transition>
            
          </Popover>
          <a href="/pricing"
            className="text-base font-semibold leading-6 text-gray-900 dark:text-white  hover:text-main-50 focus:outline-none">
            Pricing
          </a>
          <a target="_blank" rel="noopener noreferrer" href="/conference" className="text-base font-semibold leading-6 text-gray-900 dark:text-white  hover:text-main-50 focus:outline-none">
            Conference
          </a>
        </Popover.Group>



      {displayContact && <ContactPopup  onClose={() => setDisplayContact(false)} onSubmit={handleSubmit} callBackMessage={callBackMessage} />}

        <div className="hidden lg:flex lg:flex-1 lg:justify-end">
          {/* <Switcher /> */}
          {role === null ? (
            <a
              href="/login" className="text-base font-semibold leading-6 text-gray-900 dark:text-white">
              Console <span aria-hidden="true"> <svg className="w-4 h-4  inline-block dark:text-white group-hover:text-main-50" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd" />
              </svg></span>
              </a>
          ) : (
          <Popover className="relative">
          <Popover.Button className="flex hover:text-main-50 focus:outline-none focus:border-none  items-center gap-x-1 text-base font-semibold leading-6 text-gray-900 dark:text-white">
            
               <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-7 h-7">
                <path fillRule="evenodd" d="M18.685 19.097A9.723 9.723 0 0021.75 12c0-5.385-4.365-9.75-9.75-9.75S2.25 6.615 2.25 12a9.723 9.723 0 003.065 7.097A9.716 9.716 0 0012 21.75a9.716 9.716 0 006.685-2.653zm-12.54-1.285A7.486 7.486 0 0112 15a7.486 7.486 0 015.855 2.812A8.224 8.224 0 0112 20.25a8.224 8.224 0 01-5.855-2.438zM15.75 9a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0z" clipRule="evenodd" />
              </svg>
            <ChevronDownIcon className="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />
          </Popover.Button>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="z-30  absolute top-full mt-3 right-[0px] w-screen max-w-[200px] overflow-hidden rounded-3xl bg-white  ring-1 ring-gray-900/5  dark:bg-black">
              <div className="p-4">
                {userCategory.map((item, index) => (
                  <div
                    key={item.name}
                    className={`group relative flex items-center gap-x-3 rounded-lg p-3 px-0 text-base leading-6  cursor-pointer ${
                      index !== 0 ? 'transition ease-out duration-200' : ''
                    }`}
                  >
               
                    <div className="flex h-10 w-10 flex-none items-center justify-center rounded-lg ">
                      <item.icon className="h-5 w-5 text-gray-600 dark:text-gray-400 group-hover:text-main-50 " aria-hidden="true" />
                    </div>
                    <div className="flex-auto ">
                      {item.name === 'Console' ? (
                        <a
                          href={item.href} className="block text-[16px] font-semibold text-gray-900 dark:text-white group-hover:text-main-50 ">
                         {item.name}
                         <span className="absolute inset-0 " />
                       </a>
                      ) : (
                        <button onClick={handleLogout} href={item.href} className="block text-[16px] font-semibold text-gray-900 dark:text-white group-hover:text-main-50 ">
                        {item.name}
                        <span className="absolute inset-0 " />
                      </button>
                      )}
                     
                    </div>
                  </div>
                ))}
               
              </div>
            </Popover.Panel>
          </Transition>
          
        </Popover>
          )}
         
        </div>
      </nav>


      {/* ------------------------------------------------------------------------------------------- */}

      <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
        <div className="fixed inset-0 z-[100] " />
        <Dialog.Panel className="fixed inset-y-0 right-0 z-[100] w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm   dark:bg-black">
          <div className="flex items-center justify-between">
            <a
              href="/" className="-m-1.5 p-1.5">
              <span className="sr-only">Omnitalk</span>
              <img
                className="h-8 w-20 focus:outline-none"
                src={logo}
                alt="omnitalk-logo"
              />
            </a>
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-gray-700 dark:text-white"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon className="h-6 w-6 dark:text-white " aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6">
            
                {/* ========community=========================== */}
                <Disclosure as="div" className="-mx-3">
                  {({ open }) => (
                    <>
                      <Disclosure.Button className="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                        Developer
                        <ChevronDownIcon
                          className={classNames(open ? 'rotate-180' : '', 'h-5 w-5 flex-none')}
                          aria-hidden="true"
                        />
                      </Disclosure.Button>
                      <Disclosure.Panel className="mt-2 space-y-2">
                        {[...developerCategory].map((item) => (
                          <Disclosure.Button
                            key={item.name}
                            as="a"
                            href={item.href}
                            className="block rounded-lg py-2 pl-6 pr-3 text-base font-medium leading-7 text-gray-600 dark:text-white hover:bg-gray-50"
                          >
                            {item.name}
                          </Disclosure.Button>
                        ))}
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
              
                
                <Disclosure as="div" className="-mx-3">
                  {({ open }) => (
                    <>
                      <Disclosure.Button className="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                        Community
                        <ChevronDownIcon
                          className={classNames(open ? 'rotate-180' : '', 'h-5 w-5 flex-none')}
                          aria-hidden="true"
                        />
                      </Disclosure.Button>
                      <Disclosure.Panel className="mt-2 space-y-2">
                        {[...communityCategory].map((item) => (
                          <Disclosure.Button
                            key={item.name}
                            as="a"
                            href={item.href}
                            className="block rounded-lg py-2 pl-6 pr-3 text-base font-medium leading-7 text-gray-600 dark:text-white hover:bg-gray-50"
                          >
                            {item.name}
                          </Disclosure.Button>
                        ))}
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
{/* ===================================모바일 서비스 탭 ================= */}

                {role !== null && (
                  <Disclosure as="div" className="-mx-3">
                  {({ open }) => (
                    <>
                      <Disclosure.Button className="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                        Console
                        <ChevronDownIcon
                          className={classNames(open ? 'rotate-180' : '', 'h-5 w-5 flex-none')}
                          aria-hidden="true"
                        />
                      </Disclosure.Button>
                      <Disclosure.Panel className="mt-2 space-y-2">
                        {[...consoleServiceCategory].map((item) => (
                          <Disclosure.Button
                            key={item.name}
                            as="a"
                            href={item.href}
                            className="block rounded-lg py-2 pl-6 pr-3 text-base font-medium leading-7 text-gray-600 dark:text-white hover:bg-gray-50"
                          >
                            {item.name}
                          </Disclosure.Button>
                        ))}
                      </Disclosure.Panel>
                    </>
                  )}
                  </Disclosure>
                ) }
                 {role === 'admin' && (
                  <Disclosure as="div" className="-mx-3">
                  {({ open }) => (
                    <>
                      <Disclosure.Button className="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                        Admin
                        <ChevronDownIcon
                          className={classNames(open ? 'rotate-180' : '', 'h-5 w-5 flex-none')}
                          aria-hidden="true"
                        />
                      </Disclosure.Button>
                      <Disclosure.Panel className="mt-2 space-y-2">
                        {[...adminCategory].map((item) => (
                          <Disclosure.Button
                            key={item.name}
                            as="a"
                            href={item.href}
                            className="block rounded-lg py-2 pl-6 pr-3 text-base font-medium leading-7 text-gray-600 dark:text-white hover:bg-gray-50"
                          >
                            {item.name}
                          </Disclosure.Button>
                        ))}
                      </Disclosure.Panel>
                    </>
                  )}
                  </Disclosure>
                )}
{/* ===================================모바일 서비스 탭 ================= */}
                <a
                  href="/pricing"
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 dark:text-white hover:bg-gray-50"
                >
                  Pricing
                    </a>
                <a
                  href="/conference"
                  target="_blank" rel="noopener noreferrer"
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 dark:text-white hover:bg-gray-50"
                >
                  Conference
                    </a>
                  
              </div>

              {/* -------------모바일 로그인 블럭 -------------- */}
              
              <div className="py-6">
                {role === null ? (
                  <a
                  href="/login"
                  className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 dark:text-white text-gray-900 hover:bg-gray-50"
                >
                  Console 
                  <span aria-hidden="true"> 
                  <svg className="w-4 h-4  ml-2 inline-block dark:text-white group-hover:text-main-50" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd" />
                </svg>
                </span>
                </a>
                ) : (
                  <button
                  onClick={handleLogout}
                  className="-mx-3 block w-full rounded-lg text-left px-3 py-2.5 text-base font-semibold leading-7  dark:text-white text-gray-900 hover:bg-gray-50"
                >
                  Logout 
                  <span aria-hidden="true"> 
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 inline-block ml-2">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15M12 9l-3 3m0 0l3 3m-3-3h12.75" />
                  </svg>
                </span>
                </button>

                )}
                
              </div>
              {/* -------------모바일 로그인 블럭 -------------- */}
              
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </header>
  );
}