import { EventEmitter } from 'events';
import { IRequestAnswerCall,  IRequestCreateRoom, IRequestCreateSesion, IRequestDestroyRoom, IRequestJoinRoom, IRequestKickout, IRequestLeave, IRequestMakeSipNumer, IRequestMessage, IRequestMute, IRequestOfferCall, IRequestPartiList, IRequestPublish, IRequestPublishList, IRequestResolution, IRequestResolutionReport, IRequestRoomList, IRequestScreenList, IRequestScreenShare, IRequestScreenUnShare, IRequestSessionList, IRequestSubscribe, IRequestSubscribeComp, IRequestTrickle, IRequestUnsubscribe, IResponsePong } from '../types/request';
import { REQUEST_MESSAGE_ACTION, REQ_CMD, RES_CMD } from '../types/enum';
import { IResponseAnswerCall, IResponseCommon, IResponseCreateRoom, IResponseCreateSession, IResponseJoinRoom, IResponseKickout, IResponseLeave, IResponseMakeSipNumber, IResponseMessage, IResponseMessageList, IResponseMute, IResponseOfferCall, IResponsePartiList, IResponsePublish, IResponsePublishList, IResponseRoomList, IResponseScreenList, IResponseScreenShare, IResponseScreenUnshare, IResponseSessionList, IResponseSubscribe, IResponseUnmute, IResponseUnsubscribe } from '../types/response';
import { CALL_TYPE, TRACK_TYPE, VIDEOROOM_TYPE } from '../public-types/common';
import Utils from '../commons/utils';
import { IceCandidate } from '../types/common';

export default class OmniWebsocket extends EventEmitter {
    private WS_URL = 'wss://omnitalk.io:9090';
    private REQ_TIMEOUT = 5000;
    private eventHandlers: Map<string, Function>;
    private ws?: WebSocket;
    private session?: string;
    private transactionId;

    constructor() {
        super();
        this.setMaxListeners(50); // MaxListenersExceededWarning: Possible EventEmitter memory leak detected. 11 trickle_rsp listeners added. Use emitter.setMaxListeners() to increase limit
        this.eventHandlers = new Map();
        this.transactionId = 1;
    }

    async connect(): Promise<void> {
        return new Promise(async (resolve, reject) => {
            this.ws = new WebSocket(this.WS_URL);
            this.ws!.onopen = e => {
                // console.log("websocket connected!");
                resolve();
            }
            this.ws!.onclose = e => {
                // console.log("websocket disconnected!");
                this.emit("close");
            }
            this.ws!.onerror = e => {
                // console.error(`websocket onerror, ${e.toString()}`);
                reject(e);
            }
            this.ws!.onmessage = async (msg) => {
                let jsonMsg = JSON.parse(msg.data);
                if (jsonMsg.event) {
                    // console.log('Receive Message :', msg.data);
                    this.emit('event', jsonMsg);
                } else if (jsonMsg.cmd == 'WS_HEARTBEAT_REQ') {
                    const pongMsg: IResponsePong = {
                        cmd: REQ_CMD.RES_HEARTBEAT,
                        timestamp: jsonMsg['timestamp'],
                        session: jsonMsg["session"]
                    }
                    let data = JSON.stringify(pongMsg);
                    this.ws!.send(data);
                } else {
                    // console.log('Receive Message :', msg.data);
                    this.dispatchCustomEvent(jsonMsg);
                }
            }
        });
    }

    close(): void {
        if (this.ws) {
            this.ws!.close();
        }
    }

    dispatchCustomEvent(jsonMsg: any) {
        let pendingKey;
        switch (jsonMsg['cmd'] as RES_CMD) {
            case RES_CMD.SUBSCRIBE:
                pendingKey = `${jsonMsg['cmd']}${jsonMsg['subscribe']}`;
                break;
            case RES_CMD.UNSUBSCRIBE:
                pendingKey = `${jsonMsg['cmd']}${jsonMsg['subscribe']}`;
                break;
            case RES_CMD.MESSAGE:
                pendingKey = `${jsonMsg['cmd']}${jsonMsg['transaction']}`;
                break;
            default:
                pendingKey = jsonMsg['cmd'];
                break;
        }
        var callback = this.eventHandlers.get(pendingKey);
        if (callback) {
            callback(jsonMsg);
            this.eventHandlers.delete(pendingKey);
        } else {
            console.error("Receive unknown error,", JSON.stringify(jsonMsg));
        }
    }

    responseHandler(jsonMsg: any): Promise<any> {
        if (jsonMsg['result'] != 'success') {
            return Promise.reject(jsonMsg.reason ?? `Failed to request without reason`);
        } else {
            return Promise.resolve(jsonMsg);
        }
    }

    async sendMessage<T>(pendingKey: string, msg: any): Promise<T> {
        return new Promise(async (resolve, reject) => {
            try {
                if (!this.ws) {
                    reject(`Invalid websoket connection state`);
                    return;
                }
                await this.send(msg);
                const timer = setTimeout(() => {
                    this.eventHandlers.delete(pendingKey)
                    reject(`Request timeout for request: ${pendingKey}`);
                }, this.REQ_TIMEOUT);
                this.eventHandlers.set(pendingKey, async (e: any) => {
                    try {
                        clearTimeout(timer);
                        const responseData = await this.responseHandler(e);
                        resolve(responseData);
                    } catch (err) {
                        reject(`${err}`);
                    }
                });
            } catch (err) {
                reject(err);
            }
        })
    }

    async send(msg: any) {
        let data = JSON.stringify(msg);
        // console.log(`Send Message: ${data}`);
        this.ws!.send(data);
    }

    async requestCreateSession(service_id: string, sdk: string, ver: string, service_key?: string, user_id?: string): Promise<IResponseCreateSession> {
        return new Promise(async (resolve, reject) => {
            try {
                const msg: IRequestCreateSesion = {
                    cmd: REQ_CMD.CREATE_SESSION,
                    service_id,
                    service_key,
                    sdk,
                    ver,
                    heartbeat: true,
                    user_id
                }
                const result = await this.sendMessage<IResponseCreateSession>(RES_CMD.CREATE_SESSION, msg);
                this.session = result.session;
                resolve(result);
            } catch (err) {
                reject(err);
            }
        });
    }

    async requestMakeSipNumber(call_number?: string, room_id?: string): Promise<IResponseMakeSipNumber> {
        return new Promise(async (resolve, reject) => {
            try {
                const msg: IRequestMakeSipNumer = {
                    cmd: REQ_CMD.MAKE_SIPNUM,
                    session: this.session!,
                    call_number,
                    room_id
                }
                const result = await this.sendMessage<IResponseMakeSipNumber>(RES_CMD.MAKE_SIPNUM, msg);
                resolve(result);
            } catch (err) {
                reject(err);
            }
        });
    }

    async requestSessionList(page?: number): Promise<IResponseSessionList> {
        return new Promise(async (resolve, reject) => {
            try {
                const msg: IRequestSessionList = {
                    cmd: REQ_CMD.SESSION_LIST,
                    session: this.session!,
                    page
                }
                const result = await this.sendMessage<IResponseSessionList>(RES_CMD.SESSION_LIST, msg);
                resolve(result);
            } catch (err) {
                reject(err);
            }
        });
    }

    async requestCreateRoom(room_type: VIDEOROOM_TYPE, subject?: string, secret?: string, start_date?: Date, end_date?: Date): Promise<IResponseCreateRoom> {
        return new Promise(async (resolve, reject) => {
            try {
                const msg: IRequestCreateRoom = {
                    cmd: REQ_CMD.CREATE_ROOM,
                    session: this.session!,
                    room_type,
                    subject,
                    secret,
                    start_date: start_date ? await Utils.getUnixTimestamp(start_date) : undefined,
                    end_date: end_date ? await Utils.getUnixTimestamp(end_date) : undefined
                }
                const result = await this.sendMessage<IResponseCreateRoom>(RES_CMD.CREATE_ROOM, msg);
                resolve(result);
            } catch (err) {
                reject(err);
            }
        });
    }

    async requestDestroyRoom(room_id: string): Promise<IResponseCreateRoom> {
        return new Promise(async (resolve, reject) => {
            try {
                const msg: IRequestDestroyRoom = {
                    cmd: REQ_CMD.DESTROY_ROOM,
                    session: this.session!,
                    room_id
                }
                const result = await this.sendMessage<IResponseCreateRoom>(RES_CMD.DESTROY_ROOM, msg);
                resolve(result);
            } catch (err) {
                reject(err);
            }
        });
    }

    async requestJoinRoom(room_id: string, secret?: string, user_name?: string): Promise<IResponseJoinRoom> {
        return new Promise(async (resolve, reject) => {
            try {
                const msg: IRequestJoinRoom = {
                    cmd: REQ_CMD.JOIN_ROOM,
                    session: this.session!,
                    room_id,
                    secret,
                    user_name
                }
                const result = await this.sendMessage<IResponseJoinRoom>(RES_CMD.JOIN_ROOM, msg);
                resolve(result);
            } catch (err) {
                reject(err);
            }
        });
    }

    async requestRoomList(room_type?: VIDEOROOM_TYPE, page?: number): Promise<IResponseRoomList> {
        return new Promise(async (resolve, reject) => {
            try {
                const msg: IRequestRoomList = {
                    cmd: REQ_CMD.ROOM_LIST,
                    session: this.session!,
                    room_type: room_type ?? 'all' as VIDEOROOM_TYPE,
                    page
                }
                const result = await this.sendMessage<IResponseRoomList>(RES_CMD.ROOM_LIST, msg);
                resolve(result);
            } catch (err) {
                reject(err);
            }
        });
    }

    async requestPartiList(room_id: string, page?: number): Promise<IResponsePartiList> {
        return new Promise(async (resolve, reject) => {
            try {
                const msg: IRequestPartiList = {
                    cmd: REQ_CMD.PARTI_LIST,
                    session: this.session!,
                    room_id,
                    page
                }
                const result = await this.sendMessage<IResponsePartiList>(RES_CMD.PARTI_LIST, msg);
                resolve(result);
            } catch (err) {
                reject(err);
            }
        });
    }

    async requestPublishList(room_id: string, page?: number): Promise<IResponsePublishList> {
        return new Promise(async (resolve, reject) => {
            try {
                const msg: IRequestPublishList = {
                    cmd: REQ_CMD.PUBLISH_LIST,
                    session: this.session!,
                    room_id,
                    page
                }
                const result = await this.sendMessage<IResponsePublishList>(RES_CMD.PUBLISH_LIST, msg);
                resolve(result);
            } catch (err) {
                reject(err);
            }
        });
    }

    async requestScreenList(room_id: string): Promise<IResponseScreenList> {
        return new Promise(async (resolve, reject) => {
            try {
                const msg: IRequestScreenList = {
                    cmd: REQ_CMD.SCREEN_LIST,
                    session: this.session!,
                    room_id
                }
                const result = await this.sendMessage<IResponseScreenList>(RES_CMD.SCREEN_LIST, msg);
                resolve(result);
            } catch (err) {
                reject(err);
            }
        });
    }

    async requestPublish(track: TRACK_TYPE, jsep: RTCSessionDescriptionInit, resolution?: number): Promise<IResponsePublish> {
        return new Promise(async (resolve, reject) => {
            try {
                const msg: IRequestPublish = {
                    cmd: REQ_CMD.PUBLISH,
                    session: this.session!,
                    track,
                    jsep,
                    resolution
                }
                const result = await this.sendMessage<IResponsePublish>(RES_CMD.PUBLISH, msg);
                resolve(result);
            } catch (err) {
                reject(err);
            }
        });
    }

    async requestScreenShare(track: TRACK_TYPE, jsep: RTCSessionDescriptionInit, resolution?: number): Promise<IResponseScreenShare> {
        return new Promise(async (resolve, reject) => {
            try {
                const msg: IRequestScreenShare = {
                    cmd: REQ_CMD.SCREEN_SHARE,
                    session: this.session!,
                    track,
                    jsep,
                    resolution
                }
                const result = await this.sendMessage<IResponsePublish>(RES_CMD.SCREEN_SHARE, msg);
                resolve(result);
            } catch (err) {
                reject(err);
            }
        });
    }

    async requestScreenUnshare(): Promise<IResponseScreenUnshare> {
        return new Promise(async (resolve, reject) => {
            try {
                const msg: IRequestScreenUnShare = {
                    cmd: REQ_CMD.SCREEN_UNSHARE,
                    session: this.session!,
                }
                const result = await this.sendMessage<IResponseScreenUnshare>(RES_CMD.SCREEN_UNSHARE, msg);
                resolve(result);
            } catch (err) {
                reject(err);
            }
        });
    }

    async requestSubscribe(subscribe: string): Promise<IResponseSubscribe> {
        return new Promise(async (resolve, reject) => {
            try {
                const msg: IRequestSubscribe = {
                    cmd: REQ_CMD.SUBSCRIBE,
                    session: this.session!,
                    subscribe
                }
                const result = await this.sendMessage<IResponseSubscribe>(`${RES_CMD.SUBSCRIBE}${subscribe}`, msg);
                resolve(result);
            } catch (err) {
                reject(err);
            }
        });
    }

    async requestSubscribeComp(subscribe: string, jsep: RTCSessionDescriptionInit): Promise<void> {
        return new Promise(async (resolve, reject) => {
            try {
                const msg: IRequestSubscribeComp = {
                    cmd: REQ_CMD.SUBSCRIBE_COMP,
                    session: this.session!,
                    subscribe,
                    jsep
                }
                let data = JSON.stringify(msg);
                this.ws!.send(data);
                resolve();
            } catch (err) {
                reject(err);
            }
        });
    }

    async requestUnsubscribe(subscribe: string): Promise<IResponseUnsubscribe> {
        return new Promise(async (resolve, reject) => {
            try {
                const msg: IRequestUnsubscribe = {
                    cmd: REQ_CMD.UNSUBSCRIBE,
                    session: this.session!,
                    subscribe
                }
                const result = await this.sendMessage<IResponseUnsubscribe>(`${RES_CMD.UNSUBSCRIBE}${subscribe}`, msg);
                resolve(result);
            } catch (err) {
                reject(err);
            }
        });
    }

    async requestOfferCall(call_type: CALL_TYPE, caller: string, callee: string, record: boolean): Promise<IResponseOfferCall> {
        return new Promise(async (resolve, reject) => {
            try {
                const msg: IRequestOfferCall = {
                    cmd: REQ_CMD.OFFER_CALL,
                    session: this.session!,
                    call_type,
                    caller,
                    callee,
                    record
                }
                const result = await this.sendMessage<IResponseOfferCall>(RES_CMD.OFFER_CALL, msg);
                resolve(result);
            } catch (err) {
                reject(err);
            }
        });
    }

    async requestAnswerCall(call_type: CALL_TYPE, caller: string, callee: string): Promise<IResponseAnswerCall> {
        return new Promise(async (resolve, reject) => {
            try {
                const msg: IRequestAnswerCall = {
                    cmd: REQ_CMD.ANSWER_CALL,
                    session: this.session!,
                    call_type,
                    caller,
                    callee,
                }
                const result = await this.sendMessage<IResponseAnswerCall>(RES_CMD.ANSWER_CALL, msg);
                resolve(result);
            } catch (err) {
                reject(err);
            }
        });
    }

    async requestLeave(): Promise<IResponseLeave> {
        return new Promise(async (resolve, reject) => {
            try {
                const msg: IRequestLeave = {
                    cmd: REQ_CMD.LEAVE,
                    session: this.session!
                }
                const result = await this.sendMessage<IResponseLeave>(RES_CMD.LEAVE, msg);
                resolve(result);
            } catch (err) {
                reject(err);
            }
        });
    }

    async requestKickOut(target: string): Promise<IResponseKickout> {
        return new Promise(async (resolve, reject) => {
            try {
                const msg: IRequestKickout = {
                    cmd: REQ_CMD.KICK_OUT,
                    session: this.session!,
                    target
                }
                const result = await this.sendMessage<IResponseKickout>(RES_CMD.KICK_OUT, msg);
                resolve(result);
            } catch (err) {
                reject(err);
            }
        });
    }

    async requestMute(track: TRACK_TYPE): Promise<IResponseMute> {
        return new Promise(async (resolve, reject) => {
            try {
                const msg: IRequestMute = {
                    cmd: REQ_CMD.MUTE,
                    session: this.session!,
                    track,
                }
                const result = await this.sendMessage<IResponseMute>(RES_CMD.MUTE, msg);
                resolve(result);
            } catch (err) {
                reject(err);
            }
        });
    }

    async requestUnmute(track: TRACK_TYPE): Promise<IResponseUnmute> {
        return new Promise(async (resolve, reject) => {
            try {
                const msg: IRequestMute = {
                    cmd: REQ_CMD.UNMUTE,
                    session: this.session!,
                    track,
                }
                const result = await this.sendMessage<IResponseUnmute>(RES_CMD.UNMUTE, msg);
                resolve(result);
            } catch (err) {
                reject(err);
            }
        });
    }

    async requestResolution(resolution: number): Promise<void> {
        return new Promise(async (resolve, reject) => {
            try {
                const msg: IRequestResolution = {
                    cmd: REQ_CMD.RESOLUTION,
                    session: this.session!,
                    resolution
                }
                await this.sendMessage<IResponseCommon>(RES_CMD.RESOLUTION, msg);
                resolve();
            } catch (err) {
                reject(err);
            }
        });
    }

    async requestTrickle(track: TRACK_TYPE, candidate: IceCandidate, subscribe?: string): Promise<void> {
        return new Promise(async (resolve, reject) => {
            try {
                const msg: IRequestTrickle = {
                    cmd: REQ_CMD.TRICKLE,
                    session: this.session!,
                    track,
                    candidate,
                    subscribe
                }
                await this.send(msg);
                resolve();
            } catch (err) {
                reject(err);
            }
        });
    }
    
    async requestResolutionReport(track: TRACK_TYPE, resolution: number): Promise<void> {
        return new Promise(async (resolve, reject) => {
            try {
                const msg: IRequestResolutionReport = {
                    cmd: REQ_CMD.RESOLUTION_RPT,
                    session: this.session!,
                    track,
                    resolution
                }
                await this.send(msg);
                resolve();
            } catch (err) {
                reject(err);
            }
        });
    }

    async requestMessage(action: REQUEST_MESSAGE_ACTION, message?: string, to_user?: string): Promise<IResponseMessage> {
        return new Promise(async (resolve, reject) => {
            try {
                const transaction = (this.transactionId++).toString();
                const msg: IRequestMessage = {
                    cmd: REQ_CMD.MESSAGE,
                    session: this.session!,
                    action,
                    transaction,
                    message,
                    to_user
                }
                const result = await this.sendMessage<IResponseMessage>(`${RES_CMD.MESSAGE}${transaction}`, msg);
                resolve(result);
            } catch (err) {
                reject(err);
            }
        });
    }

    async requestMessageList(): Promise<IResponseMessageList> {
        return new Promise(async (resolve, reject) => {
            try {
                const transaction = (this.transactionId++).toString();
                const msg: IRequestMessage = {
                    cmd: REQ_CMD.MESSAGE,
                    session: this.session!,
                    action: REQUEST_MESSAGE_ACTION.LIST,
                    transaction
                }
                const result = await this.sendMessage<IResponseMessageList>(`${RES_CMD.MESSAGE}${transaction}`, msg);
                resolve(result);
            } catch (err) {
                reject(err);
            }
        });
    }
    
}