import React from 'react';
import repositoryData from '../../assets/data/changelog.json';

export default function RepositoryList() {
	return (
		<div>
			<span className="lg:text-lg ">Repository</span>
			<ol className=" max-w-[150px]">
				<hr className="mt-3 bg-black border-none h-[1px] " />
				{repositoryData.LanguageList.filter(
					language => language.name !== 'All',
				).map((category, index) => {
					return (
						<a
							href={category.repository}
							target="_blank"
							rel="noreferrer"
							key={index}
						>
							<li className="align-middle ml-2 mt-4">
								<svg
									xmlns="http://www.w3.org/2000/svg"
									fill="none"
									viewBox="0 0 24 24"
									strokeWidth="1.5"
									stroke="currentColor"
									className="w-5 h-5 mr-2  text-gray-400 dark:text-white inline-block"
								>
									<path
										strokeLinecap="round"
										strokeLinejoin="round"
										d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"
									/>
								</svg>
								<span className="text-sm lg:text-base font-medium hover:text-main-50 text-gray-700 dark:text-white">
									{category.name}
								</span>
							</li>
						</a>
					);
				})}
			</ol>
		</div>
	);
}
