import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Marquee from 'react-fast-marquee';
import { defaultFlexCenter, fontSize, fontWeight, palette } from '../../modules/defines/style';
import mainMarqueeGameLg from '../../assets/main-marquee-game-lg.png';
import mainMarqueeGameMd from '../../assets/main-marquee-game-md.png';
import mainMarqueeGameWebp from '../../assets/main-marquee-game-webp.webp';
import mainMarqueeVrLg from '../../assets/main-marquee-vr-lg.png';
import mainMarqueeVrMd from '../../assets/main-marquee-vr-md.png';
import mainMarqueeVrWebp from '../../assets/main-marquee-vr-webp.webp';
import mainMarqueeEduLg from '../../assets/main-marquee-edu-lg.png';
import mainMarqueeEduMd from '../../assets/main-marquee-edu-md.png';
import mainMarqueeEduWebp from '../../assets/main-marquee-edu-webp.webp';
import mainMarqueeHealthLg from '../../assets/main-marquee-health-lg.png';
import mainMarqueeHealthMd from '../../assets/main-marquee-health-md.png';
import mainMarqueeHealthWebp from '../../assets/main-marquee-health-webp.webp';

function MarqueeSlider() {
  const { t } = useTranslation();
  return (
    <StyledSlider>
      <div className="slider max-w-full mr-auto ml-auto">
        <div className="slider_track"> 
          <Marquee speed={100} gradient={false} autoFill>
            <div className="slide">
            <picture>
              <source type="image/webp" srcSet={mainMarqueeHealthWebp}/>
                <source type="image/png"  srcSet={`${mainMarqueeHealthMd} 400w, ${mainMarqueeHealthLg} 768w`}/>
                <img src={mainMarqueeHealthMd}  srcSet={`${mainMarqueeHealthMd} 400w, ${mainMarqueeHealthLg} 768w`} alt={t('main.slide.healthcareTitle')} /> 
              </picture>
              {/* <img src={mainMarqueeHealthMd}  srcSet={`${mainMarqueeHealthMd} 400w, ${mainMarqueeHealthLg} 768w`} alt={t('main.slide.healthcareTitle')} />  */}
              {/* <img src={t('main.slide.healthcareIcon')} alt={t('main.slide.healthcareTitle')} className="slide_icon " /> */}
              <span className="slide_title">
                {t('main.slide.healthcareText')} 
              </span>
            </div>
            <div className="slide">
        
            <picture>
              <source type="image/webp" srcSet={mainMarqueeVrWebp}/>
                <source type="image/png"  srcSet={`${mainMarqueeVrMd} 400w, ${mainMarqueeVrLg} 768w`}/>
                <img src={mainMarqueeVrMd}  srcSet={`${mainMarqueeVrMd} 400w, ${mainMarqueeVrLg} 768w`} alt={t('main.slide.healthcareTitle')} /> 
              </picture>
              {/* <img src={mainMarqueeVrMd}  srcSet={`${mainMarqueeVrMd} 400w, ${mainMarqueeVrLg} 768w`} alt={t('main.slide.healthcareTitle')} />  */}
              {/* <img src={t('main.slide.metaIcond')} alt={t('main.slide.metaTitle')} className="slide_icon" /> */}
              <span className="slide_title">
                {t('main.slide.metaText')}
              </span>
            </div>
            <div className="slide">

            <picture>
              <source type="image/webp" srcSet={mainMarqueeEduWebp}/>
                <source type="image/png"  srcSet={`${mainMarqueeEduMd} 400w, ${mainMarqueeEduLg} 768w`}/>
                <img src={mainMarqueeEduMd}  srcSet={`${mainMarqueeEduMd} 400w, ${mainMarqueeEduLg} 768w`} alt={t('main.slide.healthcareTitle')} /> 
              </picture>
              {/* <img src={mainMarqueeEduMd}  srcSet={`${mainMarqueeEduMd} 400w, ${mainMarqueeEduLg} 768w`} alt={t('main.slide.healthcareTitle')} />  */}
              {/* <img src={t('main.slide.educationIcon')} alt={t('main.slide.educationTitle')} className="slide_icon" /> */}
              <span className="slide_title">{t('main.slide.educationText')} </span>
            </div>
            <div className="slide">
            <picture>
              <source type="image/webp" srcSet={mainMarqueeGameWebp}/>
                <source type="image/png"  srcSet={`${mainMarqueeGameMd} 400w, ${mainMarqueeGameLg} 768w`}/>
                <img src={mainMarqueeGameMd}  srcSet={`${mainMarqueeGameMd} 400w, ${mainMarqueeGameLg} 768w`} alt={t('main.slide.healthcareTitle')} /> 
              </picture>
              {/* <img src={mainMarqueeGameMd}  srcSet={`${mainMarqueeVrMd} 400w, ${mainMarqueeGameLg} 768w`} alt={t('main.slide.healthcareTitle')} />  */}
              {/* <img src={t('main.slide.gameIcon')} alt={t('main.slide.gameTitle')} className="slide_icon" /> */}
              <span className="slide_title">{t('main.slide.gameText')} </span>
            </div>
          </Marquee>
        </div>
      </div>
    </StyledSlider>
  );
}

export default MarqueeSlider;

const StyledSlider = styled.div`
width: 100%;
h3{
  padding-top: 45px;
  width: 1200px;
  margin: 0 auto;    
  margin-bottom: 40px;
  font-size: 2.375rem;
  font-weight: ${fontWeight.semiBold};
}
.slider{
  width: 100%;
      height: 300px;
  overflow: hidden;
  position: relative;
  .slider_track{
  width: 100%;
  background-color: transparent;
  ${defaultFlexCenter}
  .slide{
    position: relative;
    margin-right:35px;
    margin-bottom:50px;
    img{
      max-width: 100%;
      width: 500px;
      height: 300px;
      border-radius: 20px;
    }
    .slide_icon{
      position: absolute;
      right: 40px;
      top: 40px;
      width: 54px;
      height: 54px;
      
    }
    .slide_title{
      position: absolute;
      top: 50%;
      left: 50%;
      transform:translate(-50%, -50%);
      color: ${palette.white};
      font-size: ${fontSize.medium};
      font-weight: ${fontWeight.semiBold};
      text-align:center;
      text-shadow: 3px 4px 5px black;
    }
  }
}
}

@media screen and (max-width:1200px) and (min-width: 980px) {
  width: 100%;
  h3{
    width: 100%;
    padding: 0 5%;
    margin: 0 auto;
    margin-bottom: 70px;
    padding-top: 40px;
}
.slider{
  width: 100%;
  overflow: hidden;
  position: relative;
  .slider_track{
  width: 100%;
  ${defaultFlexCenter}
}
}
}

@media screen and (max-width:979px) and (min-width:580px) {
  padding-top: 40px;
  width: 100%;
  h3{
    width: 100%;
    padding: 0 5%;
    margin: 0 auto;
    margin-bottom: 70px;
    font-size: 2rem;
}
.slider{
  width: 100%;
  .slider_track{
  width: 100%;
  .slide{
    position: relative;
    margin-right:20px;
    /* margin-bottom:50px; */
    img{
      width: 500px;
      border-radius: 15px;
    }
  }
}
}
}

@media screen and (max-width:579px) and (min-width:230px) {
  padding-top: 40px;
width: 100%;
  h3{
    width: 100%;
    padding: 0 5%;
    margin-bottom: 40px;
    font-size: 1.3rem;
}
.slider{
  width: 100%;
  .slider_track{
  width: 100%;
  .slide{
    position: relative;
    margin-right:20px;
    /* margin-bottom:40px; */
    img{
      width: 360px;
      border-radius: 15px;
    }
    .slide_icon{
      right: 30px;
      top: 30px;
      width: 40px;
      height: 40px;
      
    }
    .slide_title{
      font-size: ${fontSize.regular};
    }
  }
}
}
}

`;
