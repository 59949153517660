import { useState } from 'react';
import changelogData from '../assets/data/changelog.json';

export default function Dropdown({ handleLanguage }) {
	const [dropdownOpen, setDropdownOpen] = useState(false);
	const toggleDropdown = () => {
		setDropdownOpen(!dropdownOpen);
	};
	const handleButtonClick = selectedIndex => {
		handleLanguage(selectedIndex);
		toggleDropdown(!dropdownOpen);
	};
	return (
		<div className="flex justify-center ">
			<div className="relative ">
				<button
					type="button"
					onClick={toggleDropdown}
					className="relative z-10 block rounded-md bg-white p-1 focus:outline-none"
				>
					<svg
						className="h-7 w-7 text-main-50"
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 20 20"
						fill="currentColor"
					>
						<path
							fillRule="evenodd"
							d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
							clipRule="evenodd"
						/>
					</svg>
				</button>

				{dropdownOpen && (
					<>
						<button
							type="button"
							onClick={toggleDropdown}
							className="fixed inset-0 h-full w-full z-10"
						/>
						<div className="absolute mt-2 py-2 w-48 bg-white rounded-md shadow-xl z-20">
							{changelogData.LanguageList.map((language, index) => (
								<button
									onClick={() => handleButtonClick(index)}
									key={index}
									href={language.changelogURL}
									className="block px-4 py-2 text-sm capitalize text-gray-700 hover:text-main-50 "
								>
									{language.name}
								</button>
							))}
						</div>
					</>
				)}
			</div>
		</div>
	);
}
