import { Outlet, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import Footer from './components/Footer';
import ConsoleBar from './components/ConsoleBar';
import Header from './components/Header';
function App() {
	const location = useLocation();
	return (
		<>
			<Header />

			<StyledSide>
				{location.pathname === '/console/my-page' ||
				location.pathname === '/console/service/service-id' ||
				location.pathname === '/console/service/web-hook' ||
				location.pathname === '/console/service/recording' ||
				location.pathname === '/console/billing/invoice' ||
				location.pathname === '/console/billing/voucher' ||
				location.pathname === '/console/billing/daily' ||
				location.pathname === '/console/admin/agency' ||
				location.pathname === '/console/admin/voucher' ||
				location.pathname === '/console/admin/cdr/realtime' ||
				location.pathname === '/console/admin/cdr/daily' ||
				location.pathname === '/console/admin/testkey' ||
				location.pathname === '/console/admin/contact' ? (
					<>
						<div className="side_page">
							<div className="category">
								<ConsoleBar />
							</div>
						</div>
					</>
				) : null}
				<div className="contents">
					<Outlet />
				</div>
			</StyledSide>

			<Footer />
		</>
	);
}

export default App;


const StyledSide = styled.div`
width: 100%;
.side_page{
position: relative;
max-width: 1200px;
margin: 0 auto;
}
.category{
position: fixed;
top: 140px;
}
.contents{
  width: 100%;
  margin: 0;
  overflow: auto;
  padding-top: -100px;
}
.contents::-webkit-scrollbar{
  display: none;
}

@media screen and (max-width:1200px) and (min-width: 980px) {
  width: 100%;
.side_page{
position: relative;
width: 100%;
padding-left: 20px;
}
.category{
position: fixed;
top: 120px;
}
}

@media screen and (max-width:979px) and (min-width:580px) {
width: 100%;
.side_page{
position: relative;
width: 100%;
margin: 0 auto;
}
.category{
position: relative;
top: 120px;
z-index: 10;
}
}

@media screen and (max-width:579px) and (min-width:230px) {
width: 100%;
.side_page{
position: relative;
width: 100%;
margin: 0 auto;
}
.category{
position: relative;
top: 80px;
z-index: 10;
}
}
`;

