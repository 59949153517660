import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import { IoLogoOctocat, IoMdPlayCircle } from 'react-icons/io';
import { Helmet } from 'react-helmet-async';
import {
	fontSize,
	fontWeight,
	palette,
	defaultFlexCenter,
	defaultInput,
	signinUpBtn,
} from '../../../modules/defines/style';
import DemoCategory from '../DemoCategory';
import DemoPopup from '../DemoPopup';
import Popup from '../../ui/Popup';
import myAxios from '../../../modules/api/myAxios';
import { EMAIL_REGEX } from '../../utill/regex';
import DemoLoading from '../DemoLoading';

export default function Audio({ type }) {
	const { t } = useTranslation();
	const location = useLocation();
	const [testKeyOpen, setTestKeyOpen] = useState(false);
	const [audioCallOpen, setAudioCallOpen] = useState(false);
	const [audioConferenceOpen, setAudioConferenceOpen] = useState(false);
	const [audioCallReactOpen, setAudioCallReactOpen] = useState(false);
	const [audioConfReactOpen, setAudioConfReactOpen] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [isComplete, setIsComplete] = useState(false);
	const [formData, setFormData] = useState({ email: '' });
	const [errorMsg, setErrorMsg] = useState('');

	const handleChange = e => {
		setFormData(prev => ({ ...prev, [e.target.name]: e.target.value }));
	};
	const onOffTestKey = () => {
		setTestKeyOpen(!testKeyOpen);
	};
	const onOffAudioCall = () => {
		setAudioCallOpen(!audioCallOpen);
	};
	const onOffAudioConference = () => {
		setAudioConferenceOpen(!audioConferenceOpen);
	};
	const onOffAudioCallReact = () => {
		setAudioCallReactOpen(!audioCallReactOpen);
	};
	const onOffAudioConfReact = () => {
		setAudioConfReactOpen(!audioConfReactOpen);
	};
	const getTestKey = async () => {
		setErrorMsg('');
		const result = EMAIL_REGEX.test(formData.email);
		if (result === false) return setErrorMsg('* 유효하지 않은 이메일입니다.');
		try {
			setIsLoading(true);
			const response = await myAxios.post('/users/temp-service', formData);
			if (response.data.result === 'success') {
				setIsLoading(false);
				setIsComplete(true);
			}
		} catch (error) {
			setIsLoading(false);
			if (error.response.data.status === 4037)
				return setErrorMsg('* 이미 발급받은 테스트키가 존재합니다.');
			if (error.response.data.statusCode === 400)
				return setErrorMsg('* 유효하지 않은 이메일입니다.');
		}
	};
	return (
		<>
			<StyledTitle>
				{type === 'old' ? (
					<Helmet>
						<meta name="robots" content="noindex,nofollow" />
					</Helmet>
				) : (
					<Helmet>
						<title>{t('helmet.demo.audio.title')}</title>
						<meta
							name="keywords"
							content="데모,SDK,OmnitalkSDK,API,javascript,typescript,unity,unreal,ios,android,js,ts,asset,flutter,jsx,react,api-reference,WebRTC,WebRTCdemo,audio,video,message,telephone,chat"
						/>
						<meta name="description" content={t('helmet.demo.audio.desc')} />
						<meta property="og:title" content={t('helmet.demo.audio.title')} />
						<meta
							property="og:description"
							content={t('helmet.demo.audio.desc')}
						/>
						<meta property="og:url" content="https://omnitalk.io/demo/audio" />
						<link rel="canonical" href="https://omnitalk.io/demo/audio/" />
						<meta
							property="twitter:title"
							content={t('helmet.demo.audio.title')}
						/>
						<meta
							property="twitter:description"
							content={t('helmet.demo.audio.desc')}
						/>
						<meta
							property="twitter:card"
							content={t('helmet.demo.audio.card')}
						/>
						<meta name="robots" content="index" />
						<meta name="title" content={t('helmet.demo.audio.title')}></meta>
						<meta name="author" content="omnitalk"></meta>
						<meta property="og:type" content="website" />
					</Helmet>
				)}

				<section>
					<h1>{type === 'old' && <>Legacy </>}Audio Demo </h1>
					<span>
						{t('demo.audio.testDescOne')}
						<br />
						{t('demo.audio.testDescTwo')}
						<br />
						{t('demo.audio.testDescThree')}
					</span>
					<div className="btn-wrap">
						<button
							className="button"
							type="button"
							onClick={() => onOffTestKey()}
						>
							{t('demo.button.key')}
						</button>
					</div>
				</section>
			</StyledTitle>

			<DemoCategory type={type} />
			{location.pathname === '/demo/audio' ||
			location.pathname === '/demo/legacy/audio' ? (
				<>
					{/* -------------------1. Audio Call */}
					<StyledAudioWrap>
						<StyledAudioImg>
							<div className="demo-btn">
								<button type="button" onClick={onOffAudioCall}>
									{t('demo.button.test')}
								</button>
							</div>
							<h3>Audio Call</h3>
							<div className="calling_spinner audio-call-vanila-wrap">
								<img
									className="audio-call-vanila"
									alt="audio-call-vanilajs"
									src="https://user-images.githubusercontent.com/125844802/228772189-c5861d49-7c91-4e2e-8f36-c336e9a7c234.png"
								/>
							</div>
						</StyledAudioImg>

						<StyledAudioDesc>
							<h4>Audio Call</h4>
							<div className="description-wrap">
								<span>{t('demo.audio.jsCallOne')}</span>
								<span>{t('demo.audio.jsCallTwo')}</span>
								<span>{t('demo.audio.jsCallThree')}</span>
								{type !== 'old' && (
									<span>
										<span>{t('demo.docslinkIntro')}</span>
										<a
											className="text-main-50 hover:text-main-100 text-base font-medium inline-block"
											href="https://docs.omnitalk.io/typescript/developers-guide/audio-call"
											target="_blank"
											rel="noreferrer"
										>
											{t('demo.docslink')}
										</a>
										<span>{t('demo.docslinkEnd')}</span>
									</span>
								)}
							</div>
							<div className="feature-wrap">
								<ul>
									<li>Add-On: basic</li>
									<li>Feature: html5/css/javascript, bootstrap, jquery</li>
									<li>Version: 1.0</li>
									<li>Creator: jason@omnistory.net</li>
								</ul>
							</div>
							<div className="btn-wrap">
								<div className="btn-git">
									<button type="button" onClick={() => onOffAudioCall()}>
										<IoMdPlayCircle size="18" className="btn-git__icon" />
										{t('demo.button.test')}
									</button>
								</div>
								<div className="btn-git">
									<button
										type="button"
										style={{ marginLeft: '14px' }}
										onClick={() =>
											type === 'old'
												? window.open(
														'https://github.com/omnistory-labs/demo/tree/main/audio/call/1',
												  )
												: window.open(
														'https://github.com/omnistory-labs/omnitalk.sdk/tree/demo-js',
												  )
										}
									>
										<IoLogoOctocat size="18" className="btn-git__icon" />
										{t('demo.button.git')}
									</button>
								</div>
							</div>
						</StyledAudioDesc>
					</StyledAudioWrap>
					{/* -------------------2. Audio Conference */}
					<StyledAudioWrap>
						<StyledAudioImg>
							<div className="demo-btn">
								<button type="button" onClick={() => onOffAudioConference()}>
									{t('demo.button.test')}
								</button>
							</div>
							<h3>Audio Conference</h3>
							<div className="calling_spinner audio-conf-vanila-wrap">
								<img
									className="audio-conf-vanila"
									alt="telephone"
									src="https://user-images.githubusercontent.com/125844802/228773988-fc9177ca-3e40-4e21-bf47-a1f6b6d5635e.png"
								/>
							</div>
						</StyledAudioImg>
						<StyledAudioDesc>
							<h4>Audio Conference</h4>
							<div className="description-wrap">
								<span>{t('demo.audio.jsConferenceOne')}</span>
								<span>{t('demo.audio.jsConferenceTwo')}</span>
								<span>{t('demo.audio.jsConferenceThree')}</span>
								{type !== 'old' && (
									<span>
										<span>{t('demo.docslinkIntro')}</span>
										<a
											className="text-main-50 hover:text-main-100 text-base font-medium inline-block"
											href="https://docs.omnitalk.io/typescript/developers-guide/audio-room"
											target="_blank"
											rel="noreferrer"
										>
											{t('demo.docslink')}
										</a>
										<span>{t('demo.docslinkEnd')}</span>
									</span>
								)}
							</div>
							<div className="feature-wrap">
								<ul>
									<li>Add-On: basic, mic select</li>
									<li>Feature: html5/css/javascript, bootstrap, jquery</li>
									<li>Version: 1.0</li>
									<li>Creator: jason@omnistory.net</li>
								</ul>
							</div>
							<div className="btn-wrap">
								<div className="btn-git">
									<button type="button" onClick={() => onOffAudioConference()}>
										<IoMdPlayCircle size="18" className="btn-git__icon" />
										{t('demo.button.test')}
									</button>
								</div>

								<div className="btn-git">
									<button
										type="button"
										style={{ marginLeft: '14px' }}
										onClick={() =>
											type === 'old'
												? window.open(
														'https://github.com/omnistory-labs/demo/tree/main/audio/conf/1',
												  )
												: window.open(
														'https://github.com/omnistory-labs/omnitalk.sdk/tree/demo-js',
												  )
										}
									>
										<IoLogoOctocat size="18" className="btn-git__icon" />
										{/* <img src="https://github.githubassets.com/images/modules/logos_page/GitHub-Mark.png" alt="git" /> */}
										{t('demo.button.git')}
									</button>
								</div>
							</div>
						</StyledAudioDesc>
					</StyledAudioWrap>
					{/* -------------------3. Audio Call */}
					<StyledAudioWrap>
						<StyledAudioImg>
							<div className="demo-btn">
								<button type="button" onClick={() => onOffAudioCallReact()}>
									{t('demo.button.test')}
								</button>
							</div>
							<h3>Audio Call For React</h3>
							<div className="calling_spinner audio-call-react-wrap">
								<img
									className="audio-call-react"
									alt="telephone"
									src="https://user-images.githubusercontent.com/125844802/230559090-4efbec25-de9b-471b-8ad5-158cac1fc351.png"
								/>
							</div>
						</StyledAudioImg>
						<StyledAudioDesc>
							<h4>Audio Call For React</h4>
							<div className="description-wrap">
								<span>{t('demo.audio.reactCallOne')}</span>
								<span>{t('demo.audio.reactCallTwo')}</span>
								<span>{t('demo.audio.reactCallThree')}</span>
								{type !== 'old' && (
									<span>
										<span>{t('demo.docslinkIntro')}</span>
										<a
											className="text-main-50 hover:text-main-100 text-base font-medium inline-block"
											href="https://docs.omnitalk.io/typescript/developers-guide/audio-call"
											target="_blank"
											rel="noreferrer"
										>
											{t('demo.docslink')}
										</a>
										<span>{t('demo.docslinkEnd')}</span>
									</span>
								)}
							</div>
							<div className="feature-wrap">
								<ul>
									<li>Add-On: basic</li>
									<li>Feature: React JS</li>
									<li>Version: 1.0</li>
									<li>Creator: luna@omnistory.net</li>
								</ul>
							</div>
							<div className="btn-wrap">
								<div className="btn-git">
									<button type="button" onClick={() => onOffAudioCallReact()}>
										<IoMdPlayCircle size="18" className="btn-git__icon" />
										{t('demo.button.test')}
									</button>
								</div>

								<div className="btn-git">
									<button
										type="button"
										style={{ marginLeft: '14px' }}
										onClick={() =>
											type === 'old'
												? window.open(
														'https://github.com/omnistory-labs/demo/tree/main/audio/call/2',
												  )
												: window.open(
														'https://github.com/omnistory-labs/omnitalk.sdk/tree/demo-react',
												  )
										}
									>
										<IoLogoOctocat size="18" className="btn-git__icon" />
										{/* <img src="https://github.githubassets.com/images/modules/logos_page/GitHub-Mark.png" alt="git" /> */}
										{t('demo.button.git')}
									</button>
								</div>
							</div>
						</StyledAudioDesc>
					</StyledAudioWrap>
					{/* -------------------4. Audio Conf */}
					<StyledAudioWrap>
						<StyledAudioImg>
							<div className="demo-btn">
								<button type="button" onClick={() => onOffAudioConfReact()}>
									{t('demo.button.test')}
								</button>
							</div>
							<h3>Audio Conference For React</h3>
							<div className="calling_spinner audio-call-react-wrap">
								<img
									className="audio-call-react"
									alt="telephone"
									src="https://user-images.githubusercontent.com/125844802/230559417-66bf3ea7-08ef-4182-b948-3b6e6a8db32a.png"
								/>
							</div>
						</StyledAudioImg>
						<StyledAudioDesc>
							<h4>Audio Conference For React</h4>
							<div className="description-wrap">
								<span>{t('demo.audio.reactConferenceOne')}</span>
								<span>{t('demo.audio.reactConferenceTwo')}</span>
								<span>{t('demo.audio.reactConferenceThree')}</span>
								{type !== 'old' && (
									<span>
										<span>{t('demo.docslinkIntro')}</span>
										<a
											className="text-main-50 hover:text-main-100 text-base font-medium inline-block"
											href="https://docs.omnitalk.io/typescript/developers-guide/audio-room"
											target="_blank"
											rel="noreferrer"
										>
											{t('demo.docslink')}
										</a>
										<span>{t('demo.docslinkEnd')}</span>
									</span>
								)}
							</div>
							<div className="feature-wrap">
								<ul>
									<li>Add-On: basic, mic select</li>
									<li>Feature: React JS</li>
									<li>Version: 1.0</li>
									<li>Creator: luna@omnistory.net</li>
								</ul>
							</div>
							<div className="btn-wrap">
								<div className="btn-git">
									<button type="button" onClick={() => onOffAudioConfReact()}>
										<IoMdPlayCircle size="18" className="btn-git__icon" />
										{t('demo.button.test')}
									</button>
								</div>

								<div className="btn-git">
									<button
										type="button"
										style={{ marginLeft: '14px' }}
										onClick={() =>
											type === 'old'
												? window.open(
														'https://github.com/omnistory-labs/demo/tree/main/audio/conf/2',
												  )
												: window.open(
														'https://github.com/omnistory-labs/omnitalk.sdk/tree/demo-react',
												  )
										}
									>
										<IoLogoOctocat size="18" className="btn-git__icon" />
										{t('demo.button.git')}
									</button>
								</div>
							</div>
						</StyledAudioDesc>
					</StyledAudioWrap>

					{testKeyOpen && (
						<>
							<Popup onClose={onOffTestKey}>
								{isComplete ? (
									<>
										<StyledCompleted>
											<h2>Completed!</h2>
											<div className="img-wrap">
												<img
													src="https://user-images.githubusercontent.com/125844802/228473825-93294135-6080-425c-b926-ef909805ced5.png"
													alt="completed"
												/>
											</div>
											<div className="txt-wrap">
												<span>{t('demo.complete.textOne')}</span>
												<span>{t('demo.complete.textTwo')}</span>
											</div>
										</StyledCompleted>
									</>
								) : (
									<StyledInCompleted>
										<div className="container">
											<h2>{t('demo.button.key')}</h2>
											<div className="input-wrap">
												<input
													placeholder="email"
													onChange={handleChange}
													name="email"
												/>
												<button type="button" onClick={() => getTestKey()}>
													{t('demo.incomplete.button')}
												</button>
											</div>
											<div className="info-wrap">
												<span className="error">
													{errorMsg}
													<br />
												</span>
												<span className="notice">
													{/* <MdInfo className="icon" /> */}
													{t('demo.incomplete.textOne')}
													<br />
													{t('demo.incomplete.textTwo')}
												</span>
											</div>
										</div>
									</StyledInCompleted>
								)}
								{isLoading && (
									<>
										<DemoLoading />
									</>
								)}
							</Popup>
						</>
					)}
					{audioCallOpen && (
						<DemoPopup onClose={onOffAudioCall} size="md">
							<iframe
								style={{
									border: 'none',
									width: '100%',
									height: '500px',
									overflowY: 'auto',
								}}
								id="audioCall"
								scrolling="no"
								allow="camera *; microphone *; display-capture *;"
								title="audio-call"
								src={`${
									type === 'old'
										? 'https://omnitalk.io/testbed/old/audio/call/1'
										: 'https://omnitalk.io/testbed/audio/call/1'
								}`}
							/>
						</DemoPopup>
					)}
					{audioConferenceOpen && (
						<DemoPopup onClose={onOffAudioConference} size="md">
							<iframe
								style={{
									border: 'none',
									width: '100%',
									height: '500px',
									overflowY: 'auto',
								}}
								id="audioConference"
								allow="camera *; microphone *; display-capture *;"
								title="audio-conference"
								src={`${
									type === 'old'
										? 'https://omnitalk.io/testbed/old/audio/conf/1'
										: 'https://omnitalk.io/testbed/audio/conf/1'
								}`}
							/>
						</DemoPopup>
					)}
					{audioCallReactOpen && (
						<DemoPopup onClose={onOffAudioCallReact} size="md">
							<iframe
								style={{
									border: 'none',
									width: '100%',
									height: '600px',
									overflow: 'hidden',
								}}
								id="audioCallReact"
								scrolling="yes"
								allow="camera *; microphone *; display-capture *;"
								title="audio-call-react"
								src={`${
									type === 'old'
										? 'https://omnitalk.io/testbed/old/audio/call/2'
										: 'https://omnitalk.io/testbed/audio/call/2'
								}`}
							/>
						</DemoPopup>
					)}
					{audioConfReactOpen && (
						<DemoPopup onClose={onOffAudioConfReact} size="md">
							<iframe
								style={{
									border: 'none',
									width: '100%',
									height: '600px',
									overflow: 'hidden',
								}}
								id="audioCallReact"
								scrolling="yes"
								allow="camera *; microphone *; display-capture *;"
								title="audio-call-react"
								src={`${
									type === 'old'
										? 'https://omnitalk.io/testbed/old/audio/conf/2'
										: 'https://omnitalk.io/testbed/audio/conf/2'
								}`}
							/>
						</DemoPopup>
					)}
				</>
			) : null}
		</>
	);
}
// *********** title ******************
const StyledTitle = styled.div`
padding-top: 120px;
margin: 0 auto;
text-align: center;
max-width: 1200px;
h1{
  font-weight: 600;
}
span{
    color: #545454;
    margin-bottom: 90px;
    line-height: 26px;
}
.btn-wrap{
.button {
  margin-top: 20px;
  border-radius: 5px;
display: inline-block;
width: 200px;
height: 54px;
text-align: center;
text-decoration: none;
line-height: 54px;
outline: none;
background-color: ${palette.main.default};
border: 2px solid ${palette.main.default};
color: #fff;
line-height: 50px;
}
.button::before,
.button::after {
position: absolute;
z-index: -1;
display: block;
content: '';
}
.button:hover {
background-color: #fff;
border-color: ${palette.main.default};
color: ${palette.main.default};
}
.button,
.button::before,
.button::after {
-webkit-box-sizing: border-box;
-moz-box-sizing: border-box;
box-sizing: border-box;
-webkit-transition: all .3s;
transition: all .3s;
}
}
@media screen and (max-width: 640px) and (min-width: 230px){
    h1{
      font-size: 24px;
    }
  }

`;
// *********** AudioWrap ******************
const StyledAudioWrap = styled.div`
display: flex;
margin: 0 auto;
max-width: 1000px;
justify-content: space-around;
margin-bottom: 105px;
@media screen and (max-width: 980px) and (min-width: 641px){
    padding: 0 3%;
}
@media screen and (max-width: 640px) and (min-width: 230px){
  padding: 0 10%;
}
@media screen and (max-width: 800px) and (min-width: 641px){
  justify-content: space-evenly;
}
@media screen and (max-width: 640px) and (min-width: 230px){
  flex-direction: column-reverse;
  align-items: center;
  margin-top: 20px;
}
`;

// *********** AudioImg ******************
const StyledAudioImg = styled.div`
display: flex;
flex-direction: column;
width: 340px;
height: 340px;
padding: 40px;
border-radius: 15px;
background-color: #fff;
box-shadow: ${palette.shadow};
position: relative;
overflow: hidden;
    :hover{
        z-index: 99;
        background: rgba(232, 121, 58, 0.65);
        background:rgba(239, 189, 12, 0.30);
        background: rgba(245, 154, 17, 0.44);
        transition: all 1s;
        h3, .dot_wrap, p, .btn_wrap ,.calling_spinner{
            display: none;
        }
        .demo-btn{
            display: block;
        }
        
    }
 
  h3{
    margin-top: 0px;
    margin-bottom: 16px;
    text-align: center;
    margin-bottom: 13px;
    font-size: 1.2rem;
    font-weight: ${fontWeight.regular};
  }
  .dot_wrap{
    margin-bottom: 15px;
    ${defaultFlexCenter}
    .dot{
    display: inline-block;
    width: 10px;
    height: 10px;
    margin: 0;
    border-radius: 100px;
    background-color: ${palette.gray.bright};
  }
  .dot_active{
    display: inline-block;
    width: 10px;
    height: 10px;
    margin: 0;
    border-radius: 100px;
    background-color: ${palette.main.default};
  }
  .hyphen{
    display: inline-block;
    width: 20px;
    height: 2px;
    margin: 0;
    background-color:${palette.gray.bright}; ;
  }
  .hyphen_active{
    display: inline-block;
    width: 20px;
    height: 2px;
    margin: 0;
    background-color:${palette.main.default}; ;
  }
  }
  h4{
    display: inline-block;
    width: 100%;
    margin: 0;
    margin-bottom: 20px;
    text-align: start;
    font-weight: ${fontWeight.regular};
    font-size: ${fontSize.regular};
   
  }
  .name,
  .calling{
    text-align: center;
    font-size: 1rem;
    color: ${palette.text.light};
    margin: 0;
  }
  .name{
    margin-top: 0px;
    font-size: 1.7rem;
    margin-bottom: 20px;
    font-weight: ${fontWeight.medium};
    color: #333;
  }
  .calling_spinner{
    position: absolute;
    top: 85px;
    left: 50%;
    transform: translateX(-50%);
    .demo-image__telephone{
      width: 159px;
      margin-bottom: 0;
      padding-bottom: -20px;
    }
  }

  .audio-call-vanila-wrap{
    position: absolute;
    top: 85px;
    left: 50%;
    transform: translateX(-50%);
    .audio-call-vanila{
        width: 160px;
        height: 220px;
        /* margin-bottom: 29px; */
    }
  }
  .audio-conf-vanila-wrap{
    position: absolute;
    top: 120px;
    left: 50%;
    transform: translateX(-50%);
    .audio-conf-vanila{
        width: 230px;
        height: 220px;
    }
  }
  .audio-call-react-wrap{
    position: absolute;
    top: 60px;
    left: 50%;
    transform: translateX(-50%);
    width: 260px;
        height: 260px;
    .audio-call-react{
        width: 260px;
        height: 260px;
    }
  }
  .demo-btn{
   position: absolute;
    display: none;
   top: 45%;
   left: 50%;
   transform: translateX(-50%);
   transition: all 2s;
   opacity:1;
   button{
    
      padding: 0 25px;
      height: 48px;
      background-color: #fff;
      color: #333;
      box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.16);
      border-radius: 5px;
      border: none;
      font-weight: 600;

   }
  }
  p{
    /* padding-left: 14px; */
    position: relative;
    color: ${palette.text.default};
    text-align: start;
    span{
      position: absolute;
      top: 0;
      left: 0;
      font-size: ${fontSize.medium};
      color: ${palette.main.default};
    }
  }
  .btn_wrap{
    position: absolute;
    bottom: 40px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    ${defaultFlexCenter}
    justify-content: space-evenly;
    button{
    background-color: ${palette.opacity};
    border: 0;
    :hover{
      cursor: pointer;
    }
    img {
        width: 80%;
    }
  }

  }
  @media screen and (max-width: 980px) and (min-width: 801px){
    
    width: 300px;
    height: 310px;
    p.name{
      font-size: 19px;
    }
    .calling_spinner{
    position: absolute;
    top: 140px;
    left: 50%;
    transform: translateX(-50%);
    img{
        width: 56px;
        height: 56px;
        /* margin-bottom: 29px; */
    }
    .demo-image__telephone{
      width: 159px;
      margin-bottom: 0;
      padding-bottom: -20px;
    }

  
  }
   .audio-call-vanila-wrap{
    position: absolute;
    top: 62px;
    left: 50%;
    transform: translateX(-50%);
    
  }
  .audio-conf-vanila-wrap{
    position: absolute;
    top: 100px;
    left: 50%;
    width: 230px;
        height: 240px;
    transform: translateX(-50%);
    .audio-conf-vanila{
        width: 230px;
        height: 240px;
    }
  }
  .audio-call-react-wrap{
    position: absolute;
    top: 70px;
    left: 50%;
    transform: translateX(-50%);
    width: 230px;
        height: 220px;
    .audio-call-react{
        width: 230px;
        height: 220px;
    }
  }
  }
  @media screen and (max-width: 800px) and (min-width: 641px){
    width: 220px;
    height: 400px;
    p.name{
      font-size: 19px;
    }
    .calling_spinner{
    position: absolute;
    top: 160px;
    left: 50%;
    transform: translateX(-50%);
    img{
        width: 56px;
        height: 56px;
        /* margin-bottom: 29px; */
    }
    .demo-image__telephone{
      width: 159px;
      margin-bottom: 0;
      padding-bottom: -20px;
    }
  }
     .audio-call-vanila-wrap{
    position: absolute;
    top: 90px;
    left: 50%;
    transform: translateX(-50%);
    width: 160px;
    height: 220px;
    
  }
  .audio-conf-vanila-wrap{
    position: absolute;
    top: 150px;
    left: 50%;
    transform: translateX(-50%);
    width: 180px;
        height: 210px;
    .audio-conf-vanila{
        width: 180px;
        height: 210px;
    }
  }
  .audio-call-react-wrap{
    position: absolute;
    top: 120px;
    left: 50%;
    transform: translateX(-50%);
      width: 230px;
        height: 220px;
    .audio-call-react{
        width: 230px;
        height: 220px;
    }
  }
}
@media screen and (max-width: 640px) and (min-width: 580px){
    width: 220px;
    height: 240px;
    h3{
      font-size: 16px;
    }
    p.name{
      font-size: 16px;
      margin-bottom: 0;
    }
    .calling_spinner{
    position: absolute;
    top: 140px;
    left: 50%;
    transform: translateX(-50%);
    img{
        width: 40px;
        height: 40px;
        /* display: none; */
        /* margin-bottom: 29px; */
    }
    .demo-image__telephone{
      width: 159px;
      margin-bottom: 0;
      padding-bottom: -20px;
    }
  }
     .audio-call-vanila-wrap{
    position: absolute;
    top: 60px;
    left: 50%;
    transform: translateX(-50%);
    width: 160px;
    
  }
  .audio-conf-vanila-wrap{
    position: absolute;
    top: 100px;
    left: 50%;
    transform: translateX(-50%);
    width: 180px;
    .audio-conf-vanila{
        width: 180px;
        height: 180px;
    }
  }
  .audio-call-react-wrap{
    position: absolute;
    top: 60px;
    left: 50%;
    transform: translateX(-50%);
    width: 160px;
        height: 160px;
    .audio-call-react{
        width: 160px;
        height: 160px;
    }
  }
  
  .btn_wrap{
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    ${defaultFlexCenter}
    justify-content: space-evenly;
    button{
    background-color: ${palette.opacity};
    border: 0;
    :hover{
      cursor: pointer;
    }
    img {
        width: 70%;
    }
  }

  }
}
@media screen and (max-width: 579px) and (min-width: 230px){
  width: 220px;
    height: 220px;
    h3{
      font-size: 16px;
    }
    p.name{
      font-size: 16px;
      margin-bottom: 0;
    }
    .calling_spinner{
    position: absolute;
    top: 140px;
    left: 50%;
    transform: translateX(-50%);
    img{
        width: 40px;
        height: 40px;
    }
    .demo-image__telephone{
      width: 159px;
      margin-bottom: 0;
      padding-bottom: -20px;
    }
  }
  .audio-call-vanila-wrap{
    position: absolute;
    top: 60px;
    left: 50%;
    transform: translateX(-50%);
    width: 160px;
    
    
  }
  .audio-conf-vanila-wrap{
    position: absolute;
    top: 100px;
    left: 50%;
    width: 180px;
    transform: translateX(-50%);
    .audio-conf-vanila{
        width: 180px;
        height: 180px;
    }
  }
  .audio-call-react-wrap{
    position: absolute;
    top: 70px;
    left: 50%;
    transform: translateX(-50%);
    width: 140px;
        height: 140px;
    .audio-call-react{
        width: 140px;
        height: 140px;
    }
  }
  .btn_wrap{
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    ${defaultFlexCenter}
    justify-content: space-evenly;
    button{
    background-color: ${palette.opacity};
    border: 0;
    :hover{
      cursor: pointer;
    }
    img {
        width: 70%;
    }
  }

  }
}
`;
// *********** AudioDesc ******************
const StyledAudioDesc = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
span{
    line-height: 26px;
    font-size: 1rem;
}
h4{
    margin-top: 0;
    font-size: 22px;
    margin-bottom: 22px;
    font-weight: 500;
}
button {
    background-color: #fff;
    border: none;
    padding: 0 23px;
    color: ${palette.main.bright};
    height: 42px;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.16);
    border-radius: 5px;
    font-weight: 700;
font-size: 14px;
}
.description-wrap{
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
    width: 522px;
  color: ${palette.text.default};
}

.feature-wrap{
  color: ${palette.text.default};
  ul {
    padding-left: 12px;
    display: list-item;
    line-height: 22px;
    li{
      display: list-item;
       list-style-image: linear-gradient(to left bottom, red, blue); 
       list-style-image: url("https://user-images.githubusercontent.com/125844802/228739533-2e2653bd-7418-4e10-9ec4-7a6c09cf7645.svg");
       background-size: 40px;
      }
}

}
.btn-wrap{
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
    .btn-git{
      display: flex;
      button{
        display: inline-flex;
        align-items: center;
      }
      .btn-git__icon{
      margin-right: 4px;
      }
    }
    button{
      transition: all .3s;
      :hover{
          color: #fff;
          cursor: pointer;
          background-color: ${palette.main.vivid};
        }
        span{
          font-size: ${fontSize.micro};
          font-weight: ${fontWeight.semiBold};
          color: ${palette.text.disabled};
          :hover{
            color: #fff;
          cursor: pointer;
          }
        }
    }
    img{
          display: flex;
          align-items: center;
          width: 22px;
          margin-right:6px;
        }

        
}
@media screen and (max-width: 980px) and (min-width: 801px){
    justify-content: flex-end;
  
    .description-wrap{
      width: 420px;
    }
}
@media screen and (max-width: 800px) and (min-width: 641px){
  .description-wrap{
      width: 342px;
    }
}
@media screen and (max-width: 640px) and (min-width: 580px){
  margin-bottom: 50px;
  h4{
    font-size: 18px;
    /* text-align: center; */
  }
  .description-wrap{
      width: 410px; 
    }
    button {
    background-color: #fff;
    border: none;
    padding: 0 23px;
    color: ${palette.main.bright};
    height: 30px;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.16);
    border-radius: 5px;
    font-weight: 700;
font-size: 14px;
}
.btn-wrap{
      justify-content: center;
    }
}
@media screen and (max-width: 579px) and (min-width: 230px){
  /* margin-top: 20px; */
  margin-bottom: 50px;
  .description-wrap{
      width: 100%;
    }
    button{
      height: 30px;
    }
    .btn-wrap{
      justify-content: center;
    }
}
`;

const StyledCompleted = styled.div`
h2{
  text-align: center;
  margin-bottom: 20px;
}
.img-wrap{
  display: flex;
}
 img {
  width: 120px;
  height: 120px;
  margin: 0 auto;
 }
 .txt-wrap{
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 14px;
 }
`;

const StyledInCompleted = styled.div`
.container{
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  h2{
    text-align: center;
    color: #333;
    font-size: 18px;
    margin-bottom: 30px;
  }
  .input-wrap{
    display: flex;
    flex-direction: column; 
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
    padding-left: 5px;
    padding-right: 5px;
  }
  input{
    ${defaultInput};
    border:none;
    border-bottom: 1px solid #d1d1d1;
    border-radius: 0;
  
    width: 100%;
    ::placeholder{
      padding-left: 5px;
    }
    margin-bottom: 10px; 
  }
  button{
    /* margin-left: 5px; */
    ${signinUpBtn}
    width: 30%;
    height: 30px;
    font-size: 14px;
    align-self: flex-end;
    margin-bottom: 10px; 

  }
  .info-wrap{
    display: flex;
    justify-content: center;
    flex-direction: row;
    font-size: 14px;
    text-align: center;
    .error{
      color: red;
      font-weight: 500;

    }
    .notice{
      vertical-align: middle;
      color: #545454;
    }
    .icon{
      vertical-align: text-bottom;
      color: #146DCF;
    }
  }
}
`;