import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import styled from 'styled-components';
import { useRecoilValue } from 'recoil';
import {
	defaultFlexCenter,
	fontSize,
	fontWeight,
	palette,
} from '../modules/defines/style';
import { roleState } from '../recoil';

function ConsoleBar() {
	const [billing, setBilling] = useState(true);
	const [serviceToggle, setServiceToggle] = useState(true);
	const [openMenu, setOpenMenu] = useState(false);
	const [filter, setFilter] = useState('Service ID');
	const role = useRecoilValue(roleState);
	const location = useLocation();
	const navigate = useNavigate();

	const handleToggle = () => {
		setServiceToggle(prev => !prev);
		setBilling(false);
	};
	const handleBillingToggle = () => {
		setBilling(prev => !prev);
		setServiceToggle(false);
	};

	const handleTabMenu = () => {
		setOpenMenu(prev => !prev);
	};
	return (
		<StyledSidebar>
			<section>
				<div className="side_bar">
					<button
						type="button"
						style={{ pointerEvents: 'none' }}
						onClick={handleToggle}
					>
						<h3 className="console-title">Service</h3>
					</button>
					{serviceToggle === true ||
					location.pathname === '/console/service/service-id' ||
					location.pathname === '/console/service/web-hook' ||
					location.pathname === '/console/service/recording' ? (
						<div className="toggle_open">
							<Link to="/console/service/service-id">
								<h3
									className={`${
										location.pathname === '/console/service/service-id'
											? 'active_semibold'
											: null
									}`}
								>
									Service ID
								</h3>
							</Link>
							<Link to="/console/service/web-hook">
								<h3
									className={`${
										location.pathname === '/console/service/web-hook'
											? 'active_semibold'
											: null
									}`}
								>
									Web Hook
								</h3>
							</Link>
							<Link to="/console/service/recording">
								<h3
									className={`${
										location.pathname === '/console/service/recording'
											? 'active_semibold'
											: null
									}`}
								>
									Recording
								</h3>
							</Link>
						</div>
					) : null}

					<button
						type="button"
						style={{ pointerEvents: 'none' }}
						onClick={handleBillingToggle}
					>
						<h3>Billing</h3>
					</button>
					{billing === true ||
					location.pathname === '/console/billing/invoice' ||
					location.pathname === '/console/billing/voucher' ||
					location.pathname === '/console/billing/daily' ? (
						<div className="toggle_open">
							<Link to="/console/billing/invoice">
								<h3
									className={`${
										location.pathname === '/console/billing/invoice'
											? 'active_semibold'
											: null
									}`}
								>
									Invoice
								</h3>
							</Link>
							<Link to="/console/billing/voucher">
								<h3
									className={`${
										location.pathname === '/console/billing/voucher'
											? 'active_semibold'
											: null
									}`}
								>
									Voucher
								</h3>
							</Link>
							<Link to="/console/billing/daily">
								<h3
									className={`${
										location.pathname === '/console/billing/daily'
											? 'active_semibold'
											: null
									}`}
								>
									CDR
								</h3>
							</Link>
						</div>
					) : null}
					<Link to="/console/my-page">
						<h3
							style={{ fontWeight: '600' }}
							className={`${
								location.pathname === '/console/my-page'
									? 'active_semibold'
									: null
							}`}
						>
							MyPage
						</h3>
						{/* ------- super admin 추가 --------- */}
					</Link>
					{role === 'admin' ? (
						<button
							type="button"
							style={{ pointerEvents: 'none' }}
							onClick={handleBillingToggle}
						>
							<h3>Super Admin</h3>
						</button>
					) : null}
					{role === 'admin' ||
					location.pathname === '/console/admin/agency' ||
					location.pathname === '/console/admin/voucher' ||
					location.pathname === '/console/admin/cdr/realtime' ||
					location.pathname === '/console/admin/cdr/daily' ||
					location.pathname === '/console/admin/testkey' ||
					location.pathname === '/console/admin/contact' ? (
						<div className="toggle_open">
							<Link to="/console/admin/agency">
								<h3
									className={`${
										location.pathname === '/console/admin/agency'
											? 'active_semibold'
											: null
									}`}
								>
									Agency
								</h3>
							</Link>
							<Link to="/console/admin/voucher">
								<h3
									className={`${
										location.pathname === '/console/admin/voucher'
											? 'active_semibold'
											: null
									}`}
								>
									Voucher
								</h3>
							</Link>
							<Link to="/console/admin/cdr/realtime">
								<h3
									className={`${
										location.pathname === '/console/admin/cdr/realtime' ||
										location.pathname === '/console/admin/cdr/daily'
											? 'active_semibold'
											: null
									}`}
								>
									CDR
								</h3>
							</Link>
							<Link to="/console/admin/testkey">
								<h3
									className={`${
										location.pathname === '/console/admin/testkey'
											? 'active_semibold'
											: null
									}`}
								>
									TestKey
								</h3>
							</Link>
							<Link to="/console/admin/contact">
								<h3
									className={`${
										location.pathname === '/console/admin/contact'
											? 'active_semibold'
											: null
									}`}
								>
									Contact us
								</h3>
							</Link>
						</div>
					) : null}
					{/* ------- super admin 추가  끝--------- */}
				</div>

				<div className="mobile_side_bar">
					<button type="button" onClick={handleTabMenu}>
						<h3 className="h3_active">{filter}</h3>{' '}
						{openMenu ? (
							<IoIosArrowUp
								style={{ fontSize: '20px', color: '#333', cursor: 'pointer' }}
							/>
						) : (
							<IoIosArrowDown
								style={{ fontSize: '20px', color: '#333', cursor: 'pointer' }}
							/>
						)}
					</button>
					<>
						{openMenu && role === 'agency' && (
							/* openMenu이 true이면 공통 컴포넌트 렌더링 */
							<>
								<div className="display">
									<h3>Service</h3>
									<button
										type="button"
										onClick={() => {
											setFilter('Service ID');
											navigate('/console/service/service-id');
											setOpenMenu(false);
										}}
									>
										<h3 className="menu">Service ID</h3>
									</button>
									<button
										type="button"
										onClick={() => {
											setFilter('Web Hook');
											navigate('/console/service/web-hook');
											setOpenMenu(false);
										}}
									>
										<h3 className="menu">Web Hook</h3>
									</button>

									<button
										type="button"
										onClick={() => {
											setFilter('Recording');
											navigate('/console/service/recording');
											setOpenMenu(false);
										}}
									>
										<h3 className="menu">Recording</h3>
									</button>

									<h3>Billing</h3>
									<button
										type="button"
										onClick={() => {
											setFilter('Invoice');
											navigate('/console/billing/invoice');
											setOpenMenu(false);
										}}
									>
										<h3 className="menu">Invoice</h3>
									</button>
									<button
										type="button"
										onClick={() => {
											setFilter('Voucher');
											navigate('/console/billing/voucher');
											setOpenMenu(false);
										}}
									>
										<h3 className="menu">Voucher</h3>
									</button>
									<button
										type="button"
										onClick={() => {
											setFilter('CDR');
											navigate('/console/billing/realtime');
											setOpenMenu(false);
										}}
									>
										<h3 className="menu">CDR</h3>
									</button>
									<h3>MyPage</h3>
									<button
										type="button"
										onClick={() => {
											setFilter('MyPage');
											navigate('/console/my-page');
											setOpenMenu(false);
										}}
									>
										<h3 className="menu">MyPage</h3>
									</button>
									{/* ------일반 사용자 공통 ---- */}
								</div>
							</>
						)}
						{openMenu && role === 'admin' && (
							/* openMenu이 true이고 role이 'admin'인 경우에는 공통 컴포넌트와 관리자 컴포넌트를 함께 렌더링 */
							<>
								<div className="display">
									<h3>Service</h3>
									<button
										type="button"
										onClick={() => {
											setFilter('Service ID');
											navigate('/console/service/service-id');
											setOpenMenu(false);
										}}
									>
										<h3 className="menu">Service ID</h3>
									</button>

									<button
										type="button"
										onClick={() => {
											setFilter('Web Hook');
											navigate('/console/service/web-hook');
											setOpenMenu(false);
										}}
									>
										<h3 className="menu">Web Hook</h3>
									</button>

									<button
										type="button"
										onClick={() => {
											setFilter('Recording');
											navigate('/console/service/recording');
											setOpenMenu(false);
										}}
									>
										<h3 className="menu">Recording</h3>
									</button>

									<h3>Billing</h3>
									<button
										type="button"
										onClick={() => {
											setFilter('Invoice');
											navigate('/console/billing/invoice');
											setOpenMenu(false);
										}}
									>
										<h3 className="menu">Invoice</h3>
									</button>
									<button
										type="button"
										onClick={() => {
											setFilter('Voucher');
											navigate('/console/billing/voucher');
											setOpenMenu(false);
										}}
									>
										<h3 className="menu">Voucher</h3>
									</button>
									<h3>MyPage</h3>
									<button
										type="button"
										onClick={() => {
											setFilter('MyPage');
											navigate('/console/my-page');
											setOpenMenu(false);
										}}
									>
										<h3 className="menu">MyPage</h3>
									</button>
									{/* ------일반 사용자 공통 ---- */}
									{/* -----어드민 시작 ------ */}
									<h3>Super Admin</h3>
									<button
										type="button"
										onClick={() => {
											setFilter('Agency');
											navigate('/console/admin/agency');
											setOpenMenu(false);
										}}
									>
										<h3 className="menu">Agency</h3>
									</button>
									<button
										type="button"
										onClick={() => {
											setFilter('Voucher');
											navigate('/console/admin/voucher');
											setOpenMenu(false);
										}}
									>
										<h3 className="menu">Voucher</h3>
									</button>
									<button
										type="button"
										onClick={() => {
											setFilter('CDR');
											navigate('/console/admin/cdr/realtime');
											setOpenMenu(false);
										}}
									>
										<h3 className="menu">CDR</h3>
									</button>
									<button
										type="button"
										onClick={() => {
											setFilter('TestKey');
											navigate('/console/admin/testkey');
											setOpenMenu(false);
										}}
									>
										<h3 className="menu">TestKey</h3>
									</button>
									<button
										type="button"
										onClick={() => {
											setFilter('Contact us');
											navigate('/console/admin/contact');
											setOpenMenu(false);
										}}
									>
										<h3 className="menu">Contact us</h3>
									</button>
									{/* -----어드민 끝 ------ */}
								</div>
							</>
						)}
					</>
				</div>
			</section>
		</StyledSidebar>
	);
}

export default ConsoleBar;

const StyledSidebar = styled.div`
/* padding-top: 120px; */
*{
  font-family: Pretendard;
}
section{
    h3{
    font-size: 20px;
    font-weight: ${fontWeight.semiBold};
    }
  .side_bar{
    width: 200px;
    padding-right: 38px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    h3{
        position: relative;
        font-size: 20px;
        font-weight: ${fontWeight.medium};
        margin-top: 11px;
      :hover{
        font-weight: ${fontWeight.medium};
        color: ${palette.main.default};
      }
    
    }
      .active_semibold{
        font-weight: ${fontWeight.semiBold};
        color: ${palette.main.default};
    }
    button{
      padding: 0;
      text-align: start;
      border: 0;
      height: 40px;
      background-color: ${palette.opacity};
      .menu{
        cursor: pointer;
      }
      h3{
        font-size: 20px;
        font-weight: ${fontWeight.semiBold};
       
      :hover{
        font-weight: ${fontWeight.semiBold};
        color: ${palette.main.default};
      }
      .console-title{
        pointer-events: none;

      }
      }
    }
    .intro{
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      h3{
      margin: 0;
      margin-bottom: 15px;
      font-size: 20px;
      color: ${palette.text.disabled};
      :hover{
        color: ${palette.main.default}; 
      }
      }
    .active{
      margin: 0;
      margin-bottom: 15px;
      color: ${palette.main.default};
    }
    }
    .toggle_open{
      h3{
      font-size: ${fontSize.small};
      color: ${palette.text.disabled};
      :hover{
        color: ${palette.main.default};
      }
      }
      .active_semibold{
        font-weight: ${fontWeight.medium};
        color: ${palette.main.default};
    }
    }
  }
  .mobile_side_bar{
    display: none;

  }
}

@media screen and (max-width: 979px) and (min-width: 580px) {
  section{
    .side_bar{
      display: none;
    }
    .mobile_side_bar{
    margin: 20px auto;
    display: block;
    width: 90%;
    height: 42px;
    border: 1px solid ${palette.gray.consoleBar};
    border-radius: 10px;
   
    button{
      width: 100%;
      height: 40px;
      margin: 0 auto;
      padding: 0;
      border: 0;
      cursor: pointer;
      background-color: ${palette.opacity};
      ${defaultFlexCenter}
      h3{
        width: 90%;
        color: ${palette.main.default};
        font-weight: ${fontWeight.medium};
      }
      h3:hover{
        color: ${palette.main.default};
      }
    }
  }
  .display{
    padding-top: 20px;
    border: 1px solid ${palette.gray.middle};
    background-color: ${palette.white};
    h3{
      width: 90%;
      height: 40px;
      margin: 0 auto;
      line-height: 40px;
      font-weight: ${fontWeight.medium};
      text-align: center;
      color: ${palette.text.default};
      border-bottom: 1px solid ${palette.gray.middle};
    }
    button{
      h3{
      color: ${palette.text.default};
      border-bottom: 1px solid ${palette.gray.middle};
    }
    .menu{
      border: 0;
      font-size: ${fontSize.small};
      color: ${palette.text.light};
    }
    }
    
  }
  }
}

@media screen and (max-width: 579px) and (min-width: 230px) {
  section{
    h3{
      font-size: 1.1rem;
    }
    .side_bar{
      display: none;
    }
    .mobile_side_bar{
    width: 90%;
    height: 42px;
    margin: 0 auto;
    display: block;
    border: 1px solid ${palette.gray.consoleBar};
    border-radius: 10px;
    button{
      width: 100%;
      height: 36px;
      margin: 0 auto;
      padding: 0;
      border: 0;
      color: #222;
      cursor: pointer;
      background-color: ${palette.opacity};
      ${defaultFlexCenter}
      h3{
        width: 90%;
        color: ${palette.main.default};
        font-weight: ${fontWeight.semiBold};
        font-size: 18px;
        display: flex;
      }
      h3:hover{
        color: ${palette.main.default};
      }
    }
  }
  .display{
    position: absolute;
    top: 35px;
    left: 50%;
    transform: translateX(-50%);
    width: 89.9%;
    border: 1px solid ${palette.gray.middle};
    background-color: ${palette.white};
    h3{
      width: 90%;
      height: 40px;
      margin: 0 auto;
      line-height: 40px;
      font-weight: ${fontWeight.regular};
      text-align: center;
      color: ${palette.text.default};
      border-bottom: 1px solid ${palette.gray.middle};
    }
    button{
      h3{
      color: ${palette.text.default};
      border-bottom: 1px solid ${palette.gray.middle};
    }
    .menu{
      border: 0;
      font-size: ${fontSize.small};
      color: ${palette.text.light};
    }
    }
    
  }
  }
}

`;