export const startupType = {
  '1': '법인사업자',
  '2': '개인사업자',
  '3': '예비창업자'
};

export const paidType = {
  '0': '납부완료',
  '1': '미납'
};

export const expiredType = {
  '0' : '정상',
  '1' : '만료됨'
};

export const activeType = {
  '0' : '미승인',
  '1' : '승인',
  '-1': '탈퇴'

};

export const completeType = {
  '0' : '미처리',
  '1' : '처리완료',
};

export const roomType = {
  '1': 'AC',
  '2': 'VC',
  '3': 'AR',
  '4': 'VR',
  '5': 'DR',
  '6': 'WEB'

};
