import React from 'react';

import styled from 'styled-components';
import { palette } from './style';

const ErrorMSWrapper = styled.div`
  height: 300px;
`;
const ErrorMSTitle = styled.h3`
  display: block;
  text-align: center;
  font-size: 24px;
  font-weight: 600;
  color: 'red';
  margin: 10px 20px;
`;
const ErrorMSReason = styled.h4`
  display: block;
  text-align: center;
  font-size: 18px;
  color: ${palette.text.default}
`;

const ErrorMS = ({ result, reason }) => {
  return (
    <ErrorMSWrapper>
      <ErrorMSTitle>
        {result}
      </ErrorMSTitle>
      <ErrorMSReason>
        {reason}
      </ErrorMSReason>
    </ErrorMSWrapper>
  );
};

export default ErrorMS;