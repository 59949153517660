import { sha256 } from 'js-sha256';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { PWD_REGEX } from '../utill/regex';
import API from '../../modules/api/api';
import myAxios from '../../modules/api/myAxios';
import Loading from '../Loading';

function PwdInfo({ data }) {
  const { t } = useTranslation();
  const initFormData = { currentPasswd: '',
    passwd: '', passwd_confirm: '',
  };
  
  const [formData, setFormData] = useState(initFormData);
  const [errors, setErrors] = useState('');
  const [isGoogle, setIsGoogle] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const handleFormData = (e) => {
    setFormData(prev => ({ ...prev, [e.target.name]: e.target.value }));
  };
  // 현재비밀번호 확인
  const isCurrentPwdConfirm = (data, formData) => {
    const hashedCurrentPwd = sha256(formData.currentPasswd);
    if (data.passwd !== hashedCurrentPwd) {
      setErrors(t('error.currentPw'));
      return false;
    } else {
      return true;
    }
  };

  const isNewPwdMatch = (formData) => {
    if (formData.passwd !== formData.passwd_confirm) {
      setErrors(t('error.newPw'));
      return false;
    } else {
      return true;
    }

  };

  const isFormattingRight = (formData) => {
    const result = PWD_REGEX.test(formData.passwd_confirm);
    if (result === false) setErrors(t('error.passwordTwo'));
    return result;
  };

  const submitPwd = (formData) => {
    const newPW = sha256(formData.passwd_confirm);
    const json = {
      manager_email: data.manager_email,
      newPW, 
    };
    setIsLoading(true);
    API.modifyPwd(json).then(response => {

      if (response.status === 200) {
        
        setFormData(initFormData);
        setErrors(t('error.finished'));
        setIsLoading(false);
      } else {
        setFormData(initFormData);
        setErrors(t('error.passwordFail'));
        setIsLoading(false);
      } 
    });
  };
  useEffect(() => {
    myAxios.get('/users/info').then(response => {
      if (response.data.data.passwd === 'google')
        setIsGoogle(true);
    });
  }, []); 
  return (
    <>
      {isGoogle ? (
        <form className="pwd_form">
          <div id="pwd" className=" modify_input password_modify ">
            <div className="social">
              <span>{t('login.social')}</span>
            </div>
          </div>
     
        </form>
      ) : (
        <form className="pwd_form">
          {isLoading && (
            <StyledLoading>
              <Loading />
            </StyledLoading>
          )}
          <div id="pwd" className=" modify_input password_modify ">
            <div className="input_box" style={{ marginBottom: '20px' }}>
              <label htmlFor="currentPasswd">{t('console.password.currentPw')}</label>
              <input
                value={formData.currentPasswd}
                type="password" name="currentPasswd" onChange={handleFormData} placeholder={t('console.password.currentInput')}
                maxLength="20" />
            </div>
            <div className="input_box" style={{ marginBottom: '20px' }}>
              <label htmlFor="passwd">{t('console.password.newPw')}</label>
              <input
                type="password" name="passwd" value={formData.passwd} onChange={handleFormData} placeholder={t('console.password.newInput')}
                maxLength="20" />
            </div>
          
            <div className="input_box" style={{ marginBottom: '20px' }}>
              <label htmlFor="passwd_confirm">{t('console.password.confirmPw')}</label>
              <input
                type="password" name="passwd_confirm" value={formData.passwd_confirm} placeholder={t('console.password.cfmInput')}
                maxLength="20" onChange={handleFormData} />
            </div>
            <span style={{ textAlign: 'center', color: 'red', fontSize:'13px', fontWeight:'600' }}>{errors}</span>
        
            <div className="button_type button_pwd">
              <button
                type="button" onClick={() => {
                  if (isCurrentPwdConfirm(data, formData) && isNewPwdMatch(formData) && isFormattingRight(formData)) {
                    submitPwd(formData);
                  }
                }}>{t('console.password.button')}
              </button>
            </div> 
          </div>
     
        </form>
      ) }
    </>
  );
}

export default PwdInfo;

const StyledLoading = styled.div`
@media screen and  (min-width: 700px) {
}
`;