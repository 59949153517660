import moment from 'moment';
import { useEffect, useState } from 'react';
import i18n from '../../i18n';

export default function MomentFormat(data, length = 'full') {
  const [ready, setReady] = useState(false);
  const [format, setFormat] = useState('');

  useEffect(() => {
    i18n.changeLanguage(navigator.language.slice(0, 2)).then(() => {
      moment.locale(i18n.language);
    });
  }, []);

  useEffect(() => {
    setTimeout(() => {
      switch (i18n.language) {
      case 'ko':
        setFormat(moment(data.reg_date * 1000).format(length === 'full' ? 'YYYY-MM-DD HH:mm:ss' : 'YYYY-MM-DD'));
        break;
      case 'en':
        setFormat(moment(data.reg_date * 1000).format(length === 'full' ? 'MM/DD/YYYY HH:mm:ss' : 'MM/DD/YYYY'));
        break;
      case 'ja':
        setFormat(moment(data.reg_date * 1000).format(length === 'full' ? 'YYYY年MM月DD日 HH時MM分SS秒' : 'YYYY年MM月DD日'));
        break;
      default:
        setFormat('');
      }
    }, 200);
  }, [i18n.language]);

  useEffect(() => {
    setReady(true);
  }, [format]);

  if (!ready) return null;

  return format;
}
