import React from 'react';
import styled from 'styled-components';
import { FaPhone } from 'react-icons/fa';
import { IoMdClose } from 'react-icons/io';
const PhoneAskMessgeWapper = styled.div`
	/* background-color: rgba(0,0,0,0.2); */
	height: 100%;
	width: 100%;
	/* transform: translate(0%, 50%); */
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
	position: relative;
`;

const RequestAudioCallIconWapper = styled.div`
	position: absolute;
	bottom: 30px;
	left: calc(50% - 40px);
	width: 70px;
	height: 70px;
	border: 1px solid #ccc;
	border-radius: 50%;
	background: linear-gradient(34deg, #ff7d1a 0%, #892dba 100%);
	background: linear-gradient(365deg, rgba(253, 148, 88, 0.9) 0%, #fa5734 100%);

	animation: bounce 0.8s infinite linear;
	svg {
		position: absolute;
		top: 25%;
		left: 25%;
		margin: 0;
		width: 50%;
		height: 50%;
		fill: #fff;
	}
	&:hover {
		cursor: pointer;
	}

	@keyframes bounce {
		0% {
			bottom: 30px;
		}
		50% {
			bottom: 25px;
		}
		70% {
			bottom: 40px;
		}
		100% {
			bottom: 30px;
		}
	}
`;
export const PhoneHeader = styled.div`
  color: #333;
  word-break: keep-all;
  text-align:center;
  transform: translate(0px, 30px);
  
`;



const PhoneAskMessage = ({ handleClick, handleClose }) => {
  return (
      <PhoneAskMessgeWapper>
        <IoMdClose onClick={handleClose} className="cursor-pointer w-8 h-8 ml-auto mr-2 mt-2 text-[#676767] bg-gray-200 rounded-full"></IoMdClose>
          <PhoneHeader>
              <span className="block text-4xl font-bold text-main-50">1533-5251</span> 
               <span className="block pt-[1.5rem] text-[1.4rem] font-bold">전화 상담을 연결 해드릴까요?</span> 
              </PhoneHeader>
      <RequestAudioCallIconWapper onClick={handleClick}> <FaPhone /> </RequestAudioCallIconWapper>
    </PhoneAskMessgeWapper>
  );
};

export default React.memo(PhoneAskMessage);
