import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

function ManagerInfo({ data }) {
  const { t } = useTranslation();
  return (
    <>
      <form>
      
        <div className="input_box">
          <span className="label">{t('console.managerInfo.name')}</span>
          <span>{data.manager_name}</span>
        </div>
        <div className="input_box">
          <span className="label">{t('console.managerInfo.phone')}</span>
          <span>{data.manager_phone}</span>
        </div>
        <div className="checkbox_radio input_box">
          <span className="label">{t('console.managerInfo.email')}</span>
          <span>{data.manager_email}</span>
        </div>
        <div className="button_type">
          <Link to="/contact"><button type="button">{t('console.managerInfo.contact')}</button></Link>
        </div>
      </form>
    </>
  );
}

export default ManagerInfo;