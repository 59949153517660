import * as React from 'react';

export const CustomIcon = {
	apple: props => (
		<svg
			width="10"
			height="13"
			viewBox="0 0 10 13"
			fill="currentColor"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M9.68063 4.18745C9.60938 4.24272 8.35155 4.95148 8.35155 6.52745C8.35155 8.35033 9.95209 8.99521 10 9.01118C9.99263 9.05048 9.74573 9.89436 9.15612 10.7542C8.63039 11.5109 8.08132 12.2663 7.24604 12.2663C6.41076 12.2663 6.1958 11.7811 5.23154 11.7811C4.29186 11.7811 3.95775 12.2823 3.19371 12.2823C2.42968 12.2823 1.89657 11.5821 1.28363 10.7223C0.57364 9.71257 0 8.14396 0 6.6552C0 4.26729 1.55263 3.00086 3.0807 3.00086C3.89264 3.00086 4.56946 3.53396 5.07923 3.53396C5.56443 3.53396 6.32109 2.96892 7.24481 2.96892C7.59489 2.96892 8.85272 3.00086 9.68063 4.18745ZM6.80629 1.95799C7.18831 1.50473 7.45854 0.875814 7.45854 0.246898C7.45854 0.159686 7.45117 0.0712443 7.4352 0C6.81366 0.0233387 6.07419 0.413954 5.6283 0.93109C5.27822 1.32908 4.95148 1.95799 4.95148 2.5955C4.95148 2.69132 4.96745 2.78713 4.97482 2.81784C5.01413 2.82521 5.078 2.8338 5.14187 2.8338C5.69955 2.8338 6.40093 2.46039 6.80629 1.95799Z"
				fill="currentColor"
			/>
		</svg>
	),
	googleColor: props => (
		<svg
			width="479"
			height="534"
			viewBox="0 0 479 534"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M9.64601 8.35486C2.8763 16.477 -0.547156 26.8719 0.0712839 37.4272V495.97C-0.512162 506.521 2.90718 516.903 9.64601 525.042L11.2128 526.609L267.99 269.658V263.565L11.2128 6.78809L9.64601 8.35486Z"
				fill="url(#paint0_linear_2454_30758)"
			/>
			<path
				d="M353.64 355.308L267.99 269.658V263.565L353.64 178.089L355.555 179.133L457.047 236.756C486.119 253.12 486.119 280.103 457.047 296.641L355.555 354.264L353.64 355.308Z"
				fill="url(#paint1_linear_2454_30758)"
			/>
			<path
				d="M355.555 354.264L268.512 267.221L9.646 525.042C15.6128 529.948 23.0416 532.73 30.763 532.95C38.4844 533.171 46.0599 530.818 52.2971 526.261L355.033 354.264"
				fill="url(#paint2_linear_2454_30758)"
			/>
			<path
				d="M355.555 179.133L52.2971 6.96224C46.1268 2.21842 38.501 -0.236097 30.722 0.017909C22.9431 0.271915 15.4937 3.21868 9.646 8.35493L267.99 266.699L355.555 179.133Z"
				fill="url(#paint3_linear_2454_30758)"
			/>
			<path
				opacity="0.1"
				d="M353.64 352.349L52.2969 523.301C46.2859 527.78 38.9898 530.199 31.4936 530.199C23.9974 530.199 16.7013 527.78 10.6903 523.301L9.12354 524.868L10.6903 526.435C16.7013 530.914 23.9974 533.333 31.4936 533.333C38.9898 533.333 46.2859 530.914 52.2969 526.435L355.032 354.438L353.64 352.349Z"
				fill="black"
			/>
			<path
				opacity="0.07"
				d="M9.64595 522.257C2.89585 514.056 -0.522091 503.616 0.071223 493.011V495.97C-0.512223 506.521 2.90712 516.903 9.64595 525.043L11.2127 523.476L9.64595 522.257Z"
				fill="black"
			/>
			<path
				opacity="0.07"
				d="M457.047 293.508L353.64 352.349L355.555 354.264L457.047 295.945C463 293.327 468.152 289.175 471.975 283.915C475.798 278.654 478.156 272.472 478.808 266.002C477.62 271.983 475.028 277.596 471.244 282.379C467.461 287.161 462.595 290.975 457.047 293.508Z"
				fill="black"
			/>
			<path
				opacity="0.3"
				d="M52.2971 10.0961L457.047 239.716C462.54 242.185 467.374 245.916 471.155 250.604C474.936 255.293 477.558 260.807 478.808 266.699C478.191 260.217 475.847 254.02 472.02 248.753C468.192 243.487 463.021 239.344 457.047 236.756L52.2971 6.96252C23.747 -9.40156 0.0712891 4.17715 0.0712891 37.4276V40.387C0.0712891 7.31069 23.747 -6.4421 52.2971 10.0961Z"
				fill="white"
			/>
			<defs>
				<linearGradient
					id="paint0_linear_2454_30758"
					x1="245.358"
					y1="32.5528"
					x2="-102.639"
					y2="380.551"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#008EFF" />
					<stop offset="0.01" stopColor="#008FFF" />
					<stop offset="0.26" stopColor="#00ACFF" />
					<stop offset="0.51" stopColor="#00C0FF" />
					<stop offset="0.76" stopColor="#00CDFF" />
					<stop offset="1" stopColor="#00D1FF" />
				</linearGradient>
				<linearGradient
					id="paint1_linear_2454_30758"
					x1="460.529"
					y1="266.699"
					x2="-41.187"
					y2="266.699"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#FFD800" />
					<stop offset="1" stopColor="#FF8A00" />
				</linearGradient>
				<linearGradient
					id="paint2_linear_2454_30758"
					x1="308.029"
					y1="314.224"
					x2="-163.744"
					y2="785.997"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#FF3A44" />
					<stop offset="1" stopColor="#B11162" />
				</linearGradient>
				<linearGradient
					id="paint3_linear_2454_30758"
					x1="-55.4622"
					y1="-144.318"
					x2="155.356"
					y2="66.3256"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#328E71" />
					<stop offset="0.07" stopColor="#2D9571" />
					<stop offset="0.48" stopColor="#15BD74" />
					<stop offset="0.8" stopColor="#06D575" />
					<stop offset="1" stopColor="#00DE76" />
				</linearGradient>
			</defs>
		</svg>
	),
};
