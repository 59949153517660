import { OmniEvent } from "../public-types/event";
import { EVENT_CMD } from "../types/enum";

export default class Utils {
    static sleep(ms: number) {
        return new Promise((r) => setTimeout(r, ms));
    }

    static getUnixTimestamp(date: Date): Promise<number> {
        return new Promise(async (resolve, reject) => {
            if (date instanceof Date) resolve(Math.floor(date.getTime() / 1000))
            else reject('invalid date parameter');
        });
	}

    static makeOmniEvent(originEvent: EVENT_CMD): OmniEvent {
        switch (originEvent) {
            case EVENT_CMD.LEAVE_NOTI:
                return OmniEvent.LEAVE_EVENT;
            case EVENT_CMD.KICKOUT_NOTI:
                return OmniEvent.KICKOUT_EVENT;
            case EVENT_CMD.BROADCAST_NOTI:
                return OmniEvent.BROADCASTING_EVENT;
            case EVENT_CMD.RINGBACK_NOTI:
                return OmniEvent.RINGBACK_EVENT;
            case EVENT_CMD.RINGING_NOTI:
                return OmniEvent.RINGING_EVENT;
            case EVENT_CMD.CALL_CONNECTED_NOTI:
                return OmniEvent.CONNECTED_EVENT;
            case EVENT_CMD.SCREEN_SHARE_NOTI:
                return OmniEvent.SCREEN_SHARE_EVENT;
            case EVENT_CMD.SCREEN_UNSHARE_NOTI:
                return OmniEvent.SCREEN_UNSHARE_EVENT;
            case EVENT_CMD.MUTE_NOTI:
                return OmniEvent.MUTE_EVENT;
            case EVENT_CMD.UNMUTE_NOTI:
                return OmniEvent.UNMUTE_EVENT;
            case EVENT_CMD.MESSAGE:
                return OmniEvent.MESSAGE_EVENT;
        }
    }
}