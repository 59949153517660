import styled from 'styled-components';
import { breakpoint, palette } from '../../modules/defines/style';

const TableListWrapper = styled.div`
  margin: 0 auto;
  @media only screen and (max-width: ${breakpoint.md}) {
    margin: 0 -20px;
  }
  .popup & {
    margin: 0;
  }
`;
const TableHead = styled.div`
  display: flex;
  border-bottom: 1px solid #C4C4C4;
`;
const TableHeadItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${props => props.width};
  min-height: 40px;
  padding: 0 5px;
  color: ${palette.text.default};
  line-height: 60px;
  font-weight: 600;
  text-align: center;
  @media only screen and (max-width: ${breakpoint.md}) {
    font-size: 2vw;
  }
`;
const TableBody = styled.div`
margin-bottom: 60px;
`;
const TableBodyRow = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  height: 50px;
  border-bottom: 1px solid #D3D7DB;
  :hover {
    background-color: rgba(232, 107, 58, 0.1);
  }
`;
const TableBodyItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${props => props.width};
  padding: 5px;
  font-size: 14px;
  word-break: break-all;
  text-align: center;
  a {
    display: inline-flex;
    align-items: center;
    padding: 4px;
    border-radius: 6px;
    border: 1px solid ${palette.gray.dark};
    font-size: 12px;
    white-space: nowrap;
  }
  svg {
    width: 24px;
    height: 24px;
  }
  .icon-play path {
    fill: ${palette.text.default};
  }
  .icon-download path {
    stroke: ${palette.text.default};
  }
  a:hover {
   
    .icon-play path {
    }
    .icon-download path {
    }
  }
  a + a {
    margin-left: 5px;
  }
  @media only screen and (max-width: ${breakpoint.md}) {
    font-size: 2vw;
  }
`;
const RowEmpty = styled.div`
  width: 100%;
  padding: 50px;
  text-align: center;
`;

const TableList = ({ data = [], lists = [], onClick }) => {
  return (
    <TableListWrapper>
      <TableHead>
        {data.map((d, i) => (
          <TableHeadItem key={i} width={d.width}>{d.title}</TableHeadItem>
        ))}
      </TableHead>
      <TableBody>
        {lists.length === 0 ? (
          <RowEmpty>
            no data
          </RowEmpty>
        ) : lists.map((l, i) => (
          <TableBodyRow key={i} style={onClick && { cursor: 'pointer' }} onClick={() => onClick && onClick(l)}>
            {data.map((d, j) => (
              <TableBodyItem key={j} width={d.width}>
                {d.type === 'string' && l[d.element]}
                {d.type === 'element' && d.element(l)}
              </TableBodyItem>
            ))}
          </TableBodyRow>
        ))}
      </TableBody>
    </TableListWrapper>
  );
};

export default TableList;