import "webrtc-adapter";
import { EventEmitter } from 'events';
import OmniWebsocket from "./websocket/websocket-manager";
import { CALL_STATE, EVENT_CMD, REQUEST_MESSAGE_ACTION } from './types/enum';
import { CallInfo, SYSTEM } from './types/common';
import { CreateRoomResult, CreateSessionResult, JoinRoomResult, MakeSipNumberResult, MessageListResult, PartiListResult, PublishListResult, PublishResult, RoomListResult, ScreenListResult, ScreenShareResult, ScreenUnshareResult, SessionListResult, SubscribeResult, UnsubscribeResult } from './public-types/result';
import { CALL_TYPE, DeviceList, RESOLUTION, TRACK_TYPE, VIDEOROOM_TYPE } from './public-types/common';
import { EventBroadcast, EventConnected, EventKickOut, EventLeave, EventMessage, EventMute, EventRingBack, EventRinging, EventScreenShare, EventScreenUnshare, EventUnmute } from './public-types/event';
import ClientAudio from './webrtc/webrtc-client-audio';
import ClientPublisher from './webrtc/webrtc-client-publisher';
import ClientSubscriber from './webrtc/webrtc-client-subscriber';
import { NotiBroadcast, NotiConnected, NotiKickOut, NotiLeave, NotiMessage, NotiMute, NotiRingBack, NotiRinging, NotiScreenShare, NotiScreenUnshare, NotiUnmute } from './types/event';
import Utils from "./commons/utils";

export * from './public-types/common'
export * from './public-types/event'
export * from './public-types/result'

export class Omnitalk extends EventEmitter {
    private static instance: Omnitalk;

    private SERVICE_ID: string;
    private SERVICE_KEY?: string;
    private STATE: CALL_STATE;

    private ws?: OmniWebsocket;
    private session?: string;
    private user_id?: string;
    private turn_id?: string;
    private turn_secret?: string;
    private room_id?: string;
    private room_type?: VIDEOROOM_TYPE;

    private videoPublishFlag = false;
    private callInfo?: CallInfo;
    private remoteTagId?: string;

    private audioClient?: ClientAudio;
    private publisher?: ClientPublisher;
    private screenClient?: ClientPublisher;
    private subscribers: Map<string, ClientSubscriber>;

    private constructor(service_id: string, service_key?: string) {
        super();
        this.STATE = CALL_STATE.NULL_STATE;
        this.SERVICE_ID = service_id;
        this.SERVICE_KEY = service_key;
        this.subscribers = new Map();
    }

    public static sdkInit(service_id: string, service_key?: string) {
        if (Omnitalk.instance) {
            throw new Error('Already init called');
        }
        Omnitalk.instance = new Omnitalk(service_id, service_key);
    }

    public static getInstance() {
        if (!Omnitalk.instance) throw new Error('sdkInit function should be called first');
        return Omnitalk.instance;
    }

    private emitHandler() {
        this.ws?.on('close', () => {
            this.emit('close');
            this.freeResources();
        });

        this.ws?.on('event', async (jsonMsg) => {
            const cmd = jsonMsg["cmd"] as EVENT_CMD;
            const userCmd = Utils.makeOmniEvent(jsonMsg["cmd"]);
            let userEventMsg;
            switch (cmd) {
                case EVENT_CMD.LEAVE_NOTI:
                    const leaveMsg = jsonMsg as NotiLeave;
                    const subscriber = this.subscribers.get(leaveMsg.session);
                    if (subscriber) {
                        subscriber.freeResources()
                        this.subscribers.delete(leaveMsg.session);
                    }
                    const userLeaveMsg: EventLeave = {
                        cmd: userCmd,
                        session: jsonMsg.session
                    }
                    userEventMsg = userLeaveMsg;
                    break;
                case EVENT_CMD.KICKOUT_NOTI:
                    const kickOutMsg = jsonMsg as NotiKickOut;
                    const userKickOutMsg: EventKickOut = {
                        cmd: userCmd,
                        session: kickOutMsg.session,
                        room_type: kickOutMsg.room_type,
                        call_type: kickOutMsg.call_type
                    }
                    userEventMsg = userKickOutMsg;
                case EVENT_CMD.BROADCAST_NOTI:
                    const broadCastMsg = jsonMsg as NotiBroadcast;
                    if (broadCastMsg.room_type == VIDEOROOM_TYPE.AUDIO_CALL) {
                        return;
                    } else if (broadCastMsg.room_type == VIDEOROOM_TYPE.VIDEO_CALL) {
                        const subscribeResult = await this.ws!.requestSubscribe(broadCastMsg.session);
                        const subscriber = new ClientSubscriber(this.ws!, subscribeResult.session, this.turn_id!, this.turn_secret!);
                        await subscriber.subscribe(subscribeResult, this.remoteTagId);
                        return;
                    }
                    const userBroadcastMsg: EventBroadcast = {
                        cmd: userCmd,
                        session: jsonMsg.session,
                        user_id: jsonMsg.user_id,
                        room_type: jsonMsg.room_type,
                        call_type: jsonMsg.call_type
                    }
                    userEventMsg = userBroadcastMsg;
                    break;
                case EVENT_CMD.RINGBACK_NOTI:
                    const ringBackMsg = jsonMsg as NotiRingBack;
                    const userRingBackMsg: EventRingBack = {
                        cmd: userCmd,
                        session: ringBackMsg.session,
                        caller: ringBackMsg.caller,
                        callee: ringBackMsg.callee,
                        call_type: ringBackMsg.call_type
                    }
                    userEventMsg = userRingBackMsg;
                    break
                case EVENT_CMD.RINGING_NOTI:
                    this.callInfo = jsonMsg;
                    const ringingMsg = jsonMsg as NotiRinging;
                    const userRingingMsg: EventRinging = {
                        cmd: userCmd,
                        session: ringingMsg.session,
                        user_id: ringingMsg.user_id,
                        caller: ringingMsg.caller,
                        callee: ringingMsg.callee,
                        room_type: ringingMsg.room_type,
                        call_type: ringingMsg.call_type
                    }
                    userEventMsg = userRingingMsg;
                    break;
                case EVENT_CMD.CALL_CONNECTED_NOTI:
                    // this.STATE == CALL_STATE.CONNECT_STATE;
                    const connectedMsg = jsonMsg as NotiConnected;
                    const userConnectedMsg: EventConnected = {
                        cmd: userCmd,
                        session: connectedMsg.session,
                        user_id: connectedMsg.user_id,
                        room_type: connectedMsg.room_type,
                        call_type: connectedMsg.call_type
                    }
                    userEventMsg = userConnectedMsg;
                    break;
                case EVENT_CMD.MUTE_NOTI:
                    const muteMsg = jsonMsg as NotiMute;
                    const userMuteMsg: EventMute = {
                        cmd: userCmd,
                        session: muteMsg.session,
                        track: muteMsg.track
                    }
                    userEventMsg = userMuteMsg;
                    break;
                case EVENT_CMD.UNMUTE_NOTI:
                    const unmuteMsg = jsonMsg as NotiUnmute;
                    const userUnmuteMsg: EventUnmute = {
                        cmd: userCmd,
                        session: unmuteMsg.session,
                        track: unmuteMsg.track
                    }
                    userEventMsg = userUnmuteMsg;
                    break;
                case EVENT_CMD.SCREEN_SHARE_NOTI:
                    const screenShareMsg = jsonMsg as NotiScreenShare;
                    const userScreenShareMsg: EventScreenShare = {
                        cmd: userCmd,
                        session: screenShareMsg.session,
                        user_id: screenShareMsg.user_id,
                        room_type: screenShareMsg.room_type,
                        call_type: screenShareMsg.call_type
                    }
                    userEventMsg = userScreenShareMsg;
                    break;
                case EVENT_CMD.SCREEN_UNSHARE_NOTI:
                    const screenUnshareMsg = jsonMsg as NotiScreenUnshare;
                    const userScreenUnshareMsg: EventScreenUnshare = {
                        cmd: userCmd,
                        session: screenUnshareMsg.session,
                        user_id: screenUnshareMsg.user_id,
                        room_type: screenUnshareMsg.room_type,
                        call_type: screenUnshareMsg.call_type
                    }
                    userEventMsg = userScreenUnshareMsg;
                    break;
                case EVENT_CMD.MESSAGE:
                    const messageMsg = jsonMsg as NotiMessage;
                    const userMessageMsg: EventMessage = {
                        cmd: userCmd,
                        session: messageMsg.session,
                        action: messageMsg.action,
                        user_id: messageMsg.user_id,
                        user_name: messageMsg.user_name,
                        timestamp: messageMsg.timestamp,
                    }
                    if (messageMsg.message) {
                        userMessageMsg.message = messageMsg.message;
                    }
                    userEventMsg = userMessageMsg;
                    break;
            }
            this.emit('event', userEventMsg ?? jsonMsg);
        });
    }

    async createSession(user_id?: string): Promise<CreateSessionResult> {
        return new Promise(async (resolve, reject) => {
            try {
                if (!Omnitalk.instance) {
                    reject('sdkInit function should be called first');
                    return;
                }
                if (this.ws || this.session) {
                    reject('Already session created');
                    return;
                }
                if (user_id && typeof(user_id) != 'string') {
                    reject(`Invalid parameter, user_id: ${user_id}`);
                    return;
                }
                this.STATE = CALL_STATE.SESSION_STATE;
                this.ws = new OmniWebsocket();
                await this.ws!.connect();
                this.emitHandler();
                const createSessionResult = await this.ws!.requestCreateSession(this.SERVICE_ID, SYSTEM.SDK, SYSTEM.VER, this.SERVICE_KEY, user_id);
                this.session = createSessionResult.session;
                this.user_id = createSessionResult.user_id;
                this.turn_id = createSessionResult.turn_id;
                this.turn_secret = createSessionResult.turn_secret;

                this.publisher = new ClientPublisher(this.ws!, createSessionResult.session, createSessionResult.turn_id, createSessionResult.turn_secret);
                this.audioClient = new ClientAudio(this.ws!, createSessionResult.session, createSessionResult.turn_id, createSessionResult.turn_secret);

                const { cmd, result, turn_id, turn_secret, reason, ...userResult } = createSessionResult;
                resolve(userResult);
            } catch (err) {
                this.freeResources();
                reject(err);
            }
        });
    }

    async makeSipNumber(call_number?: string, room_id?: string): Promise<MakeSipNumberResult> {
        return new Promise(async (resolve, reject) => {
            try {
                if (this.STATE != CALL_STATE.SESSION_STATE) {
                    reject(`The state that cannot make sip number, state: ${this.STATE}`);
                    return;
                }
                if ( (call_number && typeof(call_number) != 'string') || (room_id && typeof(room_id) != 'string') ) {
                    reject(`Invalid parameter${call_number ? `, call_number: ${call_number}` : ''}${room_id ? `, room_id: ${room_id}` : ''}`);
                    return;
                }
                const makeSipNumberResult = await this.ws!.requestMakeSipNumber(call_number, room_id);
                const { cmd, result, reason, ...userResult } = makeSipNumberResult
                resolve(userResult);
            } catch (err) {
                reject(err);
            }
        });
    }

    async sessionList(page?: number): Promise<SessionListResult> {
        return new Promise(async (resolve, reject) => {
            try {
                if (this.STATE != CALL_STATE.SESSION_STATE) {
                    reject(`The state that cannot fetch call list, state: ${this.STATE}`);
                    return;
                }
                if (page && typeof(page) != 'number') {
                    reject(`Invalid parameter, page: ${page}`)
                    return;
                }
                const callListResult = await this.ws!.requestSessionList(page);
                
                const { cmd, result, reason, ...userResult } = callListResult
                resolve(userResult);
            } catch (err) {
                reject(err);
            }
        });
    }

    async createRoom(room_type: VIDEOROOM_TYPE, subject?: string, secret?: string, start_date?: Date, end_date?: Date): Promise<CreateRoomResult> {
        return new Promise(async (resolve, reject) => {
            try {
                if (this.STATE != CALL_STATE.SESSION_STATE) {
                    reject(`The state that cannot create room, state: ${this.STATE}`);
                    return;
                }
                if ( !room_type || !Object.values(VIDEOROOM_TYPE).includes(room_type) || (subject && typeof(subject) != 'string') || (secret && typeof(secret) != 'string') || (start_date && !(start_date instanceof Date) || (end_date && !(end_date instanceof Date) )) ){
                    reject(`Invalid parameter, room_type: ${room_type}${subject ? `, subject: ${subject}` : ''}${secret ? `, secret: ${secret}` : ''}${start_date ? `, start_date: ${start_date}` : ''}${end_date ? `, end_date: ${end_date}` : ''}`);
                    return;
                }
                const createRoomResult = await this.ws!.requestCreateRoom(room_type, subject, secret, start_date, end_date);
                const { cmd, result, reason, ...userResult } = createRoomResult;
                resolve(userResult);
            } catch (err) {
                reject(err);
            }
        });
    }

    async destroyRoom(room_id: string): Promise<CreateRoomResult> {
        return new Promise(async (resolve, reject) => {
            try {
                if (this.STATE != CALL_STATE.SESSION_STATE) {
                    reject(`The state that cannot destroy room, state = ${this.STATE}`);
                    return;
                }
                if ( !room_id && typeof(room_id) != 'string') {
                    reject(`Invalid parameter, room_id: ${room_id}`);
                    return;
                }
                const createRoomResult = await this.ws!.requestDestroyRoom(room_id ?? this.room_id!!);
                const { cmd, result, reason, ...userResult } = createRoomResult;
                resolve(userResult);
            } catch (err) {
                reject(err);
            }
        });
    }

    async roomList(room_type?: VIDEOROOM_TYPE, page?: number): Promise<RoomListResult> {
        return new Promise(async (resolve, reject) => {
            try {
                if (this.STATE != CALL_STATE.SESSION_STATE) {
                    reject(`The state that cannot fetch room list, state = ${this.STATE}`);
                    return;
                }
                if ( (room_type && !Object.values(VIDEOROOM_TYPE).includes(room_type)) || (page && typeof(page) != 'number') ){
                    reject(`Invalid parameter${room_type ? `, room_type: ${room_type}` : ''}${page ? `, page: ${page}` : ''}`);
                    return;
                }
                const roomListResult = await this.ws!.requestRoomList(room_type, page);
                const { cmd, result, reason, ...userResult } = roomListResult
                resolve(userResult);
            } catch (err) {
                reject(err);
            }
        });
    }

    async joinRoom(room_id: string, secret?: string, user_name?: string): Promise<JoinRoomResult> {
        return new Promise(async (resolve, reject) => {
            try {
                if (this.STATE != CALL_STATE.SESSION_STATE) {
                    reject(`The state that cannot join room, state = ${this.STATE}`);
                    return;
                }
                if ( !room_id || typeof(room_id) != 'string' || (secret && typeof(secret) != 'string') || (user_name && typeof(user_name) != 'string')) {
                    reject(`Invalid parameter, room_id: ${room_id}${secret ? `, secret: ${secret}` : ''}${user_name ? `, user_name: ${user_name}` : ''}`);
                    return;
                }
                const joinRoomResult = await this.ws!.requestJoinRoom(room_id, secret, user_name);
                this.STATE = CALL_STATE.ACTIVE_STATE;
                await this.audioClient!.publish();
                this.room_id = joinRoomResult.room_id;
                this.room_type = joinRoomResult.room_type;
                const { cmd, result, reason, ...userResult } = joinRoomResult;
                resolve(userResult);
            } catch (err) {
                reject(err);
            }
        });
    }

    async partiList(room_id?: string, page?: number): Promise<PartiListResult> {
        return new Promise(async (resolve, reject) => {
            try {
                if (this.STATE != CALL_STATE.ACTIVE_STATE && this.STATE != CALL_STATE.PUBLISH_STATE && this.STATE != CALL_STATE.SUBSCRIBE_STATE && this.STATE != CALL_STATE.CONNECT_STATE) {
                    reject(`The state that cannot fetch parti list, state = ${this.STATE}`);
                    return;
                }
                if (this.room_type != VIDEOROOM_TYPE.AUDIO_ROOM && this.room_type != VIDEOROOM_TYPE.VIDEO_ROOM && this.room_type != VIDEOROOM_TYPE.WEBINAR) {
                    reject(`Invalid request, parti list no required in this video room type, ${this.room_type}`);
                }
                if ( room_id && typeof(room_id) != 'string' || page && typeof(page) != 'number' ) {
                    reject(`Invalid parameter${room_id ? `, room_id: ${room_id}` : ''}${page ? `, page: ${page}` : ''}`);
                    return;
                }
                const partiListResult = await this.ws!.requestPartiList(room_id ?? this.room_id!, page);
                
                const {cmd, result, reason, ...userResult} = partiListResult;
                resolve(userResult);
            } catch (err) {
                reject(err);
            }
        });
    }

    async publishList(room_id?: string, page?: number): Promise<PublishListResult> {
        return new Promise(async (resolve, reject) => {
            try {
                if (this.STATE != CALL_STATE.ACTIVE_STATE && this.STATE != CALL_STATE.PUBLISH_STATE && this.STATE != CALL_STATE.SUBSCRIBE_STATE && this.STATE != CALL_STATE.CONNECT_STATE) {
                    reject(`The state that cannot fetch publish list, state = ${this.STATE}`);
                    return;
                }
                if (this.room_type != VIDEOROOM_TYPE.AUDIO_ROOM && this.room_type != VIDEOROOM_TYPE.VIDEO_ROOM && this.room_type != VIDEOROOM_TYPE.WEBINAR) {
                    reject("Invalid request, publish list no required in this video room type");
                }
                if ( room_id && typeof(room_id) != 'string' || page && typeof(page) != 'number' ) {
                    reject(`Invalid parameter${room_id ? `, room_id: ${room_id}` : ''}${page ? `, page: ${page}` : ''}`);
                    return;
                }
                const publishListResult = await this.ws!.requestPublishList(room_id ?? this.room_id!, page);

                const {cmd, result, reason, ...userResult} = publishListResult;
                resolve(userResult);
            } catch (err) {
                reject(err);
            }
        });
    }

    async screenList(room_id?: string): Promise<ScreenListResult> {
        return new Promise(async (resolve, reject) => {
            try {
                if (this.STATE != CALL_STATE.ACTIVE_STATE && this.STATE != CALL_STATE.PUBLISH_STATE && this.STATE != CALL_STATE.SUBSCRIBE_STATE && this.STATE != CALL_STATE.CONNECT_STATE) {
                    reject(`The state that cannot fetch screen list, state = ${this.STATE}`);
                    return;
                }
                if (this.room_type != VIDEOROOM_TYPE.VIDEO_ROOM && this.room_type != VIDEOROOM_TYPE.WEBINAR) {
                    reject("Invalid request, screen list no required in this video room type");
                }
                if ( room_id && typeof(room_id) != 'string' ) {
                    reject(`Invalid parameter${room_id ? `, room_id: ${room_id}` : ''}`);
                    return;
                }
                const screenListResult = await this.ws!.requestScreenList(room_id ?? this.room_id!);

                const {cmd, result, reason, ...userResult} = screenListResult;
                resolve(userResult);
            } catch (err) {
                reject(err);
            }
        });
    }

    async publish(tag_id?: string): Promise<PublishResult> {
        return new Promise(async (resolve, reject) => {
            try {
                if (this.STATE == CALL_STATE.PUBLISH_STATE || this.STATE == CALL_STATE.CONNECT_STATE) {
                    reject('Invalid request, Already started');
                    return;
                }
                if (this.STATE != CALL_STATE.ACTIVE_STATE) {
                    reject(`The state that cannot publish, state = ${this.STATE}`)
                    return;
                }
                if (this.room_type != VIDEOROOM_TYPE.VIDEO_ROOM && this.room_type != VIDEOROOM_TYPE.WEBINAR) {
                    reject(`Not supported by the joined room type, ${this.room_type}`);
                    return;
                }
                if ( tag_id && typeof(tag_id) != 'string') {
                    reject(`Invalid parameter, tag_id: ${tag_id}`);
                    return;
                }
                this.STATE = CALL_STATE.PUBLISH_STATE;
                const publishResult = await this.publisher!.publish(tag_id);
                this.STATE = CALL_STATE.CONNECT_STATE;
                this.videoPublishFlag = true;
                const userResult: PublishResult = {
                    session: publishResult.session
                }
                resolve(userResult);
            } catch (err) {
                this.STATE = CALL_STATE.ACTIVE_STATE;
                reject(err);
            }
        });
    }

    async screenShare(tag_id?: string): Promise<ScreenShareResult> {
        return new Promise(async (resolve, reject) => {
            try {
                if (this.STATE != CALL_STATE.ACTIVE_STATE && this.STATE != CALL_STATE.PUBLISH_STATE && this.STATE != CALL_STATE.SUBSCRIBE_STATE && this.STATE != CALL_STATE.CONNECT_STATE) { // TODO: 화면 공유 가능한 상태 확인, 웨비나의 경우 subscribe 중에도 가능한가
                    reject(`The state that cannot screen share, state = ${this.STATE}`);
                    return;
                }
                if (this.room_type != VIDEOROOM_TYPE.VIDEO_ROOM && this.room_type != VIDEOROOM_TYPE.WEBINAR) {
                    reject(`Not supported by the joined room type, ${this.room_type}`);
                    return;
                }
                if (tag_id && typeof(tag_id) != 'string') {
                    reject(`Invalid parameter, tag_id: ${tag_id}`);
                    return;
                }
                this.STATE = CALL_STATE.PUBLISH_STATE;
                this.screenClient = new ClientPublisher(this.ws!, this.session!, this.turn_id!, this.turn_secret!);
                const screenShareResult = await this.screenClient.screenShare(tag_id);
                this.STATE = CALL_STATE.CONNECT_STATE;
                const userResult: ScreenShareResult = {
                    session: screenShareResult.session
                }
                resolve(userResult);
            } catch (err) {
                this.STATE = CALL_STATE.ACTIVE_STATE;
                reject(err);
            }
        });
    }

    async screenUnshare(): Promise<ScreenUnshareResult> {
        return new Promise(async (resolve, reject) => {
            try {
                if (!this.screenClient) {
                    reject(`Notfound screen share`);
                    return;
                }
                const screenUnshareResult = await this.ws!.requestScreenUnshare();
                this.screenClient.freeResources();
                this.screenClient = undefined;
                const userResult: ScreenUnshareResult = {
                    session: screenUnshareResult.session
                }
                resolve(userResult);
            } catch (err) {
                this.STATE = CALL_STATE.ACTIVE_STATE;
                reject(err);
            }
        });
    }

    async subscribe(publisher_session: string, tag_id?: string): Promise<SubscribeResult> {
        return new Promise(async (resolve, reject) => {
            try {
                if (this.STATE != CALL_STATE.ACTIVE_STATE && this.STATE != CALL_STATE.PUBLISH_STATE && this.STATE != CALL_STATE.SUBSCRIBE_STATE && this.STATE != CALL_STATE.CONNECT_STATE) {
                    reject(`The state that cannot subscribe, state = ${this.STATE}`);
                    return;
                }
                if (this.room_type != VIDEOROOM_TYPE.VIDEO_ROOM && this.room_type != VIDEOROOM_TYPE.WEBINAR) {
                    reject("Invalid request, no subscribe required in audio mode");
                    return;
                }
                if ( !publisher_session || typeof(publisher_session) != 'string' || (tag_id && typeof(tag_id) != 'string')) {
                    reject(`Invalid parameter, publisher_session: ${publisher_session}${tag_id ? `, tag_id: ${tag_id}` : ''}`);
                    return;
                }
                if (this.subscribers.size >= SYSTEM.MAX_USER_CNT) {
                    reject(`Invalid request, subscribes exceeded`);
                    return;
                }
                if (this.subscribers.get(publisher_session)) {
                    reject(`Invalid request, already exist subscribe`);
                    return;
                }
                const subscribeResult = await this.ws!.requestSubscribe(publisher_session);
                this.STATE = CALL_STATE.SUBSCRIBE_STATE;
                const subscriber = new ClientSubscriber(this.ws!, subscribeResult.session, this.turn_id!, this.turn_secret!);
                await subscriber.subscribe(subscribeResult, tag_id);
                this.subscribers.set(subscribeResult.subscribe, subscriber);
                this.STATE = this.videoPublishFlag ? CALL_STATE.CONNECT_STATE : CALL_STATE.ACTIVE_STATE; // 웨비나에서 subscribe 이후에 publish 할 경우 대비

                const userResult: SubscribeResult = {
                    session: subscribeResult.session,
                    publisher_session: subscribeResult.subscribe,
                    screen: subscribeResult.screen
                }
                resolve(userResult);
            } catch (err) {
                reject(err);
            }
        });
    }

    async unsubscribe(publisher_session: string): Promise<UnsubscribeResult> {
        return new Promise(async (resolve, reject) => {
            try {
                if (this.STATE != CALL_STATE.ACTIVE_STATE && this.STATE != CALL_STATE.PUBLISH_STATE && this.STATE != CALL_STATE.SUBSCRIBE_STATE && this.STATE != CALL_STATE.CONNECT_STATE) {
                    reject(`The state that cannot unsubscribe, state = ${this.STATE}`);
                    return;
                }
                if ( !publisher_session || typeof(publisher_session) != 'string') {
                    reject(`Invalid parameter, publisher_session: ${publisher_session}`);
                    return;
                }
                const subscriber = this.subscribers.get(publisher_session);
                if (!subscriber) {
                    reject(`Invalid parameter, not found subscribe ${publisher_session}`);
                    return;
                }
                const subscribeResult = await this.ws!.requestUnsubscribe(publisher_session);
                subscriber.freeResources();
                this.subscribers.delete(subscribeResult.subscribe);
                const userResult: UnsubscribeResult = {
                    session: subscribeResult.session,
                    publisher_session: subscribeResult.subscribe
                }
                resolve(userResult);
            } catch (err) {
                reject(err);
            }
        });
    }

    async offerCall(call_type: CALL_TYPE, callee: string, record?: boolean, local_tag_id?: string, remote_tag_id?: string): Promise<void> {
        return new Promise(async (resolve, reject) => {
            try {
                if (this.STATE != CALL_STATE.SESSION_STATE) {
                    reject(`The state that cannot offer call, state = ${this.STATE}`);
                    return;
                }
                if ( !call_type || !Object.values(CALL_TYPE).includes(call_type) || call_type == CALL_TYPE.UNKNOWN || !callee || (typeof(callee) != 'string') || (record && typeof(record) != 'boolean') || (local_tag_id && typeof(local_tag_id) != 'string') || (remote_tag_id && typeof(remote_tag_id) != 'string') ) {
                    reject(`Invalid parameter, call_type: ${call_type}, callee: ${callee}${record ? `, record: ${record}` : ''}${local_tag_id ? `, local_tag_id: ${local_tag_id}` : ''}${remote_tag_id ? `, remote_tag_id: ${remote_tag_id}` : ''}`);
                    return;
                }
                const offerCallResult = await this.ws!.requestOfferCall(call_type, this.user_id!, callee, record ?? false);

                let room_id: string;
                if (offerCallResult.room_id) {
                    room_id = offerCallResult.room_id;
                } else {
                    let room_type;
                    switch (call_type) {
                        case CALL_TYPE.AUDIO_CALL:
                            room_type = VIDEOROOM_TYPE.AUDIO_CALL;
                            break;
                        case CALL_TYPE.VIDEO_CALL:
                            room_type = VIDEOROOM_TYPE.VIDEO_CALL;
                            break;
                        case CALL_TYPE.SIP_CALL:
                            room_type = VIDEOROOM_TYPE.SIP_CALL;
                            break;
                    }
                    const createRoomResult = await this.createRoom(room_type);
                    room_id = createRoomResult.room_id;
                }
                await this.joinRoom(room_id!);
                if (call_type == CALL_TYPE.VIDEO_CALL) {
                    await this.publisher!.publish(local_tag_id);
                    this.videoPublishFlag = true;
                }
                resolve();
            } catch (err) {
                reject(err);
            }
        });
    }

    async answerCall(call_type?: CALL_TYPE, caller?: string, local_tag_id?: string, remote_tag_id?: string): Promise<void> {
        return new Promise(async (resolve, reject) => {
            try {
                if (this.STATE != CALL_STATE.SESSION_STATE) {
                    reject(`The state that cannot answer call, state = ${this.STATE}`);
                    return;
                }
                if ( call_type && !Object.values(CALL_TYPE).includes(call_type) || call_type == CALL_TYPE.UNKNOWN || ( caller && (typeof(caller) != 'string')) || ( local_tag_id && (typeof(local_tag_id) != 'string')) || ( remote_tag_id && (typeof(remote_tag_id) != 'string')) ) {
                    reject(`Invalid parameter${call_type ? `, call_type: ${call_type}` : ''}${caller ? `, caller: ${caller}` : ''}${local_tag_id ? `, local_tag_id: ${local_tag_id}` : ''}${remote_tag_id ? `, remote_tag_id: ${remote_tag_id}` : ''}`);
                    return;
                }
                let sdk_call_type: CALL_TYPE;
                let sdk_caller: string;
                if (call_type && caller) {
                    sdk_call_type = call_type;
                    sdk_caller = caller;
                } else if (this.callInfo) {
                    sdk_call_type = this.callInfo.call_type;
                    sdk_caller = this.callInfo.caller;
                } else {
                    reject('no call request and invalid parameter');
                    return;
                }
                const answerCallResult = await this.ws!.requestAnswerCall(sdk_call_type, sdk_caller, this.user_id!);
                await this.joinRoom(answerCallResult.room_id);
                if (sdk_call_type == CALL_TYPE.VIDEO_CALL) {
                    this.remoteTagId = remote_tag_id;
                    await this.publisher!.publish(local_tag_id);
                }
                resolve();
            } catch (err) {
                reject(err);
            }
        });
    }

    async getDeviceList(): Promise<DeviceList> {
        return new Promise(async (resolve, reject) => {
            try {
                let videoDevices: Array<MediaDeviceInfo> = new Array();
                let audioDevices: Array<MediaDeviceInfo> = new Array();
                const deviceInfos = await navigator.mediaDevices.enumerateDevices();
                deviceInfos.forEach((deviceInfo, i) => {
                    if (deviceInfo.kind === 'audioinput') {
                        audioDevices.push(deviceInfo);
                    } else if (deviceInfo.kind === 'videoinput') {
                        videoDevices.push(deviceInfo);
                    }
                });
                const deviceList: DeviceList = {
                    videoinput: videoDevices,
                    audioinput: audioDevices
                }
                resolve(deviceList);
            } catch (err){
                reject(err);
            }
        });
	}

    async setMute(track_type: TRACK_TYPE): Promise<void> {
        return new Promise(async (resolve, reject) => {
            try {
                if (track_type == TRACK_TYPE.VIDEO && !this.publisher) {
                    reject(`The state that cannot set mute, no publish state`);
                    return;
                }
                if ( !track_type || !Object.values(TRACK_TYPE).includes(track_type) ) {
                    reject(`Invalid parameter, track_type: ${track_type}`);
                    return;
                }
                await this.ws?.requestMute(track_type);
                if (track_type == TRACK_TYPE.VIDEO) {
                    this.publisher?.setLocalVideoMute(true);
                }
                resolve();
            } catch (err) {
                reject(err);
            }
        });
    }

    async setUnmute(track_type: TRACK_TYPE): Promise<void> {
        return new Promise(async (resolve, reject) => {
            try {
                if (track_type == TRACK_TYPE.VIDEO && !this.publisher) {
                    reject(`The state that cannot set mute, no publish state`);
                    return;
                }
                if ( !track_type || !Object.values(TRACK_TYPE).includes(track_type) ) {
                    reject(`Invalid parameter, track_type: ${track_type}`);
                    return;
                }
                await this.ws?.requestUnmute(track_type);
                if (track_type == TRACK_TYPE.VIDEO) {
                    this.publisher?.setLocalVideoMute(false);
                }
                resolve();
            } catch (err) {
                reject(err);
            }
        });
    }

    async setAudioDevice(deviceId: string): Promise<void> {
        return new Promise(async (resolve, reject) => {
            try {
                if (!deviceId || typeof(deviceId) != 'string') {
                    reject(`Invalid parameter, deviceId: ${deviceId}`);
                    return;
                }
                await this.audioClient!.setAudioDevice(deviceId);
                resolve();
            } catch (err) {
                reject(err);
            }
        });
    };

    async setVideoDevice(deviceId: string): Promise<void> {
        return new Promise(async (resolve, reject) => {
            try {
                if (!deviceId || typeof(deviceId) != 'string') {
                    reject(`Invalid parameter, deviceId: ${deviceId}`);
                    return;
                }
                await this.publisher!.setVideoDevice(deviceId);
                resolve();
            } catch (err) {
                reject(err);
            }
        });
    };

    async setResolution(resolution: RESOLUTION): Promise<void> {
        return new Promise(async (resolve, reject) => {
            try {
                if ( !resolution || !Object.values(RESOLUTION).includes(resolution) ) {
                    reject(`Invalid parameter, resolution: ${resolution}`);
                    return;
                }
                await this.publisher!.setResolution(resolution, this.STATE);
                resolve();
            } catch (err) {
                reject(err);
            }
        });
    }

    async leave(): Promise<void> {
        return new Promise(async (resolve, reject) => {
            try {
                if (!this.ws) {
                    reject(`Invalid request, already disconnected`);
                    return;
                }
                // if (session && (typeof(session) != 'string')) {
                    // reject(`Invalid parameter, session: ${session}`);
                // }
                // if (!session && this.STATE == CALL_STATE.SESSION_STATE) {
                    // reject(`The state that cannot leave session: ${session}, the state = ${this.STATE}`);
                    // return;
                // }
                await this.ws!.requestLeave();
                resolve();
            } catch (err) {
                reject(err);
            }
        });
    }

    async kickOut(target: string): Promise<void> {
        return new Promise(async (resolve, reject) => {
            try {
                if (!this.ws) {
                    reject(`Invalid request, already disconnected`);
                    return;
                }
                if (!target && (typeof(target) != 'string')) {
                    reject(`Invalid parameter, target: ${target}`);
                }
                if (this.STATE != CALL_STATE.ACTIVE_STATE && this.STATE != CALL_STATE.PUBLISH_STATE && this.STATE != CALL_STATE.SUBSCRIBE_STATE && this.STATE != CALL_STATE.CONNECT_STATE) {
                    reject(`The state that cannot kickout, state = ${this.STATE}`);
                    return;
                }
                // if (!session && this.STATE == CALL_STATE.SESSION_STATE) {
                    // reject(`The state that cannot leave session: ${session}, the state = ${this.STATE}`);
                    // return;
                // }
                await this.ws!.requestKickOut(target);
                resolve();
            } catch (err) {
                reject(err);
            }
        });
    }

    async sendMessage(message: string): Promise<void> {
        return new Promise(async (resolve, reject) => {
            try {
                if (this.STATE != CALL_STATE.ACTIVE_STATE && this.STATE != CALL_STATE.PUBLISH_STATE && this.STATE != CALL_STATE.SUBSCRIBE_STATE && this.STATE != CALL_STATE.CONNECT_STATE) {
                    reject(`The state that cannot send message, state = ${this.STATE}`);
                    return;
                }
                if (!this.ws) {
                    reject(`Invalid websoket connection state`);
                    return;
                }
                if ( !message || typeof(message) != 'string' ) {
                    reject(`Invalid parameter, message: ${message}`);
                    return;
                }
                await this.ws!.requestMessage(REQUEST_MESSAGE_ACTION.SEND, message);
                resolve();
            } catch (err) {
                reject(err);
            }
        });
    }

    async sendWhisper(message: string, target: string): Promise<void> {
        return new Promise(async (resolve, reject) => {
            try {
                if (this.STATE != CALL_STATE.ACTIVE_STATE && this.STATE != CALL_STATE.PUBLISH_STATE && this.STATE != CALL_STATE.SUBSCRIBE_STATE && this.STATE != CALL_STATE.CONNECT_STATE) {
                    reject(`The state that cannot send whisper message, state = ${this.STATE}`);
                    return;
                }
                if (!this.ws) {
                    reject(`Invalid websoket connection state`);
                    return;
                }
                if ( !message || typeof(message) != 'string' || !target || typeof(target) != 'string') {
                    reject(`Invalid parameter, message: ${message}, target: ${target}`);
                    return;
                }
                await this.ws!.requestMessage(REQUEST_MESSAGE_ACTION.WHISPER, message, target);
                resolve();
            } catch (err) {
                reject(err);
            }
        });
    }

    async messageList(): Promise<MessageListResult> {
        return new Promise(async (resolve, reject) => {
            try {
                if (!this.ws) {
                    reject(`Invalid websoket connection state`);
                    return;
                }
                if (this.STATE != CALL_STATE.ACTIVE_STATE && this.STATE != CALL_STATE.PUBLISH_STATE && this.STATE != CALL_STATE.SUBSCRIBE_STATE && this.STATE != CALL_STATE.CONNECT_STATE) {
                    reject(`The state that cannot fetch message list, state = ${this.STATE}`);
                    return;
                }
                const messageListResult = await this.ws!.requestMessageList();
                const { cmd, transaction, result, reason, ...userResult }  = messageListResult;
                resolve(userResult);
            } catch (err) {
                reject(err);
            }
        });
    }

    private freeResources() {
        this.STATE = CALL_STATE.NULL_STATE;
        this.ws = undefined;
        this.session = undefined;
        this.user_id = undefined;
        this.turn_id = undefined;
        this.turn_secret = undefined;
        this.room_id = undefined;
        this.room_type = undefined;

        this.videoPublishFlag = false;
        this.callInfo = undefined;

        this.audioClient?.freeResources();
        this.audioClient = undefined;
        this.publisher?.freeResources();
        this.publisher = undefined;
        this.screenClient?.freeResources();
        this.screenClient = undefined;
        this.subscribers.forEach(subscriber => { // (subscriber, key)
            subscriber.freeResources();
          });
        this.subscribers = new Map();
    }

}