import React from 'react';
import demoAudio from '../../assets/demo-audio.png';
import demoAudioWebp from '../../assets/demo-audio.webp';
import demoVideo from '../../assets/demo-video.png';
import demoVideoWebp from '../../assets/demo-video.webp';
import demoPhone from '../../assets/demo-phone.png';
import demoPhoneWebp from '../../assets/demo-phone.webp';
import demoMessage from '../../assets/demo-message.png';
import demoMessageWebp from '../../assets/demo-message.webp';
import { useTranslation } from 'react-i18next';
const demoCategory = [
	{
		type: 'Audio',
		URL: '/demo/audio',
		description: '옴니톡의 오디오 데모입니다.',
		src: demoAudio,
		webp: demoAudioWebp,
		alt: 'demo-audio-img',
	},
	{
		type: 'Video',
		URL: '/demo/video',
		description: '옴니톡의 비디오 데모입니다.',
		src: demoVideo,
		webp: demoVideoWebp,
		alt: 'demo-video-img',
	},
	{
		type: 'Telephone',
		URL: '/demo/telephone',
		description: '옴니톡의 비디오 데모입니다.',
		src: demoPhone,
		webp: demoPhoneWebp,
		alt: 'demo-video-img',
	},
	{
		type: 'Message',
		URL: '/demo/message',
		description: '옴니톡의 메시지 데모입니다.',
		src: demoMessage,
		webp: demoMessageWebp,
		alt: 'demo-video-img',
	},
];


export default function DemoCard() {
  const { t } = useTranslation();

  return (
    <>
      <div className="xs:mt-10 sm:mt-16 container  mx-auto px-4 md:px-12">
   
        <div className="flex flex-wrap -mx-1 lg:-mx-4 ">
          {demoCategory.map((category, index) => {
            return (
              <a
                href={category.URL}
                className=" px-4 w-full md:w-1/2  lg:px-4 lg:w-1/2 mb-10 "
                key={index}
              >
                <div className="overflow-hidden rounded-lg shadow-lg hover:border-opacity-0 transform hover:-translate-y-1 transition-all duration-200">
                  <div>
                    <picture>
                    <source type="image/webp" srcSet={category.webp} />
                    <source type="image/png" src={category.src} srcSet={`${category.src} 768w`} alt={category.alt} />
                    <img src={category.src} srcSet={`${category.src} 768w`} alt={category.alt} /> 
                  </picture>
                  <div className="flex items-center justify-between leading-tight p-4 pb-0 dark:bg-gray-100 dark:border-t-[1px] ">
                    <h4 className="text-[20px] px-2 py-3 ">
                    <span className={`no-underline hover:underline xs:text-base sm:text-xl lg:text-[24px] font-[600] dark:text-black text-semi`}>
                       {category.type}
                    </span>
                    </h4>
                  </div>
                    <p className={`px-6 leading-6 mt-0 mb-0 xs:text-sm sm:text-base font-[400] text-gray-700 dark:bg-gray-100 dark:text-black 
                    `} >
                        {t(`demo.${category.type.toLowerCase()}.description`)}
                  </p>
                    <div className="flex items-center justify-between leading-none py-2 md:py-4 dark:bg-gray-100">
                  <span className="flex items-center no-underline  text-main-50" >
                      <p className= {`xs:text-sm sm:text-base pl-6 font-medium text-main-50 `}>
                        Explore demo
                      </p>
                      <svg className={`w-4 h-4 ml-2`} fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M14 5l7 7m0 0l-7 7m7-7H3" />
                      </svg>
                    </span>
                  </div>
                  </div>
                </div>
              </a>
            );
          })}
        </div>

      </div>
    </>

  );
}

