import React from 'react';
import { Editor } from '@toast-ui/react-editor';
import '@toast-ui/editor/dist/toastui-editor.css';
import { XMarkIcon } from '@heroicons/react/24/outline';

export default function TextEditor({
	editorRef,
	onChange,
	onSelect,
	onClose,
	isValidErrOn,
	notice,
	isPatching,
	handleModify,
	type,
	reason,
	onUploadImage,
}) {
	return (
		<>
			{type === 'notice' ? (
				<>
					{isPatching === true ? (
						<>
							<div className="bg-[#f7f9fc] border-[#dadde6] border-[1px] border-b-0 rounded-t-lg p-3">
								<div className="flex justify-end items-center ">
									<button
										type="button"
										className="w-6 h-6 mr-1 cursor-pointer"
										onClick={() => onClose()}
									>
										<XMarkIcon />
									</button>
								</div>
								<div className="flex w-full mb-1 justify-start mt-1 px-1">
									<select
										name="category"
										defaultValue={notice.category}
										onChange={onSelect}
										id="category"
										className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg  focus:ring-blue-500 focus:border-blue-500 block w-50 p-2.5  "
									>
										<option disabled>카테고리 선택</option>
										<option value="일반">일반</option>
										<option value="이벤트">이벤트</option>
										<option value="패치">패치</option>
									</select>
									<input
										defaultValue={notice.writer}
										onChange={onSelect}
										name="writer"
										type="text"
										id="writer"
										aria-describedby="writer-explanation"
										className="ml-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block  p-2.5  w-40  "
										placeholder="작성자"
									/>
								</div>
								<div className="flex w-full mb-1 justify-start px-1">
									<input
										defaultValue={notice.title}
										onChange={onSelect}
										name="title"
										type="text"
										id="title"
										aria-describedby="title-explanation"
										className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 w-full "
										placeholder="제목"
									/>
								</div>
							</div>

							<Editor
								ref={editorRef}
								onChange={onChange}
								initialValue={notice.contents}
								height="500px"
								previewStyle="vertical"
								initialEditType="wysiwyg"
								useCommandShortcut={false}
								toolbarItems={[
									['heading', 'bold', 'italic', 'strike'],
									['hr'],
									['image', 'link'],
									['code'],
								]}
								hooks={{
									addImageBlobHook: onUploadImage,
								}}
							/>
							<div className="flex  flex-row justify-end mt-4">
								<button
									onClick={() => handleModify()}
									className="p-4 w-fit bg-main-50 text-white"
									type="button"
								>
									수정 완료
								</button>
							</div>
							{isValidErrOn && (
								<div className="flex justify-end text-red-500 mt-5 font-semibold">
									<span>* 입력 값이 유효한지 확인해 주세요.</span>
								</div>
							)}
						</>
					) : (
						<>
							<div className="bg-[#f7f9fc] border-[#dadde6] border-[1px] border-b-0 rounded-t-lg p-3">
								<div className="flex justify-end items-center ">
									<button
										type="button"
										className="w-6 h-6 mr-1 cursor-pointer"
										onClick={() => onClose()}
									>
										<XMarkIcon />
									</button>
								</div>
								<div className="flex w-full mb-1 justify-start mt-1 px-1">
									<select
										name="category"
										defaultValue="카테고리 선택"
										onChange={onSelect}
										id="category"
										className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg  focus:ring-blue-500 focus:border-blue-500 block w-50 p-2.5  "
									>
										<option disabled>카테고리 선택</option>
										<option value="일반">일반</option>
										<option value="이벤트">이벤트</option>
										<option value="패치">패치</option>
									</select>
									<input
										onChange={onSelect}
										name="writer"
										type="text"
										id="writer"
										aria-describedby="writer-explanation"
										className="ml-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block  p-2.5  w-40  "
										placeholder="작성자"
									/>
								</div>
								<div className="flex w-full mb-1 justify-start px-1">
									<input
										onChange={onSelect}
										name="title"
										type="text"
										id="title"
										aria-describedby="title-explanation"
										className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 w-full "
										placeholder="제목"
									/>
								</div>
							</div>
							<Editor
								ref={editorRef}
								onChange={onChange}
								initialValue=""
								height="500px"
								previewStyle="vertical"
								initialEditType="wysiwyg"
								useCommandShortcut={false}
								toolbarItems={[
									['heading', 'bold', 'italic', 'strike'],
									['hr'],
									['image', 'link'],
									['code'],
								]}
								hooks={{
									addImageBlobHook: onUploadImage,
								}}
							/>
							{isValidErrOn && (
								<div className="flex justify-end text-red-500 mt-5 font-semibold">
									<span>* 입력 값이 유효한지 확인해 주세요.</span>
								</div>
							)}
						</>
					)}
				</>
			) : type === 'contact' ? (
				<>
					{' '}
					<Editor
						ref={editorRef}
						onChange={onChange}
						initialValue=""
						height="400px"
						width="90%"
						previewStyle="none"
						initialEditType="markdown"
						hideModeSwitch={true}
						useCommandShortcut={false}
						toolbarItems={[
							['bold', 'italic'],
							['task', 'ol', 'ul'],
						]}
					/>
				</>
			) : (
				<>
					{' '}
					{isPatching === true ? (
						<>
							<div className="bg-[#f7f9fc] border-[#dadde6] border-[1px] border-b-0 rounded-t-lg p-3">
								<div className="flex justify-end items-center ">
									<button
										type="button"
										className="w-6 h-6 mr-1 cursor-pointer"
										onClick={() => onClose()}
									>
										<XMarkIcon />
									</button>
								</div>
								<div className="flex w-full mb-1 justify-start mt-1 px-1">
									<select
										name="category"
										defaultValue="카테고리 선택"
										onChange={onSelect}
										id="category"
										className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg  focus:ring-blue-500 focus:border-blue-500 block w-50 p-2.5  "
									>
										<option disabled>카테고리 선택</option>
										<option value="javascript">Javascript</option>
										<option value="typescript">Typescript</option>
										<option value="reactnative">ReactNative</option>
										<option value="flutter">Flutter</option>
										<option value="ios">iOS</option>
										<option value="unity">Unity</option>
										<option value="android">Android</option>
									</select>
									<input
										defaultValue={notice.writer}
										onChange={onSelect}
										name="writer"
										type="text"
										id="writer"
										aria-describedby="writer-explanation"
										className="ml-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block  p-2.5  w-40  "
										placeholder="작성자"
									/>
								</div>
							</div>

							<Editor
								ref={editorRef}
								onChange={onChange}
								initialValue={notice.contents}
								height="500px"
								previewStyle="vertical"
								initialEditType="markdown"
								hideModeSwitch={false}
								useCommandShortcut={false}
							/>
							<div className="flex  flex-row justify-end mt-4">
								<button
									onClick={() => handleModify()}
									className="p-4 w-fit bg-main-50 text-white"
									type="button"
								>
									수정 완료
								</button>
							</div>
							{console.log(reason)}
							{isValidErrOn && (
								<div className="flex justify-end text-red-500 mt-5 font-semibold">
									<span>* {reason}</span>
								</div>
							)}
						</>
					) : (
						<>
							<div className="max-w-[700px] ml-auto mr-auto">
								<div className="bg-[#f7f9fc] border-[#dadde6] border-[1px] border-b-0 rounded-t-lg p-3">
									<div className="flex justify-end items-center ">
										<button
											type="button"
											className="w-6 h-6 mr-1 cursor-pointer"
											onClick={() => onClose()}
										>
											<XMarkIcon />
										</button>
									</div>
									<div className="flex w-full mb-1 justify-start mt-1 px-1">
										<select
											name="lang"
											defaultValue="카테고리 선택"
											onChange={onSelect}
											id="category"
											className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg  focus:ring-blue-500 focus:border-blue-500 block w-50 p-2.5  "
										>
											<option disabled>카테고리 선택</option>
											<option value="javascript">Javascript</option>
											<option value="typescript">Typescript</option>
											<option value="reactnative">ReactNative</option>
											<option value="flutter">Flutter</option>
											<option value="ios">iOS</option>
											<option value="unity">Unity</option>
											<option value="android">Android</option>
										</select>
										<input
											onChange={onSelect}
											name="writer"
											type="text"
											id="writer"
											aria-describedby="writer-explanation"
											className="ml-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block  p-2.5  w-40  "
											placeholder="작성자"
										/>
										<input
											onChange={onSelect}
											name="version"
											type="text"
											id="version"
											aria-describedby="version-explanation"
											className="ml-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block  p-2.5  w-40  "
											placeholder="버전 정보"
										/>
									</div>
								</div>
								<Editor
									ref={editorRef}
									onChange={onChange}
									initialValue=""
									height="500px"
									previewStyle="vertical"
									initialEditType="markdown"
									hideModeSwitch={false}
									useCommandShortcut={false}
									toolbarItems={[
										['bold', 'italic'],
										['task', 'ol', 'ul'],
									]}
								/>
								{isValidErrOn && (
									<div className="flex justify-end text-red-500 mt-5 font-semibold">
										<span>* {reason}</span>
									</div>
								)}
							</div>
						</>
					)}
				</>
			)}
		</>
	);
}
