import React, { useEffect, useState } from 'react';
import { useSetRecoilState } from 'recoil';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { MdAddCard } from 'react-icons/md';
import Pagination from 'react-js-pagination';
import ErrorMS from '../../modules/defines/ErrorMsg';
import { roleState } from '../../recoil';
import API from '../../modules/api/api';
import {
	palette,
	StyledDiv,
	StyledPagination,
} from '../../modules/defines/style';
import SoftTab from '../../components/admin/SoftTab';
import TableList from '../../components/admin/TableList';
import { startupType, paidType, activeType } from '../../modules/defines/enums';
import FormCheck from '../../components/admin/FormCheck';
import Button from '../../components/admin/Button';
import myAxios from '../../modules/api/myAxios';
import Popup, { PopupFormRow } from '../../components/ui/Popup';
import PopupLoading from '../../components/PopupLoading';

function Agency() {
 
  const [errorMS, setErrorMS] = useState({ result:null, reason: null });
  const TabData = [
    { text: '전체', value: 0 },
    { text: '승인', value: 1 },
    { text: '미승인', value: 2 },
    { text: '미납', value: 3 },
    { text: '탈퇴', value: 4 },
  ];
 
  const initAddFormValues = {
    credit:'', depositor: '', bank_name: '', status: 0, memo: '', support:''
  };
  const [addFormValues, setAddFormValues] = useState(initAddFormValues);
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  // const [user, setUser] = useRecoilState(userState);
  const setRole = useSetRecoilState(roleState);
  const [idx, setIdx] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const handlePageChange = currentPage => {
    setCurrentPage(currentPage);
  };
  const [popupState, setPopupState] = useState({
    display: false,
    type: '', // add, rowdata, delete
    data: null,
  });
  const [confirm, setConfirm] = useState({
    display: false,
    type: null,
  });

  const ViewButton = (props) => (
    <ViewButtonWrapper type="button" {...props}>
      {props.children}
    </ViewButtonWrapper>
  );
  const handleRadioChange = (name, value) => {
    setAddFormValues(prev => ({ ...prev, [name]: Number(value) }));
  };
  const handleClosePopup = () => {
    setPopupState(prev => ({ ...prev, display: false, type:null, data: null }));
  };
  const handleClickTab = (index) => {
    setCurrentPage(1);
    switch (index) {
    case 0: 
      requestData(1);
      break;
    case 1: 
      requestAuthData(1);
      break;
    case 2: 
      requestUnAuthData(1);
      break;
    case 3:
      requestUnpaidData(1);
      break;
    case 4:
      requestWithdrawData(1);
      break;
    default:
      requestData(1);
    }
  };

  const handleAddVoucher = (props) => {
    console.log(props);
    setIdx(() => props.idx);
    setPopupState((prev) => ({ ...prev, display: true, type: 'add', data:props.agency_name }));
  };
  
  const handleRequestAdd = () => {
    setIsLoading(true);
    API.addAdminVoucher({
      bank_name: '(주)옴니스토리',
      depositor: '(주)옴니스토리',
      credit: Number(addFormValues.credit),
      support:Number(addFormValues.support),
      agency: idx,
      status: addFormValues.status,
      agency_name: popupState.data
      
    }).then(response => {
      setAddFormValues(initAddFormValues);
      if (response.data.result === 'success') {
        setIsLoading(false);
        setAddFormValues(initAddFormValues);
        setPopupState((prev) => ({ ...prev, display: false }));
        requestData(currentPage);
        setErrorMS((prev) => ({ ...prev, result: response.data.result }));
      } else {
        setIsLoading(false);
        // setErrorMS((prev) => ({ ...prev, result: response.result, reason: response.reason }));
      }
    }, error => {
      setIsLoading(false);
      console.log(error);
    });
  };
  const handleFormChange = (e) => {
    setAddFormValues(prev => ({ ...prev, [e.target.name]: e.target.value }));
  };
  const handleApprove = async() => {
    setIsLoading(true);
    try {
      const response = await myAxios.patch('superadmin/agency', { idx: popupState.data.idx, status:1, role: 'agency' });
      if (response.data.result === 'success') {
        setIsLoading(false);
        setSelectedTabIndex(1);
        setConfirm(false);
        setPopupState(prev => ({ ...prev, display: false, data: null }));
        // setErrorMS((prev) => ({ ...prev, result: response.result }))
      } else {
        // setErrorMS((prev) => ({ ...prev, result: response.result, reason: response.reason }));
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };
  const handleDelete = async() => {
    setIsLoading(true);
    try {
      const response = await myAxios.delete(`users/${popupState.data.idx}`);
      if (response.data.result === 'success') {
        setIsLoading(false);
        setConfirm(false);
        setPopupState(prev => ({ ...prev, display: false, data: null }));
        requestWithdrawData(currentPage);
      }
    } catch (error) {
      setIsLoading(false);
      setConfirm(false);
    }
  };
  const handleWithDraw = async() => {
    setIsLoading(true);
    try {
      const response = await myAxios.patch('superadmin/agency', { idx: popupState.data.idx, status:-1, role: 'expired' });
      if (response.data.result === 'success') {
        setIsLoading(false);
        setSelectedTabIndex(4);
        setConfirm(false);
        setPopupState(prev => ({ ...prev, display: false, data: null }));
        // setErrorMS((prev) => ({ ...prev, result: response.result }))
      } else {
        // setErrorMS((prev) => ({ ...prev, result: response.result, reason: response.reason }));
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }

  };

  const handleClickRow = (row) => {
    setPopupState(prev => ({ ...prev, display: true, type: 'rowdata', data: row }));
  };
  // ================== Calling Data start ===========================
  useEffect(() => {
    authUser();
    switch (selectedTabIndex) { // * 0=전체 1=인증 2=미인증 3=미납 4=탈퇴
    case 0: 
      requestData(currentPage);
      break;
    case 1: 
      requestAuthData(currentPage);
      break;
    case 2:
      requestUnAuthData(currentPage);
      break;
    case 3: 
      requestUnpaidData(currentPage);
      break;
    case 4:
      requestWithdrawData(currentPage);
      break;
    default:
      requestData(currentPage);
    }
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTabIndex, currentPage]);
  const authUser = async() => {
    try {
      const response = await myAxios.get('/users/info');
      if (response.data.data.role !== 'admin') {
        navigate('/login', { replace: true });
      } else {
        setRole('admin');
      }
      
    } catch (error) {
      if (error.response.status === 401) {
        navigate('/login', { replace: true });
      }
      return error;
    }
  };

  const requestAuthData = async(currentPage) => {
    try {
      const response = await myAxios.get(`/superadmin/agency/?page=${currentPage}&perPage=10&authorized=true`);
      if (response.data.result === 'success') {
        setData(response.data.data);
        setTotalPages(response.data.total);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const requestUnAuthData = async (currentPage) => {
    try {
      const response = await myAxios.get(`/superadmin/agency/?page=${currentPage}&perPage=10&unauthorized=true`);
      if (response.data.result === 'success') {
        setData(response.data.data);
        setTotalPages(response.data.total);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const requestWithdrawData = async (currentPage) => {
    try {
      const response = await myAxios.get(`/superadmin/agency/?page=${currentPage}&perPage=10&expired=true`);
      if (response.data.result === 'success') {
        setData(response.data.data);
        setTotalPages(response.data.total);
      }
    } catch (error) {
      console.log(error);
    }
   
  };
  const requestUnpaidData = async (currentPage) => {
    try {
      const response = await myAxios.get(`/superadmin/agency/?page=${currentPage}&perPage=10&unpaid=true`);
      if (response.data.result === 'success') {
        setData(response.data.data);
        setTotalPages(response.data.total);
      }
      
    } catch (error) {
      console.log(error);
    }
  };
  const requestData = async(currentPage) => {
    try {
      const response = await myAxios.get(`/superadmin/agencies?page=${currentPage}&perPage=10`);
      if (response.data.result === 'success')
        setTotalPages(response.data.total);
      setData(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };
  // ================== Calling Data end ===========================

  return (

    <StyledDiv>
      <section>
        <div className="title">
          <h3>Agency</h3>
        </div>
        <SoftTab list={TabData} selectedIndex={selectedTabIndex} onClick={handleClickTab} />
        <TableList
          data={[
            { width: '15%', title: '등록일', type: 'element', element: (props) => moment(props.reg_date * 1000).format('YYYY-MM-DD') },
            { width: '15%', title: '기업명', type: 'string', element: 'agency_name' },
            { width: '15%', title: '담당자', type: 'string', element: 'manager_name' },
            { width: '15%', title: '전화번호', type: 'string', element: 'manager_phone' },
            { width: '15%', title: '구분', type: 'element', element: (props) => startupType[props.startup] },
            { width: '15%', title: '미납요금', type: 'element', element: (props) => paidType[props.unpaid] },
            { width: '15%', title: '상태', type: 'element', element: (props) => activeType[props.status] },
            { width: '15%', title: '바우처 등록', type: 'element', element: (props) => (
              <ViewButton
                className="btn-addvoucher" 
                onClick={(e) => { e.stopPropagation(); handleAddVoucher(props); }}><MdAddCard />
              </ViewButton>
            ) },
          ]}
          lists={data}
          onClick={handleClickRow}
        />
        <StyledPagination>
          <Pagination    
            activePage={currentPage}
            itemsCountPerPage={10}
            totalItemsCount={totalPages}
            pageRangeDisplayed={5}
            prevPageText="‹"
            nextPageText="›"
            onChange={handlePageChange}
          />
        </StyledPagination>
        {errorMS.result === 'failed' && <ErrorMS result={errorMS.result} reason={errorMS.reason} />}
        {popupState.display && popupState.type === 'add' && (
          <Popup title="바우처 등록" theme="add" size="md" onClose={() => { setAddFormValues(initAddFormValues); setPopupState(prev => ({ ...prev, display: false })); }}>
            {isLoading && (
              <PopupLoading />
            )}
            <PopupFormRow title="기업명">
              <div className="row-content">
                {popupState.data}
              </div>
            </PopupFormRow>
            <PopupFormRow title="크레딧">
              <div className="row-content">
                <input
                  type="text" name="credit" placeholder="원 단위로 입력해주세요." maxLength="30"
                  value={addFormValues.credit} onChange={handleFormChange}
                />
              </div>
            </PopupFormRow>
            <PopupFormRow title="서포트">
              <div className="row-content">
                <input
                  type="text" name="support" placeholder="원 단위로 입력해주세요." maxLength="30"
                  value={addFormValues.support} onChange={handleFormChange}
                />
              </div>
            </PopupFormRow>
            <PopupFormRow title="승인 여부">
              <div className="row-content">
                <FormRadioWrap>
                  <FormCheck theme="radio" name="status" value="1" checked={addFormValues.status === 1} onChange={handleRadioChange}>
                    승인
                  </FormCheck>
                  <FormCheck theme="radio" name="status" value="0" checked={addFormValues.status === 0} onChange={handleRadioChange}>
                    미승인
                  </FormCheck>
                </FormRadioWrap>
              </div>
            </PopupFormRow>
            <PopupFormRow title="메모">
              <div className="row-content">
                <input
                  type="text" name="memo" maxLength="100"
                  value="스타트업 개발지원 바우처" disabled
                  style={{ backgroundColor: 'transparent' }}
                />
              </div>
            </PopupFormRow>
            <PopupFormRow>
              <div className="row-btn">
                <Button color="red" disabled={addFormValues.credit.trim() === ''} onClick={() => handleRequestAdd()}>등록</Button> 
              </div>
            </PopupFormRow>
        
          </Popup>
          
        )}
      
        {popupState.display && popupState.data && popupState.type === 'rowdata' && (
          <Popup title="상세 보기" onClose={(handleClosePopup)} size="md">
            {isLoading && (
              <PopupLoading />
            )}
            <PopupFormRow title="기업명">
              <div className="row-content">
                {`${popupState.data.agency_name}`}
              </div>
            </PopupFormRow>
            <PopupFormRow title="주소">
              <div className="row-content">
                {`(${popupState.data.address_code === null ? 'N/A' : popupState.data.address_code}) 
                ${popupState.data.address === null ? 'N/A' : popupState.data.address}`}
              </div>
            </PopupFormRow>
            <PopupFormRow title="회사 전화번호">
              <div className="row-content">
                {popupState.data.business_phone === null ? 'N/A' : popupState.data.business_phone }
              </div>
            </PopupFormRow>
            <PopupFormRow title="사업자등록번호">
              <div className="row-content">
                {popupState.data.business_code === null ? 'N/A' : popupState.data.business_code }
              </div>
            </PopupFormRow>
            <PopupFormRow title="사업 계획">
              <div className="row-content">
                {popupState.data.intro}
              </div>
            </PopupFormRow>
            <PopupFormRow title="사업자 등록증">
              <div className="row-content">
                {popupState.data.business_file === null ? '등록된 파일이 없습니다.' : <a href={`https://omnitalk.io/uploads/${popupState.data.business_file}`} target="_blank" rel="noreferrer" download>사업자등록증_{popupState.data.agency_name}</a>}
              </div>
            </PopupFormRow>
            <PopupFormRow title="등록일">
              <div className="row-content">
                {moment(popupState.data.reg_date * 1000).format('YYYY년 MM월 DD일')}
              </div>
            </PopupFormRow>
            <PopupFormRow title="담당자">
              <div className="row-content">
                {`${popupState.data.manager_name} | ${popupState.data.manager_email} | ${popupState.data.manager_phone}`}
              </div>
            </PopupFormRow>
            <PopupFormRow title="홈페이지">
              <div className="row-content">
                {popupState.data.homepage === '' ? 'N/A' 
                  : (
                    <a
                      href={popupState.data.homepage} target="_blank" rel="noopener noreferrer">{popupState.data.homepage}
                    </a>
                  )}
              </div>
            </PopupFormRow>
            <PopupFormRow title="창업상태">
              <div className="row-content">
                {startupType[popupState.data.startup]}
              </div>
            </PopupFormRow>
            <PopupFormRow>
              <div className="row-two-btn">
                {popupState.data.status === 0 && (
                  <Button onClick={() => setConfirm(prev => ({ ...prev, display: true, type: 'approve' }))}>
                    가입 승인하기
                  </Button>
                )}
                {popupState.data.status !== -1 ? (
                  <Button color="blue" onClick={() => setConfirm(prev => ({ ...prev, display: true, type: 'withdraw' }))}>
                    탈퇴처리
                  </Button>
                ) : (
                  <Button color="blue" onClick={() => setConfirm(prev => ({ ...prev, display: true, type: 'delete' }))}>
                    삭제
                  </Button>
                )}
               
              </div>
            </PopupFormRow>
          </Popup>
        )}
       
        {confirm.display && (
          <Popup theme="confirm" onClose={() => setConfirm(prev => ({ ...prev, display: false, type: null }))}>
            <p>
              {confirm.type === 'withdraw' && ('정말 탈퇴 처리하시겠습니까?')}
              {confirm.type === 'delete' && ('정말 삭제 처리하시겠습니까?')}
              {confirm.type === 'approve' && ('승인하시겠습니까?')} 
            </p>
            <div>
              <Button
                color="red" onClick={() => { 
                  if (confirm.type === 'withdraw') handleWithDraw();
                  if (confirm.type === 'delete') handleDelete();
                  if (confirm.type === 'approve') handleApprove(); 
                }}>예
              </Button>
              <Button color="blue" onClick={() => setConfirm(prev => ({ ...prev, display: false, type: null }))}>아니오</Button>
            </div>
          </Popup>
        )}
      </section>

    </StyledDiv>
    
  );
}

export default Agency;

const ViewButtonWrapper = styled.button`
  background-color: transparent;
  border: none;
  color: #666;
  :hover{
    color: ${palette.main.default};
  }
  cursor: pointer;
  svg {
    max-width: 24x;
    max-height: 24px;
  }
  &.btn-invoice:hover {
    svg path {
        stroke: ${palette.main.default};
    }
  }
  &.btn-invoice:disabled {
    svg path {
      fill: ${palette.gray.bright};
    }
  }
  &.btn-zoom:hover {
    svg path {
      stroke: ${palette.main.default};
    }
  }
`;
const FormRadioWrap = styled.div`
  display: flex;
  label {
    display: flex;
    align-items: center;
  }
`;