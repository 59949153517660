import React from 'react';
import { useTranslation } from 'react-i18next';
import { GrClose } from 'react-icons/gr';
import styled from 'styled-components';
import { defaultFlexCenter, fontSize, palette } from '../../modules/defines/style';

function InvoiceModal({ setShow, agencyName, unpaid, startDate, endDate }) {
  const { t } = useTranslation();
  return (
    <StyledModal>
      <div className="title">
        <h1>{t('console.modal.invoce.title')}</h1>
        <button
          type="button" onClick={() => {
            setShow(false);
          }}><GrClose style={{ fontSize:'20px' }} />
        </button>
      </div>
      <div className="info">
        <ul>
          <li>
            <span>{t('console.modal.invoce.agencyName')}</span>
            <span>{agencyName}</span>
          </li>
          <li>
            <span>{t('console.modal.invoce.bank')}</span>
            <span>111-22-333-4444</span>
          </li>
          <li>
            <span> {t('console.modal.invoce.startDate')}</span>
            <span>{startDate}</span>
          </li>
          <li>
            <span> {t('console.modal.invoce.endDate')}</span>
            <span>{endDate}</span>
          </li>
          <li>
            <span>{t('console.modal.invoce.unpaid')}</span>
            <span className="point">{unpaid}</span>
          </li>
        </ul>
      </div>
      <div className="warning">
        {t('console.modal.invoce.warning')}
        
      </div>
    </StyledModal>
  );
}
  
export default InvoiceModal;

const StyledModal = styled.div`
width: 400px;
height: fit-content;
padding: 25px;
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%,-50%);
border-radius:10px;
background-color: ${palette.gray.light};
.warning{
  display: flex;
  text-align: center;
  justify-content: center;
  font-size: 14px;
  margin-top: 10px;
  margin-bottom: 10px;
  color: ${palette.text.default};
  font-weight: 500;
}
.title{
    ${defaultFlexCenter}
    justify-content: space-between;
    border-bottom: 1px solid #d1d1d1;
    h1{
    font-size: ${fontSize.regular};
    margin-top: 0px;
    padding-top: 20px;
    }
    button{
        border: 0;
        background-color: ${palette.opacity};
        cursor: pointer;
        padding-bottom: 40px;
    }
}

.form_guide{
    width: 380px;
    margin: 0 auto;
    padding-top: 20px;
    margin-bottom: 20px;
    font-size: ${fontSize.micro};
    color: ${palette.text.light};
}
.button{
    ${defaultFlexCenter}
    button{
        width: 164px;
        height: 42px;
        background-color: ${palette.main.default};
        border: 0;
        border-radius: 5px;
        color: ${palette.white};
        :hover{
          cursor: pointer;
        }
    }

}
ul{
        li{
          width: 100%;
          height: 50px;
          ${defaultFlexCenter}
          justify-content: space-between;
          border-bottom: 1px solid ${palette.gray.bright};
          span{
            width: 100px;
            text-align: end;
            font-size: ${fontSize.micro};
            color: ${palette.text.light};
          }
          span:nth-child(1){
            text-align: start;
          }
          .point{
            color: ${palette.main.default};
            font-weight: 600;
          }
        }
        li:last-child{
          border: 0;
        }
      }
.info{
      p{
        width: 100%;
        height: 50px;
        margin: 0;
        color: ${palette.text.light};
          ${defaultFlexCenter}
          justify-content: space-between;
        border-bottom: 1px solid ${palette.gray.bright};
        span{
          font-size: ${fontSize.micro};
        }
      }
      ul{
 
        li{
          span{
            width: 100%;
            
        margin: 0;
          }
        }
      }
    }
@media screen and (max-width:579px) and (min-width:230px) {
width: 90%;
.form_guide{
    width: 90%;
    margin: 0 auto;
    padding-top: 20px;
    margin-bottom: 20px;
    font-size: ${fontSize.micro};
    color: ${palette.text.light};
}
}
`;
