import { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { useSetRecoilState } from 'recoil';
import { useNavigate } from 'react-router-dom';
import Pagination from 'react-js-pagination';
import SoftTab from '../../components/admin/SoftTab';
import TableList from '../../components/admin/TableList';
import Button from '../../components/admin/Button';
import { completeType } from '../../modules/defines/enums';
import FormCheck from '../../components/admin/FormCheck';
import { StyledDiv, StyledPagination } from '../../modules/defines/style';
import myAxios from '../../modules/api/myAxios';
import { roleState } from '../../recoil';
import Popup, { PopupFormRow } from '../../components/ui/Popup';
import PopupLoading from '../../components/PopupLoading';
import TextEditor from '../../components/community/TextEditor';

// TODO: 권한이 없는 유저는 돌려보낼 것 , pagination, error msg, 수정 삭제 후, 안내멘트
const FormRadioWrap = styled.div`
	display: flex;
	label {
		display: flex;
		align-items: center;
	}
`;
const AdminContactUs = () => {
	const TabData = [
		{ text: '전체', value: 0 },
		{ text: '미처리', value: 1 },
		{ text: '처리 완료', value: 2 },
	];
	const setRole = useSetRecoilState(roleState);
	// const [user, setUser] = useRecoilState(userState);
	const [data, setData] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const navigate = useNavigate();
	const editorRef = useRef();
	const [content, setContent] = useState('');

	const [selectedTabIndex, setSelectedTabIndex] = useState(0);
	const [popupState, setPopupState] = useState({
		display: false,
		data: null,
	});
	const [confirm, setConfirm] = useState({
		display: false,
		// type: null,
	});
	const [formValues, setFormValues] = useState({
		idx: '',
		status: 0,
		reply: '',
	});
	const [currentPage, setCurrentPage] = useState(1);
	const [totalPages, setTotalPages] = useState(0);
	const handlePageChange = currentPage => {
		setCurrentPage(currentPage);
	};
	useEffect(() => {
		authUser();
		switch (selectedTabIndex) {
			case 0:
				requestData(currentPage);
				break;
			case 1:
				requestPendingData(currentPage);
				break;
			case 2:
				requestFulfilledData(currentPage);
				break;
			default:
				requestData(currentPage);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedTabIndex, currentPage]);
		const onChange = () => {
		setContent(editorRef.current.getInstance().getHTML());
	};
	const authUser = async () => {
		try {
			const response = await myAxios.get('/users/info');
			if (response.data.data.role !== 'admin') {
				navigate('/login', { replace: true });
			} else {
				setRole('admin');
			}
		} catch (error) {
			if (error.response.status === 401) {
				navigate('/login', { replace: true });
			}
			return error;
		}
	};
	const requestData = async currentPage => {
		try {
			const response = await myAxios.get(
				`/superadmin/contactus?page=${currentPage}&perPage=10`,
			);
			if (response.data.result === 'success') {
				setTotalPages(response.data.total);
				setData(response.data.data);
			}
		} catch (error) {
			console.log(error);
		}
	};

	const requestPendingData = async currentPage => {
		try {
			const response = await myAxios.get(
				`/superadmin/contactus?&page=${currentPage}&perPage=10&status=0`,
			);
			if (response.data.result === 'success') {
				setTotalPages(response.data.total);
				setData(response.data.data);
			}
		} catch (error) {
			console.log(error);
		}
	};

	const requestFulfilledData = async currentPage => {
		try {
			const response = await myAxios.get(
				`/superadmin/contactus?status=1&page=${currentPage}&perPage=10`,
			);
			if (response.data.result === 'success') {
				setData(response.data.data);
				setTotalPages(response.data.total);
			}
		} catch (error) {
			console.log(error);
		}
	};
	const handleClickTab = index => {
		setSelectedTabIndex(index);
		setCurrentPage(1);
		switch (index) {
			case 0:
				requestData(1);
				break;
			case 1:
				requestPendingData(1);
				break;
			case 2:
				requestFulfilledData(1);
				break;
			default:
				requestData(1);
		}
	};

	const handleClickRow = row => {

		setPopupState(prev => ({ ...prev, display: true, data: row }));
		setFormValues({
			idx: row.idx,
			status: row.status,
			reply: row.reply,
		});
	};

	const handleUpdate = async () => {
		setIsLoading(true);

		try {
			const response = await myAxios.patch('/superadmin/contactus', {
				status: formValues.status,
				idx: formValues.idx,
				reply: content
			});
			if (response.data.result === 'success') {
				requestPendingData(currentPage);
				setPopupState(prev => ({ ...prev, display: false, data: null }));
				setIsLoading(false);
			}
		} catch (error) {
			console.log(error);
			setIsLoading(false);
		}
	};

	const handleDelete = async () => {
		setIsLoading(true);
		try {
			const response = await myAxios.delete(
				`/superadmin/contactus/${popupState.data.idx}`,
			);
			if (response.data.result === 'success') {
				setIsLoading(false);
				requestData(currentPage);
				setConfirm(false);
				setPopupState(prev => ({ ...prev, display: false, data: null }));
			}
		} catch (error) {
			setIsLoading(false);
			console.log(error);
		}
	};

	const handleClosePopup = () => {
		setPopupState(prev => ({ ...prev, display: false, data: null }));
	};

	const handleRadioChange = (name, value) => {
		setFormValues(prev => ({ ...prev, [name]: Number(value) }));
	};

	const handleChange = e => {
		setFormValues(prev => ({ ...prev, [e.target.name]: e.target.value }));
	};

	return (
		<>
			<StyledDiv>
				<section>
					<div className="title">
						<h3>Contact us</h3>
					</div>
					<SoftTab
						list={TabData}
						selectedIndex={selectedTabIndex}
						onClick={handleClickTab}
					/>
					<TableList
						data={[
							{
								width: '25%',
								title: '등록일',
								type: 'element',
								element: props =>
									moment(props.reg_date * 1000).format('YYYY-MM-DD HH:mm'),
							},
							{
								width: '25%',
								title: '이메일',
								type: 'string',
								element: 'email',
							},
							{
								width: '25%',
								title: '담당자',
								type: 'string',
								element: 'name',
							},
							{
								width: '25%',
								title: '전화번호',
								type: 'string',
								element: 'phone',
							},
							{
								width: '25%',
								title: '처리 여부',
								type: 'element',
								element: props => completeType[props.status],
							},
						]}
						lists={data}
						onClick={handleClickRow}
					/>
					{/* <Pagination totalPages={totalPages} setCurrentPage={setCurrentPage} /> */}
					<StyledPagination>
						<Pagination
							activePage={currentPage}
							itemsCountPerPage={10}
							totalItemsCount={totalPages}
							pageRangeDisplayed={5}
							prevPageText="‹"
							nextPageText="›"
							onChange={handlePageChange}
						/>
					</StyledPagination>
					{popupState.display && popupState.data && (
						<Popup title="상세 보기" onClose={handleClosePopup} size="md">
							{isLoading && (
								<>
									<PopupLoading />
								</>
							)}

							<PopupFormRow title="처리 여부">
								<FormRadioWrap>
									<FormCheck
										theme="radio"
										name="status"
										value="0"
										checked={formValues.status === 0}
										onChange={handleRadioChange}
									>
										미처리
									</FormCheck>
									<FormCheck
										theme="radio"
										name="status"
										value="1"
										checked={formValues.status === 1}
										onChange={handleRadioChange}
									>
										처리완료
									</FormCheck>
								</FormRadioWrap>
							</PopupFormRow>
							<PopupFormRow title="이름/직책">
								<div className="row-content">
									{popupState.data.name} - {popupState.data.position}
								</div>
							</PopupFormRow>
							<PopupFormRow title="이메일/전화번호">
								<div className="row-content">
									{popupState.data.email} | {popupState.data.phone}
								</div>
							</PopupFormRow>
							<PopupFormRow title="제목">
								<div className="row-content">
									{popupState.data.title || '-'}
								</div>
							</PopupFormRow>
							<PopupFormRow>
								<textarea
									rows="5"
									cols="30"
									readOnly
									value={popupState.data.question}
								>
									{popupState.data.question || '-'}
								</textarea>
							</PopupFormRow>
							{popupState.data.status === 1 && (

								<PopupFormRow>
									<textarea
										rows="5"
										cols="30"
										readOnly
									>
										{popupState.data.reply}
									</textarea>
								</PopupFormRow>
							)}
							{popupState.data.status === 0 && (
								<>
									{/* <PopupFormRow title="답변" /> 
									 <PopupFormRow>
										<div
											className="row-content"
											style={{ width: '100%', marginTop: '10px' }}
										>
											<textarea
												rows="5"
												cols="30"
												type="text"
												name="reply"
												value={formValues.reply || ''}
												onChange={handleChange}
												maxLength="500"
											/>
										</div>
									</PopupFormRow>  */}
									<PopupFormRow title="답변 입력" >
										<div className="w-full">
											<TextEditor type="contact"
												editorRef={editorRef}
												onChange={onChange}
											/>
										</div>
									</PopupFormRow>
								</>
							)}

							<PopupFormRow>
								<div className="row-two-btn">

									{popupState.data.status === 0 &&  (
										<Button
											disabled={popupState.data.status === formValues.status || content.trim()===''}
											onClick={handleUpdate}
										>
											업데이트
										</Button>
									)}
									<Button
										color="blue"
										onClick={() =>
											setConfirm(prev => ({ ...prev, display: true }))
										}
									>
										삭제
									</Button>
								</div>
							</PopupFormRow>
						</Popup>
					)}
					{confirm.display && (
						<Popup
							theme="confirm"
							onClose={() => setConfirm(prev => ({ ...prev, display: false }))}
						>
							<p>정말 삭제하시겠습니까?</p>
							<div>
								<Button color="red" onClick={handleDelete}>
									예
								</Button>
								<Button
									color="blue"
									onClick={() =>
										setConfirm(prev => ({ ...prev, display: false }))
									}
								>
									아니오
								</Button>
							</div>
						</Popup>
					)}
				</section>
			</StyledDiv>
		</>
	);
};

export default AdminContactUs;
