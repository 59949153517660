import React, { useEffect, useState } from 'react';
import myAxios from '../../modules/api/myAxios';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

export default function LatestIssue() {
	const [latestIssue, setLatestIssue] = useState(null);

	useEffect(() => {
		requestData();
	}, []);

	const requestData = async () => {
		try {
			const response = await myAxios.get('/superadmin/issue');
			const sliceData = response.data.data.slice(0, 4);
			setLatestIssue(sliceData);
		} catch (error) {
			console.log(error);
		}
	};
	const { t } = useTranslation();

	return (
		<>
			<span className=" lg:text-lg  ">Latest Issues</span>
			<ol className="relative border-l border-gray-200 max-w-[150px] mt-5 ">
				{latestIssue !== null &&
					latestIssue.map((data, index) => {
						const startIdx = data.createdAt.indexOf('T');
						const slicedDate = data.createdAt.substring(0, startIdx);
						return (
							<li className="mb-10 ml-4" key={index}>
								<div className="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -left-1.5 border border-white " />
								{data.title.slice(0, 16)}
								<br />
								<time className="mb-1 text-sm font-normal leading-none text-gray-700">
									{slicedDate}
								</time>
								<br />
								<span className="mb-1 text-sm font-normal leading-none text-gray-700">
									{data.lang}
								</span>
							</li>
						);
					})}
				{latestIssue === null && (
					<li className="mb-10 ml-4">
						<div className="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -left-1.5 border border-white " />
						<p className="mb-4 text-sm font-normal text-black ">
							{t('history.nodata')}
							<br />
						</p>
					</li>
				)}
			</ol>
		</>
	);
}






