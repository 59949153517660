import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';

i18n
	.use(Backend)
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		// debug: true,
		lng: 'en',
		fallbackLng: 'en',
		detection: {
			order: ['sessionStorage', 'navigator'],
			lookupFromPathIndex: 0,
			lookupSessionStorage: 'lang',
			cacheUserLanguage: true,
		},
	});

// 언어 변경 시 로컬 스토리지에 저장
i18n.on('languageChanged', lng => {
	sessionStorage.setItem('lang', lng);
});

export default i18n;
