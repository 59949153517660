import { TRACK_TYPE } from "../public-types/common";
import { IceCandidate, SYSTEM } from "../types/common";
import { IResponseSubscribe } from "../types/response";
import OmniWebsocket from "../websocket/websocket-manager";
import WebRTCCommon from "./webrtc-common";


export default class ClientSubscriber extends WebRTCCommon {
    public VIDEO_TAG?: HTMLVideoElement;
    public resolution_reporter?: NodeJS.Timer;

    private videoStream?: MediaStream;

    private remoteTrackWidth = 0;
    private remoteTrackHeight = 0;

    constructor(ws: OmniWebsocket, session: string, turn_id: string, turn_secret: string) {
        super(ws, session, TRACK_TYPE.VIDEO, turn_id, turn_secret);
    }

    async subscribe(subscribeResult: IResponseSubscribe, tag_id?: string): Promise<void> {
        return new Promise(async (resolve, reject) => {
            try {
                // find video tag
                let remoteVideo: HTMLVideoElement | undefined;
                if (tag_id) {
                    remoteVideo = document.querySelector(`video#${tag_id}`) as HTMLVideoElement;
                } else if (subscribeResult.screen) {
                    remoteVideo = document.querySelector("video#Omnitalk-LocalScreen-0") as HTMLVideoElement;
                } else {
                    for (let i=0; i<SYSTEM.MAX_USER_CNT; i++) {
                        const findVideoTag = document.querySelector('video#Omnitalk-RemoteVideo-' + i) as HTMLVideoElement;
                        if (findVideoTag != null && findVideoTag.getAttribute("data-onair") == null) {
                            remoteVideo = findVideoTag;
                            break;
                        }
                    }
                }
                if (!remoteVideo) {
                    reject(`Notfound remote video tag${tag_id ? `, tag_id: ${tag_id}` : ''}`);
                    return;
                }
                this.VIDEO_TAG = remoteVideo;
                this.VIDEO_TAG.setAttribute("playsinline", "");
                this.VIDEO_TAG.setAttribute("autoplay", "");
                this.VIDEO_TAG.setAttribute("controls", "false");
                this.VIDEO_TAG.setAttribute("data-onair", "true");
                this.VIDEO_TAG.setAttribute("data-session", subscribeResult.subscribe);
                
                this.trickleHandler(subscribeResult.subscribe);
                this.onTrackListener();

                await this.setRemoteDescription(subscribeResult.jsep);
                const answer = await this.createAnswer();
                await this.setLocalDescription(answer);
                await this.ws?.requestSubscribeComp(subscribeResult.subscribe, answer);
                resolve();
            } catch (err) {
                reject(err);
            }
        });
    }

    onTrackListener() {
        this.pc.ontrack = e => {
            this.videoStream = e.streams[0];
            this.VIDEO_TAG!.srcObject = e.streams[0];
            // Resolution report message
            this.resolution_reporter = setInterval(async () => {
                const videoTracks = this.videoStream!.getVideoTracks();
                const trackSettings = videoTracks[0].getSettings();
                // console.log("Track Settings : ", trackSettings.width, trackSettings.height);
                if (trackSettings.width && trackSettings.height && trackSettings.width != this.remoteTrackWidth) {
                    this.remoteTrackWidth = trackSettings.width;
                    this.remoteTrackHeight = trackSettings.height;
                    await this.ws.requestResolutionReport(TRACK_TYPE.VIDEO, this.remoteTrackWidth * this.remoteTrackHeight);
                }
            }, 1000 * 10);
        }
    }

    freeResources() {
        clearInterval(this.resolution_reporter);
        this.closePeerConnection();
        this.stopMediaStream(this.videoStream)
        if (this.VIDEO_TAG) {
            this.VIDEO_TAG.srcObject = null;
            this.VIDEO_TAG.removeAttribute('data-onair');
            this.VIDEO_TAG.removeAttribute('data-session');
        }
    }

}