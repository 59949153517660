// Loading.js
import React from 'react';
import { PopupLoadingBackground } from '../modules/defines/style';
import spinner from '../assets/spinner.gif';

export default function PopupLoading() {
	return (
		<PopupLoadingBackground>
			{/* <LoadingText>잠시만 기다려 주세요.</LoadingText> */}
			<img src={spinner} alt="로딩중" width="50%" />
		</PopupLoadingBackground>
	);
}
