import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import CopyToClipboard from 'react-copy-to-clipboard';
import { BiLink } from 'react-icons/bi';
import { useState } from 'react';
import { TbCheck } from 'react-icons/tb';
import { defaultFlexCenter, fontSize, fontWeight, palette } from '../../modules/defines/style';
import momentFormat from '../hook/momentFormat';

function HookView({ data }) {
  const { t } = useTranslation();
  const [copy, setCopy] = useState(false);

  const handleLinkCopied = () => {
    setCopy((prev) => !prev);
  };
  return (
    <>
      
      <StyledCard>
        <div className="top">
          <dl>
            <dt>Service ID</dt>
            <dd>{data.service_id}</dd>
          </dl>
          
        </div>
        <div className="center">
          <dl>
            <dt>Hook Type</dt>
            <dd>{data.hook_type}</dd>
          </dl>
          <dl>
            <dt>Date</dt>
            <dd>{ momentFormat(data, 'short') }</dd>
          </dl>
        </div>
        <div className="bottom">
          <dl>
            <dt>Url</dt>
            <dd>{data.url}</dd>
            <CopyToClipboard text={data.url}>
              <button
                type="button" className="link"
                onClick={handleLinkCopied}><BiLink /><span>{t('console.button.copyLink')}</span>
                {copy && (
                  <span className="copied"><TbCheck /> Copied!</span>  
                )}
              </button>
            </CopyToClipboard>
          </dl>
        </div>
            
      </StyledCard>
    </>
  );
}

export default HookView;

const StyledCard = styled.div`
width: 460px;
height: 300px;
padding: 30px;
margin-right: 20px;
margin-bottom: 30px;
border-radius: 10px;
box-shadow: ${palette.shadow};
background-color: ${palette.gray.light};
div{
  width: 100%;
  margin-bottom: 40px;
  ${defaultFlexCenter}
  justify-content: flex-start;
  dl{
    width: 50%;
    dt{
      font-weight: ${fontWeight.medium};
    }
    dd{
      font-size:${fontSize.micro};
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
.link{
  font-size: ${fontSize.micro};
  padding-top: 10px;
  color: ${palette.text.disabled};
  :hover{
    cursor: pointer;
    color: ${palette.main.default};
  }
}
.copied{
  margin-left: 30px;
  color: ${palette.main.default};
}

@media screen and (max-width:979px) and (min-width:580px) {
margin-right: 0;
}

@media screen and (max-width:579px) and (min-width:230px) {
width: 100%;
height: 300px;
padding: 20px;
div{
  width: 100%;
  dl{
    width: 100%;
    dt{
      font-weight: ${fontWeight.medium};
    }
    dd{
      font-size:${fontSize.micro};
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
.link{
  font-size: ${fontSize.micro};
  padding-top: 10px;
  color: ${palette.text.disabled};
  :hover{
    cursor: pointer;
    color: ${palette.main.default};
  }
}
.copied{
  margin-left: 30px;
  color: ${palette.main.default};
}
}
`;