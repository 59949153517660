import React from 'react';
import { useTranslation } from 'react-i18next';

export default function IssueContents({ data }) {
	const { t } = useTranslation();

	return (
		<>
			<div className="">
				{data && data.length > 0 ? (
					<div className="">
						{data.map((item, index) => {
							const limitedContents =
								item.contents.length > 90
									? `${item.contents.substring(0, 90)}...`
									: item.contents;

							return (
								<a
									href={item.link}
									target="_blank"
									className="font-medium"
									key={index}
									rel="noreferrer"
								>
									<div className=" border-b-[1px] solid border-[#dee2e6]  hover:bg-gray-50 h-auto ">
										<div className="px-4 py-5">
											<div className="flex justify-start items-center">
												{item.state === 'open' ? (
													<>
														{/* <div className="w-2 h-2 mr-2  inline-block rounded-full bg-green-500" /> */}

														<span className="text-sm  mr-2 px-2  rounded-large bg-green-500 text-white dark:text-white">
															{item.state}
														</span>
													</>
												) : (
													<>
														{/* <div className="w-2 h-2 mr-2  inline-block rounded-full bg-red-500" /> */}
														<span className="text-sm mr-2   px-2  rounded-large bg-red-500 text-white dark:text-white">
															{item.state}
														</span>
													</>
												)}
												<span className="text-black font-[600] lg:text-lg text-[15px] dark:text-white">
													{item.title}
												</span>
											</div>
											<p className="text-sm mt-3 mb-3 lg:text-base text-gray-800 dark:text-gray-400">
												{limitedContents}
											</p>
											{/* <div className='mb-5'> 
                    </div> */}

											<div className="flex justify-between items-center">
												<div className="text-xs lg:text-[0.938rem]  text-gray-500 dark:text-gray-500">
													<span className=" mr-2  lg:text-[0.938rem]  ">
														{item.writer}
													</span>
													<span>{item.createdAt.split('T')[0]}</span>
												</div>
												<div>
													<span className="text-xs mr-3 bg-[#eff3fa]   lg:text-[0.938rem] rounded-md px-2 py-1  dark:text-white">
														# {item.lang}
													</span>

													<svg
														xmlns="http://www.w3.org/2000/svg"
														fill="none"
														viewBox="0 0 24 24"
														strokeWidth={1.5}
														stroke="currentColor"
														className="text-gray-500 dark:text-white w-4 h-4 lg:w-5 lg:h-5 mr-2 inline-block"
													>
														<path
															strokeLinecap="round"
															strokeLinejoin="round"
															d="M12 20.25c4.97 0 9-3.694 9-8.25s-4.03-8.25-9-8.25S3 7.444 3 12c0 2.104.859 4.023 2.273 5.48.432.447.74 1.04.586 1.641a4.483 4.483 0 01-.923 1.785A5.969 5.969 0 006 21c1.282 0 2.47-.402 3.445-1.087.81.22 1.668.337 2.555.337z"
														/>
													</svg>

													<span className="text-xs  lg:text-[0.938rem]  dark:text-white">
														{item.commentsNum}
													</span>
												</div>
											</div>
										</div>
									</div>
								</a>
							);
						})}
					</div>
				) : (
					<p>{t('issue.nodata')}</p>
				)}
			</div>
		</>
	);
}
