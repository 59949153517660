import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { AiFillHome } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import styled from 'styled-components';
import CompanyInfo from '../components/console/CompanyInformation';
import ConsoleCategory from '../components/console/ConsoleCategory';
import ManagerInfo from '../components/console/ManagerInfo';
import PwdInfo from '../components/console/PwdInfo';
import API from '../modules/api/api';
import {
  defaultFlexCenter,
  defaultInput,
  fontSize,
  fontWeight,
  pagePaddingTop,
  palette,
} from '../modules/defines/style';
import { googleUserState, userState } from '../recoil';

function MyPage() {
	const { t } = useTranslation();
	const [notValidStatus, setNotValidStatus] = useState(false);
	// eslint-disable-next-line no-unused-vars
	const [user, setUser] = useRecoilState(userState);
	const [tab, setTab] = useState(0);
	// eslint-disable-next-line no-unused-vars
	const [googleUser, setGoogle] = useRecoilState(googleUserState);
	if (googleUser !== null) {
		API.getInfo().then(response => {
			if (response.status === 0) {
				return setNotValidStatus(true);
			}
			if (response.status === 1) {
				setUser(response.agency_name);
			}
		});
	}
	const [data, setData] = useState([]);

	// get data
	const requestData = () => {
		API.getInfo()
			.then(response => {
				if (response.status === 0) {
					return setNotValidStatus(true);
				}
				setData(response);
			})
			.catch(error => {
				console.error(error);
			});
	};
	useEffect(() => {
		if (data.length === 0) {
			requestData();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	const handleTabChange = {
		0: <CompanyInfo data={data} requestData={requestData} />,
		1: <ManagerInfo data={data} />,
		2: <PwdInfo data={data} />,
	};

	return !notValidStatus ? (
		<>
			<Helmet>
				<title>{t('helmet.mypage.title')}</title>
				<meta charset="utf-8" />
				<meta name="description" content={t('helmet.mypage.desc')} />
				<meta property="og:title" content={t('helmet.mypage.ogTitle')} />
				<meta property="og:description" content={t('helmet.mypage.ogDesc')} />
				<meta property="og:url" content="https://omnitalk.io/console/my-page" />
				<meta
					property="twitter:title"
					content={t('helmet.mypage.twitterTitle')}
				/>
				<meta
					property="twitter:description"
					content={t('helmet.mypage.twitterDesc')}
				/>
				<meta name="robots" content="noindex" />
			</Helmet>
			<StyledOverview>
				<section>
					<div className="title">
						<h3>계정 관리</h3>
					</div>
					<div className="consoleCategory" />
					<ConsoleCategory setTab={setTab} />
					<article>{handleTabChange[tab]}</article>
				</section>
			</StyledOverview>
		</>
	) : (
		<StyledLoginWait>
			<section>
				<h1>Welcome!</h1>
				<p>
					가입 승인이 진행중입니다.
					<br />
					<strong>옴니톡</strong>을 이용해 주셔서 감사합니다.
				</p>
				<div className="home_btn">
					<Link to="/" style={{ color: '#FA5734' }}>
						{' '}
						<AiFillHome /> 메인 페이지 가기{' '}
					</Link>
				</div>
			</section>
		</StyledLoginWait>
	);
}

export default MyPage;

const StyledOverview = styled.div`
margin: 0 auto;
width: 100%;
max-width: 1200px;
min-height: 800px;
section{
  font-family: 'Pretendard';
  .modify_input.password_modify {
  display: flex;
  flex-direction: column;
  height:fit-content;
}

  padding-left: 240px;
   .title{
    width: 100%;
    margin-bottom: 50px;
    h3{
      margin-bottom: 65px;
      font-size: 1.75rem;
      font-weight: ${fontWeight.semiBold};
    }
  }
  .mypage-container{
    display: flex;
    justify-content: center;
  }
  .underline {
    border:  1px solid #ebebeb;
  }
  article{
    max-width: 700px;
    margin: 0 auto;
    h4{
      font-size: ${fontSize.small};
      color: ${palette.main.default};
    }
    span{
      color: #666666;
    }
    span label {
      color: red;
    }
    form{
      width: 100%;
      ${defaultFlexCenter}
      flex-direction: column;
      padding:0 5%;
      padding-top: 20px;
      color: ${palette.text.light};
      background-color: #ffffff;
      border-left: 1px solid${palette.gray.formBorder};
      border-right:1px solid ${palette.gray.formBorder};
      border-bottom:1px solid ${palette.gray.formBorder};
      border-radius: 0px 0px 10px 10px;
      margin-bottom: 80px;
      .button_type{
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        button{
          width: 300px;
          height: 40px;
          font-weight: 600;
          cursor: pointer;
          margin-left: 5px;
        border: 0;
        color: #fff;
        border-radius: 5px;
        background-color: ${palette.main.default};
        margin-bottom: 30px;
        margin-top: 20px;
        }
      }
     .pwdsubmit {
      position: absolute;
      top: 10px;
     }
      .input_box{
        width: 100%;
        height: 60px;
        ${defaultFlexCenter}
        justify-content: flex-start;
        .label{
          width: 200px;
          color: ${palette.default};
          display: inline-block;
        }
        input{
          border: 0;
          line-height: 48px;
          background-color: ${palette.opacity};
        }
        :last-child{
          border: 0;
        }
        .label{
          color:${palette.text.default};
        }
        span{
          a{
            text-decoration: underline;
            color: #a4a3a3;
            font-size: 16px;
            font-weight: 500;
          }
          a:hover{
            color: #206EB6;
          }
        }
      }
      .modify_input.password_modify .input_box{
          justify-content: space-between;
      }
      .file_box{
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box; 
        width: 90%;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
      }
      .modify_input{
        width: 100%;
        height: 80px;
        ${defaultFlexCenter}
        justify-content: flex-start;
        :last-child{
          border: 0;
        }
        label{
          width: 200px;
          display: inline-block;
        }
        input{
          ${defaultInput}
        }
      }
      .file_box{
        width: 100%;
        height: 60px;
        ${defaultFlexCenter}
        justify-content: flex-start;
        p{
          width: 200px;
          margin-right: 50px;
          font-size: ${fontSize.small};
        }
        span:nth-child(2){
        overflow: hidden;
        text-overflow: ellipsis;
      }
       label {
        cursor: pointer;
       }
       span{
          a{
            color: #a4a3a3;
            text-decoration: underline;
            font-size: 16px;
            font-weight: 500;
          }
          a:hover{
            color: #206EB6;
          }
       }
      .file_container{
        width: 100%;
        ${defaultFlexCenter}
        justify-content: flex-start;
        label{
        display: inline-block;
        width: 100px;
        height: 42px;
        background-color: ${palette.main.default};
        border-radius: 5px;
        margin-right: 6px;
        margin-left: 12px;
        ${defaultFlexCenter}
        color: ${palette.white};
      }
      input[type=file]{
        display: none;
      }
      input{
        ${defaultInput}
        width: 302px;
      }
   
      }
      
      }
      .checkbox_radio{
        width: 100%;
        height: 80px;
        margin: 0 auto;
        ${defaultFlexCenter}
        justify-content: flex-start;
        label{
          width: 200px;
        }
      }
      .address_box{
      width: 100%;
      height: 60px;
      ${defaultFlexCenter}
      justify-content: flex-start;
      label{

        width: 200px;
      }
      .address_input_box{
        input{
        ${defaultInput}
        }
        input:nth-child(1){
          width: 140px;
          margin-right: 6px;
        }
        input:nth-child(2){
          width: 260px;
        }
      }
    }
  }
  .password{
    border: 0;
    .password_modify{
        width: 100%;
        padding-top: 30px;
        display: block;
      .input_box{
        width: 100%;
        padding-top: 0;
        label{
          width: 200px;
        }
        input{
        }
      }
      }
  }
 
}
#button_cancle{
    background-color: ${palette.gray.camera};
  }
  .disabledModify {
  background-color: ${palette.main.disabled} !important;
}

}
//  📌 반응형1200
@media screen and (min-width: 1200px) {
  form {
    .button_type {
    cursor: pointer;
    button{
      width: 300px;
    }
  }
  
  }
  
  .modify_input.password_modify {
    /* margin-top: -; */
    display: flex;
    flex-direction: column;
  }
  span {
    color: #a4a3a3;
  }
  .title {
  margin-top: 120px;
    max-width: 862px;

  }
  section {
    article{
    
      form{
        margin-top: -80px;
        margin-bottom: 80px;
        
      }
    }
  }
} 
//  📌 반응형1200~980
@media screen and (max-width:1200px) and (min-width: 980px) {
padding: 120px 5% 0 0;
section{
  article{
    max-width: 700px;
    form{
      width: 100%;
      margin-top: -80px;
      max-width: 860px;
      .button_type{
        position: relative;
        width: 100%;
        cursor: pointer;
        button{
          cursor: pointer;
          width: 260px;
        padding: 6px 8px;
        }
      }
      .input_box{
        width: 100%;
        span {
          color: ${palette.text.gray};
        }
      }
      .modify_input{
        label{
          width: 120px;
        }
      }
      .file_box{
        p{
          width: 120px;
          margin-right:24px;
          font-size: ${fontSize.small};
        }
      }
      .checkbox_radio{
        label{
          width: 120px;
        }
      }
      .address_box{
      label{
        width: 120px;
      }
    }
  }
  .password{
    .password_modify{
        width: 600px;
        height: 140px;
        ${defaultFlexCenter}
        justify-content: flex-start;
        flex-wrap: wrap;
        border: 0;
        label{
          width: 120px;
        }
        input{
          width: 380px;
        }
      }
    
  }
.modify_input.password_modify {
  display: flex;
  flex-direction: column;
  height:fit-content;
}
  .modify_input.password_modify .input_box{
    margin-top: 10px;
    
  }
  .pwd_form{
    height: 400px;
  }
}
}
}
@media screen and (max-width: 979px) and (min-width: 700px) {
  .modify_input.password_modify .input_box{
    margin-top: 20px;
  }
  .modify_input.password_modify .input_box label{
    
  }
  .modify_input.password_modify input {
    width: 550px;
  }
}
//  📌 반응형980~580
@media screen and (max-width: 979px) and (min-width: 652px) {
  padding-top: 120px;
section{
  padding: 0 5%;
  .modify_input.password_modify {
    height: fit-content;

  }
  .modify_input.password_modify label{
    width: 30%;
    
  }
  /* .modify_input.password_modify label:first-child{
    margin-top: 25px; 
  } */
  .modify_input.password_modify button{
    margin-top: 20px;
  }
  .button_type.button_pwd{
    margin-top: 30px;
  }
  .modify_input.password_modify .input_box{
    margin-top: 20px;
  }
  
  article{
    form{
      width: 100%;
      margin-top: -80px;
      max-width: 860px;
      .button_type{
        position: relative;
        width: 100%;
        button{
        padding: 6px 8px;
        width: 260px;
        margin-left:3px;
        cursor: pointer;
        }
      }
      .input_box{
        .label{
          width: 120px;
         
        }
        span {
          color: ${palette.text.gray};
        }
      }
      .input_box:last-child{
        border: 0;
        span{
          width: 80%;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .label{
          width: 120px;
        }
      }
      .modify_input{
        label{
          width: 120px;
        }
      }
      .password_modify{
        width: 100%;
        ${defaultFlexCenter}
        justify-content: space-between;
        flex-wrap: wrap;
        border: 0;
        label{
          width: 120px;
        }
        input{
          width: 380px;
        }
      }
      .info_password{
      width: 100%;
      height: 80px;
    }
      .file_box{
        p{
          width: 120px;
          margin-right: 16px;
          font-size: ${fontSize.small};
          color: #666666;
        }
      .file_container{
        width: 408px;
        label{
        width: 80px;
      }
      input[type=file]{
        display: none;
      }
      input{
        ${defaultInput}
        width: 323px;
      }
      }
      }
      .checkbox_radio{
        label{
          width: 120px;
        }
      }
      .address_box{
      label{
        width: 120px;
      }
      .address_input_box{
        input{
        ${defaultInput}
        }
        input:nth-child(1){
          width: 100px;
          margin-right: 6px;
        }
        input:nth-child(2){
          width: 300px;
        }
      }
    }
  }
}
}
}
// 📌 반응형580~230
@media screen and (max-width: 651px) and (min-width: 230px) {
  padding-top: 80px;
section{
  padding: 0 5%;
  margin-top: 60px;
  .modify_input.password_modify {
    height: fit-content;

  }
  .modify_input.password_modify label{
    width: 40%;
    font-size: 13px;
  }
  .modify_input.password_modify lbel:first-child{
    margin-top: 25px; 
  }
  .modify_input.password_modify button{
    margin-top: 20px;
  }
  .button_type.button_pwd{
    margin-top: 30px;
  }

  .modify_input.password_modify button{
    margin-top: 50px;
  }
  article{
    form{
      .modify_input.password_modify.input_box{
    margin-bottom: 30px;
  }
    }
   
  }
  .title{
    
    h3{
      margin-bottom: 30px;
      font-size: 1.5rem;
      font-weight: ${fontWeight.semiBold};
      
    }
    h4{
      font-size: 1.4rem;
    }
    .consoleCategory{
      margin-top: 30px;
    }
  }
  form {
    margin-top: -80px;
  }
  article{
    h4{
      font-size: 18px;
      font-weight: ${fontWeight.medium};
    }
    form{
      
      width: 100%;
      
      .button_type{
        position: relative;
        width: 100%;
        cursor: pointer;
        button{
        padding: 6px 8px;
        cursor: pointer;
        }
        .button-box{
          display: flex;
        }
      }
      
      .input_box{
        flex-direction: column;
        justify-content:center;
        align-items: flex-start;
        .label{
          line-height: 28px;
          padding-left: 0;
          color: ${palette.black};
        }
        span{
          font-size: 14px;

          /* padding-left: 30px; */
          color: ${palette.gray.normal};
        }
   
    }
    .input_box:last-child{
      border: 0;
     
    }
    .file_box{
      width: 100%;
      span:nth-child(2){
        overflow: hidden;
        text-overflow: ellipsis;
      }
      span{
          a{
            font-size: 14px;
          }
          a:hover{
            color: #206EB6;
          }
       }
    }
    .modify_input,
    .address_box{
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      margin-bottom: 10px;
      label{
        width: 30%;
        font-size: 14px;
        line-height: 26px;
        color: ${palette.text.default};
      }
      input{
        width: 100%;
        font-size: 14px;
        color: ${palette.text.gray};
        ::placeholder{
          font-size: 14px;
        }
        }
    }
    .checkbox_radio{
      width: 100%;
      display: flex;
      flex-direction: column;
      label{
        width: 100%;
        padding-top: 10px;
        display: block;
        font-size: 14px;
        line-height: 26px;
        color: ${palette.text.default};
      }
      .redio_box{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        color: ${palette.text.light};
      }
    }
    .address_box{
      .address_input_box{
        width: 100%;
        ${defaultFlexCenter}
        input:first-child{
        width: 30%;
        ::placeholder{
          font-size: 14px;
          padding-left: 10px;
        }
      }
      input:last-child{
        width: 70%;
      }
      }
    }
    .file_box{
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      p{
        padding-top: 10px;
        font-size: 14px;
        margin: 0;
        margin-bottom: 10px;
        color: ${palette.text.light};
      }
   .file_container{
    input{
      width: 100%;
    }
    label{
      height: 26px;
      font-size: 15px;
    }
   }
    }

  }
  /* password_form */
  .password{
    position: relative;
    width: 100%;
    .button_type{
      position: absolute;
      top: 0;
      right: 10px;
      cursor: pointer;
    }
    .password_modify{
      width: 100%;
      height: fit-content;
      padding-top: 30px;
      display: block;
      .input_box{
        width: 100%;
        padding-top: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        label{
          width: 100%;
        }
      }
    }
    .info_password{
      width: 100%;
      height: 80px;
    }
    #pwd {
      width: fit-content;
    }
  }
  .mobile{
    position: relative;
    padding-left: 0;
    .button_type{
      position: absolute;
      bottom: 56px;
      right: 10px;
      cursor: pointer;
      button{
        background-color: ${palette.opacity};
        box-shadow: unset;
        color: ${palette.main.default};
        :hover{
          cursor: pointer;
        }
        cursor: pointer;
      }
    }
    span{
      width: 100%;
      padding: 0;
      font-size: 14px;
    }
  }
  
}
#sign-out{
  display: flex;
  align-items: center;
  margin-bottom: 25px;
}
article {
  form{
    .button-box{
      display: flex;
      flex-direction: row;
    }
    .modify_input{
     
    }
  }
}
}
}
`;

const StyledLoginWait = styled.div`
/* ${pagePaddingTop} */
section{
  h1{
    font-size: 2.125rem;
    margin-bottom: 70px;
    color: ${palette.main.default};
  }
  p{
    font-size: 1.75rem;
    margin-bottom: 30px;
  }
  .home_btn{
      margin-bottom: 80px;
    }
}
`;