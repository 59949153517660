import { useState } from 'react';
import styled from 'styled-components';
import iconPlusTab from '../../assets/icon_plus_tab.svg';
import {
	defaultFlexCenter,
	fontSize,
	fontWeight,
	palette,
} from '../../modules/defines/style';

function Section04({ t }) {
	const [tab, setTab] = useState(0);
	const data = t('pricing.section4.card', { returnObjects: true });
	return (
		<StyledFaq>
			<h3>
				FAQ
				<br />
			</h3>
			{data.map(data => {
				return (
					<article key={data.id}>
						<div className="section_faq_title mt-6 mb-6" key={data.id}>
							<button type="button" onClick={() => setTab(data.id)}>
								<img src={iconPlusTab} alt="더보기" />
								<h4>{data.title}</h4>
							</button>
						</div>
						<div className="section_contents ">
							{tab === data.id && <p>{data.text}</p>}
						</div>
					</article>
				);
			})}
		</StyledFaq>
	);
}

export default Section04;

const StyledFaq = styled.section`
	display: flex;
	flex-direction: column;
	h3 {
		margin-top: 40px;
		width: 100%;
		margin-bottom: 20px;
		font-size: ${fontSize.medium};
		font-weight: ${fontWeight.semiBold};
	}
	article {
		width: 998px;
		.section_faq_title {
			button {
				${defaultFlexCenter}
				justify-content: flex-start;
				border: 0;
				color: #222;
				background-color: ${palette.opacity};
				img {
					width: 24px;
					margin-right: 10px;
					:hover {
						cursor: pointer;
					}
				}
				h4 {
					font-size: 1.25rem;
					color: #222;
					font-weight: ${fontWeight.medium};
				}
			}
		}
		.section_contents {
			width: 998px;
			margin: 0 auto;
			margin-bottom: 30px;
			border-bottom: 1px solid ${palette.gray.middle};
			h5 {
				font-size: 1.375rem;
				font-weight: ${fontWeight.semiBold};
			}
			p {
				margin-top: 24px;
				margin-bottom: 20px;
				font-size: 16px;
				line-height: 1.65rem;
			}
		}
	}

	@media screen and (max-width: 1200px) and (min-width: 980px) {
		width: 100%;
		article {
			width: 100%;
			.section_contents {
				width: 96%;
			}
		}
	}

	@media screen and (max-width: 979px) and (min-width: 580px) {
		article {
			width: 100%;
			.section_faq_title {
				display: flex;
				align-items: center;
				button {
					img {
						width: 24px;
						margin: 0;
						padding: 0;
						margin-right: 10px;
						:hover {
							cursor: pointer;
						}
					}
				}
			}
			.section_contents {
				width: 100%;
				margin: 0 auto;
				margin-bottom: 30px;
				border-bottom: 1px solid ${palette.gray.middle};
			}
		}
	}

	@media screen and (max-width: 579px) and (min-width: 230px) {
		h3 {
			margin-bottom: 30px;
			font-size: 1.25rem;
			font-weight: ${fontWeight.regular};
		}
		article {
			width: 100%;
			.section_faq_title {
				display: flex;
				align-items: center;
				button {
					img {
						width: 18px;
						margin: 0;
						padding: 0;
						margin-bottom: 26px;
						margin-right: 10px;
						:hover {
							cursor: pointer;
						}
					}
					h4 {
						margin: 0;
						margin-bottom: 20px;
						font-size: 1rem;
					}
				}
			}
			.section_contents {
				width: 100%;
				margin: 0 auto;
				margin-bottom: 30px;
				border-bottom: 1px solid ${palette.gray.middle};
				p {
					margin: 0;
					margin-bottom: 20px;
					font-size: 14px;
					color: ${palette.text.light};
					line-height: 20px;
				}
			}
		}
	}
`;