import { useEffect, useState } from 'react';
import HTMLReactParser from 'html-react-parser';

import Popup, { PopupFormRow } from '../../components/ui/Popup';
import { EMAIL_REGEX, MOBILE_REGEX } from '../utill/regex';
import PopupLoading from '../PopupLoading';
import TextEditor from '../community/TextEditor';
const initFormValues = {
	title: '',
	name: '',
	email: '',
	phone0: '',
	phone1: '',
	phone2: '',
	position: '',
	question: '',
};
const initFormErrors = {
	title: '',
	name: '',
	email: '',
	question: '',
	phone: '',
};

const ContactPopup = ({
	email = '',
	onSubmit,
	onClose,
	callBackMessage = '',
}) => {
	const [formValues, setFormValues] = useState({ ...initFormValues, email });
	const [errors, setErrors] = useState(initFormErrors);

	const validate = () => {
		if (formValues.email.trim() === '') {
			setIsLoading(false);
			return setErrors(prev => ({ ...prev, email: 'This field is required.' }));
		}
		if (!EMAIL_REGEX.test(formValues.email)) {
			setIsLoading(false);
			return setErrors(prev => ({ ...prev, email: 'Invalid email format.' }));
		}
		if (formValues.title.trim() === '') {
			setIsLoading(false);
			return setErrors(prev => ({ ...prev, title: 'This field is required.' }));
		}
		if (formValues.name.trim() === '') {
			setIsLoading(false);
			return setErrors(prev => ({ ...prev, name: 'This field is required.' }));
		}
		if (formValues.question.trim() === '') {
			setIsLoading(false);
			return setErrors(prev => ({
				...prev,
				question: 'This field is required.',
			}));
		}
		if (
			!MOBILE_REGEX.test(
				`${formValues.phone0}-${formValues.phone1}-${formValues.phone2}`,
			)
		) {
			setIsLoading(false);
			return setErrors(prev => ({ ...prev, phone: 'Invalid phone format.' }));
		}
		return true;
	};

	const handleClosePopup = () => {
		onClose();
	};

	const handleChange = e => {
		setErrors(prev => ({ ...prev, [e.target.name]: '' }));
		setFormValues(prev => ({ ...prev, [e.target.name]: e.target.value }));
	};

	const handleSend = () => {
		setIsLoading(true);
		if (!validate()) return;
		onSubmit(formValues);
	};

	useEffect(() => {
		setFormValues({ ...initFormValues, email });
		setErrors(initFormErrors);
	}, [email]);
	const [isLoading, setIsLoading] = useState(false);

	return (
		<Popup title="Contact us" onClose={handleClosePopup} size="md">
			{callBackMessage !== '' ? (
				<>
					<PopupFormRow>
						<div className="flex items-center justify-center">
							<span>{HTMLReactParser(callBackMessage)}</span>
						</div>
					</PopupFormRow>
				</>
			) : (
				<>
					{isLoading && <PopupLoading></PopupLoading>}
					<PopupFormRow title="성함 <em>*</em>">
						<div className="row-content">
							<input
								type="text"
								name="name"
								value={formValues.name}
								onChange={handleChange}
								maxLength="30"
							/>
						</div>
						{errors.name !== '' && <p>{errors.name}</p>}
					</PopupFormRow>
					<PopupFormRow title="직급/직책 <em>*</em>">
						<div className="row-content ">
							<input
								type="text"
								name="position"
								value={formValues.position}
								onChange={handleChange}
								maxLength="30"
							/>
						</div>
					</PopupFormRow>
					<PopupFormRow title="이메일 <em>*</em>">
						<div className="row-content">
							<input
								type="email"
								name="email"
								value={formValues.email}
								onChange={handleChange}
								maxLength="50"
							/>
						</div>
						{errors.email !== '' && <p>{errors.email}</p>}
					</PopupFormRow>
					<PopupFormRow title="전화번호 <em>*</em>">
						<div className="row-content">
							<input
								type="tel"
								name="phone0"
								value={formValues.phone0}
								onChange={handleChange}
								maxLength="3"
							/>
							<span className="mark">-</span>
							<input
								type="tel"
								name="phone1"
								value={formValues.phone1}
								onChange={handleChange}
								maxLength="4"
							/>
							<span className="mark">-</span>
							<input
								type="tel"
								name="phone2"
								value={formValues.phone2}
								onChange={handleChange}
								maxLength="4"
							/>
						</div>
						{errors.phone !== '' && <p>{errors.phone}</p>}
					</PopupFormRow>

					<PopupFormRow title="제목 <em>*</em>">
						<div className="row-content">
							<input
								type="text"
								name="title"
								value={formValues.title}
								onChange={handleChange}
								maxLength="30"
							/>
						</div>
						{errors.title !== '' && <p>{errors.title}</p>}
					</PopupFormRow>

					<PopupFormRow title="문의 내용 <em>*</em>">
						<textarea
							rows="5"
							cols="30"
							name="question"
							value={formValues.question}
							onChange={handleChange}
						/>
						{errors.question !== '' && <p>{errors.question}</p>}
					</PopupFormRow>

					<PopupFormRow>
						<button
							type="button"
							className="w-full h-10 rounded-md disabled:bg-gray-200 disabled:text-white bg-main-50 text-white"
							disabled={
								formValues.title === '' ||
								formValues.name === '' ||
								formValues.email === '' ||
								formValues.question === ''
							}
							onClick={handleSend}
						>
							Send
						</button>
					</PopupFormRow>
				</>
			)}
		</Popup>
	);
};

export default ContactPopup;