import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { palette, breakpoint } from '../../modules/defines/style';

const ContactWapper = styled.div`
	height: fit-contents;
	display: flex;
	justify-content: center;
	align-items: center;
`;

const ContactUsBox = styled.div`
	width: 100%;
	max-width: 960px;
	margin-bottom: 144px;
	padding: 30px;
	border-radius: 10px;
	background-color: #f9fafb;
	margin: 0 auto;
	@media only screen and (max-width: ${breakpoint.lg}) {
		margin: auto;
		width: 100%;
	}
`;

const Text = styled.div`
	color: ${palette.title};
	font-size: 26px;
	font-weight: 600;
	line-height: calc(24px * 2);
	margin: 35px 30px;
	text-align: center;
	white-space: pre-line;
	word-break: keep-all;

	@media only screen and (max-width: ${breakpoint.lg}) {
		margin: auto;
		font-size: 4vw;
	}
`;
const InputWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 2rem;
	margin-bottom: 35px;
	input[type='email'] {
		width: 40%;
		height: 54px;
		border: 0;
		border-radius: 5px;
		text-indent: 8px;
		:focus {
			box-shadow: -1px 4px 19px ${palette.shadow};
		}
	}
	button {
		margin-left: 10px;
	}
`;

const ContactBox = ({ children }) => {
  const { t } = useTranslation();
  return (
    <ContactWapper> 
      <div className="w-full max-w-[1200px] mb-36 p-6 rounded-[20px] bg-[#f9fafb] dark:bg-[#121212] ">
       
        <div className="xs:text-lg sm:text-2xl text-primary text-[26px] font-semibold leading-[46px] xs:w-full xs:pt-6 sm:pt-0 xs:m-0 sm:my-9 sm:mx-8 text-center whitespace-pre-line break-all lg:mx-auto  dark:text-white">
       {t('main.contactbox.desc')}
          <br />     {t('main.contactbox.desc2')}
    </div>
        <InputWrapper>
          {children}
        </InputWrapper>
        <Text>
            
        </Text>
      </div>
    </ContactWapper>
    
  );
};

export default ContactBox;