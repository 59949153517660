import { useEffect, useState } from 'react';
import _ from 'lodash';
import DaumPostcode from 'react-daum-postcode';
import { AiFillHome } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import {
	MOBILE_REGEX,
	BUSINESS_CODE_REGEX,
	PHONE_REGEX,
} from '../components/utill/regex';
import {
	defaultFlexCenter,
	defaultInput,
	fontSize,
	fontWeight,
	pagePaddingTop,
	palette,
} from '../modules/defines/style';
import API from '../modules/api/api';
import Loading from '../components/Loading';

const initData = {
	startup: '3',
	manager_name: '',
	manager_phone0: '',
	manager_phone1: '',
	manager_phone2: '',
	manager_email: '',
	agency_name: '',
	homepage: '',
	address_code: '',
	address: '',
	address_detail: '',
	business_phone0: '',
	business_phone1: '',
	business_phone2: '',
	business_code0: '',
	business_code1: '',
	business_code2: '',
	intro: '',
	agree: false,
};

function SignupGoogle() {
	const [disabled, setDisabled] = useState(false);
	const [formData, setFormData] = useState(initData);
	const [errors, setErrors] = useState('');
	const [displayPostcode, setDisplayPostCode] = useState(false);
	const [filename, setFilename] = useState('');
	const [isComplete, setComplete] = useState(false);
	const [googleEmail, setGoogleEmail] = useState('');
	const [isChecked, setIsChecked] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const handleChange = e => {
		setDisabled(false);
		setErrors('');
		setFormData(prev => ({ ...prev, [e.target.name]: e.target.value }));
	};
	const handleChangeRadio = name => {
		setFormData(prev => ({ ...prev, [name.target.name]: name.target.value }));
	};

	const handleChangeFile = e => {
		const file = e.target.files[0];
		setFormData(prev => ({ ...prev, file }));
		setFilename(file.name);
	};
	const handleAddressCompleted = data => {
		setDisplayPostCode(false);
		setFormData(prev => ({
			...prev,
			address_code: data.zonecode,
			address: data.roadAddress,
		}));
	};
	const setAgreeInfo = name => {
		setIsChecked(!isChecked);
		setFormData(prev => ({ ...prev, [name.target.name]: isChecked }));
	};

	const handleSubmit = async e => {
		e.preventDefault();
		if (isChecked === false)
			return setErrors('개인정보 동의 약관에 체크해주세요.');
		const mobileValid = MOBILE_REGEX.test(
			`${formData.manager_phone0}-${formData.manager_phone1}-${formData.manager_phone2}`,
		);
		if (!mobileValid) return setErrors('핸드폰 번호를 확인해주세요.');
		if (formData.startup !== '3') {
			const businessPhoneValid = PHONE_REGEX.test(
				`${formData.business_phone0}-${formData.business_phone1}-${formData.business_phone2}`,
			);
			if (!businessPhoneValid)
				return setErrors('회사 전화번호를 확인해주세요. ');
			const businessCodeValid = BUSINESS_CODE_REGEX.test(
				`${formData.business_code0}-${formData.business_code1}-${formData.business_code2}`,
			);
			if (!businessCodeValid) return setErrors('사업자 번호를 확인해주세요.');
		}
		if (!isAllInput()) return setErrors('필수값을 모두 입력해주세요.');

		settingFinalData(formData);
		setIsLoading(true);
		await API.signUp(formData)
			.then(response => {
				if (response.result === 'success') {
					setIsLoading(false);
					return setComplete(true);
				}
			})
			.catch(error => {
				console.log(error);
				if (error.statusCode === 400) {
					setIsLoading(false);
					setErrors('입력하신 항목을 확인해주세요.');
				}
				return error;
			});
	};

	/**
	 * 유효성 검사
	 * @returns
	 */

	const findNull = clonedData => {
		const validArray = Object.values(clonedData);
		const isEmpty = function (item) {
			if (item === '' || item === null) return true;
			else return false;
		};
		return validArray.some(isEmpty);
	};

	/**
	 * 1. 모두 입력되었는지
	 * @returns
	 */
	const isAllInput = () => {
		formData.manager_email = googleEmail;
		const clonedData = _.cloneDeep(formData);
		// homepage값
		if (clonedData.startup === '3') {
			delete clonedData.business_code0;
			delete clonedData.business_code1;
			delete clonedData.business_code2;
			delete clonedData.business_phone0;
			delete clonedData.business_phone1;
			delete clonedData.business_phone2;
			delete clonedData.file;
			delete clonedData.address_code;
			delete clonedData.homepage;
			delete clonedData.address_detail;
			delete clonedData.address;
		} else {
			delete clonedData.homepage;
			delete clonedData.business_file;
		}
		const isEmpty = findNull(clonedData);
		if (isEmpty === true) return false;
		else return true;
	};

	const settingFinalData = formData => {
		const clonedData = _.cloneDeep(formData);
		formData.manager_phone = `${clonedData.manager_phone0}-${clonedData.manager_phone1}-${clonedData.manager_phone2}`;
		delete formData.manager_phone0;
		delete formData.manager_phone1;
		delete formData.manager_phone2;
		delete formData.business_code0;
		delete formData.business_code1;
		delete formData.business_code2;
		delete formData.business_phone0;
		delete formData.business_phone1;
		delete formData.business_phone2;
		delete formData.agree;
		delete formData.address_detail;
		formData.startup = Number(clonedData.startup);

		if (formData.startup === 3) {
			delete formData.address_code;
			delete formData.address;
		} else {
			formData.business_phone = `${clonedData.business_phone0}-${clonedData.business_phone1}-${clonedData.business_phone2}`;
			formData.business_code = `${clonedData.business_code0}-${clonedData.business_code1}-${clonedData.business_code2}`;
			formData.address = `${clonedData.address}${clonedData.address_detail}`;
		}
	};

	// FIXME: 서버요청 추가

	useEffect(() => {
		setDisabled(false);
		setErrors('');
		setFormData(initData);
		setDisplayPostCode(false);
		setFilename('');
		setComplete(false);
		const urlParameter = window.location.search;
		const emailValue = urlParameter.substring(1);
		setGoogleEmail(emailValue);
	}, []);
	if (isComplete)
		return (
			<StyledSignup>
				<section className="complete">
					<h1>Completed!</h1>
					<p>
						회원가입 신청이 완료되었습니다.
						<br />
						영업일 기준 2일 이내에 처리될 예정입니다.
					</p>
					<div className="home_btn">
						<Link to="/" style={{ color: '#FA5734' }}>
							{' '}
							<AiFillHome /> 메인 페이지가기{' '}
						</Link>
					</div>
				</section>
			</StyledSignup>
		);

	return (
		<StyledSignup>
			<section>
				{isLoading && <Loading />}
				<h1>회원가입</h1>
				<span
					style={{
						display: 'flex',
						justifyContent: 'center',
						fontWeight: '600',
					}}
				>
					옴니톡은 기업 전용 서비스이며, 심사 및 승인 후 이용 가능합니다.
				</span>
				<p className="border_line"> </p>

				<div className="checkbox_radio">
					<input
						onChange={handleChangeRadio}
						type="radio"
						name="startup"
						value="3"
						checked={formData.startup === '3'}
					/>
					<label htmlFor="startup"> 예비창업자 </label>
					<input
						type="radio"
						name="startup"
						value="2"
						checked={formData.startup === '2'}
						onChange={handleChangeRadio}
						defaultChecked
					/>
					<label htmlFor="startup"> 개인사업자 </label>
					<input
						type="radio"
						name="startup"
						value="1"
						checked={formData.startup === '1'}
						onChange={handleChangeRadio}
					/>
					<label htmlFor="startup"> 법인사업자 </label>
				</div>

				<form method="post" encType="multipart/form-data">
					<p style={{ fontWeight: 'bold', fontSize: '13px' }}>
						<span style={{ color: 'red' }}>* </span>표시는 필수 입력 값입니다.
					</p>
					<div
						className="input_box"
						style={{ display: 'flex', flexDirection: 'column' }}
					>
						<label htmlFor="manager_email">
							<span style={{ color: 'red' }}>* </span>담당자 이메일
						</label>
						{/* 메일 value= param 값 */}
						<div className="input_box-wrap">
							<input
								type="email"
								name="manager_email"
								value={googleEmail}
								disabled={{ googleEmail }}
								onChange={handleChange}
							/>
						</div>
					</div>

					<div className="input_box">
						<label htmlFor="manager_name">
							<span style={{ color: 'red' }}>* </span>담당자 이름
						</label>
						<input
							type="text"
							name="manager_name"
							value={formData.manager_name}
							maxLength="30"
							disabled={disabled}
							onChange={handleChange}
						/>
					</div>
					<div className="manager_phone">
						<label htmlFor="manager_phone">
							<span style={{ color: 'red' }}>* </span>담당자 전화번호
						</label>
						<input
							type="text"
							name="manager_phone0"
							value={formData.manager_phone0}
							maxLength="3"
							disabled={disabled}
							onChange={handleChange}
						/>
						<span>-</span>
						<input
							type="text"
							name="manager_phone1"
							value={formData.manager_phone1}
							maxLength="4"
							disabled={disabled}
							onChange={handleChange}
						/>
						<span>-</span>
						<input
							type="text"
							name="manager_phone2"
							value={formData.manager_phone2}
							maxLength="4"
							disabled={disabled}
							onChange={handleChange}
						/>
					</div>

					<div className="input_box">
						<label htmlFor="agency_name">
							<span style={{ color: 'red' }}>* </span>팀명 또는 회사명
						</label>
						<input
							type="text"
							name="agency_name"
							value={formData.agency_name}
							disabled={disabled}
							onChange={handleChange}
						/>
					</div>

					{formData.startup !== '3' && (
						<>
							<div className="address_box">
								<label htmlFor="address_code">
									{' '}
									<span style={{ color: 'red' }}>* </span>사업자 주소
								</label>
								<input
									type="text"
									readOnly
									name="address_code"
									value={formData.address_code}
									placeholder="우편 번호"
									maxLength="6"
									onClick={() => setDisplayPostCode(true)}
								/>
								<input
									type="text"
									readOnly
									name="address"
									value={formData.address}
									onClick={() => setDisplayPostCode(true)}
								/>
								<input
									type="text"
									name="address_detail"
									value={formData.address_detail}
									placeholder="상세주소"
									disabled={disabled}
									onChange={handleChange}
								/>
							</div>

							<div className="business_phone">
								<label htmlFor="business_phone">
									<span style={{ color: 'red' }}>* </span>회사 전화번호
								</label>
								<input
									type="text"
									name="business_phone0"
									value={formData.business_phone0}
									maxLength="3"
									disabled={disabled}
									onChange={handleChange}
								/>
								<span>-</span>
								<input
									type="text"
									name="business_phone1"
									value={formData.business_phone1}
									maxLength="4"
									disabled={disabled}
									onChange={handleChange}
								/>
								<span>-</span>
								<input
									type="text"
									name="business_phone2"
									value={formData.business_phone2}
									maxLength="4"
									disabled={disabled}
									onChange={handleChange}
								/>
							</div>

							<div className="business_code">
								<label htmlFor="business_code">
									<span style={{ color: 'red' }}>* </span>사업자 등록번호
								</label>
								<input
									type="text"
									name="business_code0"
									value={formData.business_code0}
									maxLength="3"
									disabled={disabled}
									onChange={handleChange}
								/>
								<span>-</span>
								<input
									type="text"
									name="business_code1"
									value={formData.business_code1}
									maxLength="2"
									disabled={disabled}
									onChange={handleChange}
								/>
								<span>-</span>
								<input
									type="text"
									name="business_code2"
									value={formData.business_code2}
									maxLength="5"
									disabled={disabled}
									onChange={handleChange}
								/>
							</div>

							<div className="file_box">
								<p>
									<span style={{ color: 'red' }}>* </span>사업자등록증 파일첨부
								</p>
								<div className="file_container">
									<input
										type="file"
										id="file"
										name="file"
										accept="image/png, image/jpg, .pdf"
										onChange={handleChangeFile}
									/>
									<input
										type="text"
										name="file"
										readOnly
										value={filename}
										placeholder="png, jpg, pdf"
									/>
									<label htmlFor="file">
										<span style={{ color: 'red' }}>* </span>파일선택
									</label>
								</div>
							</div>
						</>
					)}

					<div className="input_box">
						<label htmlFor="intro">
							<span style={{ color: 'red' }}>* </span>비즈니스 계획(중요)
						</label>
						<input
							type="text"
							name="intro"
							value={formData.intro}
							placeholder="불분명한 비즈니스는 가입 승인이 보류됩니다."
							maxLength="50"
							onChange={handleChange}
						/>
					</div>
					<div className="input_box">
						<label htmlFor="homepage">홈페이지 주소</label>
						<input
							type="text"
							name="homepage"
							value={formData.homepage}
							disabled={disabled}
							onChange={handleChange}
						/>
					</div>
					<span
						style={{
							textAlign: 'center',
							color: 'red',
							fontSize: '16px',
							fontWeight: '600',
						}}
					>
						{errors}
					</span>
					<div className="checkbox">
						<input
							type="checkbox"
							name="agree"
							style={{ cursor: 'pointer' }}
							onChange={setAgreeInfo}
							disabled={isChecked}
						/>
						<label htmlFor="agree">아래 이용 약관에 동의합니다. (필수)</label>
					</div>

					<div className="clause">
						<button
							type="button"
							onClick={() => window.open('/terms', '_blank')}
						>
							서비스 이용 약관
						</button>
						<button
							type="button"
							onClick={() => window.open('/terms/privacy-policy', '_blank')}
						>
							개인정보처리방침
						</button>
					</div>

					<button
						style={{ cursor: 'pointer' }}
						type="button"
						className="signup_btn"
						onClick={handleSubmit}
					>
						회원 가입
					</button>
				</form>
				{displayPostcode && (
					// onClose : 주소클릭시 닫힘
					<Popup onClose={() => setDisplayPostCode(prev => !prev)}>
						<div className="container">
							<button
								style={{ backgroundColor: 'white', cursor: 'pointer' }}
								onClick={() => setDisplayPostCode(prev => !prev)}
								type="button"
							>
								✖
							</button>
							<DaumPostcode onComplete={handleAddressCompleted} />
						</div>
					</Popup>
				)}
			</section>
		</StyledSignup>
	);
}

export default SignupGoogle;

const StyledSignup = styled.div`
${pagePaddingTop}
section{
  font-family: 'Pretendard';
  max-width: 410px;
  margin: 0 auto;
  color: ${palette.text.default};
}
  .description-text{
    color: red;
    font-size: 15px;
    text-align: center;
    font-weight: 500;
  }

  button{
    background-color: ${palette.main.vivid};
    font-family: 'Pretendard';
    font-weight: ${fontWeight.bold};
    font-size: ${fontSize.regular};
    cursor: pointer;
  }
  input[type=radio] {
    cursor: pointer;
  }
  h1{
    font-size:1.625rem;
    color: ${palette.main.default};
    font-weight: ${fontWeight.bold};
    text-align: center;
  }
  p{
    font-size: 1.75rem;
    margin-bottom: 30px;
  }
  p:nth-child(3){
    font-size: 1.25rem;
    margin-bottom: 50px;
    color: ${palette.text.default};
  }
  .border_line{
    width: 100%;
    height: 1px;
    background-color: ${palette.gray.middle};
  }
  .checkbox_radio{
    width: 100%;
    margin: 0 auto;
    label{
      font-size: 15px;
    }
  }
  form{
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    .input_box{
      margin-bottom: 16px;
    label{
      font-size: ${fontSize.small};
      font-weight: ${fontWeight.regular};
      line-height: 20px;
      color:#3e4042;
    }
    input{
      ${defaultInput}
      width: 100%;
    }
    p > span {
      color: blue;
    }
    }
    
    .manager_phone,
    .business_phone,
    .business_code{
      width: 100%;
      margin-bottom: 20px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      align-items: center;
      label{
        display: block;
        width: 100%;
        font-size: ${fontSize.small};
        font-weight: ${fontWeight.medium};
        line-height: 30px;
      }
      input{
        width: 30%;
        height: 42px;
        border: 1px solid #C4C4C4;
        border-radius: 5px;
        padding: 0;
        ::placeholder{
          font-size: 1rem;
          padding-left:10px;
}
      }
    }
    .confirmEmail {
      display: inline-flex;
    }
    .description {
      margin-top: 4px;
      margin-bottom: 4px;
      font-size: 12px;
      color: red;
      ${defaultInput}
      width: 100%;
    }
     button.description{
      color: red;
     }
    .hide-description {
      display: none;
    }
    .show-description {
      color: red;
    }
    /* .showConfirmBox{
      display: none;
    } */
    input.description,
    .address_box{
      width: 100%;
      margin-bottom: 10px;
      label{
        display: block;
        width: 100%;
        font-size: ${fontSize.small};
        font-weight: ${fontWeight.semiBold};
        line-height: 30px;
      }
      input{
        height: 48px;
        margin-bottom:6px;
        border: 1px solid #d1d1d1;
        border-radius: 5px;
      }
      input:nth-child(3){
        width: 402px;
        margin-left: 6px;
      }
      input:last-child{
        width: 100%;
      }
    }
    .file_box{
        width: 556px;
      margin-bottom: 20px;
        p{
          margin: 0 0 10px 0;
          font-size: ${fontSize.small};
          font-weight: ${fontWeight.semiBold};
          line-height: 30px;
        }
      .file_container{
        ${defaultFlexCenter}
        justify-content: flex-start;
        label{
        width: 70px;
        height: 32px;
        font-size: 14px;
        padding-right: 5px;
        cursor: pointer;
        text-align: center;
        ${defaultFlexCenter}
        color: ${palette.white};
        background-color: ${palette.main.default};
        border-radius: 5px;
      }
      input[type=file]{
        display: none;
      }
      input{
        ${defaultInput}
        width: 310px;
        margin-bottom: 0;
        margin-right:6px;
      }
      }
    }
    .signup_btn{
      width: 274px;
      height: 42px;
      margin: 0 auto;
      margin-bottom: 180px;
      background-color: ${palette.main.default};
      border: 0;
      border-radius: 5px;
      color: ${palette.white};
      font-size: ${fontSize.regular};
    }
  }
  .checkbox{
    padding-top: 150px;
    ${defaultFlexCenter}
    input{
      width: 16px;
      margin: 0;
      margin-right: 6px;
    }
  }
  .clause{
      ${defaultFlexCenter}
      padding-top: 18px;
      margin-bottom: 50px;
      button{
        border: 0;
        border-right: 1px solid ${palette.gray.middle};
        background-color: ${palette.opacity};
        color: ${palette.text.light};
        cursor: pointer;
      }
      button:nth-child(2){
        border: 0;
      }
    }
    .home_btn{
      margin-bottom: 80px;
    }

    #confirm_btn{
    width: 200px;
    margin-left: 5px;
    margin-top: 0px;
    margin-bottom: 0px;
  }

@media screen and (max-width: 2400px ) and (min-width: 630px) {
  .input_box-wrap {
    display: flex;
  }

  form {
    input.description{
      margin-top: 0px;
    }
  }


  section{
    margin: 0 auto;

   
    .discription{
      width: 200px;
    }
  
  }
  button {
    .description {
      width: 200px;
    }
    font-size: 16px;
  }
  
}

@media screen and (min-width: 580px) {
padding: 100px 5% 0 5%;
section{
  margin: 0 auto;
  h1{
    font-size: 1.625rem;
    font-weight: ${fontWeight.medium};
    color: ${palette.main.default};
  }

  p{
    font-size: 1.5rem;
    margin-bottom: 20px;
  }
  p:nth-child(3){
    font-size: 1rem;
    margin-bottom: 50px;
    color: ${palette.text.default};
  }
  .border_line{
    width: 100%;
    height: 1px;
    background-color: ${palette.gray.middle};
  }
  [type="radio"] {
  vertical-align: middle;
  appearance: none;
  border: max(2px, 0.1em) solid #dee2e6;
  border-radius: 50%;
  width: 1.25em;
  height: 1.25em;
  transition: border 0.5s ease-in-out;
}
[type="radio"]:checked {
  border: 0.4em solid ${palette.main.default};
}


  .checkbox_radio{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin: 0 auto;
    margin-top: 14px;
    margin-bottom: 10px;
    label{
      font-weight: ${fontWeight.regular};
      font-size: 15px;
      padding-left:5px;
      padding-right: 5px;
    }
    input {
      margin: 0;
    }
  }
  form{
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    .input_box-wrap{
      display: flex;
    }
    #send_email_btn{
    width: 200px; 
    margin-left: 5px;
    margin-top: 0;
   
  } 
   #confirm_btn{
    width: 200px;
    margin-left: 5px;
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 16px;
  }
  .email_confirm_box{
    display: flex;
  
  }

    .input_box{
      margin-bottom: 16px;
    label{
      font-size: ${fontSize.small};
      font-weight: ${fontWeight.medium};
      line-height: 30px;
    }
    input{
      ${defaultInput}
      width: 100%;
      ::placeholder{
        font-size: 14px;
      }
    }
    }
    .manager_phone,
    .business_phone,
    .business_code{
      width: 100%;
      margin-bottom: 20px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      align-items: center;
      label{
        display: block;
        width: 100%;
        font-size: ${fontSize.small};
        font-weight: ${fontWeight.medium};
        line-height: 30px;
      }
      input{
        width: 30%;
        height: 48px;
        border: 1px solid #d1d1d1;
        border-radius: 5px;
        ::placeholder{
          font-size: 1rem;
          padding-left: 10px;
          color: #d1d1d1;
}
      }
    }
    .address_box{
      width: 100%;
      margin-bottom: 20px;
      label{
        display: block;
        width: 100%;
        font-size: ${fontSize.small};
        font-weight: ${fontWeight.medium};
        line-height: 30px;
      }
      input{
        width: 30%;
        height: 48px;
        margin-bottom:6px;
        border: 1px solid #d1d1d1;
        border-radius: 5px;
      }
      input:nth-child(3){
        width: 68%;
        margin-left: 2%;
      }
      input:last-child{
        width: 100%;
      }
      input::placeholder {
        padding-left: 10px;
      }
    }
    .file_box{
        width: 100%;
      margin-bottom: 20px;
        p{
          margin: 0 0 10px 0;
          font-size: ${fontSize.small};
          font-weight: ${fontWeight.semiBold};
          line-height: 30px;
        }
      .file_container{
        ${defaultFlexCenter}
        justify-content: flex-start;
        label{
          width: 70px;
        height: 32px;
        font-size: 14px;
        text-align: center;
        ${defaultFlexCenter}
        color: ${palette.white};
        background-color: ${palette.main.default};
        border-radius: 5px;
      }
      input[type=file]{
        display: none;
      }
      input{
        ${defaultInput}
        width: 330px;
        margin-bottom: 0;
        margin-right:6px;
      }
      }
    }
    .signup_btn{
      width: 274px;
      height: 42px;
      margin: 0 auto;
      margin-bottom: 180px;
      background-color: ${palette.main.default};
      border: 0;
      border-radius: 5px;
      color: ${palette.white};
      font-size: ${fontSize.regular};
    }
  }
  .checkbox{
    padding-top: 40px;
    ${defaultFlexCenter}
    input{
      width: 16px;
      margin: 0;
      margin-right: 6px;
    }
  }
  .clause{
      ${defaultFlexCenter}
      margin-bottom: 50px;
      button{
        border: 0;
        border-right: 1px solid ${palette.gray.middle};
        background-color: ${palette.opacity};
        font-size: 14px;
        color: ${palette.text.light};
      }
      button:nth-child(2){
        border: 0;
      }
    }
  .home_btn{
    margin-bottom: 80px;
  }
}
}

@media screen and (max-width: 579px) and (min-width: 230px) {
padding: 80px 5% 0 5%;
section{

 
  .input_box-wrap{
      display: flex;
    }

 
  
  h1{
    font-size: 1.5rem;
    margin-bottom: 30px;
    font-weight: ${fontWeight.medium};
  }
  p{
    font-size: 1.1rem;
    margin-bottom: 10px;
  }
  p:nth-child(3){
    font-size: 14px;
    margin-bottom: 30px;
    color: ${palette.text.light};
  }
  .border_line{
    width: 100%;
    height: 1px;
    margin-bottom: 50px;
    background-color: ${palette.gray.middle};
  }
  .checkbox_radio{
    width: 100%;
    margin: 0 auto;
    margin-bottom: 30px;
    label{
      font-size: 15px;
      font-weight: ${fontWeight.regular};
    }
  }
  form{
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    .input_box{
      margin-bottom: 16px;
    label{
      font-size: ${fontSize.small};
      font-weight: ${fontWeight.semiBold};
      line-height: 30px;
    }
    input{
      ${defaultInput}
      width: 100%;
      ::placeholder{
        font-size: 14px;
      }
    }
    }
    .manager_phone,
    .business_phone,
    .business_code{
      width: 100%;
      margin-bottom: 16px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      align-items: center;
      label{
        display: block;
        width: 100%;
        font-size: ${fontSize.small};
        font-weight: ${fontWeight.semiBold};
        line-height: 30px;
      }
      input{
        width: 30%;
        height: 42px;
        border: 1px solid #C4C4C4;
        border-radius: 5px;
        ::placeholder{
          font-size: 1rem;
          padding-left: 10px;
}
      }
    }
    .address_box{
      width: 100%;
      margin-bottom: 10px;
      label{
        display: block;
        width: 100%;
        font-size: ${fontSize.small};
        font-weight: ${fontWeight.semiBold};
        line-height: 30px;
      }
      input{
        width: 30%;
        height: 48px;
        margin-bottom:6px;
        border: 1px solid #d1d1d1;
        border-radius: 5px;
      }
      input:nth-child(3){
        width: 68%;
        margin-left: 2%;
      }
      input:last-child{
        width: 100%;
      }
      input::placeholder {
        padding-left: 10px;
        color: #d1d1d1;
      }
    }
    .file_box{
        width: 100%;
      margin-bottom: 20px;
        p{
          margin: 0 0 10px 0;
          font-size: ${fontSize.small};
          font-weight: ${fontWeight.semiBold};
          line-height: 30px;
        }
      .file_container{
        ${defaultFlexCenter}
        justify-content: flex-start;
        label{
        width: 100px;
        height: 32px;
        font-size: 14px;
        text-align: center;
        ${defaultFlexCenter}
        color: ${palette.white};
        background-color: ${palette.main.default};
        border-radius: 5px;
      }
      input[type=file]{
        display: none;
      }
      input{
        ${defaultInput}
        width: 100%;
        margin-bottom: 0;
        margin-right: 6px;
      }
      }
    }
    .signup_btn{
      width: 274px;
      height: 42px;
      margin: 0 auto;
      margin-bottom: 180px;
      background-color: ${palette.main.default};
      border: 0;
      border-radius: 5px;
      color: ${palette.white};
      font-size: ${fontSize.regular};
    }
  }
  .checkbox{
    padding-top: 30px;
    ${defaultFlexCenter}
    input{
      width: 16px;
      margin: 0;
      margin-right: 6px;
    }
    label{
    }
  }

  [type="radio"] {
  vertical-align: middle;
  appearance: none;
  border: max(2px, 0.1em) solid #dee2e6;
  border-radius: 50%;
  width: 1.25em;
  height: 1.25em;
  transition: border 0.5s ease-in-out;
}
[type="radio"]:checked {
  border: 0.4em solid ${palette.main.default};
}
  .checkbox_radio{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin: 0 auto;
    margin-top: 14px;
    margin-bottom: 10px;
    label{
      font-weight: ${fontWeight.regular};
      font-size: 15px;
      padding-left:5px;
      padding-right: 5px;
    }
    input {
      margin: 0;
    }
  }
  .clause{
      ${defaultFlexCenter}
      margin-bottom: 50px;
      button{
        border: 0;
        border-right: 1px solid ${palette.gray.middle};
        background-color: ${palette.opacity};
        color: ${palette.text.light};
        font-size: 14px;
      }
      button:nth-child(2){
        border: 0;
      }
    }
    .home_btn{
      margin-bottom: 80px;
    }
}
}

`;
const Popup = styled.div`
width: 100vw;
height: 100vh;
position: fixed;
left: 0;
top: 0;
background-color: ${palette.white};
.container{
  width: 420px;
  position: fixed;
left: 50%;
top: 50%;
transform: translate(-50%,-50%);
}
button {
  margin-left: 390px;
  background-color: white;
  margin-bottom: 20px;
  width: 30px;
  font-size: 20px;
  color: #666666;
  background-color: transparent;
  border: none;
  margin-bottom: 30px;
}
`;
