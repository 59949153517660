import React, { useState } from 'react';
import {
	SiJavascript,
	SiTypescript,
	SiApple,
	SiFlutter,
	SiUnity,
	SiReact,
	SiAndroid,
} from 'react-icons/si';
import { RiCodeBoxFill } from 'react-icons/ri';
import { useTranslation } from 'react-i18next';

const docsCategory = [
	{
		language: 'Javascript',
		icon: SiJavascript,
		iconColor: '#f7df1e',
		docsURL: 'https://docs.omnitalk.io/javascript',
	},
	{
		language: 'Typescript',
		icon: SiTypescript,
		iconColor: '#3178c6',
		docsURL: 'https://docs.omnitalk.io/typescript',
	},
	{
		language: 'Flutter',
		icon: SiFlutter,
		iconColor: '#1D5898',
		docsURL: 'https://docs.omnitalk.io/flutter',
	},
	{
		language: 'ReactNative',
		icon: SiReact,
		iconColor: '#00d8ff',
		docsURL: 'https://docs.omnitalk.io/react-native',
	},

	{
		language: 'iOS',
		icon: SiApple,
		iconColor: '#ebebeb',
		docsURL: 'https://docs.omnitalk.io/ios',
	},

	{
		language: 'Android',
		icon: SiAndroid,
		iconColor: '#78C257',
		docsURL: 'https://docs.omnitalk.io/android',
	},
	{
		language: 'RESTAPI',
		icon: RiCodeBoxFill,
		iconColor: '#ebebeb',
		docsURL: 'https://docs.omnitalk.io/rest-api',
	},
	{
		language: 'Unity',
		icon: SiUnity,
		iconColor: '#ebebeb',
		docsURL: 'https://docs.omnitalk.io/unity',
	},
];
export default function DocsIntro() {
  const {t} = useTranslation();
  const [hoveredIndex, setHoveredIndex] = useState(-1);
  const openDocsURL = (docsURL) => {
    window.open(docsURL, '_blank'); // Open the docsURL in a new tab/window
  };

  const handleMouseEnter = (index) => {
    setHoveredIndex(index);
  };
  
  const handleMouseLeave = () => {
    setHoveredIndex(-1);
  };
  return (
    <article className="  xs:pt-12 xs:pb-12 sm:pt-20 sm:pb-20 lg:pt-[144px] lg:mb-[144px] max-w-full h-auto ml-auto mr-auto">
      <div className="flex flex-col  ">
        <div className="" />
      </div>
      <div className="flex flex-col xs:px-5 sm:px-0 items-center">
          <p className=" xs:mt-0 mb-0 text-center font-black dark:text-white sm:text-xl md:text-2xl lg:text-[3rem] lg:leading-[3rem] ">{t('main.doc.desc')}</p>
          <p className="xs:mt-0 mb-0 lg:mt-4 text-center font-black dark:text-white sm:text-xl md:text-2xl lg:text-[3rem] lg:leading-[3rem] ">{t('main.doc.desc2')}</p>
      </div>
      <div className="flex flex-col  items-center ">
        <span className="lg:max-w-lg text-center lg:leading-[1.8rem] xs:text-sm sm:text-base xs:mt-2 lg:mt-8 xs:mb-8 lg:mb-[7rem] md:text-base  self-center lg:text-[1.375rem] text-[#323232] font-semi-bold dark:text-white ">
       {t('main.doc.desc3')}</span>
        <div className="container mt-4 mx-auto">
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-4 border-none max-w-[1200px] mr-auto ml-auto gap-4 justify-items-center">
            {docsCategory.map((category, index) => {
              const IconComponent = category.icon;
              const isHovered = hoveredIndex === index;
              //  hover:shadow-md
              return (
                <button
                  type="button"
                  key={index}
                  className="flex items-center flex-col  px-24 py-8 rounded-[20px] w-full bg-[#fcfcfc] dark:bg-black  dark:border-white group-hover:text-white hover:bg-black  cursor-pointer  hover:border-opacity-0 transform hover:-translate-y-1 transition-all duration-200"
                  onMouseEnter={() => handleMouseEnter(index)}
                  onMouseLeave={handleMouseLeave}
                  onClick={() => openDocsURL(category.docsURL)}
                >
                  <div className="flex items-center mb-6 font-semibold w-14 h-14">
                    <IconComponent
                      style={{
                        color: isHovered ? category.iconColor : '#999999',
                        width: '55px',
                        height: '55px',
                        transition: 'color 200ms ease-in-out',
                      }}
                    />
                  </div>
                  <span className={` text-sm lg:text-base font-medium text-gray-600 dark:text-white-800 ${
            isHovered ? 'text-white' : ''
          }`}>{category.language}</span>
                </button>
              );
            })}
          </div>
        </div>
      </div>
    </article>
  );
}
