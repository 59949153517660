/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { BsPlusCircleFill } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { Helmet } from 'react-helmet-async';
import {
	defaultFlexCenter,
	fontSize,
	fontWeight,
	palette,
} from '../../modules/defines/style';
import API from '../../modules/api/api';
import DeleteModal from '../../components/ui/DeleteModal';
import HookView from '../../components/Service/HookView';
import AddHook from '../../components/Service/AddHook';
import myAxios from '../../modules/api/myAxios';
import { roleState, userState } from '../../recoil';
import BASE_URL from '../../config';
import ModifyModal from '../../components/ui/ModifyModal';

const deleteState = {
	display: false,
	type: '', // delete | add
	data: {},
};
const modifyState = {
	display: false,
	type: '', // delete | add
	data: {},
};
function WebHook() {
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(deleteState);
  const [modifyModal, setModifyModal] = useState(modifyState);
  const [modify, setModify] = useState(true);
  const navigate = useNavigate();
  const [user, setUser] = useRecoilState(userState);
  const [role, setRole] = useRecoilState(roleState);
  // get data
  const requestData = () => {
    
    API.getWebHookList({
    }).then(response => {
      setData(response.data.data);
    }, error => {
      console.log(error);
    });
    
  };
  const authUser = async() => {
    try {
      const response = await myAxios.get('/users/info');
      if (response.data.result === 'success') {
        if (response.data.data.role === 'yet')
          navigate('/wait', { replace: true });
        if (response.data.data.role !== 'yet') {
          setUser(response.data.data.agency_name);
          setRole(response.data.data.role);
        }
      }
    } catch (error) {
      if (error.response.status === 401) {
        navigate('/login', { replace: true });
      }
      return error;
    }
  };


  const handleDelete = (idx) => {
    setDeleteModal({
      display: true,
      type: 'delete',
      idx,
    });
  };

  const handleModify = (data) => {
    setModifyModal({
      display: true,
      type: 'modify',
      data
    });
  };
  const handleChildSuccess = () => {
    requestData();
  };
  
  const handleModalConfirm = () => {
    if (deleteModal.type === 'delete') {
      myAxios.delete(`${BASE_URL}/users/webhook/${deleteModal.idx}`)
        .then(response => {
          if (response.status === 200) {
            requestData();
            setDeleteModal(false);
          }
        }, error => {
          console.log(error);
        });
    }
    if (modifyModal.type === 'modify') {
    }
  };

  useEffect(() => {
    authUser();
    requestData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <Helmet>
        <title>{t('helmet.webhook.title')}</title>
        <meta charset="utf-8" />
        <meta name="keywords" content="WebRTC, SIP, CPAAS, communication, cloud-based, integration, solution, provider,WebRTC CPAAS,SIP CPaaS,WebRTC SIP integration,Cloud-based WebRTC,Cloud-based SIP,WebRTC and SIP solution,WebRTC and SIP provider,옴니톡,옴니스토리,비대면통신,커뮤니케이션플랫폼,메타버스,통신,통신서비스" />
        <meta name="description" content={t('helmet.webhook.desc')} />
        <meta property="og:title" content={t('helmet.webhook.ogTitle')} />
        <meta property="og:description" content={t('helmet.webhook.ogDesc')} />
        <meta property="og:url" content="https://omnitalk.io/console/service/web-hook" />
        <meta property="twitter:title" content={t('helmet.webhook.twitterTitle')} />
        <meta property="twitter:description" content={t('helmet.webhook.twitterDesc')} />
        <meta name="robots" content="noindex" />

      </Helmet>
      <StyledCard>
        <section>
          <div className="title">
            <h3>Web Hook</h3>
          </div>
          <article>
            <BsPlusCircleFill style={{ color: '#FA5734', fontSize: '20px', marginRight:'10px' }} />
            <button
              type="button"
              onClick={() => {
                setOpenModal(true);
              }}> Add
            </button>
          </article>
          <article>
            {data.length === 0 ? (
              <span />
            ) : (
              <>
                {
                  data.map((card, i) => {
                    return (
                      <StyledCardView key={i}>
                        <HookView
                          data={card} handleModify={handleModify}
                          modify={modify} setModify={setModify} />
                        <div className="button-box">
                     
                          <div className="button">
                            <button
                              style={{ fontWeight:'500', color: 'red', marginRight: '40px' }}
                              type="button" onClick={() => handleDelete(card.idx)}>{t('console.button.del')}
                            </button>
                          </div>
                          <div className="button">
                            <button
                              style={{ fontWeight:'500' }}
                              type="button" onClick={() => handleModify(card)}>{t('console.button.edit')}
                            </button>
                          </div>
                        </div>
                      
                      </StyledCardView>
                    );
                  })
                }
              </>
            )}
          </article>
        </section>
        {openModal && (
          <StyledModal>
            <AddHook setOpenModal={setOpenModal} requestData={requestData} t={t} />
          </StyledModal>
        )}
        {deleteModal.display === true && (
          <StyledModal>
            <DeleteModal handleDelete={handleModalConfirm} setDeleteModal={setDeleteModal} t={t} text="WebHook" />
         
          </StyledModal>
        )}
        {modifyModal.display === true && (
          <StyledModal>
            <ModifyModal
              onChildSuccess={handleChildSuccess} selectedData={modifyModal} handleModify={handleModalConfirm} setModifyModal={setModifyModal} t={t}
              text="WebHook" />
          </StyledModal>
        )}
      </StyledCard>
    </>
  );
}
export default WebHook;

const StyledCard = styled.div`
width: 100%;
padding-top: 120px;

section{
    padding-left: 240px;
    min-height: 800px;
    .title{
    width: 100%;
    margin-bottom: 50px;
    border-bottom: 1px solid ${palette.gray.middle};
    h3{
      margin-bottom: 20px;
      font-size: 1.75rem;
      font-weight: ${fontWeight.semiBold};
    }
  }
  article{
    margin-bottom: 50px;
    ${defaultFlexCenter}
    justify-content: flex-start;
    flex-wrap: wrap;
  
    button{
        font-size: 1.25rem;
        background-color: ${palette.opacity};
        border: 0;
        
        :hover{
          cursor: pointer;
          color: ${palette.main.default}
        }
    }
    .card{
      width: 100%;
      ${defaultFlexCenter}
      justify-content: flex-start;
      flex-wrap: wrap;
    }
  
  }
}

@media screen and (max-width:979px) and (min-width: 580px) {
section{
    padding:0 5%;
  article{
    ${defaultFlexCenter}
    margin-right: 0;
  }
}
}

@media screen and (max-width:579px) and (min-width: 230px) {
padding-top: 80px;
section{
    padding: 0 5%;
    .title{
    h3{
      margin-bottom: 30px;
      font-size: 1.5rem;
      font-weight: ${fontWeight.medium};
    }
  }
  article{
    margin-bottom: 20px;
    ${defaultFlexCenter}
    justify-content: flex-start;
    flex-wrap: wrap;
    .card{
      width: 100%;
      ${defaultFlexCenter}
      justify-content: center;
      flex-wrap: wrap;
    }
  }
}

}
`;

const StyledModal = styled.div`
width: 100%;
height: 100%;
position: fixed;
top: 0;
left: 0;
background-color: rgba(0,0,0,0.4);
z-index: 100;
`;

const StyledCardView = styled.div`
position: relative;
.button{
  position: absolute;
  bottom: 50px;
  right: 50px;
  button{
    font-size: ${fontSize.micro};
    color: #999;
    :hover{
      color: ${palette.main.default};
    }
  }
}

@media screen and (max-width:579px) and (min-width: 230px) {
  width: 100%;
.button{
  position: absolute;
  bottom: 50px;
  right: 20px;
  button{
    font-size: ${fontSize.micro};
    :hover{
      cursor: pointer;
      color: ${palette.main.default};
    }
  }
}
}
`;