import React from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import BreadCrumb from '../components/BreadCrumb';
import DemoCard from '../components/demo/DemoCard';

function Demo() {
	const { t } = useTranslation();
	return (
		<>
			<Helmet>
				<title>{t('helmet.demo.title')}</title>
				<meta charset="utf-8" />
				<meta
					name="keywords"
					content="javascript,typescript,unity,unreal,flutter,ios,react-native,react,jsx,rn,js,ts,dart,api,api-reference,document,sdk,sdk-guide"
				/>
				<meta name="description" content={t('helmet.demo.desc')} />
				<meta property="og:title" content={t('helmet.demo.title')} />
				<meta property="og:description" content={t('helmet.demo.desc')} />
				<meta property="og:url" content="https://omnitalk.io/demo" />
				<link rel="canonical" href="https://omnitalk.io/demo/" />

				<meta property="twitter:title" content={t('helmet.demo.title')} />
				<meta property="twitter:card" content={t('helmet.demo.card')} />
				<meta property="twitter:description" content={t('helmet.demo.desc')} />
				<meta name="robots" content="index" />
				<meta name="title" content={t('helmet.demo.title')}></meta>
				<meta name="author" content="omnitalk"></meta>
				<meta property="og:type" content="website" />
			</Helmet>
			<section className="max-w-[1200px] mx-auto px-[5%] min-h-[70vh]">
				<BreadCrumb />
				<div />
				<article className="">
					<h2 className="xs:mt-7 font-bold lg:text-3xl text-left xs:px-2  xs:text-xl sm:text-2xl sm:px-8 lg:mt-10 dark:text-white">
						Demo (2.0 version)
					</h2>
					<p className=" xs:px-2  xs:text-sm sm:px-8 text-left text-gray-700   md:text-lg sm:text-md mb-0 dark:text-white">
						{t('demo.descOne')}
						<br />
						{t('demo.descTwo')}
					</p>
					<DemoCard />
				</article>
			</section>
		</>
	);
}

export default Demo;

