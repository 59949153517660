import { useState, useEffect } from 'react';
import styled from 'styled-components';

import { palette } from '../../modules/defines/style';

const TabWrapper = styled.div`
  margin-bottom: 20px;
  display: flex;
  justify-content: flex-start;
`;
const TabItem = styled.button`
  height: 34px;
  cursor: pointer;
  margin-left: 5px;
  line-height: 10px;
  padding: 12px 13px;
  border-radius: 5px;
  font-size: 15px;
  white-space: nowrap;
  background-color: transparent;
  border: none;
  background-color: ${(props) => (props.theme === 'selected' ? '#E86B3A' : '')};
  color: ${(props) => (props.theme === 'selected' ? 'white' : '#A99585')};
  border-bottom: ${(props) => (props.theme === 'selected' ? '#E86B3A' : '#A99585')};
  font-weight: ${(props) => (props.theme === 'selected' ? '800' : '500')};
  :hover {
    /* background-color :rgba(232, 107, 58, 0.1);
    color:'red'; */
  }
  /* :hover {
    color: ${palette.main.vivid};
  } */
`;
const SoftTab = ({ list, selectedIndex = 0, onClick }) => {

  const [selected, setSelected] = useState(selectedIndex);
  useEffect(() => {
    setSelected(selectedIndex);
  }, [selectedIndex]);

  const handleClick = (index) => {
    setSelected(index);
    if (onClick) onClick(index);
  };

  return (
    <TabWrapper>
      {list.map((d, i) => (
        <TabItem
          key={i}
          theme={i === selected && 'selected'}
          onClick={() => handleClick(i)}
        >
          {d.text}
        </TabItem>
      ))}
    </TabWrapper>
  );
};

export default SoftTab;
