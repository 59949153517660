import axios from 'axios';

// axios.defaults.withCredentials = true;
const myAxios = axios.create({
	withCredentials: true,
	baseURL: 'https://omnitalk.io:3001',
	// baseURL: 'http://localhost:3001',
	headers: {
		// 'Access-Control-Allow-Origin' : '*',
		Https: true,
		'Content-Type': 'application/json',
	},
});
export default myAxios;
