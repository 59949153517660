import styled from 'styled-components';
import holdingPhone from '../../assets/main-hero-phone-lg.png';
import holdingPhoneWebp from '../../assets/main-hero-phone-webp.webp';

export default function Slider() {
	return (
		<>
			<SlideShow>
				<SlideshowSlider>
					<Slide>
						<picture>
							<source type="image/webp" srcSet={holdingPhoneWebp} />
							<source
								type="image/png"
								src={holdingPhone}
								srcSet={`${holdingPhone} 768w`}
								alt="holding-phone"
							/>
							<img
								src={holdingPhone}
								srcSet={`${holdingPhone} 768w`}
								alt="holding-phone"
							/>
						</picture>
					</Slide>
				</SlideshowSlider>
			</SlideShow>
		</>
	);
}
const SlideshowSlider = styled.div`
	white-space: nowrap;
	transition: ease 1000ms;
	img {
		width: 100%;
		height: 100%;
	}
	transition: transform 0.5s ease-in-out;
`;
const SlideShow = styled.div`
	overflow: hidden;
	width: 500px;
	max-width: 400px;
`;

const Slide = styled.div`
	display: inline-block;
	height: 400px;
	width: 100%;
	border-radius: 40px;
	transition: opacity 0.5s ease-in-out;
`;

//    <SlideShow>
// <SlideshowSlider
//   style={{ transform: `translate3d(${-index * 100}%, 0, 0)` }}
//   {images.map((images, index) => (
//     <Slide >
//       <img alt="holding-phone" src={holdingPhoneBg} />
//     </Slide>
//   ))} 
// </SlideshowSlider>
// </SlideShow>